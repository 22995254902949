import { getApiClient } from '../../../../../../services/api/apiClient';
import { CachedApiClientTransformer } from '../../../../../../services/api/CachedApiClientTransformer';
import { ResponseTransformer, UrlGenerator } from '../../../../../../services/api/types';
import FileModule from '../../../enums/FileModule.enum';
import { useFetchFiles } from '../../hooks/fetchFiles';
import { MemberFileDto, MemberFile } from '../../../entities/MemberFile';
import { mapMemberFileDto } from '../../../utils/mapper';

export interface MemberFileQueryArgs {
  module: FileModule;
}

const memberFileUrlGenerator: UrlGenerator<MemberFileQueryArgs> = ({ module }) => {
  switch (module) {
    case FileModule.Departure:
      return `Departure/Files/Member`;
    case FileModule.Arrival:
      return `Arrival/Files/Member`;
    case FileModule.Commercial:
      return `Commercial/Files/Member`;
    default:
      throw new Error(`The file module ${module} doesn't exist.`);
  }
};

const memberFilesResponseTransformer: ResponseTransformer<MemberFileQueryArgs, MemberFileDto[], MemberFile[]> = ({
  queryArgs: { module },
  data,
  error,
}) => {
  if (error !== undefined) return { error };
  return { data: data?.map((fileDto) => mapMemberFileDto(fileDto, module)) };
};

const generateMemberFileApiClient = () =>
  new CachedApiClientTransformer<MemberFileQueryArgs, MemberFileDto[], MemberFile[]>(
    memberFileUrlGenerator,
    getApiClient,
    memberFilesResponseTransformer,
  );

export function useFetchMemberFiles(queryArgsList?: MemberFileQueryArgs[]) {
  return useFetchFiles(generateMemberFileApiClient, queryArgsList);
}
