import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Report } from 'powerbi-client';

export interface ExecutiveState {
  currentReport: Report | null;
  currentPage?: string;
}

const initialState: ExecutiveState = {
  currentReport: null,
  currentPage: undefined,
};

const executiveSlice = createSlice({
  name: 'executive',
  initialState,
  reducers: {
    changeCurrentReport: (state, action: PayloadAction<Report>) => ({
      ...state,
      currentReport: action.payload,
    }),
    changeCurrentPage: (state, action: PayloadAction<string>) => ({
      ...state,
      currentPage: action.payload,
    }),
  },
});

export const { changeCurrentReport, changeCurrentPage } = executiveSlice.actions;

export default executiveSlice.reducer;
