import { ReactElement } from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Button from './Button';
import LoadingPromiseButton from './LoadingPromiseButton';

interface VoidOnClickAction {
  onClick: () => void;
  onClickType: 'void';
}
interface PromiseOnClickAction {
  onClick: () => Promise<void>;
  onClickType: 'promise';
}
type OnClickActions = PromiseOnClickAction | VoidOnClickAction;

export type BasicCardActionProps = OnClickActions & {
  title: string;
  tooltip?: string;
  variant?: 'text' | 'outlined' | 'contained';
  disabled?: boolean;
};

export default function BasicCardAction({
  title,
  tooltip,
  onClick,
  onClickType,
  variant = 'contained',
  disabled = false,
}: BasicCardActionProps): ReactElement {
  const actionButton =
    onClickType === 'promise' ? (
      <Box sx={{ textAlign: 'center' }}>
        <LoadingPromiseButton disabled={disabled} variant={variant} onClick={() => onClick() as Promise<void>}>
          {title}
        </LoadingPromiseButton>
      </Box>
    ) : (
      <CenteredButton disabled={disabled} variant={variant} onClick={onClick}>
        {title}
      </CenteredButton>
    );

  if (tooltip) {
    return (
      <Box display="flex" justifyContent="center">
        <Tooltip title={tooltip} placement="top">
          <Box display="inline-block">{actionButton}</Box>
        </Tooltip>
      </Box>
    );
  }

  return actionButton;
}

const CenteredButton = styled(Button)(() => ({
  display: 'block',
  margin: '0 auto',
}));
