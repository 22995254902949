import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PaymentIntentResult, SetupIntentResult, StripeError } from '@stripe/stripe-js';
import { PricingProduct } from '../../../modules/DDP/pricing/entities/PricingProduct';
import BillingInformation from './entities/BillingInformation';

interface State {
  isPurchaseDialogOpen: boolean;
  isFreeTrialDialogOpen: boolean;
  isUpgradeToFullPlanDialogOpen: boolean;
  isUpgradeToAnnualDialogOpen: boolean;
  isUpgradeToBundleDialogOpen: boolean;
  isFreeTrialInformationPopupOpen: boolean;
  isPurchaseForAccessDialogOpen: boolean;
  isFreeTrialForAccessDialogOpen: boolean;
  isFullPlanForAccessDialogOpen: boolean;
  currentStep: number;
  modifiedForm: boolean;
  currentReasonsForComing: number[];
  currentOtherReason: string;
  currentCompany: string;
  currentTitle: string;
  currentContactNumber: string;
  termsAndConditionsAccepted: boolean;
  selectedProduct: PricingProduct;
  paymentCardErrorMessage?: string;
  areBillingDetailsFilled: boolean;
  billingInformation?: BillingInformation;
  confirmCardPayment: (clientSecret: string) => Promise<PaymentIntentResult> | Promise<SetupIntentResult>;
  submitPaymentElement: () => Promise<{ error?: StripeError }>;
}

export const initialState: State = {
  isPurchaseDialogOpen: false,
  isFreeTrialDialogOpen: false,
  isUpgradeToFullPlanDialogOpen: false,
  isUpgradeToAnnualDialogOpen: false,
  isUpgradeToBundleDialogOpen: false,
  isFreeTrialInformationPopupOpen: false,
  isPurchaseForAccessDialogOpen: false,
  isFreeTrialForAccessDialogOpen: false,
  isFullPlanForAccessDialogOpen: false,
  currentStep: 0,
  modifiedForm: false,
  currentReasonsForComing: [],
  currentOtherReason: '',
  currentCompany: '',
  currentTitle: '',
  currentContactNumber: '',
  termsAndConditionsAccepted: false,
  selectedProduct: {} as PricingProduct,
  paymentCardErrorMessage: undefined,
  areBillingDetailsFilled: false,
  billingInformation: undefined,
  confirmCardPayment: () => Promise.resolve({} as PaymentIntentResult),
  submitPaymentElement: () => Promise.resolve({}),
};

// Actions
const purchaseSlice = createSlice({
  name: 'freeTrial',
  initialState,
  reducers: {
    openPurchaseDialog: (state, action: PayloadAction<PricingProduct>) => ({
      ...state,
      isPurchaseDialogOpen: true,
      selectedProduct: action.payload,
      currentStep: 0,
    }),
    openFreeTrialDialog: (state, action: PayloadAction<PricingProduct>) => ({
      ...state,
      isFreeTrialDialogOpen: true,
      selectedProduct: action.payload,
      currentStep: 0,
    }),
    openUpgradeToFullPlanDialog: (state, action: PayloadAction<PricingProduct>) => ({
      ...state,
      isUpgradeToFullPlanDialogOpen: true,
      selectedProduct: action.payload,
      currentStep: 0,
    }),
    openUpgradeToAnnualDialog: (state, action: PayloadAction<PricingProduct>) => ({
      ...state,
      isUpgradeToAnnualDialogOpen: true,
      selectedProduct: action.payload,
      currentStep: 0,
    }),
    openUpgradeToBundleDialog: (state, action: PayloadAction<PricingProduct>) => ({
      ...state,
      isUpgradeToBundleDialogOpen: true,
      selectedProduct: action.payload,
      currentStep: 0,
    }),
    openPurchaseForAccessDialog: (state) => ({
      ...state,
      isPurchaseForAccessDialogOpen: true,
      currentStep: 0,
    }),
    openFreeTrialForAccessDialog: (state, action: PayloadAction<PricingProduct>) => ({
      ...state,
      isFreeTrialForAccessDialogOpen: true,
      selectedProduct: action.payload,
      currentStep: 0,
    }),
    openFullPlanForAccessDialog: (state, action: PayloadAction<PricingProduct>) => ({
      ...state,
      isFullPlanForAccessDialogOpen: true,
      selectedProduct: action.payload,
      currentStep: 0,
    }),
    closeDialog: (state) => ({
      ...initialState,
      selectedProduct: state.selectedProduct,
      currentStep: state.currentStep,
    }),
    openFreeTrialInformationPopup: (state) => ({
      ...state,
      isFreeTrialInformationPopupOpen: true,
    }),
    closeFreeTrialInformationPopup: (state) => ({
      ...state,
      isFreeTrialInformationPopupOpen: false,
    }),
    changeActiveStep: (state, action: PayloadAction<number>) => ({
      ...state,
      currentStep: action.payload,
    }),
    changeReasonsForComing: (state, action: PayloadAction<number[]>) => ({
      ...state,
      currentReasonsForComing: action.payload,
    }),
    changeOtherReason: (state, action: PayloadAction<string>) => ({
      ...state,
      currentOtherReason: action.payload,
    }),
    changeModifiedForm: (state, action: PayloadAction<boolean>) => ({
      ...state,
      modifiedForm: action.payload,
    }),
    changeCompany: (state, action: PayloadAction<string>) => ({
      ...state,
      currentCompany: action.payload,
    }),
    changeTitle: (state, action: PayloadAction<string>) => ({
      ...state,
      currentTitle: action.payload,
    }),
    changeContactNumber: (state, action: PayloadAction<string>) => ({
      ...state,
      currentContactNumber: action.payload,
    }),
    changeTermsAndConditions: (state, action: PayloadAction<boolean>) => ({
      ...state,
      termsAndConditionsAccepted: action.payload,
    }),
    changeAreBillingDetailsFilled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      areBillingDetailsFilled: action.payload,
    }),
    changeBillingInformation: (state, action: PayloadAction<BillingInformation>) => ({
      ...state,
      billingInformation: action.payload,
    }),
    changePaymentCardErrorMessage: (state, action: PayloadAction<string | undefined>) => ({
      ...state,
      paymentCardErrorMessage: action.payload,
    }),
    changeConfirmCardPaymentCallback: (
      state,
      action: PayloadAction<(clientSecret: string) => Promise<PaymentIntentResult> | Promise<SetupIntentResult>>,
    ) => ({
      ...state,
      confirmCardPayment: action.payload,
    }),
    changeSubmitPaymentElementCallback: (state, action: PayloadAction<() => Promise<{ error?: StripeError }>>) => ({
      ...state,
      submitPaymentElement: action.payload,
    }),
  },
});

export const {
  openPurchaseDialog,
  openFreeTrialDialog,
  openUpgradeToFullPlanDialog,
  openUpgradeToAnnualDialog,
  openUpgradeToBundleDialog,
  openPurchaseForAccessDialog,
  openFreeTrialForAccessDialog,
  openFullPlanForAccessDialog,
  closeDialog,
  openFreeTrialInformationPopup,
  closeFreeTrialInformationPopup,
  changeActiveStep,
  changeModifiedForm,
  changeCompany,
  changeContactNumber,
  changeOtherReason,
  changeReasonsForComing,
  changeTitle,
  changeTermsAndConditions,
  changeAreBillingDetailsFilled,
  changeBillingInformation,
  changePaymentCardErrorMessage,
  changeConfirmCardPaymentCallback,
  changeSubmitPaymentElementCallback,
} = purchaseSlice.actions;

export default purchaseSlice.reducer;
