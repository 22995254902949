import { useCallback, useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import AutoComplete, { AutoCompleteOption } from '../../../../common/components/atoms/AutoComplete';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { useRegionFilterQuery } from '../../../../common/hooks/backend';
import { useAppDispatch } from '../../../../store';
import { setSelectedAggregations } from '../analystSlice';
import RegionDto from '../entities/RegionDto';
import { FilterLevels } from '../enums/filterLevels.enum';
import { useAggregationFilters } from '../hooks/useAggregationFilters';

interface SubregionFiltersProps {
  value: string[];
  onValueChange: (newValue: string[]) => void;
}

export default function SubregionFilters(props: SubregionFiltersProps) {
  const { value, onValueChange } = props;

  const { data: filters = [], isLoading } = useRegionFilterQuery();

  const { t } = useTranslation('Analyst');

  const [selectedOptions, setSelectedOptions] = useState<AutoCompleteOption[]>([]);
  const { fullSelectedRegions, getClosestFilters } = useAggregationFilters();
  const dispatch = useAppDispatch();

  const closestFilters = getClosestFilters(FilterLevels.Subregion);

  const filteredOptions = useMemo(
    () =>
      closestFilters.closestFilterLevels.includes(FilterLevels.Region)
        ? filters?.filter((r) => closestFilters.regionCodes?.includes(r.parentRegionCode))
        : filters,
    [filters, closestFilters],
  );

  const changeSelectedOptions = (newvalue: AutoCompleteOption[]) => {
    setSelectedOptions(newvalue);
    onValueChange([
      ...value.filter(
        (val) =>
          !filters.filter((region) => region.parentRegionCode !== null).find((filter) => val === filter.regionCode),
      ),
      ...newvalue.map((option) => option.key),
    ]);
  };

  const toAutoCompleteOptions = useCallback(
    (regions: RegionDto[]) =>
      regions
        .filter((region) => region.parentRegionCode !== null)
        .map((region) => ({
          label: region.regionName,
          key: region.regionCode,
        })),
    [],
  );

  useEffect(() => {
    if (filters.length > 0) {
      setSelectedOptions(toAutoCompleteOptions(filters.filter((filter) => value.includes(filter.regionCode))));
    }
  }, [filters, toAutoCompleteOptions, value]);

  useEffect(() => {
    const newSubregions: AutoCompleteOption[] = selectedOptions.filter((o) =>
      filteredOptions.map((f) => f.regionCode).includes(o.key),
    );

    if (newSubregions.length !== selectedOptions.length) {
      dispatch(
        setSelectedAggregations({
          key: FilterLevels.Subregion,
          value: newSubregions.map((sr) => sr.key).concat(fullSelectedRegions.map((r) => r.regionCode)),
        }),
      );
    }
  }, [selectedOptions, dispatch, filteredOptions, fullSelectedRegions]);

  return (
    <Grid item width="20%">
      <AutoComplete
        loading={isLoading}
        options={toAutoCompleteOptions(filteredOptions)}
        width="100%"
        label={t('Subregion', { count: 2 })}
        value={selectedOptions}
        onChange={changeSelectedOptions}
        fullStoryTag="AnalystAggregationLocationSubregion"
        icon={<FontAwesomeSvgIcon icon={faAngleDown} />}
      />
    </Grid>
  );
}
