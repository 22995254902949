import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBarTitlePortal } from '../../../../common/components/atoms/AppBarTitlePortal';
import ModuleUpselling from '../../../../common/components/molecules/ModuleUpselling';
import { TrainingModule } from '../../asqModules';

export default function TrainingContainer(): ReactElement {
  const { t } = useTranslation('Training');

  return (
    <>
      <AppBarTitlePortal>
        <h4>{t('Title')}</h4>
      </AppBarTitlePortal>
      <ModuleUpselling module={TrainingModule} displayActions={false} />
    </>
  );
}
