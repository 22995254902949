import { ReactElement } from 'react';

import { styled } from '@mui/material/styles';
import MuiTextField, { TextFieldProps } from '@mui/material/TextField';

export default function TextField(props: TextFieldProps): ReactElement {
  return <StyledTextField {...(props as any)} />;
}

const StyledTextField = styled(MuiTextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    minWidth: '100px',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: '#f1f2f2',
  },
  '.MuiOutlinedInput-root:not(.Mui-error, .Mui-disabled)': {
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    color: theme.palette.primaryText[100],
  },
}));
