import { ReactElement } from 'react';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { useDrawerOpen } from '../../../../common/components/context/DrawerContext';
import { ModuleAccess } from '../../../../common/modules/purchase/entities/ModuleAccess';
import { ddpModules } from '../../ddpModules';
import Tile from './Tile';

interface Props {
  accesses: ModuleAccess[] | undefined;
}

export default function TileGrid({ accesses }: Props): ReactElement {
  const { drawerOpen } = useDrawerOpen();

  return (
    <>
      <Container maxWidth="lg" disableGutters>
      <Grid container spacing={4} mt={1} role="list" alignItems="stretch">
          {ddpModules.map((module) => (
            <Grid
              item
              xs={12}
              md={drawerOpen ? 12 : 6}
              lg={6}
              key={module.name}
              role="listitem"
              container
              justifyContent="center"
            >
              <Tile access={accesses?.find(a => a.moduleKey === module.moduleKey)} module={module} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
