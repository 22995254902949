import { RouteDefinition } from '../core/entities/routeDefinition';
import TermsOfSale from '../modules/common/termsOfSale/pages/TermsOfSale';
import TermsOfUse from '../modules/common/termsOfUse/pages/TermsOfUse';
import { AppUrls } from './urls';

const TermsOfUseRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Terms of Use',
  path: AppUrls.TermsOfUse,
  Component: () => <TermsOfUse />,
};

const TermsOfSaleRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Terms of Sale',
  path: AppUrls.TermsOfSale,
  Component: () => <TermsOfSale />,
};

const appRoutes: RouteDefinition[] = [TermsOfUseRoute, TermsOfSaleRoute];

export default appRoutes;
