import { ReactElement } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import MuiPaper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { Trans, useTranslation } from 'react-i18next';
import Chips from '../../../../common/components/atoms/Chips';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import {
  useAirportFilterQuery,
  useCountryFilterQuery,
  useCountryGroupFilterQuery,
  useRegionFilterQuery,
} from '../../../../common/hooks/backend';
import { useAppDispatch } from '../../../../store';
import { setSelectedAggregations } from '../analystSlice';
import { FilterLevels } from '../enums/filterLevels.enum';

export interface ChipsContainerProps {
  countries: string[];
  airports: string[];
  regions: string[];
  countryGroups: string[];
}

interface ChipsData {
  label: string;
  key: string;
}

export default function ChipsContainer(props: ChipsContainerProps): ReactElement {
  const { countries, airports, regions, countryGroups } = props;

  const dispatch = useAppDispatch();
  const { t } = useTranslation('Analyst');
  const { data: countriesData = [], isLoading: countriesLoad } = useCountryFilterQuery();
  const { data: regionData = [], isLoading: regoinsLoad } = useRegionFilterQuery();
  const { data: countryGroupData = [], isLoading: countrtGroupLoad } = useCountryGroupFilterQuery();
  const { data: airportsData = [], isLoading: airportsLoad } = useAirportFilterQuery();

  const allChips = [...countries, ...airports, ...regions, ...countryGroups];
  const anyFilterLoading = countriesLoad || regoinsLoad || countrtGroupLoad || airportsLoad;

  const handleDelete = (chipToDelete: ChipsData, level: FilterLevels) => () => {
    let currentArray: string[] = [];
    if (level === FilterLevels.Airport) {
      currentArray = airports;
    }
    if (level === FilterLevels.Region) {
      currentArray = regions;
    }
    if (level === FilterLevels.Country) {
      currentArray = countries;
    }
    if (level === FilterLevels.CountryGroup) {
      currentArray = countryGroups;
    }
    dispatch(
      setSelectedAggregations({
        key: level,
        value: currentArray.filter((item) => item !== chipToDelete.key),
      }),
    );
  };

  const toChipsData = (array: string[], data: any[], key: string, label: string) =>
    data.filter((x) => array.includes(x[key])).map((x) => ({ label: x[label], key: x[key] }));

  if (allChips.length > 0 && anyFilterLoading) {
    return (
      <ChipsList variant="outlined" square sx={{ position: 'relative' }}>
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }}
          variant="indeterminate"
        />
      </ChipsList>
    );
  }

  if (allChips.length === 0) {
    return (
      <ChipsList variant="outlined" square sx={{ position: 'relative' }}>
        <StyledTypography variant="pMedium">
          <Trans t={t}>EmptyMessage</Trans>
        </StyledTypography>
      </ChipsList>
    );
  }

  return (
    <ChipsList variant="outlined" square>
      {toChipsData(regions, regionData, 'regionCode', 'regionName').map((region) => (
        <StyledChips
          sx={{ mx: 1, mb: 2 }}
          key={region.key}
          label={<Typography variant="pMedium">{region.label}</Typography>}
          onDelete={handleDelete(region, FilterLevels.Region)}
          deleteIcon={<FontAwesomeSvgIcon fontSize="inherit" icon={faTimes} />}
        />
      ))}
      {toChipsData(countryGroups, countryGroupData, 'countryGroupCode', 'countryGroupName').map((countryGroup) => (
        <StyledChips
          sx={{ mx: 1, mb: 2 }}
          key={countryGroup.key}
          label={<Typography variant="pMedium">{countryGroup.label}</Typography>}
          onDelete={handleDelete(countryGroup, FilterLevels.CountryGroup)}
          deleteIcon={<FontAwesomeSvgIcon fontSize="inherit" icon={faTimes} />}
        />
      ))}
      {toChipsData(countries, countriesData, 'countryCode', 'countryName').map((country) => (
        <StyledChips
          sx={{ mx: 1, mb: 2 }}
          key={country.key}
          label={<Typography variant="pMedium">{country.label}</Typography>}
          onDelete={handleDelete(country, FilterLevels.Country)}
          deleteIcon={<FontAwesomeSvgIcon fontSize="inherit" color="inherit" sx={{ color: 'white' }} icon={faTimes} />}
        />
      ))}
      {toChipsData(airports, airportsData, 'airportCode', 'airportName').map((airport) => (
        <StyledChips
          sx={{ mx: 1, mb: 2 }}
          key={airport.key}
          label={<Typography variant="pMedium">{airport.label}</Typography>}
          onDelete={handleDelete(airport, FilterLevels.Airport)}
          deleteIcon={<FontAwesomeSvgIcon fontSize="inherit" icon={faTimes} />}
        />
      ))}
    </ChipsList>
  );
}

const ChipsList = styled(MuiPaper)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  height: '300px',
  padding: theme.spacing(3, 2),
  overflow: 'auto',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  color: theme.palette.primaryText[100],
  textAlign: 'center',
}));

const StyledChips = styled(Chips)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '& .MuiTypography-root': {
    color: 'white',
  },
  '& .MuiSvgIcon-root': {
    color: 'white',
  },
}));
