import { Stack } from '@mui/material';
import { compareDesc, parseISO } from 'date-fns';
import Scrollbars from 'react-custom-scrollbars-2';
import { TransformedFileDto } from '../entities/File';
import FileCard from './FileCard';

type FileCarouselProps = { files: TransformedFileDto[]; dense?: boolean };

export default function FilesCarousel({ files, dense = false }: FileCarouselProps) {
  return (
    <Scrollbars autoHeight autoHeightMin={dense ? 315 : 415} style={{ width: '74vw' }}>
      <Stack direction="row" spacing={4}>
        {files
          .sort(
            (a, b) =>
              a.accessRequirements - b.accessRequirements ||
              compareDesc(parseISO(a.publishedDate), parseISO(b.publishedDate)),
          )
          .map((file) => (
            <FileCard dense={dense} key={file.id} file={file} />
          ))}
      </Stack>
    </Scrollbars>
  );
}
