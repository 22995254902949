import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Platform } from '../enums/platform.enum';
import { PlatformState, setCurrentPlatform } from './platformSlice';
import { getHomeLocationFromPlatform } from './utils';

function usePlatform(): PlatformState {
  return useAppSelector((state) => state.platform);
}

function useSetCurrentPlatform(): [Platform, (newPlatform: Platform) => void] {
  const { currentPlatform } = usePlatform();

  const dispatch = useAppDispatch();

  const setCurrentPlatformCallback = useCallback(
    (platform: Platform) => {
      dispatch(setCurrentPlatform(platform));
    },
    [dispatch],
  );

  return [currentPlatform, setCurrentPlatformCallback];
}

function useIsCurrentPlatform(platform: Platform): boolean {
  const { currentPlatform } = usePlatform();
  return currentPlatform === platform;
}

function useCurrentPlatformHomeUrl(): string {
  const { currentPlatform } = usePlatform();
  return getHomeLocationFromPlatform(currentPlatform);
}

function useRedirectToPlatformHome(): (replace?: boolean) => void {
  const history = useHistory();
  const platformHomeUrl = useCurrentPlatformHomeUrl();

  return useCallback(
    (replace = false) => {
      if (replace) history.replace(platformHomeUrl);
      else history.push(platformHomeUrl);
    },
    [history, platformHomeUrl],
  );
}

export {
  usePlatform,
  useSetCurrentPlatform,
  useIsCurrentPlatform,
  useCurrentPlatformHomeUrl,
  useRedirectToPlatformHome,
};
