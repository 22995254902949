import { useMsal } from '@azure/msal-react';
import { faDollarSign, faQuestionCircle, faSignOut } from '@fortawesome/pro-light-svg-icons';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { CSSObject, Theme, styled } from '@mui/material/styles';
import React from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useHistory, useLocation } from 'react-router-dom';
import { Platform } from '../../../core/enums/platform.enum';
import { FeatureFlagKey } from '../../../core/featureFlags/featureFlagKeys';
import { useFeatureFlag, useFeatureFlagPlatform } from '../../../core/featureFlags/hooks';
import { DdpPlatformFeature } from '../../../core/platform/PlatformFeature';
import { useSetCurrentPlatform } from '../../../core/platform/hooks';
import { getHomeLocationFromPlatform } from '../../../core/platform/utils';
import { useIsAsqPowerBiPage } from '../../../core/powerBi/hooks';
import { DdpUrls } from '../../../modules/DDP/urls';
import { useIsMobile } from '../../hooks/hooks';
import { useNavItemsForPlatform } from '../../hooks/navItem';
import { NavItemProps, NavItemType } from '../../types/NavItem';
import DrawerHeader from '../atoms/DrawerHeader';
import DrawerLink from '../atoms/DrawerLink';
import { FontAwesomeSvgIcon } from '../atoms/FontAwesomeIcon';
import PlatformSelect from '../atoms/PlatformSelect';
import { useDrawerOpen } from '../context/DrawerContext';
import PowerBiFiltersDisplay from './PowerBiFiltersDisplay';

export const drawerWidth = 260;

type NavItemPropsWithSelected = Omit<NavItemProps, 'selected'> & {
  selected: boolean | ((pathname: string) => boolean);
};

const SignOutNavItem: NavItemPropsWithSelected = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faSignOut} />,
  translateKey: 'SignOut',
  url: '',
  selected: false,
  type: NavItemType.Link,
};

const PricingNavItem: NavItemPropsWithSelected = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faDollarSign} />,
  translateKey: 'Pricing',
  url: DdpUrls.Pricing,
  selected: (pathname) => pathname === DdpUrls.Pricing,
  type: NavItemType.Link,
};

const FaqNavItem: NavItemPropsWithSelected = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faQuestionCircle} />,
  translateKey: 'FAQ',
  url: DdpUrls.Faq,
  selected: (pathname) => pathname === DdpUrls.Faq,
  type: NavItemType.Link,
};

const mapNavItemToNavItemProps = (navItem: NavItemPropsWithSelected, pathname: string): NavItemProps => {
  const { selected, ...rest } = navItem;
  return {
    ...rest,
    selected: typeof selected === 'function' ? selected(pathname) : selected,
  };
};

function LeftNav() {
  const isMobile = useIsMobile();
  const { drawerOpen, setDrawerOpen } = useDrawerOpen();
  const { instance } = useMsal();
  const history = useHistory();
  const { pathname } = useLocation();
  const asqEnabled = useFeatureFlagPlatform(Platform.ASQ);
  const asqPreReleaseBannerEnabled = useFeatureFlag(FeatureFlagKey.PreReleaseBanner);
  const [currentPlatform, setCurrentPlatform] = useSetCurrentPlatform();
  const navItems = useNavItemsForPlatform();
  const isAsqPowerBiPage = useIsAsqPowerBiPage();
  const shouldShowPowerBiFilters = asqEnabled && drawerOpen && currentPlatform === Platform.ASQ && isAsqPowerBiPage;

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const logoutClicked = () => {
    linkClicked();
    instance.logoutRedirect();
  };

  const linkClicked = () => {
    if (isMobile) {
      handleDrawerClose();
    }
  };

  const handlePlatformChange = (platform: Platform) => {
    if (!asqEnabled && !asqPreReleaseBannerEnabled) return;

    linkClicked();
    setCurrentPlatform(platform);
    history.push(getHomeLocationFromPlatform(platform));
  };

  return (
    <StyledDrawer
      variant={isMobile ? 'temporary' : 'permanent'}
      open={drawerOpen}
      role="navigation"
      PaperProps={{ style: { borderRight: 'unset' } }}
    >
      <DrawerHeader onCollapseClick={handleDrawerClose} onExpandClick={handleDrawerOpen} />
      {asqEnabled && drawerOpen && (
        <StyledPlatformSelectContainer>
          <PlatformSelect onChange={handlePlatformChange} />
        </StyledPlatformSelectContainer>
      )}
      <Scrollbars autoHide hideTracksWhenNotNeeded>
        <StyledList disablePadding>
          {navItems.map((navItem) => (
            <React.Fragment key={navItem.translateKey}>
              <DrawerLink
                aria-expanded={drawerOpen}
                navItem={navItem}
                drawerExpanded={isMobile || drawerOpen}
                onClick={linkClicked}
                variant="large"
              />
              <Divider sx={{ borderColor: 'white', opacity: 0.5 }} />
            </React.Fragment>
          ))}
        </StyledList>
      </Scrollbars>
      {shouldShowPowerBiFilters && <PowerBiFiltersDisplay />}
      <StyledList disablePadding style={{ justifySelf: 'end', flex: 1 }}>
        <DdpPlatformFeature>
          <>
            <DrawerLink
              aria-expanded={drawerOpen}
              navItem={mapNavItemToNavItemProps(PricingNavItem, pathname)}
              drawerExpanded={isMobile || drawerOpen}
              onClick={linkClicked}
              variant="small"
            />
            <DrawerLink
              aria-expanded={drawerOpen}
              navItem={mapNavItemToNavItemProps(FaqNavItem, pathname)}
              drawerExpanded={isMobile || drawerOpen}
              onClick={linkClicked}
              variant="small"
            />
          </>
        </DdpPlatformFeature>
        <DrawerLink
          aria-expanded={drawerOpen}
          navItem={mapNavItemToNavItemProps(SignOutNavItem, pathname)}
          drawerExpanded={isMobile || drawerOpen}
          onClick={logoutClicked}
          variant="small"
        />
      </StyledList>
    </StyledDrawer>
  );
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(9),
});

const StyledDrawer = styled(MuiDrawer)(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': {
    background: theme.palette.primary.main,
  },
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .MuiListItemText-root': {
        display: 'none',
        visibility: 'hidden',
      },
    }),
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDrawer-paper': {
      width: '100%',
    },
  },
}));

const StyledList = styled(List)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledPlatformSelectContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
  '& .MuiSelect-root': {
    backgroundColor: 'white',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0,0,0,0.23)',
      borderWidth: '1px',
    },
  },
}));

export default LeftNav;
