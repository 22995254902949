import { ReactElement } from 'react';

import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StatisticDto } from '../../../../core/entities/statisticDto';
import StatisticWithVariation from './StatisticWithVariation';
import TilePeriod from './TilePeriod';

interface TileStatisticProps {
  statistic?: StatisticDto;
  isLoading: boolean;
}

export default function TileStatistic({ statistic, isLoading }: TileStatisticProps): ReactElement {
  const { t } = useTranslation('Home', { keyPrefix: 'ASQ' });

  const hasData = statistic?.date !== null;

  return (
    <Box my={1}>
      <Box mb={2}>
        <TilePeriod date={statistic?.date} isLoading={isLoading} />
      </Box>
      {(isLoading || hasData) && (
        <Stack direction="row" justifyContent="space-evenly">
          <StatisticWithVariation
            title={t('Cards.Experience')}
            value={statistic?.experience}
            variation={statistic?.experienceVariation}
            variationDate={statistic?.variationDate}
            isLoading={isLoading}
          />
          <StatisticWithVariation
            title={t('Cards.Satisfaction')}
            value={statistic?.satisfaction}
            variation={statistic?.satisfactionVariation}
            variationDate={statistic?.variationDate}
            isLoading={isLoading}
          />
        </Stack>
      )}
    </Box>
  );
}
