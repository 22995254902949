import { useMsal } from '@azure/msal-react';
import { faSignOut } from '@fortawesome/pro-light-svg-icons';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { CSSObject, Theme, styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { Platform } from '../../../core/enums/platform.enum';
import { FeatureFlagKey } from '../../../core/featureFlags/featureFlagKeys';
import { useFeatureFlag, useFeatureFlagPlatform } from '../../../core/featureFlags/hooks';
import { useSetCurrentPlatform } from '../../../core/platform/hooks';
import { getHomeLocationFromPlatform } from '../../../core/platform/utils';
import { useIsAsqPowerBiPage } from '../../../core/powerBi/hooks';
import { useIsMobile } from '../../hooks/hooks';
import { useNavItemsForPlatform } from '../../hooks/navItem';
import { NavItemProps, NavItemType } from '../../types/NavItem';
import ASQDrawerLink from '../atoms/ASQDrawerLink';
import DrawerHeader from '../atoms/DrawerHeader';
import { FontAwesomeSvgIcon } from '../atoms/FontAwesomeIcon';
import PlatformSelect from '../atoms/PlatformSelect';
import { useDrawerOpen } from '../context/DrawerContext';
import PowerBiFiltersDisplay from './PowerBiFiltersDisplay';

export const drawerWidth = 240;

const SignOutNavItem: NavItemProps = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faSignOut} />,
  translateKey: 'SignOut',
  url: '',
  selected: false,
  type: NavItemType.Link,
};

function ASQLeftNav() {
  const isMobile = useIsMobile();
  const { drawerOpen, setDrawerOpen } = useDrawerOpen();
  const { instance } = useMsal();
  const history = useHistory();
  const asqEnabled = useFeatureFlagPlatform(Platform.ASQ);
  const asqPreReleaseBannerEnabled = useFeatureFlag(FeatureFlagKey.PreReleaseBanner);
  const [currentPlatform, setCurrentPlatform] = useSetCurrentPlatform();
  const navItems = useNavItemsForPlatform();
  const isAsqPowerBiPage = useIsAsqPowerBiPage();
  const shouldShowPowerBiFilters = asqEnabled && drawerOpen && currentPlatform === Platform.ASQ && isAsqPowerBiPage;

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const logoutClicked = () => {
    linkClicked();
    instance.logoutRedirect();
  };

  const linkClicked = () => {
    if (isMobile) {
      handleDrawerClose();
    }
  };

  const handlePlatformChange = (platform: Platform) => {
    if (!asqEnabled && !asqPreReleaseBannerEnabled) return;

    linkClicked();
    setCurrentPlatform(platform);
    history.push(getHomeLocationFromPlatform(platform));
  };

  return (
    <Drawer variant={isMobile ? 'temporary' : 'permanent'} open={drawerOpen} role="navigation">
      <DrawerHeader onCollapseClick={handleDrawerClose} onExpandClick={handleDrawerOpen} />
      {asqEnabled && drawerOpen && (
        <Box mx={2} mt={2} mb={3}>
          <PlatformSelect onChange={handlePlatformChange} />
        </Box>
      )}
      <StyledList>
        {navItems.map((navItem) => (
          <ASQDrawerLink
            aria-expanded={drawerOpen}
            navItem={navItem}
            expanded={isMobile || drawerOpen}
            onClick={linkClicked}
            key={navItem.translateKey}
          />
        ))}
      </StyledList>
      {shouldShowPowerBiFilters && <PowerBiFiltersDisplay />}
      <StyledList style={{ justifySelf: 'end', flex: 1 }}>
        <ASQDrawerLink
          aria-expanded={drawerOpen}
          navItem={SignOutNavItem}
          expanded={isMobile || drawerOpen}
          onClick={logoutClicked}
        />
      </StyledList>
    </Drawer>
  );
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: theme.spacing(9),
});

const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  [theme.breakpoints.up('md')]: {
    width: drawerWidth,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .MuiListItemText-root': {
        display: 'none',
        visibility: 'hidden',
      },
    }),
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDrawer-paper': {
      width: '100%',
    },
  },
}));

const StyledList = styled(List)(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

export default ASQLeftNav;
