import { ReactElement } from 'react';

import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { Box, FormControlLabel, Radio, RadioGroup, Stack, Tooltip, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import OptionButton from '../../../../common/components/atoms/OptionButton';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { changeExportColumn } from '../analystSlice';
import ColumnValuesList from '../atoms/ColumnValuesList';
import NoAccessAlert from '../atoms/NoAccessAlert';
import ExtractColumns from '../enums/extractColumns.enum';

export default function ReshapeData(): ReactElement {
  const { t } = useTranslation('Analyst');
  const dispatch = useAppDispatch();
  const { columnBy } = useAppSelector((state) => state.analyst.currentTemplate);

  const handleExtractColumnType = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeExportColumn(+event.target.value as ExtractColumns));
  };

  const handleReshapeDataInfoClick = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}faq#question-7`, '_blank')?.focus();
  };

  const reportExamples: Map<ExtractColumns, Map<string, string>> = new Map([
    [
      ExtractColumns.Metrics,
      new Map([
        ['Region', 'Europe'],
        ['Subregion', 'Eastern Europe'],
        ['Country', 'Poland'],
        ['Airport', 'Katowice'],
        ['Code', 'KTW'],
        ['Period', 'Nov-23'],
        ['Metric (Aircraft Movements, Commercial Passengers, or Cargo)', '2706'],
      ]),
    ],
    [
      ExtractColumns.Time,
      new Map([
        ['Region', 'Europe'],
        ['Subregion', 'Eastern Europe'],
        ['Country', 'Poland'],
        ['Airport', 'Katowice'],
        ['Code', 'KTW'],
        ['Period', 'Nov-23'],
        ['Metric', 'Total Air Transport Movements'],
        ['Time (Jan-2023, Feb-2023, etc.)', '2706'],
      ]),
    ],
    [
      ExtractColumns.Airport,
      new Map([
        ['Period', 'Nov-23'],
        ['Metric', 'Total Air Transport Movements'],
        ['Airport Code (YYZ, YUL, KTW, etc.)', '2706'],
      ]),
    ],
    [
      ExtractColumns.None,
      new Map([
        ['Region', 'Europe'],
        ['Subregion', 'Eastern Europe'],
        ['Country', 'Poland'],
        ['Airport', 'Katowice'],
        ['Code', 'KTW'],
        ['Period', 'Nov-23'],
        ['Metric', 'Total Air Transport Movements'],
        ['Value', '2417'],
      ]),
    ],
  ]);

  return (
    <Box>
      <Stack direction="row" mt={1}>
        <Typography mt={2} variant="h6">
          {t('ExtractData')}
        </Typography>
        <Tooltip title={<Trans t={t}>ReshapeDataSampleToolTip</Trans>} placement="top-start">
          <OptionButton
            sx={{ mx: 1, mt: 1.2 }}
            onClick={handleReshapeDataInfoClick}
            data-fullstory="AnalystReshapeDataToolTip"
          >
            <FontAwesomeSvgIcon sx={{ fontSize: 19 }} icon={faInfoCircle} />
          </OptionButton>
        </Tooltip>
      </Stack>
      <Typography pb={2} variant="pSmall">
        {t('ExtractDataDescription')}
      </Typography>
      <Typography variant="pXSmall" color="#77797E">
        {t('AsColumns').toUpperCase()}
      </Typography>
      <RadioGroup
        row
        sx={{ marginBottom: 2 }}
        value={columnBy}
        aria-label="extract"
        name="extract"
        onChange={handleExtractColumnType}
      >
        <FormControlLabel value={ExtractColumns.Metrics} control={<Radio />} label={t('Metrics')} />
        <FormControlLabel value={ExtractColumns.Time} control={<Radio />} label={t('Time')} />
        <FormControlLabel value={ExtractColumns.Airport} control={<Radio />} label={t('Airport')} />
        <FormControlLabel value={ExtractColumns.None} control={<Radio />} label={t('None')} />
      </RadioGroup>
      <Box
        marginRight={3}
        padding={3}
        display="block"
        sx={{ backgroundColor: 'white', border: '1px solid', borderColor: 'primary.main', width: 'fit-content' }}
      >
        <Typography variant="h6">{t('OutputFormatPreview')}</Typography>
        <ColumnValuesList information={reportExamples.get(columnBy)!} />
      </Box>
      <Box sx={{ paddingRight: 3, marginTop: 2 }}>
        <NoAccessAlert isTemplateSavable />
      </Box>
    </Box>
  );
}
