import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SlicersAndFilters {
  primaryFilters: DisplayFilters[];
  secondaryFilters: DisplayFilters[];
}

export interface DisplayFilters {
  displayName: string;
  values: string[];
}

export const initialState: SlicersAndFilters = {primaryFilters: [], secondaryFilters: []};

// Actions
const powerBiFiltersSlice = createSlice({
  name: 'slicersAndFilters',
  initialState,
  reducers: {
    setCurrentPowerBiFilters: (_, { payload }: PayloadAction<SlicersAndFilters>) => ({
        ...payload,
    }),
  },
});

export const { setCurrentPowerBiFilters } = powerBiFiltersSlice.actions;

export default powerBiFiltersSlice.reducer;
