import { getApiClient } from '../../../../../../services/api/apiClient';
import { CachedApiClientTransformer } from '../../../../../../services/api/CachedApiClientTransformer';
import { ResponseTransformer, UrlGenerator } from '../../../../../../services/api/types';
import { useFetchFiles } from '../../hooks/fetchFiles';
import { PublicFileDto, PublicFile } from '../../../entities/PublicFile';
import { mapPublicFileDto } from '../../../utils/mapper';

// there is only one url for the public files so we have to "trick" the fetcing system (because we made it generic)

type PublicFileQueryArgs = null;

const queryArgsList: PublicFileQueryArgs[] = [null];

const publicFileUrl = 'Files/Asq/PublicMember';

const publicFileUrlGenerator: UrlGenerator<PublicFileQueryArgs> = () => publicFileUrl;

const publicFilesResponseTransformer: ResponseTransformer<PublicFileQueryArgs, PublicFileDto[], PublicFile[]> = ({
  data,
  error,
}) => {
  if (error !== undefined) return { error };
  return { data: data?.map((fileDto) => mapPublicFileDto(fileDto)) };
};

const generatePublicFileApiClient = () =>
  new CachedApiClientTransformer<PublicFileQueryArgs, PublicFileDto[], PublicFile[]>(
    publicFileUrlGenerator,
    getApiClient,
    publicFilesResponseTransformer,
  );

export function useFetchPublicFiles() {
  return useFetchFiles(generatePublicFileApiClient, queryArgsList);
}
