import { RouteDefinition } from '../../core/entities/routeDefinition';
import { FeatureFlagKey } from '../../core/featureFlags/featureFlagKeys';
import ArrivalContainer from './arrival/container/ArrivalContainer';
import CommercialContainer from './commercial/container/CommercialContainer';
import DepartureContainer from './departure/container/DepartureContainer';
import { DepartureDissatisfiedPassengerDashboardsRouterPath } from './departure/dashboards/dissatisfiedPassenger';
import { DepartureCommentsAnalysisDashboardsRouterPath } from './departure/dashboards/commentsAnalysis';
import HomeContainer from './home/containers/HomeContainer';
import RepositoryContainer from './repository/container/RepositoryContainer';
import TrainingContainer from './training/container/TrainingContainer';
import {
  AsqUrls,
  ArrivalDashboardsRouterPath,
  CommercialDashboardsRouterPath,
  DepartureDashboardsRouterPath,
  RepositoryRouterPath,
} from './urls';

const HomeRoute: RouteDefinition = {
  title: 'ACI ASQ Hub | Home',
  path: AsqUrls.Home,
  Component: HomeContainer,
};

const DepartureModuleRoute: RouteDefinition = {
  title: 'ACI ASQ Hub | Departure',
  path: DepartureDashboardsRouterPath,
  Component: DepartureContainer,
};

const DepartureCommentsAnalysisModuleRoute: RouteDefinition = {
  title: 'ACI ASQ Hub | Departure',
  path: DepartureCommentsAnalysisDashboardsRouterPath,
  Component: DepartureContainer,
};

const DepartureDissatisfiedPassengerModuleRoute: RouteDefinition = {
  title: 'ACI ASQ Hub | Departure',
  path: DepartureDissatisfiedPassengerDashboardsRouterPath,
  Component: DepartureContainer,
  featureFlagKey: FeatureFlagKey.DppReports,
};

const ArrivalModuleRoute: RouteDefinition = {
  title: 'ACI ASQ Hub | Arrival',
  path: ArrivalDashboardsRouterPath,
  Component: ArrivalContainer,
};

const CommercialModuleRoute: RouteDefinition = {
  title: 'ACI ASQ Hub | Commercial',
  path: CommercialDashboardsRouterPath,
  Component: CommercialContainer,
};

const RepositoryModuleRoute: RouteDefinition = {
  title: 'ACI ASQ Hub | Repository',
  path: RepositoryRouterPath,
  Component: RepositoryContainer,
};

const TrainingModuleRoute: RouteDefinition = {
  title: 'ACI ASQ Hub | Training',
  path: AsqUrls.TrainingModule,
  Component: TrainingContainer,
  featureFlagKey: FeatureFlagKey.TrainingModule,
};

const routes: RouteDefinition[] = [
  HomeRoute,
  DepartureModuleRoute,
  DepartureCommentsAnalysisModuleRoute,
  DepartureDissatisfiedPassengerModuleRoute,
  ArrivalModuleRoute,
  CommercialModuleRoute,
  RepositoryModuleRoute,
  TrainingModuleRoute,
];

export default routes;
