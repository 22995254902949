import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatPriceWithTwoOrNoDecimals } from '../../../../common/utils/priceHelper';
import { ProductRecurringInterval } from '../enums/ProductReccuringInterval';

interface PriceRecurringIntervalLabelProps {
  unitPrice: number;
  recurringInterval: ProductRecurringInterval;
}

const PriceRecurringIntervalLabel = ({ unitPrice, recurringInterval }: PriceRecurringIntervalLabelProps) => {
  const { t } = useTranslation('Pricing');

  const formattedPrice = formatPriceWithTwoOrNoDecimals(unitPrice);

  const recurringIntervalLabel = () => {
    if (recurringInterval === ProductRecurringInterval.None) {
      return (
        <Typography minHeight={50} pt={2} variant="subtitle1" fontWeight="bold">
          {t('NoReccuringBilling')}
        </Typography>
      );
    }

    const recurringBillingText =
      recurringInterval === ProductRecurringInterval.Monthly ? t('MonthlyBilling') : t('YearlyBilling');

    return (
      <Typography minHeight={50} color="primary">
        {recurringBillingText}
      </Typography>
    );
  };

  return (
    <Box>
      <Typography color="primary" variant="pXLarge">
        {t('Price', { price: formattedPrice })}
      </Typography>
      {recurringIntervalLabel()}
    </Box>
  );
};

export default PriceRecurringIntervalLabel;
