import { useCallback, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { cancellablePromise } from '../../../../../common/utils/promise';

import { displaySnackbar } from '../../../../../core/notifications/snackbarSlice';
import { ICachedApiClientTransformer } from '../../../../../services/api/types';
import { useAppDispatch } from '../../../../../store';
import BaseFile from '../../entities/BaseFile';

interface FetchFilesHookReturnType<File extends BaseFile> {
  files: File[] | undefined;
  isLoading: boolean;
}

export function useFetchFiles<QueryArgs, FileDto, File extends BaseFile>(
  getCachedApiClientTransformer: () => ICachedApiClientTransformer<QueryArgs, FileDto[], File[]>,
  queryArgsList?: QueryArgs[],
): FetchFilesHookReturnType<File> {
  const { t } = useTranslation('Repository');
  const dispatch = useAppDispatch();

  const apiClient = useMemo(() => getCachedApiClientTransformer(), [getCachedApiClientTransformer]);

  useEffect(() => () => apiClient.clearCache(), [apiClient]);

  const [files, setFiles] = useState<File[]>();
  const [isLoading, setIsLoading] = useState(queryArgsList !== undefined);

  const onError = useCallback(
    (error) => {
      console.error(error);
      dispatch(
        displaySnackbar({
          message: t('Errors.FetchingFiles'),
          severity: 'error',
        }),
      );
    },
    [dispatch, t],
  );

  useEffect(() => {
    let cleanup: void | (() => void);

    if (queryArgsList !== undefined) {
      setIsLoading(true);

      const { promise, cancel, isCancelled } = cancellablePromise(apiClient.getListAsyncTransformed(queryArgsList));

      promise
        .then(({ data, error }) => {
          if (!isCancelled()) {
            if (error !== undefined) onError(error);
            else if (data !== undefined) setFiles(data.flat());
            setIsLoading(false);
          }
        })
        .catch((reason) => {
          if (!isCancelled()) {
            onError(reason);
            setIsLoading(false);
          }
        });

      cleanup = cancel;
    }

    return cleanup;
  }, [apiClient, queryArgsList, onError]);

  return {
    files,
    isLoading,
  };
}
