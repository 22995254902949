import { ReactElement } from 'react';

import { Alert, AlertTitle, Link } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import { useAppSelector } from '../../../../store';
import { DdpUrls } from '../../urls';

interface NoAccessAlertProps {
  alwaysShow?: boolean;
  isTemplateSavable?: boolean;
}

export default function NoAccessAlert({ isTemplateSavable, alwaysShow }: NoAccessAlertProps): ReactElement {
  const { t } = useTranslation(['Analyst']);
  const { downloadAccessDenied } = useAppSelector((state) => state.analyst);
  const { data: access } = useModuleAccessesQuery();
  const isFreeTrialAccess =
    access?.find((a) => a.moduleKey === ModuleKey.Analyst)?.status === ModuleAccessStatus.Trialing;

  if (alwaysShow || downloadAccessDenied) {
    if (isFreeTrialAccess) {
      return (
        <Alert severity="info">
          <AlertTitle>{t('FreeTrialRestrictions')}</AlertTitle>
          {t('AccessMoreThan2021Data')}{' '}
          <Link color="inherit" href={DdpUrls.Pricing} target="_blank">
            {t('Common:ClickHere')}
          </Link>{' '}
          {t('UpgradeSubscription')}
        </Alert>
      );
    }
    return (
      <Alert severity="info">
        <AlertTitle>{t('NoAccessRestriction')}</AlertTitle>
        {t('CannotDownloadFile')}{' '}
        <Link color="inherit" href={DdpUrls.Pricing} target="_blank">
          {t('Common:Here')}
        </Link>
        . {isTemplateSavable && <Trans t={t}>SaveConfigurationToDownload</Trans>}
      </Alert>
    );
  }
  return <></>;
}
