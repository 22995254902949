import { ReactElement } from 'react';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { useAppDispatch, useAppSelector } from '../../store';
import { hideSnackbar } from './snackbarSlice';

export default function SnackbarProvider(): ReactElement {
  const dispatch = useAppDispatch();
  const { open, message, severity, icon } = useAppSelector((state) => state.snackbar);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideSnackbar());
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} variant="outlined" severity={severity} icon={icon}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}
