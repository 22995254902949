import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from '../../core/Auth/MsalInstance';
import { AccountDetails } from '../../core/entities/accountDetails';
import { AsqAccessDto } from '../../core/entities/asqAccessDto';
import { AsqAdditionalAirportAccessDto } from '../../core/entities/asqAdditionalAirportAccessDto';
import { AsqAirportSubscriptionDto } from '../../core/entities/asqAirportSubscriptionDto';
import { AsqUserAirportsSubscriptionDto } from '../../core/entities/asqUserAirportsSubscriptionDto';
import { BannerDto } from '../../core/entities/bannerDto';
import { CustomPanelDto } from '../../core/entities/customPanelDto';
import { EmbedRequest } from '../../core/entities/embedRequest';
import { FeatureFlagDto } from '../../core/entities/featureFlags';
import { StatisticDto } from '../../core/entities/statisticDto';
import { Module } from '../../core/enums/module.enum';
import Airport from '../../modules/DDP/analyst/entities/Airport';
import Country from '../../modules/DDP/analyst/entities/Country';
import CountryGroup from '../../modules/DDP/analyst/entities/CountryGroup';
import RegionDto from '../../modules/DDP/analyst/entities/RegionDto';
import { Template } from '../../modules/DDP/analyst/entities/Template';
import { ExecutiveReportInfo } from '../../modules/DDP/executive/models/executiveReportInfo';
import FileDto from '../../modules/DDP/library/entities/File';
import User from '../../modules/common/profile/entities/User';

export const expirationTokenInterval = 3000000; // 50 minutes

export const backend = createApi({
  reducerPath: 'backend',
  tagTypes: ['Template', 'User', 'Subscription'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BACKEND_URL}/`,
    prepareHeaders: async (headers) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = await getToken();

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    airportFilter: builder.query<Airport[], void>({
      query: () => ({ url: 'Filters/Airports' }),
    }),
    regionFilter: builder.query<RegionDto[], void>({
      query: () => 'Filters/Regions',
    }),
    countryGroupFilter: builder.query<CountryGroup[], void>({
      query: () => 'Filters/CountryGroups',
    }),
    countryFilter: builder.query<Country[], void>({
      query: () => 'Filters/Countries',
    }),
    executiveReports: builder.query<ExecutiveReportInfo[], void>({
      query: () => 'Executive/Reports',
      providesTags: ['Subscription'],
    }),
    executiveReport: builder.query<EmbedRequest, string>({
      query: (reportId) => `Executive/Reports/${reportId}`,
    }),
    departureReports: builder.query<EmbedRequest, void>({
      query: () => 'Departure/Reports',
    }),
    departureCommentsAnalysisReports: builder.query<EmbedRequest, void>({
      query: () => 'Departure/Reports/CommentsAnalysis',
    }),
    departureDissatisfiedPassengerReports: builder.query<EmbedRequest, void>({
      query: () => 'Departure/Reports/DissatisfiedPassenger',
    }),
    arrivalReports: builder.query<EmbedRequest, void>({
      query: () => 'Arrival/Reports',
    }),
    commercialReports: builder.query<EmbedRequest, void>({
      query: () => 'Commercial/Reports',
    }),
    asqAccess: builder.query<AsqAccessDto, void>({
      query: () => 'Subscriptions/ASQ',
    }),
    departureLatestStatistic: builder.query<StatisticDto, string>({
      query: (airportCode) => ({
        url: 'Departure/Statistics/Latest',
        params: { airportCode },
      }),
    }),
    arrivalLatestStatistic: builder.query<StatisticDto, string>({
      query: (airportCode) => ({
        url: 'Arrival/Statistics/Latest',
        params: { airportCode },
      }),
    }),
    commercialLatestStatistic: builder.query<StatisticDto, string>({
      query: (airportCode) => ({
        url: 'Commercial/Statistics/Latest',
        params: { airportCode },
      }),
    }),
    userAirportsSubscriptions: builder.query<AsqUserAirportsSubscriptionDto, void>({
      query: () => ({
        url: 'Airport/ASQ/UserAirportsSubscriptions',
      }),
    }),
    userAvailableCommentsAirportsSubscriptions: builder.query<AsqAdditionalAirportAccessDto, void>({
      query: () => ({
        url: 'Airport/ASQ/UserAvailableCommentsAirportsSubscriptions',
      }),
    }),
    userAvailableDissatisfiedPassengerAirportsSubscriptions: builder.query<AsqAdditionalAirportAccessDto, void>({
      query: () => ({
        url: 'Airport/ASQ/UserAvailableDissatisfiedPassengerAirportsSubscriptions',
      }),
    }),
    airportSubscriptionAndPeriod: builder.query<AsqAirportSubscriptionDto, [string, Module]>({
      query: ([airportCode, module]) => {
        switch (module) {
          case Module.Departure:
            return {
              url: `Departure/Airport/Subscriptions/${airportCode}`,
            };
          case Module.Commercial:
            return {
              url: `Commercial/Airport/Subscriptions/${airportCode}`,
            };
          case Module.Arrival:
            return {
              url: `Arrival/Airport/Subscriptions/${airportCode}`,
            };
          default:
            return {
              url: '',
            };
        }
      },
    }),
    airportsForCustomPanel: builder.query<string[], CustomPanelDto>({
      query: (body) => ({
        url: 'Airport/ASQ/CustomPanelAirports',
        method: 'POST',
        body,
      }),
    }),
    files: builder.query<FileDto[], string>({
      query: (folderId) => ({ url: 'Files', params: { folderId } }),
    }),
    filesAll: builder.query<FileDto[], void>({
      query: () => ({ url: 'Files/All' }),
    }),
    fileDescription: builder.query<{ description: string }, string>({
      query: (folderId) => `/Files/Description/${folderId}`,
    }),
    fileSearch: builder.query<string[], string>({
      query: (searchTerm) => ({ url: 'Files/Search', params: { query: searchTerm } }),
    }),
    tutorial: builder.query<FileDto[], string>({
      query: (folderId) => ({ url: 'Tutorial', params: { folderId } }),
    }),
    tutorialDescription: builder.query<{ description: string }, string>({
      query: (folderId) => `Tutorial/Description/${folderId}`,
    }),
    tutorialSearch: builder.query<FileDto[], string>({
      query: (searchTerm) => ({ url: 'Tutorial/Search', params: { query: searchTerm } }),
    }),
    template: builder.query<Template[], void>({
      query: () => 'ReportTemplates',
      providesTags: (result) =>
        result
          ? [...result.map(({ id }) => ({ type: 'Template' as const, id })), { type: 'Template', id: 'LIST' }]
          : [{ type: 'Template', id: 'LIST' }],
    }),
    updateTemplate: builder.mutation<Template, Partial<Template> & Pick<Template, 'id'>>({
      query: (body) => ({
        url: 'ReportTemplates',
        method: 'PATCH',
        body,
      }),
      async onQueryStarted({ id, ...body }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          backend.util.updateQueryData('template', undefined, (draft) => {
            const index = draft.findIndex((template) => template.id === id);
            return Object.assign(draft.slice(), { [index]: { id, ...body } });
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    createTemplate: builder.mutation<void, Template>({
      query: (body) => ({
        url: 'ReportTemplates',
        method: 'POST',
        body,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(backend.util.updateQueryData('template', undefined, (draft) => [body, ...draft]));
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    deleteTemplate: builder.mutation<void, Partial<Template> & Pick<Template, 'id'>>({
      query: ({ id }) => ({
        url: `ReportTemplates/${id}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          backend.util.updateQueryData('template', undefined, (draft) =>
            draft.filter((template) => template.id !== id),
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
      invalidatesTags: (result, error, { id }) => [{ type: 'Template', id }],
    }),
    updateUser: builder.mutation<void, User>({
      query: (body) => ({
        url: 'User',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['User'],
      async onQueryStarted({ ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          if (patch.hasAcceptedTermsAndConditions) {
            dispatch(
              backend.util.updateQueryData('getUser', undefined, (draft) => {
                Object.assign(draft, patch);
              }),
            );
          }
          // eslint-disable-next-line no-empty
        } catch {}
      },
    }),
    getUser: builder.query<User, void>({
      query: () => ({
        url: 'User',
        method: 'GET',
      }),
      providesTags: ['User'],
    }),
    getBannerText: builder.query<BannerDto, void>({
      query: () => ({
        url: 'Notification/GetBannerMessage',
        method: 'GET',
      }),
    }),
    getAccountDetails: builder.query<AccountDetails, void>({
      query: () => 'User/Account',
    }),
    getFeatureFlags: builder.query<FeatureFlagDto[], void>({
      query: () => 'FeatureFlags/',
    }),
  }),
});

export const {
  useAirportFilterQuery,
  useRegionFilterQuery,
  useCountryGroupFilterQuery,
  useCountryFilterQuery,
  useExecutiveReportsQuery,
  useExecutiveReportQuery,
  useDepartureReportsQuery,
  useDepartureCommentsAnalysisReportsQuery,
  useDepartureDissatisfiedPassengerReportsQuery,
  useArrivalReportsQuery,
  useCommercialReportsQuery,
  useAsqAccessQuery,
  useDepartureLatestStatisticQuery,
  useArrivalLatestStatisticQuery,
  useCommercialLatestStatisticQuery,
  useUserAirportsSubscriptionsQuery,
  useUserAvailableCommentsAirportsSubscriptionsQuery,
  useUserAvailableDissatisfiedPassengerAirportsSubscriptionsQuery,
  useLazyAirportSubscriptionAndPeriodQuery,
  useLazyAirportsForCustomPanelQuery,
  useFilesQuery,
  useFilesAllQuery,
  useLazyFileDescriptionQuery,
  useLazyFileSearchQuery,
  useTutorialQuery,
  useLazyTutorialDescriptionQuery,
  useLazyTutorialSearchQuery,
  useTemplateQuery,
  useDeleteTemplateMutation,
  useUpdateTemplateMutation,
  useCreateTemplateMutation,
  useUpdateUserMutation,
  useGetUserQuery,
  useGetAccountDetailsQuery,
  useGetBannerTextQuery,
  useGetFeatureFlagsQuery,
} = backend;
