import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { ModuleKey } from '../../enums/moduleKey';
import { closeDialog, openUpgradeToFullPlanDialog } from '../../purchaseSlice';
import BaseNoAccessDialog from './BaseNoAccessDialog';

export interface FullPlanForAccessDialogProps {
  moduleKey: ModuleKey;
}

export default function FullPlanForAccessDialog({ moduleKey }: FullPlanForAccessDialogProps) {
  const { t } = useTranslation('Pricing');
  const { isFullPlanForAccessDialogOpen, selectedProduct } = useAppSelector((state) => state.purchase);
  const dispatch = useAppDispatch();

  const handleUpgrade = () => {
    dispatch(closeDialog());
    dispatch(openUpgradeToFullPlanDialog(selectedProduct));
  };

  return (
    <BaseNoAccessDialog
      moduleKey={moduleKey}
      isOpen={isFullPlanForAccessDialogOpen}
      handleAccept={handleUpgrade}
      fullStory="FullPlanForAccess"
      buttonText={t('NoAccessDialog.UpgradeFullPlan')}
    />
  );
}
