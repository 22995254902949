export interface PromiseProgress {
  resolved: number;
  rejected: number;
  total: number;
}

interface CancellablePromise<T> {
  promise: Promise<T>;
  cancel: () => void;
  isCancelled: () => boolean;
}

export const CancelReason = '____CANCELLED____';

export function promisesWithProgress<T>(
  promises: Promise<T>[],
  onProgressChange: (progress: PromiseProgress) => void,
): CancellablePromise<T[]> {
  let isCancelled = false;
  const progress: PromiseProgress = { resolved: 0, rejected: 0, total: promises.length };
  const values: T[] = [];

  const wrappedPromise = new Promise<T[]>((resolve, reject) => {
    promises.forEach((promise) => {
      promise
        .then((value) => {
          if (isCancelled) {
            reject(CancelReason);
          } else {
            progress.resolved += 1;
            onProgressChange({ ...progress });

            values.push(value);
            if (values.length === promises.length) resolve(values);
          }
        })
        .catch((reason) => {
          if (isCancelled) {
            reject(CancelReason);
          } else {
            progress.rejected += 1;
            onProgressChange({ ...progress });

            reject(reason);
          }
        });
    });
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled = true;
    },
    isCancelled: () => isCancelled,
  };
}

export function cancellablePromise<T>(promise: Promise<T>): CancellablePromise<T> {
  let isCancelled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise
      .then((value) => {
        if (isCancelled) reject(CancelReason);
        else resolve(value);
      })
      .catch((reason) => {
        if (isCancelled) reject(CancelReason);
        else reject(reason);
      });
  });

  return {
    promise: wrappedPromise,
    cancel: () => {
      isCancelled = true;
    },
    isCancelled: () => isCancelled,
  };
}
