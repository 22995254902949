import { ReactElement } from 'react';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import { useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import HistoricalDataCard from '../molecules/HistoricalDataCard';

export default function AnalystHistoricalContainer(): ReactElement {
  const { isLoading: isAccessLoading, data: access } = useModuleAccessesQuery();
  const hasAccess = access?.some((a) => a.moduleKey === ModuleKey.AnalystHistorical);

  if (isAccessLoading) {
    return <LoadingSpinner />;
  }

  return <HistoricalDataCard hasAccess={hasAccess} />;
}
