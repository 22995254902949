import { ReactElement } from 'react';

import { Skeleton, Typography } from '@mui/material';
import { formatDecimalNumber } from '../../../../common/utils/number';

interface DataProps {
  value?: number;
  isLoading: boolean;
}

export default function Data({ value, isLoading }: DataProps): ReactElement | null {
  if (isLoading) return <Skeleton width="55px" />;
  if (value === undefined) return null;

  return (
    <Typography variant="h5" color="primary">
      {formatDecimalNumber(value)}
    </Typography>
  );
}
