import { ReactElement } from 'react';
import ModuleUpselling from '../../../../common/components/molecules/ModuleUpselling';
import { BenchmarkModule } from '../../ddpModules';

export default function BenchmarkContainer(): ReactElement {
  return (
    <>
      <ModuleUpselling module={BenchmarkModule} />
    </>
  );
}
