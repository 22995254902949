import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../store';
import { useUpgradeToFullPlanMutation } from '../../../../hooks/usePricingEndpoints';
import { PurchaseDialogStep } from '../../entities/PurchaseDialogStep';
import CongratulationsStep from '../molecules/CongratulationsStep';
import PaymentStep from '../molecules/PaymentStep';
import BasePurchaseDialog from './BasePurchaseDialog';

export default function UpgradeToFullPlanDialog() {
  const { t } = useTranslation('Pricing');
  const { isUpgradeToFullPlanDialogOpen, termsAndConditionsAccepted } = useAppSelector((state) => state.purchase);
  const [upgradeToFullPlan] = useUpgradeToFullPlanMutation();

  const PaymentStepCallback = useCallback(
    () => (
      <PaymentStep paymentInfoIsAlreadySaved>
        <Typography variant="pMedium">
          <Trans
            t={t}
            i18nKey="PurchaseDialog.UpgradeConfirmation"
            values={{ confirmButtonLabel: t('PurchaseDialog.ConfirmPurchase') }}
          />
        </Typography>
      </PaymentStep>
    ),
    [t],
  );

  const steps: PurchaseDialogStep[] = [
    {
      component: PaymentStepCallback,
      nextButtonGradient: 'contained',
      nextButtonText: t('PurchaseDialog.ConfirmPurchase'),
      validForm: termsAndConditionsAccepted,
      backButton: false,
      fullStoryName: 'Payment',
    },
    {
      component: CongratulationsStep,
      nextButtonGradient: 'gradient',
      nextButtonText: t('PurchaseDialog.AccessDashboard'),
      validForm: true,
      backButton: false,
      fullStoryName: 'Congratulations',
    },
  ];

  const save = async () => {
    await upgradeToFullPlan().unwrap();
  };

  return (
    <BasePurchaseDialog
      isDialogOpen={isUpgradeToFullPlanDialogOpen}
      steps={steps}
      onSave={save}
      fullstoryEventPrefix="Upgrade to Full Plan Flow"
    />
  );
}
