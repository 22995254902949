import { StringDict } from '@azure/msal-common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { defaultFirstName, defaultLastName } from './constants';
import { msalInstance } from '../../../core/Auth/MsalInstance';

interface State {
  firstName: string;
  lastName: string;
  email: string;
  isSavingProfile: boolean;
}

const idToken = msalInstance.getAllAccounts()[0]?.idTokenClaims! as StringDict;

export const initialState: State = {
  firstName: idToken?.given_name === defaultFirstName ? '' : idToken?.given_name,
  lastName: idToken?.family_name === defaultLastName ? '' : idToken?.family_name,
  email: idToken?.emails[0],
  isSavingProfile: false,
};

// Actions
const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    changeFirstName: (state, action: PayloadAction<string>) => ({
      ...state,
      firstName: action.payload,
    }),
    changeLastName: (state, action: PayloadAction<string>) => ({
      ...state,
      lastName: action.payload,
    }),
    updateIdClaims: (state, action: PayloadAction<any>) => ({
      ...state,
      firstName: action.payload.given_name === defaultFirstName ? '' : action.payload.given_name,
      lastName: action.payload.family_name === defaultLastName ? '' : action.payload.family_name,
      email: action.payload.emails[0],
    }),
    setIsSavingProfile: (state, action: PayloadAction<boolean>) => ({
      ...state,
      isSavingProfile: action.payload,
    }),
  },
});

export const { changeFirstName, changeLastName, updateIdClaims, setIsSavingProfile } = profileSlice.actions;

export default profileSlice.reducer;
