import { useMsal } from '@azure/msal-react';
import { DialogContent } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../common/components/atoms/Button';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import { useGetUserQuery, useUpdateUserMutation } from '../../../../common/hooks/backend';
import { displaySnackbar } from '../../../../core/notifications/snackbarSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { closeDialog, openDialog } from '../termsAndConditionsSlice';
import TermsWrapper from './TermsWrapper';

export default function TermsAndConditionsDialog() {
  const { t } = useTranslation('TermsAndConditions');
  const { instance } = useMsal();
  const [updateUser] = useUpdateUserMutation();
  const { data: user, isLoading } = useGetUserQuery();
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState(false);
  const { isDialogOpen } = useAppSelector((state) => state.termsAndConditions);

  const dispatch = useAppDispatch();

  const handleOnDisagree = () => {
    instance.logoutRedirect();
  };

  const handleOnChangeAcceptTerms = () => {
    setIsTermsAndConditionsAccepted(!isTermsAndConditionsAccepted);
  };

  const handleOnAgree = () => {
    dispatch(closeDialog());
    updateUser({ hasAcceptedTermsAndConditions: true, hasRefusedFreeTrialInformationPopup: false }).catch(
      (err: any) => {
        console.error(err);
        dispatch(displaySnackbar({ message: t('Dialog.Error'), severity: 'error' }));
      },
    );
  };

  useEffect(() => {
    if (!isLoading && user !== undefined && user.hasAcceptedTermsAndConditions !== true) {
      dispatch(openDialog());
    }
  }, [user, isLoading, dispatch]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (user === undefined) {
    return null;
  }

  return (
    <Dialog maxWidth="md" disableEnforceFocus open={isDialogOpen} sx={{ zIndex: (theme) => theme.zIndex.modal - 2 }}>
      <DialogContent sx={{ px: 7, pt: 5, pb: 0 }}>
        <TermsWrapper />
      </DialogContent>
      <FormControlLabel
        sx={{ px: 7, pt: 2 }}
        control={<Checkbox />}
        label={t('Dialog.AcceptTerms')}
        onChange={handleOnChangeAcceptTerms}
      />
      <DialogActions sx={{ padding: (theme) => theme.spacing(5) }}>
        <Button sx={{ mr: 1 }} variant="text" onClick={handleOnDisagree}>
          {t('Dialog.Disagree')}
        </Button>
        <Button variant="contained" onClick={handleOnAgree} disabled={!isTermsAndConditionsAccepted}>
          {t('Dialog.Agree')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
