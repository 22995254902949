enum FilePeriod {
  Annual = 'Annual',
  Q4 = 'Q4',
  Q3 = 'Q3',
  Q2 = 'Q2',
  Q1 = 'Q1',
  Summer = 'Summer',
  Winter = 'Winter',
}

export default FilePeriod;
