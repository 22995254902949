import { ReactElement } from 'react';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import { useGetUserQuery } from '../../../../common/hooks/backend';
import { useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';

import Home from '../pages/Home';

export default function HomeContainer(): ReactElement {
  const { data: userData } = useGetUserQuery();
  const { data: accesses, isLoading } = useModuleAccessesQuery();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return <Home name={userData?.firstName} accesses={accesses} />;
}
