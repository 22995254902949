import { ReactElement, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';

import { ActionDef } from '../types/TableTypes';
import { FontAwesomeSvgIcon } from './FontAwesomeIcon';
import OptionButton from './OptionButton';

export interface PaperTableActionsProps {
  actions: ActionDef[];
}

export default function PaperTableActions({ actions }: PaperTableActionsProps): ReactElement {
  const [isButtonLoading, setIsButtonLoading] = useState<boolean[]>(new Array(actions.length).fill(false));

  const handleButtonClick =
    (callback: (event: React.SyntheticEvent<HTMLButtonElement>) => void | Promise<void>, index: number) =>
    (event: React.SyntheticEvent<HTMLButtonElement>) => {
      const response = callback(event);

      if (response instanceof Promise) {
        changeLoadingAtIndex(index, true);
        response.then(() => changeLoadingAtIndex(index, false)).catch(() => changeLoadingAtIndex(index, false));
      }
    };

  const changeLoadingAtIndex = (index: number, isLoading: boolean) =>
    setIsButtonLoading((array) => Object.assign([], array, { [index]: isLoading }));

  return (
    <>
      {actions.map((action, index) => (
        <Tooltip key={action.id} title={action.toolTip} sx={{ mr: 1 }}>
          <Box display="inline-flex">
            <OptionButton
              disabled={isButtonLoading[index]}
              onClick={handleButtonClick(action.onClick, index)}
              data-fullstory={action.fullStoryTag}
            >
              {isButtonLoading[index] ? <CircularProgress size={24} /> : <FontAwesomeSvgIcon icon={action.icon} />}
            </OptionButton>
          </Box>
        </Tooltip>
      ))}
    </>
  );
}
