import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import MuiAccordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';

export interface QuestionProps {
  id: string;
  header: string;
  faqUrl: string;
  children: ReactNode;
}

export default function QuestionRow({ header, id, faqUrl, children }: QuestionProps): ReactElement {
  const ref = useRef<HTMLDivElement | null>(null);
  const location = useLocation();
  const history = useHistory();

  const isAnchored = location.hash === `#${id}`;

  const handleOpen = () => {
    if (isAnchored) history.push(faqUrl);
    else history.push(`${faqUrl}#${id}`);
  };

  useEffect(() => {
    if (isAnchored) ref.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isAnchored]);

  return (
    <Accordion ref={ref} expanded={isAnchored} onChange={handleOpen} id={id}>
      <AccordionSummary
        sx={{ height: (theme) => theme.spacing(8), px: 0 }}
        expandIcon={<FontAwesomeSvgIcon fontSize="inherit" sx={{ color: 'primaryText.100' }} icon={faChevronDown} />}
      >
        <Typography variant="pMedium" fontWeight="bold" color="primary.800">
          {header}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ px: 0, pt: 2 }}>
        <Typography variant="pMedium" color="primaryText.800" component="div">
          {children}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  boxShadow: Array.from(theme.shadows)[25],
  borderRadius: '8px',
  '&:before': {
    content: 'none',
  },
}));
