import { Comparer } from '../components/types/TableTypes';

export function generateValuesComparer(values: string[]): Comparer<string> {
  const valuesMap = new Map(values.map((value, index) => [value.toLocaleLowerCase(), index]));

  return (left, right) => {
    const leftValue = left.toLocaleLowerCase();
    const rightValue = right.toLocaleLowerCase();

    const indexLeft = valuesMap.get(leftValue);
    const indexRight = valuesMap.get(rightValue);

    if (indexLeft !== undefined && indexRight !== undefined) return indexLeft - indexRight;
    if (indexLeft === undefined && indexRight !== undefined) return 1;
    if (indexLeft !== undefined && indexRight === undefined) return -1;
    return leftValue.localeCompare(rightValue);
  };
}

export function numberStringComparer(left: string, right: string): number {
  const leftValue = Number(left);
  const rightValue = Number(right);

  if (Number.isNaN(leftValue)) return -1;
  if (Number.isNaN(rightValue)) return 1;
  return leftValue - rightValue;
}

export function reverseComparer<T>(comparer: Comparer<T>): Comparer<T> {
  return (a, b) => comparer(b, a);
}

export function combineComparers<T>(...comparers: Comparer<T>[]): Comparer<T> {
  return (a, b) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const comparer of comparers) {
      const result = comparer(a, b);
      if (result !== 0) return result;
    }

    return 0;
  };
}
