import {
  useFreeTrialAvailabilityQuery,
  useGetAllPricingProductsQuery,
} from '../../../../common/hooks/usePricingEndpoints';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import {
  openFreeTrialForAccessDialog,
  openFullPlanForAccessDialog,
  openPurchaseForAccessDialog,
} from '../../../../common/modules/purchase/purchaseSlice';
import { useAppDispatch } from '../../../../store';
import { TransformedFileDto } from '../entities/File';
import FileAccessRequirements from '../enums/fileAccessRequirements';
import { setDetailsOpen, setSelectedFile } from '../librarySlice';
import { useAccessibleFilesList } from './useAccessibleFilesList';

const useFileAccessValidator = () => {
  const dispatch = useAppDispatch();
  const { data: freeTrialAvailability } = useFreeTrialAvailabilityQuery();
  const { data: pricingProducts } = useGetAllPricingProductsQuery();
  const { accessLevel: userAccessLevel } = useAccessibleFilesList();

  const openFileDetails = (file: TransformedFileDto) => {
    if (userAccessLevel.includes(file.accessRequirements)) {
      dispatch(setDetailsOpen(true));
      dispatch(setSelectedFile(file));
    } else {
      const freeTrialProduct = pricingProducts?.find(
        (product) => product.priceId === freeTrialAvailability?.freeTrialPriceId,
      );
      const isLibraryFreeTrial =
        freeTrialProduct?.allowsAccessTo === ModuleKey.Library ||
        freeTrialProduct?.includedProducts.some(
          (includedProduct) =>
            pricingProducts?.find((p) => p.priceId === includedProduct.priceId)?.allowsAccessTo === ModuleKey.Library,
        );

      if (file.accessRequirements === FileAccessRequirements.FreeTrial) {
        if (isLibraryFreeTrial && freeTrialAvailability?.freeTrialAvailable) {
          dispatch(openFreeTrialForAccessDialog(freeTrialProduct!));
        } else {
          dispatch(openPurchaseForAccessDialog());
        }
      }

      if (file.accessRequirements === FileAccessRequirements.ActiveSubscription) {
        if (isLibraryFreeTrial && freeTrialAvailability?.upgradeAvailable) {
          dispatch(openFullPlanForAccessDialog(freeTrialProduct!));
        } else {
          dispatch(openPurchaseForAccessDialog());
        }
      }
    }
  };

  return {
    openFileDetails,
  };
};

export default useFileAccessValidator;
