import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBarTitlePortal } from '../../../../common/components/atoms/AppBarTitlePortal';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import ModuleUpselling from '../../../../common/components/molecules/ModuleUpselling';
import { useAsqAccess } from '../../../../common/utils/accessHelper';
import { CommercialModule } from '../../asqModules';
import CommercialPage from '../pages/CommercialPage';

export default function CommercialContainer(): ReactElement {
  const { access, isLoading } = useAsqAccess();
  const { t } = useTranslation('Commercial');

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (access?.hasCommercialGeneralAccess) {
    return <CommercialPage />;
  }

  return (
    <>
      <AppBarTitlePortal>
        <h4>{t('Title')}</h4>
      </AppBarTitlePortal>
      <ModuleUpselling module={CommercialModule} displayActions={false} />
    </>
  );
}
