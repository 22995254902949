import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { AppBarTitlePortal } from '../../../../common/components/atoms/AppBarTitlePortal';
import { useMemberFileExplorer } from '../core/repositories/memberFiles/hook';
import FileExplorer from '../molecules/FileExplorer';

export default function MemberReports(): ReactElement {
  const { t } = useTranslation('Repository');
  const fileExplorerProps = useMemberFileExplorer();

  return (
    <>
      <AppBarTitlePortal>
        <h4>{t('Title')}</h4>
      </AppBarTitlePortal>
      <FileExplorer {...fileExplorerProps} />
    </>
  );
}
