import { ReactElement } from 'react';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../store';
import ColumnValuesList from '../atoms/ColumnValuesList';
import PeriodType from '../enums/periodType.enum';
import TimeFetchingType from '../enums/timeFetchingType.enum';

export default function SummaryInformationRow(): ReactElement {
  const { t } = useTranslation('Analyst');
  const {
    periodType,
    metrics,
    nbOfLastMonths,
    timeFetchingType,
    startYear,
    endYear,
    airports,
    countries,
    countryGroups,
    regions,
  } = useAppSelector((state) => state.analyst.currentTemplate);

  const capitalizeFirstLetter = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

  const getPeriodLabel = () => {
    if (timeFetchingType === TimeFetchingType.Latest) {
      const unit =
        periodType === PeriodType.Monthly
          ? t('Month', { count: nbOfLastMonths })
          : t('Year', { count: nbOfLastMonths / 12 });
      return `${t('Latest')} ${
        periodType === PeriodType.Monthly ? nbOfLastMonths : Math.ceil(nbOfLastMonths / 12)
      } ${unit}`;
    }

    return `${startYear} ${t('To')} ${endYear}`;
  };

  const reportSummary: Map<string, string> = new Map([
    [t('Dataset'), t(capitalizeFirstLetter(PeriodType[periodType]))],
    [t('Period'), getPeriodLabel()],
    [
      t('FiltersApplied'),
      [airports, regions, countries, countryGroups].every((a) => a.length === 0) ? t('Common:No') : t('Common:Yes'),
    ],
    [t('MetricsSelected'), `${metrics.length}`],
  ]);

  return (
    <>
      <Typography pt={4} variant="h6">
        {t('Resume')}
      </Typography>
      <Typography pb={2} pt={0.4} variant="pSmall">
        {t('ResumeDescription')}
      </Typography>
      <ColumnValuesList information={reportSummary} />
    </>
  );
}
