import { useCallback, useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import AutoComplete, { AutoCompleteOption } from '../../../../common/components/atoms/AutoComplete';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { useCountryFilterQuery, useRegionFilterQuery } from '../../../../common/hooks/backend';
import { useAppDispatch } from '../../../../store';
import { setSelectedAggregations } from '../analystSlice';
import Country from '../entities/Country';
import { FilterLevels } from '../enums/filterLevels.enum';
import { useAggregationFilters } from '../hooks/useAggregationFilters';

interface CountriesFiltersProps {
  value: string[];
  onValueChange: (newValue: string[]) => void;
}

export default function CountriesFilters(props: CountriesFiltersProps) {
  const { value, onValueChange } = props;

  const { data: filters = [], isLoading } = useCountryFilterQuery();
  const { data: regions = [], isLoading: isRegionLoading } = useRegionFilterQuery();

  const { t } = useTranslation('Analyst');

  const [selectedOptions, setSelectedOptions] = useState<AutoCompleteOption[]>([]);
  const dispatch = useAppDispatch();

  const { getClosestFilters } = useAggregationFilters();
  const closestFilters = getClosestFilters(FilterLevels.Country);

  const filteredOptions = useMemo(() => {
    if (
      closestFilters.closestFilterLevels.includes(FilterLevels.Subregion) ||
      closestFilters.closestFilterLevels.includes(FilterLevels.Region) ||
      closestFilters.closestFilterLevels.includes(FilterLevels.CountryGroup)
    ) {
      return filters.filter(
        (c) =>
          closestFilters.countryGroups!.some((g) => g.countries.includes(c.countryCode)) ||
          closestFilters.fullRegions!.some((s) => s.countries.includes(c.countryCode)),
      );
    }

    return filters;
  }, [filters, closestFilters]);

  const changeSelectedOptions = (newvalue: AutoCompleteOption[]) => {
    setSelectedOptions(newvalue);
    onValueChange(newvalue.map((option) => option.key));
  };

  const toAutoCompleteOptions = useCallback(
    (countries: Country[]) =>
      countries.map((country) => ({
        group: regions.find((r) => r.regionCode === country.regionCode)?.regionName,
        label: country.countryName,
        key: country.countryCode,
      })),
    [regions],
  );

  useEffect(() => {
    if (!isLoading && !isRegionLoading) {
      setSelectedOptions(toAutoCompleteOptions(filters.filter((filter) => value.includes(filter.countryCode))));
    }
  }, [filters, toAutoCompleteOptions, value, isRegionLoading, isLoading]);

  useEffect(() => {
    const newCountries: AutoCompleteOption[] = selectedOptions.filter((o) =>
      filteredOptions.map((f) => f.countryCode).includes(o.key),
    );

    if (newCountries.length !== selectedOptions.length) {
      dispatch(
        setSelectedAggregations({
          key: FilterLevels.Country,
          value: newCountries.map((a) => a.key),
        }),
      );
    }
  }, [selectedOptions, dispatch, filteredOptions]);

  return (
    <Grid item width="20%">
      <AutoComplete
        grouped
        loading={isLoading && isRegionLoading}
        options={toAutoCompleteOptions(filteredOptions)}
        width="100%"
        label={t('Country', { count: 2 })}
        value={selectedOptions}
        onChange={changeSelectedOptions}
        fullStoryTag="AnalystAggregationLocationCountry"
        icon={<FontAwesomeSvgIcon icon={faAngleDown} />}
      />
    </Grid>
  );
}
