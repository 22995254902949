import { Purchase } from '../../../../modules/DDP/pricing/entities/Purchase';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { changePaymentCardErrorMessage } from '../purchaseSlice';

const usePaymentCard = () => {
  const { confirmCardPayment, submitPaymentElement } = useAppSelector((state) => state.purchase);
  const dispatch = useAppDispatch();

  const handlePayment = async (saveInformationInSubscriptionCallback: () => Promise<Purchase>) => {
    try {
      const { error: submitError } = await submitPaymentElement();
      if (submitError) {
        dispatch(changePaymentCardErrorMessage(submitError.message));
        throw new Error(submitError.message);
      }

      const purchase = await saveInformationInSubscriptionCallback();
      const { error: confirmError } = await confirmCardPayment(purchase.transactionClientSecret);

      if (confirmError) {
        dispatch(changePaymentCardErrorMessage(confirmError.message));
        throw new Error(confirmError.message);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    handlePayment,
  };
};

export default usePaymentCard;
