import PurchaseDialog from '../../../../common/modules/purchase/components/dialogs/PurchaseDialog';
import UpgradeToAnnualDialog from '../../../../common/modules/purchase/components/dialogs/UpgradeToAnnualDialog';
import UpgradeToBundleDialog from '../../../../common/modules/purchase/components/dialogs/UpgradeToBundleDialog';
import { useAppSelector } from '../../../../store';

export default function AllPurchaseDialogs() {
  const { isUpgradeToAnnualDialogOpen, isUpgradeToBundleDialogOpen, isPurchaseDialogOpen } = useAppSelector(
    (state) => state.purchase,
  );

  return (
    <>
      {isUpgradeToBundleDialogOpen && <UpgradeToBundleDialog />}
      {isUpgradeToAnnualDialogOpen && <UpgradeToAnnualDialog />}
      {isPurchaseDialogOpen && <PurchaseDialog />}
    </>
  );
}
