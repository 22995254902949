import { ListItemButton, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavSubItemProps } from '../../types/NavItem';

const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(0.5, 1, 0.5, 7),
  '&:hover .MuiTypography-root': {
    fontWeight: 'bold',
  },
}));

interface DrawerAccordionDetailsLinkProps {
  navItemTranslateKey: string;
  navSubItem: NavSubItemProps;
  openUrl: (url: string) => void;
  navSubItemSelected: (url: string) => boolean;
}

const DrawerAccordionDetailsLink = ({
  navItemTranslateKey,
  navSubItem,
  openUrl,
  navSubItemSelected,
}: DrawerAccordionDetailsLinkProps) => {
  const { t } = useTranslation('Navigation');

  return (
    <StyledListItemButton
      data-fullstory={`LeftNav${navItemTranslateKey}${navSubItem.translateKey}`}
      onClick={() => openUrl(navSubItem.url)}
    >
      <Typography
        variant="pSmall"
        marginLeft={navSubItem.thirdLevel ? 2 : 0}
        sx={navSubItemSelected(navSubItem.url) ? { fontWeight: 'bold', textDecoration: 'underline' } : {}}
      >
        {t(`${navSubItem.translateKey}`)}
      </Typography>
    </StyledListItemButton>
  );
};
export default DrawerAccordionDetailsLink;
