import { DdpUrlPrefix } from '../../common/constants/urls';

const ExecutiveModuleBaseUrl = `/${DdpUrlPrefix}/executive`;

const DdpUrls = {
  Home: `/${DdpUrlPrefix}`,
  ExecutiveModuleReports: `${ExecutiveModuleBaseUrl}/reports`,
  ExecutiveModuleReport: `${ExecutiveModuleBaseUrl}/reports/`,
  LibraryModule: `/${DdpUrlPrefix}/library`,
  LibraryModuleTraffic: `/${DdpUrlPrefix}/library/traffic`,
  LibraryModuleForecast: `/${DdpUrlPrefix}/library/forecast`,
  LibraryModuleEconomics: `/${DdpUrlPrefix}/library/economics`,
  LibraryModuleEnvironmentSustainability: `/${DdpUrlPrefix}/library/environment&sustainability`,
  AnalystModule: `/${DdpUrlPrefix}/analyst`,
  AnalystModuleTemplates: `/${DdpUrlPrefix}/analyst/reports`,
  AnalystModuleHistoricalData: `/${DdpUrlPrefix}/analyst/reports/historical`,
  AnalystModuleReportCreation: `/${DdpUrlPrefix}/analyst/reports/create`,
  AnalystModuleReportEdit: `/${DdpUrlPrefix}/analyst/reports/`,
  BenchmarkModule: `/${DdpUrlPrefix}/benchmark`,
  Faq: `/${DdpUrlPrefix}/faq`,
  Pricing: `/${DdpUrlPrefix}/pricing`,

  // deprecated urls (old urls before we implemented the ASQ portal)
  HomeDeprecated: '/',
  ExecutiveModuleDeprecated: '/executive',
  LibraryModuleDeprecated: '/library',
  AnalystModuleDeprecated: '/analyst',
  BenchmarkModuleDeprecated: '/benchmark',
  FaqDeprecated: '/faq',
};

export { DdpUrls, ExecutiveModuleBaseUrl };
