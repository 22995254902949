import { CategoryFilterId, PeriodFilterId, YearFilterId, TitleFilterId } from '../../../constants/ids';
import { PublicFile } from '../../../entities/PublicFile';
import {
  categoryFileFilterOptionComparer,
  periodFileFilterOptionComparer,
  yearFileFilterOptionComparer,
  titleFileFilterOptionComparer,
} from '../../../utils/filter';
import { periodValuesSelector, yearValuesSelector } from '../../../utils/selector';
import { FileFilterSystemHelper } from '../../fileFilterSystem/helper';
import { FileFilterSystemState } from '../../fileFilterSystem/types';

const periodFilter = FileFilterSystemHelper.generateInitialFileFilter(
  PeriodFilterId,
  'FileExplorer.Filters.Period',
  (file: PublicFile) => file.period,
  periodFileFilterOptionComparer,
  periodValuesSelector,
);

const yearFilter = FileFilterSystemHelper.generateInitialFileFilter(
  YearFilterId,
  'FileExplorer.Filters.Year',
  (file: PublicFile) => file.year,
  yearFileFilterOptionComparer,
  yearValuesSelector,
);

const categoryFilter = FileFilterSystemHelper.generateInitialFileFilter(
  CategoryFilterId,
  'FileExplorer.Filters.Category',
  (file: PublicFile) => file.category,
  categoryFileFilterOptionComparer,
);

const titleFilter = FileFilterSystemHelper.generateInitialFileFilter(
  TitleFilterId,
  'FileExplorer.Filters.Title',
  (file: PublicFile) => file.title,
  titleFileFilterOptionComparer,
);

export function generateInitialState(): FileFilterSystemState<PublicFile> {
  return {
    filters: [periodFilter, yearFilter, categoryFilter, titleFilter],
    filesMap: {},
    filteredFiles: [],
  };
}
