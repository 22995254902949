import { useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import FileAccessRequirements from '../enums/fileAccessRequirements';

export type UseAccessibleFilesListReturn = { isLoading: boolean; accessLevel: FileAccessRequirements[] };

export const useAccessibleFilesList = (): UseAccessibleFilesListReturn => {
  const defaultAccessLevels = [FileAccessRequirements.Public];
  const trialingAccessLevels = [FileAccessRequirements.FreeTrial, FileAccessRequirements.Public];
  const fullAccessLevels = [
    FileAccessRequirements.ActiveSubscription,
    FileAccessRequirements.FreeTrial,
    FileAccessRequirements.Public,
  ];

  const { isLoading, data: access } = useModuleAccessesQuery();
  const moduleAccess = access?.find((a) => a.moduleKey === ModuleKey.Library);
  switch (moduleAccess?.status) {
    case ModuleAccessStatus.Active:
    case ModuleAccessStatus.FreeAccess:
      return { isLoading, accessLevel: fullAccessLevels };
    case ModuleAccessStatus.Trialing:
      return { isLoading, accessLevel: trialingAccessLevels };
    default:
      return { isLoading, accessLevel: defaultAccessLevels };
  }
};
