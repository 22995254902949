import { ReactElement } from 'react';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import { useTemplateQuery } from '../../../../common/hooks/backend';
import TemplatePage from '../page/TemplatePage';

export default function AnalystTemplatesContainer(): ReactElement {
  const { isLoading: isTemplatesLoading, data: templates } = useTemplateQuery();

  if (isTemplatesLoading) {
    return <LoadingSpinner />;
  }

  return <TemplatePage templates={templates} />;
}
