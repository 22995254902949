import { Button } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllPricingProductsQuery } from '../../../../common/hooks/usePricingEndpoints';
import { ModuleAccess } from '../../../../common/modules/purchase/entities/ModuleAccess';
import {
  openPurchaseDialog,
  openUpgradeToAnnualDialog,
  openUpgradeToBundleDialog,
  openUpgradeToFullPlanDialog,
} from '../../../../common/modules/purchase/purchaseSlice';
import { useAppDispatch } from '../../../../store';
import { PricingProduct } from '../entities/PricingProduct';
import { ProductPurchaseAction } from '../enums/ProductPurchaseAction.enum';
import { ProductRecurringInterval } from '../enums/ProductReccuringInterval';
import { getProductPurchaseAction } from '../helpers/getProductPurchaseAction';

interface ProductPurchaseActionButtonProps {
  product: PricingProduct;
  moduleAccesses: ModuleAccess[];
}

const ProductPurchaseActionButton = ({ product, moduleAccesses }: ProductPurchaseActionButtonProps) => {
  const { t } = useTranslation('Pricing');
  const { data: pricingProducts } = useGetAllPricingProductsQuery();
  const dispatch = useAppDispatch();

  const { disabled, label, onClick } = useMemo(() => {
    if (!pricingProducts || !product) {
      return {};
    }

    const productPurchaseAction = getProductPurchaseAction(product, pricingProducts, moduleAccesses);

    switch (productPurchaseAction) {
      case ProductPurchaseAction.UpgradeToFullPlan:
        return {
          disabled: false,
          label: t('UpgradeToFullPlan'),
          onClick: () => dispatch(openUpgradeToFullPlanDialog(product)),
        };
      case ProductPurchaseAction.UpgradeToBundle:
        return {
          disabled: false,
          label: t('UpgradeToBundle'),
          onClick: () => dispatch(openUpgradeToBundleDialog(product)),
        };
      case ProductPurchaseAction.UpgradeToAnnual: {
        const annualPrice = pricingProducts.find(
          (p) => p.id === product.id && p.recurringInterval === ProductRecurringInterval.Yearly,
        );
        return {
          disabled: false,
          label: t('UpgradeToAnnual'),
          onClick: () => dispatch(openUpgradeToAnnualDialog(annualPrice!)),
        };
      }
      case ProductPurchaseAction.CurrentlyActiveAnnual:
        return {
          disabled: true,
          label: t('CurrentlyActiveAnnual'),
        };
      case ProductPurchaseAction.CurrentlyActive:
        return {
          disabled: true,
          label: t('CurrentlyActive'),
        };
      default:
        return {
          disabled: false,
          label: t('BuyNow'),
          onClick: () => dispatch(openPurchaseDialog(product)),
        };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product, pricingProducts, moduleAccesses]);

  return (
    <Button disabled={disabled} variant="contained" onClick={onClick}>
      {label}
    </Button>
  );
};

export default ProductPurchaseActionButton;
