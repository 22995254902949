import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const CardTag = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
  backgroundColor: theme.palette.secondary[200],
  color: 'white',
  padding: theme.spacing(0.5, 1),
  fontSize: '11px',
  fontWeight: 'bold',
}));

export default CardTag;
