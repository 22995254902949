import { models, Report, VisualDescriptor } from 'powerbi-client';
import { ReportChangeListener } from '../components/molecules/PowerBIReport';
import { ExtractKeyValuesFromHierarchicSlicer, getSlicers } from './power-bi-slicers';
import { DisplayFilters, SlicersAndFilters } from '../../core/powerBi/powerBiFiltersSlice';

export interface FilterSummary {
  displayName: string;
  filterType: models.FilterType;
  isActive: boolean;
  values?: string[];
}

export async function getFiltersSummary(report: Report): Promise<FilterSummary[]> {
  try {
    const page = await report.getActivePage();
    const activeFilters = await page.getFilters();
    const reportFilters = await report.getFilters();

    return activeFilters.concat(reportFilters).map(filter => getFilterSummary(filter));
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function getSlicerStateWithTitle(slicer: VisualDescriptor) {
  return {
    title: slicer.title, 
    state: await slicer.getSlicerState()
  };
}

export async function getSlicersFiltersSummary(report: Report): Promise<FilterSummary[]> {
  try {
    const slicers = await getSlicers(report);
    const states = await Promise.all(slicers.map(getSlicerStateWithTitle));
    return states.map((slicerState) => {
      if (slicerState.state.filters.length > 0) {
        if (slicerState.state.filters[0].filterType === models.FilterType.Hierarchy){
          const keyValues = ExtractKeyValuesFromHierarchicSlicer(slicerState.state);
          return {
              displayName: slicerState.title ?? slicerState.state.filters[0].displaySettings?.displayName ?? (slicerState.state.filters[0].target as any[])[0].table ?? 'unknown',
              filterType: slicerState.state.filters[0].filterType,
              isActive: true,
              values: keyValues.flatMap((keyValue) => keyValue.values.map((value) => `${keyValue.key} ${value}`)),
            } as FilterSummary;
        }

        return slicerState.state.filters.map(filter => getFilterSummary(filter, slicerState.title));
      }

      return [];
    }).flat();
  } catch (error) {
    console.error(error);
    return [];
  }
}

function getFilterSummary(filter: models.IFilter, title: string | null = null): FilterSummary {
  return {
    displayName: title ?? filter.displaySettings?.displayName ?? (filter.target as any)?.column ?? 'unknown',
    filterType: filter.filterType,
    isActive: isFilterActive(filter),
    values: getFilterValues(filter),
  };
}

function isFilterActive(filter: models.IFilter): boolean {
  switch (filter.filterType) {
    case models.FilterType.Basic:
      return (filter as unknown as models.BasicFilter).operator !== 'All';
    case models.FilterType.Advanced:
      return (filter as unknown as models.AdvancedFilter).conditions.length > 0;
    default:
      return false; // we don't know how to manage filters that aren't basic or advanced
  }
}

function getFilterValues(filter: models.IFilter): string[] | undefined {
  switch (filter.filterType) {
    case models.FilterType.Basic:
      return (filter as unknown as models.BasicFilter).values.map((elem) => elem.toString());
    default:
      return undefined; // we don't know how to manage filter values that are not basic
  }
}

export function getDisplayFilters(filters: FilterSummary[]): DisplayFilters[] {
  return filters
    .filter(
      ({ filterType, isActive }) =>
        (filterType === models.FilterType.Basic || filterType === models.FilterType.Advanced || filterType === models.FilterType.Hierarchy) && isActive,
    )
    .map(({ displayName, values }) => {
      if (values === undefined) return {displayName, values: ['Active']};
      return { displayName, values };
    });
}

export function getReportChangeListenerForFiltersAndSlicers(setter: (newState: SlicersAndFilters) => void): ReportChangeListener {
  return async (report) => {
    const result = await getSlicersAndFilters(report);
    setter(result);
  }
}

async function getSlicersAndFilters(report: Report): Promise<SlicersAndFilters> {
  const filters = await getFiltersSummary(report);
  const slicerFilters = await getSlicersFiltersSummary(report);
  const displayFilters = getDisplayFilters(filters);
  const displaySlicers = getDisplayFilters(slicerFilters);

  return {primaryFilters: displaySlicers, secondaryFilters: displayFilters};
};
