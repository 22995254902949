import { Box, Stack, Typography } from '@mui/material';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Button from '../../../../common/components/atoms/Button';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import { useFilesAllQuery, useLazyFileSearchQuery } from '../../../../common/hooks/backend';
import { ExternalUrlIdentifier } from '../../../../services/FullStoryService';
import { useAppDispatch, useAppSelector } from '../../../../store';
import FileSearchbar from '../atoms/FileSearchbar';
import { TransformedFileDto } from '../entities/File';
import { setDetailsOpen } from '../librarySlice';
import FileDetailsDialog from './FileDetailsDialog';
import LibraryRouting from './LibraryRouting';
import RecommendedSection from './RecommendedSection';
import SearchedFileTable from './SearchedFileTable';

export default function FileExplorer(): ReactElement {
  const { selectedFile, detailsOpen } = useAppSelector((state) => state.library);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('Library');
  const [, setIsSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [fetchSearchedFiles, { data: searchedFileIds = [], isFetching: isFetchingSearch }] = useLazyFileSearchQuery();
  const { isLoading, data: files = [] } = useFilesAllQuery();

  const location = useLocation();

  useEffect(() => {
    setIsSearching(false);
    setSearchTerm('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const transformedFiles = useMemo<TransformedFileDto[]>(
    () =>
      files.map((file) => ({
        ...file,
        path: file.path.split('/'),
      })),
    [files],
  );

  const handleDrawerClose = () => {
    dispatch(setDetailsOpen(false));
  };

  const onSearchChange = (value: string) => {
    setSearchTerm(value);
    setIsSearching(!!value);
    if (value !== '') {
      fetchSearchedFiles(value);
    }
  };

  const searchedFiles = transformedFiles.filter((f) => searchedFileIds.includes(f.id));

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Stack position="inherit" height="100%">
        <Stack direction="row" justifyContent="space-between" alignItems="center" pb={2}>
          <Typography variant="h2" color="primaryText.100">
            {t('PageTitle')}
          </Typography>
          <Button
            href={ExternalUrlIdentifier(`${process.env.REACT_APP_COMMERCE_BASE_URL}product-tag/economics-statistics/`)}
            variant="contained"
            target="_blank"
            sx={{ px: 3, py: 2 }}
            data-fullstory="LibraryExploreACIPublications"
          >
            {t('ExploreMore')}
          </Button>
        </Stack>
        <Box sx={{ mb: 7 }}>
          <FileSearchbar value={searchTerm} onChange={onSearchChange} />
        </Box>

        {searchTerm !== '' ? (
          <SearchedFileTable files={searchedFiles} loading={isLoading || isFetchingSearch} searchTerm={searchTerm} />
        ) : (
          <>
            <Typography variant="h5" color="primary" mb={1}>
              {t('RecommendedPublication').toUpperCase()}
            </Typography>
            <Typography mb={2} color="primaryText.10">
              {t('RecommendedPublicationExplaination')}
            </Typography>
            <Box mb={5}>
              <RecommendedSection files={transformedFiles} />
            </Box>
            <LibraryRouting files={transformedFiles} />
          </>
        )}
      </Stack>
      <FileDetailsDialog file={selectedFile} open={detailsOpen} onClose={handleDrawerClose} />
    </>
  );
}
