import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import React from 'react';

export interface ActionDef {
  id: string;
  toolTip: string;
  onClick: (event: React.SyntheticEvent<HTMLButtonElement>) => Promise<void> | void;
  icon: IconDefinition;
  fullStoryTag: string;
}

export type Comparer<T> = (a: T, b: T) => number;

export enum HeaderType {
  Data,
  Action,
  Select,
}

export interface SelectHeaderDef {
  id: string;
  flexWidth: number;
  type: HeaderType.Select;
}

export interface ActionHeaderDef {
  id: string;
  label: string | React.ReactElement;
  flexWidth: number;
  maxActions: number;
  type: HeaderType.Action;
}

export interface DataHeaderDef<T> {
  id: string;
  key: keyof T;
  label: string | React.ReactElement;
  rowsColor?: string;
  flexWidth: number;
  comparer?: Comparer<T>;
  type: HeaderType.Data;
}

export type HeaderDef<T> = SelectHeaderDef | ActionHeaderDef | DataHeaderDef<T>;

export interface RowDef<T> {
  id: string;
  data: T;
  actions: ActionDef[];
  fullStoryTag?: string;
  onClick?: () => void;
}

export interface SortingState<T> {
  headerId: string;
  sortingType: SortingType;
  comparer: Comparer<T>;
}

export enum SortingType {
  Sort,
  Reverse,
}

export const ActionWidth = 48;
