import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';

interface NoFilesFoundProps {
  title: string;
  description?: string;
}

export default function NoFilesFound({ title, description }: NoFilesFoundProps): ReactElement {
  return (
    <Box pt={12} display="flex" flexDirection="column" alignItems="center">
      <img width="200px" src="/images/Library/Empty.png" alt="empty" />
      <Typography pt={10} pb={1} variant="h3" color="primary.main">
        {title}
      </Typography>
      {description && (
        <Typography variant="pMedium" color="primaryText.100">
          {description}
        </Typography>
      )}
    </Box>
  );
}
