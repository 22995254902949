import { ReactElement } from 'react';

import styled from '@emotion/styled';
import { Card, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Button from '../../../../common/components/atoms/Button';
import {
  useFreeTrialAvailabilityQuery,
  useGetAllPricingProductsQuery,
} from '../../../../common/hooks/usePricingEndpoints';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import {
  openFreeTrialForAccessDialog,
  openPurchaseForAccessDialog,
  openUpgradeToFullPlanDialog,
} from '../../../../common/modules/purchase/purchaseSlice';
import { useAppDispatch } from '../../../../store';
import { DdpUrls } from '../../urls';
import { ExecutiveReportInfo } from '../models/executiveReportInfo';

export interface ReportCardProps {
  report: ExecutiveReportInfo;
  hasAccess: boolean;
  isFreeTrial: boolean;
}

const ReportImage = styled('img')(() => ({
  objectFit: 'cover',
  width: '100%',
  height: '270px',
}));

const CardButton = styled(Button)(() => ({
  width: '46%',
}));

export default function ReportCard(props: ReportCardProps): ReactElement {
  const { report, hasAccess, isFreeTrial } = props;
  const { t } = useTranslation(['Pricing', 'Common']);
  const history = useHistory();
  const { data: freeTrialAvailability } = useFreeTrialAvailabilityQuery();
  const { data: pricingProducts } = useGetAllPricingProductsQuery();
  const dispatch = useAppDispatch();

  const handleReportOpen = (reportId: string) => {
    if (!hasAccess) {
      const freeTrialProduct = pricingProducts?.find(
        (product) => product.priceId === freeTrialAvailability?.freeTrialPriceId,
      );
      const isExecutiveFreeTrial =
        freeTrialProduct?.allowsAccessTo === ModuleKey.Executive ||
        freeTrialProduct?.includedProducts?.some(
          (includedProduct) =>
            pricingProducts?.find((p) => p.priceId === includedProduct.priceId)?.allowsAccessTo === ModuleKey.Executive,
        );
      if (isExecutiveFreeTrial && freeTrialAvailability?.freeTrialAvailable) {
        dispatch(openFreeTrialForAccessDialog(freeTrialProduct!));
      } else {
        dispatch(openPurchaseForAccessDialog());
      }
    } else {
      history.push(`${DdpUrls.ExecutiveModuleReport}${reportId}`);
    }
  };

  const handleUpgradePlan = () => {
    const freeTrialProduct = pricingProducts?.find(
      (product) => product.priceId === freeTrialAvailability?.freeTrialPriceId,
    );
    if (freeTrialProduct) {
      dispatch(openUpgradeToFullPlanDialog(freeTrialProduct));
    }
  };

  const imageOnErrorHandler = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const target = event.currentTarget;
    target.src = '/images/Executive/ExecutiveReportDefaultCover.png';
    target.onerror = null;
  };

  return (
    <Card sx={{ margin: 3, width: 425, display: 'flex', flexDirection: 'column' }}>
      <ReportImage src={report.reportImage} onError={imageOnErrorHandler} />
      <Stack rowGap={3} padding={3} flexGrow={1} paddingTop={1} justifyContent="space-between">
        <Stack>
          <Typography display="flex" variant="pXLarge" minHeight={85} marginY={1} alignItems="center">
            {report.reportName}
          </Typography>
          <Typography textAlign="justify">{report.reportDescription}</Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <CardButton variant="contained" onClick={() => handleReportOpen(report.reportId)}>
            {t('Common:Open')}
          </CardButton>
          {isFreeTrial && (
            <CardButton variant="contained" onClick={handleUpgradePlan}>
              {t('Pricing:UpgradeToFullPlan')}
            </CardButton>
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
