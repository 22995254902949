import React from 'react';

export interface RendererProps {
  children: React.ReactElement;
  fallback?: React.ReactElement | null;
  reverse?: boolean;
}

interface ConditionalRendererProps extends RendererProps {
  display: boolean;
}

function ConditionalRenderer({
  display,
  children,
  fallback = null,
  reverse = false,
}: ConditionalRendererProps): React.ReactElement | null {
  if ((display && !reverse) || (!display && reverse)) return children;
  return fallback;
}

export { ConditionalRenderer };
