import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import { AppBarTitlePortal } from '../../../../common/components/atoms/AppBarTitlePortal';
import MarginCanceller from '../../../../common/components/atoms/MarginCanceller';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import { useAsqAccess } from '../../../../common/utils/accessHelper';
import RepositoryTopBar from '../molecules/RepositoryTopBar';
import RepositoryUpselling from '../molecules/RepositoryUpselling';
import repositoryRoutes from '../routes/repository.routes';

export default function RepositoryContainer(): ReactElement {
  const { access, isLoading } = useAsqAccess();
  const { t } = useTranslation('Repository');

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (access?.hasRepositoryAccess) {
    return (
      <MarginCanceller>
        <RepositoryTopBar />
        {repositoryRoutes}
      </MarginCanceller>
    );
  }

  return (
    <>
      <AppBarTitlePortal>
        <h4>{t('Title')}</h4>
      </AppBarTitlePortal>
      <RepositoryUpselling />
    </>
  );
}
