import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ExternalUrls from '../../../../common/constants/externalUrls';
import { AppUrls } from '../../../../routes/urls';
import MailToLink from '../../../DDP/common/atoms/MailToLink';
import NewTabLink from '../../../DDP/common/atoms/NewTabLink';
import { TermsAnchor } from '../../termsAndConditions/models/TermsAnchor';
import BaseTermPage from '../../termsAndConditions/molecules/BaseTermsPage';

interface TermsOfUseProps {
  asSection?: boolean;
}

const TermsOfUse = ({ asSection = false }: TermsOfUseProps) => {
  const { t } = useTranslation('TermsAndConditions');

  const linkIHub = <NewTabLink link={ExternalUrls.IntelligenceHub} />;
  const linkPrivacyPolicy = <NewTabLink link={ExternalUrls.PrivacyPolicy} />;
  const linkTermsOfSale = asSection ? <Link to={TermsAnchor.TermsOfSale} /> : <NewTabLink link={AppUrls.TermsOfSale} />;
  const linkMailTo = <MailToLink subjectType="termsOfUse" />;

  return (
    <BaseTermPage
      title={t('TermsOfUse.Title')}
      content={
        <Trans t={t} components={{ linkIHub, linkPrivacyPolicy, linkTermsOfSale, linkMailTo }}>
          TermsOfUse.Content
        </Trans>
      }
    />
  );
};

export default TermsOfUse;
