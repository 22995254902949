import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../store';
import { useUpgradeToBundleMutation } from '../../../../hooks/usePricingEndpoints';
import { PurchaseDialogStep } from '../../entities/PurchaseDialogStep';
import CongratulationsStep from '../molecules/CongratulationsStep';
import PaymentStep from '../molecules/PaymentStep';
import BasePurchaseDialog from './BasePurchaseDialog';

const PaymentStepCallback = () => <PaymentStep paymentInfoIsAlreadySaved />;

export default function UpgradeToBundleDialog() {
  const { t } = useTranslation('Pricing');
  const { isUpgradeToBundleDialogOpen, selectedProduct, termsAndConditionsAccepted } = useAppSelector(
    (state) => state.purchase,
  );
  const [upgradeToBundle] = useUpgradeToBundleMutation();

  const steps: PurchaseDialogStep[] = [
    {
      component: PaymentStepCallback,
      nextButtonGradient: 'contained',
      nextButtonText: t('PurchaseDialog.ConfirmPurchase'),
      validForm: termsAndConditionsAccepted,
      backButton: false,
      fullStoryName: 'Payment',
    },
    {
      component: CongratulationsStep,
      nextButtonGradient: 'gradient',
      nextButtonText: t('PurchaseDialog.AccessDashboard'),
      validForm: true,
      backButton: false,
      fullStoryName: 'Congratulations',
    },
  ];

  const save = async () => {
    await upgradeToBundle({ priceId: selectedProduct.priceId }).unwrap();
  };

  return (
    <BasePurchaseDialog
      isDialogOpen={isUpgradeToBundleDialogOpen}
      steps={steps}
      onSave={save}
      fullstoryEventPrefix="Upgrade to Bundle Flow"
    />
  );
}
