import { useMemo } from 'react';
import { hasAccessForAirportAndModule, useAsqAccess } from '../../../../common/utils/accessHelper';
import { AsqAccess } from '../../../../core/entities/asqAccess';
import { Module } from '../../../../core/enums/module.enum';
import { useHomePageFilter } from '../../home/filter/hooks';

export function useDefaultAirportCodeForDashboard(module: Module): string | null {
  const { access } = useAsqAccess();
  const { airportCode } = useHomePageFilter();

  return useMemo<string | null>(() => {
    if (access === undefined) return null;
    return getAvailableAirportCodeForModule(access, module, airportCode ?? undefined);
  }, [airportCode, access, module]);
}

function getAvailableAirportCodeForModule(
  access: AsqAccess,
  module: Module,
  defaultAirportCode?: string,
): string | null {
  if (defaultAirportCode !== undefined && hasAccessForAirportAndModule(access, defaultAirportCode, module)) {
    return defaultAirportCode;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const airportCode in access.airportAccesses) {
    if (hasAccessForAirportAndModule(access, airportCode, module)) {
      return airportCode;
    }
  }

  return null;
}
