import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentPlatformHomeUrl } from '../../../../core/platform/hooks';

export default function Redirect(): ReactElement {
  const history = useHistory();
  const homeUrl = useCurrentPlatformHomeUrl();

  useEffect(() => {
    const redirectRoute = window.sessionStorage.getItem('redirect_route');

    if (redirectRoute) {
      history.push(JSON.parse(redirectRoute));
    } else {
      history.push(homeUrl);
    }
  }, [history, homeUrl]);

  return <></>;
}
