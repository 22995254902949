import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Stack, Typography } from '@mui/material';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';

interface ProductFeatureListProps {
  features: string[];
}

const ProductFeatureList = ({ features }: ProductFeatureListProps) => (
  <Stack direction="column" gap={1}>
    {features.map((feature) => (
      <Stack key={feature} direction="row">
        <FontAwesomeSvgIcon fontSize="inherit" color="primary" sx={{ mt: 0.3 }} icon={faCheck} />
        <Typography variant="pSmall" sx={{ ml: 1 }}>
          {feature}
        </Typography>
      </Stack>
    ))}
  </Stack>
);

export default ProductFeatureList;
