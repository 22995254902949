import { useCallback, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import AutoComplete, { AutoCompleteOption } from '../../../../common/components/atoms/AutoComplete';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { useCountryGroupFilterQuery } from '../../../../common/hooks/backend';
import CountryGroup from '../entities/CountryGroup';

interface CountryGroupsFiltersProps {
  value: string[];
  onValueChange: (newValue: string[]) => void;
}

export default function CountryGroupsFilters(props: CountryGroupsFiltersProps) {
  const { value, onValueChange } = props;

  const { data: filters = [], isLoading } = useCountryGroupFilterQuery();

  const { t } = useTranslation('Analyst');

  const [selectedOptions, setSelectedOptions] = useState<AutoCompleteOption[]>([]);

  const changeSelectedOptions = (newvalue: AutoCompleteOption[]) => {
    setSelectedOptions(newvalue);
    onValueChange(newvalue.map((option) => option.key));
  };

  const toAutoCompleteOptions = useCallback(
    (countryGroups: CountryGroup[]) =>
      countryGroups.map((countryGroup) => ({
        label: countryGroup.countryGroupName,
        key: countryGroup.countryGroupCode,
      })),
    [],
  );

  useEffect(() => {
    if (filters.length > 0) {
      setSelectedOptions(toAutoCompleteOptions(filters.filter((filter) => value.includes(filter.countryGroupCode))));
    }
  }, [filters, toAutoCompleteOptions, value]);

  return (
    <Grid item width="20%">
      <AutoComplete
        loading={isLoading}
        options={toAutoCompleteOptions(filters)}
        width="100%"
        label={t('CountryGroup', { count: 2 })}
        value={selectedOptions}
        onChange={changeSelectedOptions}
        fullStoryTag="AnalystAggregationLocationCountryGroup"
        icon={<FontAwesomeSvgIcon icon={faAngleDown} />}
      />
    </Grid>
  );
}
