import { useState, useMemo } from 'react';
import BaseFile from '../../entities/BaseFile';
import { DetailsDrawerProps } from '../../molecules/DetailsDrawer';

interface DetailsFileHookReturnType<File extends BaseFile> {
  onDetails: (file: File) => void;
  detailsDrawerProps: DetailsDrawerProps;
}

export function useDetailsFile<File extends BaseFile>(
  onDownloadFile: (file: File) => () => Promise<void>,
  getDetailsDataFile: (file: File) => DetailsDrawerProps['data'],
): DetailsFileHookReturnType<File> {
  const [selectedFile, setSelectedFile] = useState<File>();

  const data = useMemo(() => {
    if (selectedFile !== undefined) return getDetailsDataFile(selectedFile);
    return undefined;
  }, [selectedFile, getDetailsDataFile]);

  const detailsDrawerProps: DetailsDrawerProps = {
    open: selectedFile !== undefined,
    onClose: () => {
      setSelectedFile(undefined);
    },
    data,
    onDowload: selectedFile ? onDownloadFile(selectedFile) : undefined,
  };

  return {
    detailsDrawerProps,
    onDetails: setSelectedFile,
  };
}
