import { Divider, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ExternalUrls from '../../../../common/constants/externalUrls';
import { AppUrls } from '../../../../routes/urls';
import NewTabLink from '../../common/atoms/NewTabLink';
import { DdpUrls } from '../../urls';
import Header from '../atoms/Header';
import QuestionsGroup from '../molecules/QuestionsGroup';

export default function FaqPage(): ReactElement {
  const { t } = useTranslation('Common');

  return (
    <>
      <Box display="flex" alignItems="center" flexDirection="column" pb={5}>
        <Header />
        <QuestionsGroup faqUrl={DdpUrls.Faq} />
        <Stack divider={<Divider orientation="vertical" flexItem />} direction="row" mt={5} gap={1}>
          <NewTabLink link={AppUrls.TermsOfUse}>{t('TermsOfUse')}</NewTabLink>
          <NewTabLink link={AppUrls.TermsOfSale}>{t('TermsOfSale')}</NewTabLink>
          <NewTabLink link={ExternalUrls.PrivacyPolicy}>{t('PrivacyPolicy')}</NewTabLink>
        </Stack>
      </Box>
    </>
  );
}
