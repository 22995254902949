import { HierarchySlicerInfos, SlicerInfos } from '../../../../common/utils/power-bi-slicers';

export const periodSlicerSample: Pick<HierarchySlicerInfos, 'table' | 'hierarchy'> = {
  table: 'Date',
  hierarchy: 'Date Hierarchy',
};

export const periodYearSlicerSample: Pick<HierarchySlicerInfos, 'table' | 'hierarchy' | 'hierarchyLevel'> = {
  ...periodSlicerSample,
  hierarchyLevel: 'Year',
};

export const periodQuarterSlicerSample: Pick<HierarchySlicerInfos, 'table' | 'hierarchy' | 'hierarchyLevel'> = {
  ...periodSlicerSample,
  hierarchyLevel: 'Quarter',
};

export const comparedPeriodQuarterSlicerSample: Pick<HierarchySlicerInfos, 'table' | 'hierarchy' | 'hierarchyLevel'> = {
  ...periodQuarterSlicerSample,
  table: 'Date Rolling',
};

export const comparedPeriodYearSlicerSample: Pick<HierarchySlicerInfos, 'table' | 'hierarchy' | 'hierarchyLevel'> = {
  ...periodYearSlicerSample,
  table: 'Date Rolling',
};

export const airportCodeSlicerSample: Pick<SlicerInfos, 'table' | 'column'> = {
  table: 'Airport',
  column: 'Airport Code',
};

export const subscriptionSlicerSample: Pick<SlicerInfos, 'table' | 'column'> = {
  table: 'Subscription Period Security',
  column: 'Subscription',
};

export const periodYearSeasonSlicerSample: Pick<SlicerInfos, 'table' | 'column'> = {
  table: 'Date',
  column: 'Season',
};

export const comparedPeriodYearSeasonSlicerSample: Pick<SlicerInfos, 'table' | 'column'> = {
  table: 'Date Rolling',
  column: 'Season',
};
