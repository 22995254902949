import { Route, Switch } from 'react-router-dom';
import { NotFoundPage } from '../../../../common/components/pages/NotFoundPage';
import { FeatureFlag } from '../../../../core/featureFlags/FeatureFlag';
import { FeatureFlagKey } from '../../../../core/featureFlags/featureFlagKeys';
import AirportReports from '../pages/AirportReports';
import MemberReports from '../pages/MemberReports';
import PublicReports from '../pages/PublicReports';
import { RepositoryPagesUrls } from '../urls';

const repositoryRoutes = (
  <Switch>
    <Route exact path={RepositoryPagesUrls.Airport}>
      <FeatureFlag flagKey={FeatureFlagKey.RepositoryAirport} fallback={<NotFoundPage />}>
        <AirportReports />
      </FeatureFlag>
    </Route>
    <Route exact path={RepositoryPagesUrls.Member}>
      <FeatureFlag flagKey={FeatureFlagKey.RepositoryMember} fallback={<NotFoundPage />}>
        <MemberReports />
      </FeatureFlag>
    </Route>
    <Route exact path={RepositoryPagesUrls.Public}>
      <FeatureFlag flagKey={FeatureFlagKey.RepositoryPublic} fallback={<NotFoundPage />}>
        <PublicReports />
      </FeatureFlag>
    </Route>
    <Route path="*">
      <NotFoundPage />
    </Route>
  </Switch>
);

export default repositoryRoutes;
