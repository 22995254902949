import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AppBarTitlePortal } from '../../../../common/components/atoms/AppBarTitlePortal';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import ModuleUpselling from '../../../../common/components/molecules/ModuleUpselling';
import { useAsqAccess } from '../../../../common/utils/accessHelper';
import { ArrivalModule } from '../../asqModules';
import ArrivalPage from '../pages/ArrivalPage';

export default function ArrivalContainer(): ReactElement {
  const { access, isLoading } = useAsqAccess();
  const { t } = useTranslation('Arrival');

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (access?.hasArrivalGeneralAccess) {
    return <ArrivalPage />;
  }

  return (
    <>
      <AppBarTitlePortal>
        <h4>{t('Title')}</h4>
      </AppBarTitlePortal>
      <ModuleUpselling module={ArrivalModule} displayActions={false} />
    </>
  );
}
