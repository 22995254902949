import { Box, Typography } from '@mui/material';
import { AddressElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeAddressElementChangeEvent, loadStripe } from '@stripe/stripe-js';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { ProductRecurringInterval } from '../../../../../modules/DDP/pricing/enums/ProductReccuringInterval';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useFreeTrialAvailabilityQuery, useGetPricingConfigurationQuery } from '../../../../hooks/usePricingEndpoints';
import {
  changeAreBillingDetailsFilled,
  changeBillingInformation,
  changeConfirmCardPaymentCallback,
  changeModifiedForm,
  changeSubmitPaymentElementCallback,
} from '../../purchaseSlice';

type StripeElementsMode = 'subscription' | 'payment' | 'setup';

const ElementsWrapper = ({ children, mode }: { children: ReactNode; mode: StripeElementsMode }) => {
  const { data: configuration, isLoading } = useGetPricingConfigurationQuery();
  const { selectedProduct } = useAppSelector((state) => state.purchase);

  const stripePromise = useMemo(() => {
    if (configuration?.publishableKey) {
      return loadStripe(configuration.publishableKey);
    }
    return null;
  }, [configuration?.publishableKey]);

  if (isLoading || !configuration || !stripePromise) {
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode,
        amount: mode === 'setup' ? undefined : selectedProduct?.unitPrice * 100,
        currency: 'usd',
        paymentMethodTypes: ['card'],
      }}
    >
      {children}
    </Elements>
  );
};

const WrappedElements = ({ mode }: { mode: StripeElementsMode }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { paymentCardErrorMessage, areBillingDetailsFilled } = useAppSelector((state) => state.purchase);
  const dispatch = useAppDispatch();
  const [isCardFilled, setIsCardFilled] = useState<boolean>(false);
  const [isAddressFilled, setIsAddressFilled] = useState<boolean>(false);

  useEffect(() => {
    const areNewBillingDetailsFilled = isCardFilled && isAddressFilled;
    if (areNewBillingDetailsFilled !== areBillingDetailsFilled) {
      dispatch(changeAreBillingDetailsFilled(areNewBillingDetailsFilled));
      dispatch(changeModifiedForm(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCardFilled, isAddressFilled, dispatch]);

  useEffect(() => {
    const stripeCallback = mode === 'setup' ? stripe?.confirmSetup : stripe?.confirmPayment;
    if (stripeCallback && elements?.submit) {
      const confirmCallback = (clientSecret: string) =>
        stripeCallback({
          elements,
          clientSecret,
          redirect: 'if_required',
          confirmParams: {
            return_url: window.location.href,
          },
        });
      dispatch(changeConfirmCardPaymentCallback(confirmCallback));
      dispatch(changeSubmitPaymentElementCallback(elements.submit));
    }
  }, [dispatch, elements, mode, stripe]);

  const handleAddressChange = (event: StripeAddressElementChangeEvent) => {
    setIsAddressFilled(event.complete);
    const { name, address } = event.value;
    const [firstName, lastName] = name.split(' ');
    const billingDetails = {
      firstName,
      lastName,
      address: address.line1,
      city: address.city,
      state: address.state,
      postalCode: address.postal_code,
      country: address.country,
    };
    dispatch(changeBillingInformation(billingDetails));
  };

  return (
    <>
      <Box p={2} mb={2} border="1px solid #e6e6e6" borderRadius="5px">
        <AddressElement options={{ mode: 'billing' }} onChange={handleAddressChange} />
      </Box>
      <PaymentElement
        onChange={(event) => setIsCardFilled(event.complete)}
        options={{
          layout: 'accordion',
        }}
      />
      {paymentCardErrorMessage && <Typography variant="pSmall">{paymentCardErrorMessage}</Typography>}
    </>
  );
};

interface PaymentCardFormProps {
  activateFreeTrialIfAvailable: boolean;
}

const PaymentCardForm = ({ activateFreeTrialIfAvailable }: PaymentCardFormProps) => {
  const { data: freeTrialAvailabilityData, isLoading } = useFreeTrialAvailabilityQuery();
  const { selectedProduct } = useAppSelector((state) => state.purchase);

  if (isLoading) {
    return null;
  }

  let mode: StripeElementsMode;

  if (
    activateFreeTrialIfAvailable &&
    freeTrialAvailabilityData &&
    freeTrialAvailabilityData.freeTrialAvailable &&
    !freeTrialAvailabilityData.upgradeAvailable &&
    freeTrialAvailabilityData.freeTrialPriceId === selectedProduct.priceId
  ) {
    mode = 'setup';
  } else if (selectedProduct.recurringInterval === ProductRecurringInterval.None) {
    mode = 'payment';
  } else {
    mode = 'subscription';
  }

  return (
    <ElementsWrapper mode={mode}>
      <WrappedElements mode={mode} />
    </ElementsWrapper>
  );
};

export default PaymentCardForm;
