import { Box } from '@mui/material';
import { ReactElement } from 'react';

export interface CardCornerLabelProps {
    informationText: string;
}

export default function CardCornerLabel(props: CardCornerLabelProps): ReactElement {
  const { informationText } = props;

  return (
    <Box sx={{ position: 'absolute', background: "#FC1262", padding: 0.7, textTransform: 'uppercase', color: 'white', fontSize: 12}}>
      {informationText}
    </Box>
  );
}