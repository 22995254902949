import { Box, CircularProgress, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '../../../../common/components/atoms/Button';
import { BulkDownloadProgress, BulkDownloadState } from '../utils/bulkDownload';

interface BulkDownloadButtonProps {
  fileCount: number;
  progress: BulkDownloadProgress | undefined;
  isLoading: boolean;
  disabled?: boolean;
  onClick: () => void;
}

export default function BulkDownloadButton({
  fileCount,
  progress,
  isLoading,
  disabled,
  onClick,
}: BulkDownloadButtonProps) {
  const { t } = useTranslation('Repository', { keyPrefix: 'FileExplorer.BulkDownload' });

  let buttonLabel: string = '';
  let tooltip: string = '';

  if (fileCount === 0) {
    tooltip = t('SelectFilesMessage');
  }

  if (progress === undefined) {
    buttonLabel = t('ButtonLabel', { count: fileCount });
  } else if (progress.state === BulkDownloadState.DownloadFiles) {
    buttonLabel = t('ButtonLabelProgressDownload', { done: progress.resolved, total: progress.total });
  } else if (progress.state === BulkDownloadState.ZipFiles) {
    buttonLabel = t('ButtonLabelProgressZipping');
  }

  return (
    <Tooltip title={tooltip}>
      <Box sx={{ position: 'relative' }}>
        <Button
          data-fullstory="RepositoryDownloadBulk"
          variant="contained"
          onClick={onClick}
          disabled={isLoading || disabled}
        >
          {buttonLabel}
        </Button>
        {isLoading && (
          <CircularProgress
            size={25}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
}
