import { useEffect, useMemo, useState } from 'react';

import { AsqAccess } from '../../../../../../../core/entities/asqAccess';
import { FileFilterBase, FileFilterOption } from '../../../../types/FileFilter';
import FileModule from '../../../../enums/FileModule.enum';
import { moduleFileFilterOptionComparer } from '../../../../utils/filter';
import { useAsqAccess } from '../../../../../../../common/utils/accessHelper';

function useModuleOptions(): FileFilterOption[] | null {
  const { access, isLoading } = useAsqAccess();

  const moduleOptions = useMemo<FileFilterOption[] | null>(() => {
    if (isLoading || access === undefined) return null;
    return getModulesFilterOptions(access);
  }, [isLoading, access]);

  return moduleOptions;
}

function getModulesFilterOptions(access: AsqAccess): FileFilterOption[] {
  const options: FileFilterOption[] = [];

  if (access.hasDepartureGeneralAccess) {
    options.push({
      key: FileModule.Departure,
      label: FileModule.Departure,
    });
  }

  if (access.hasArrivalGeneralAccess) {
    options.push({
      key: FileModule.Arrival,
      label: FileModule.Arrival,
    });
  }

  if (access.hasCommercialGeneralAccess) {
    options.push({
      key: FileModule.Commercial,
      label: FileModule.Commercial,
    });
  }

  return options.sort(moduleFileFilterOptionComparer);
}

export function useModulesFilter(): FileFilterBase {
  const moduleOptions = useModuleOptions();

  const [moduleValues, setModuleValues] = useState<FileFilterOption[]>([]);

  useEffect(() => {
    if (moduleOptions !== null) setModuleValues([...moduleOptions]);
  }, [moduleOptions]);

  return {
    id: 'RepositoryModulesFilter',
    labelTranslateKey: 'FileExplorer.Filters.Module',
    options: moduleOptions ?? [],
    values: moduleValues,
    onChange: setModuleValues,
    spacing: true,
    isLoading: moduleOptions === null || moduleValues === null,
  };
}
