import { useTranslation } from 'react-i18next';
import { backend } from '../../../../common/hooks/backend';
import { useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import { displaySnackbar } from '../../../../core/notifications/snackbarSlice';
import { downloadCsv, downloadHistoricalData, downloadTemplate } from '../../../../services/filesDownload';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { changeDownloadAccessDenied } from '../analystSlice';
import { Template } from '../entities/Template';
import TimeFetchingType from '../enums/timeFetchingType.enum';
import { metricTypes } from '../metricTypes';
import { useAggregationFilters } from './useAggregationFilters';

export const useReportDownload = () => {
  const { t } = useTranslation(['Analyst']);
  const {
    name,
    periodType,
    metrics,
    nbOfLastMonths,
    airports,
    countries,
    countryGroups,
    regions,
    timeFetchingType,
    columnBy,
    startYear,
    endYear,
  } = useAppSelector((state) => state.analyst.currentTemplate);
  const { getAggregationLevel } = useAggregationFilters();
  const { data: access } = useModuleAccessesQuery();
  const hasAccess = access?.some((a) => a.moduleKey === ModuleKey.Analyst);
  const isFreeTrialAccess =
    access?.find((a) => a.moduleKey === ModuleKey.Analyst)?.status === ModuleAccessStatus.Trialing;
  const freeTrialMinimumDate = new Date(2020, 11, 31);
  const freeTrialMaximumDate = new Date(2021, 11, 31);
  const dispatch = useAppDispatch();

  const allMetrics = metricTypes.flatMap((c) => c.childs).map((x) => x.value);
  const isAllMetricSelected = allMetrics.filter((x) => !metrics.includes(x)).length === 0;

  const isDownloadableInFreeTrial = (fromDate: string, toDate: string, timeType: TimeFetchingType) =>
    timeType !== TimeFetchingType.Latest &&
    new Date(fromDate) >= freeTrialMinimumDate &&
    new Date(toDate) >= freeTrialMinimumDate &&
    new Date(fromDate) <= freeTrialMaximumDate &&
    new Date(toDate) <= freeTrialMaximumDate;

  const downloadTemplateFile = async (template: Template) => {
    if (
      hasAccess &&
      (!isFreeTrialAccess ||
        (isFreeTrialAccess &&
          isDownloadableInFreeTrial(template.startYear, template.endYear, template.timeFetchingType)))
    ) {
      await downloadTemplate(template)
        .then(() => {
          dispatch(
            backend.util.updateQueryData('template', undefined, (draftTemplates: Template[]) =>
              draftTemplates.map((draftTemplate) =>
                draftTemplate.id === template.id ? { ...draftTemplate, lastExtraction: new Date() } : draftTemplate,
              ),
            ),
          );
        })
        .catch((err) => {
          console.error(err);
          dispatch(displaySnackbar({ message: t('Common:ErrorDownload'), severity: 'error' }));
        });
    } else {
      dispatch(changeDownloadAccessDenied(true));
    }
  };

  const downloadReportFile = async () => {
    if (
      hasAccess &&
      (!isFreeTrialAccess || (isFreeTrialAccess && isDownloadableInFreeTrial(startYear, endYear, timeFetchingType)))
    ) {
      await downloadCsv(
        {
          airports,
          regions,
          countries,
          countryGroups,
          startYear: timeFetchingType === TimeFetchingType.Latest ? undefined : startYear,
          endYear: timeFetchingType === TimeFetchingType.Latest ? undefined : endYear,
          metrics: isAllMetricSelected ? [] : metrics,
          periodType,
          columnBy,
          timeFetchingType,
          nbOfLastMonths: timeFetchingType === TimeFetchingType.From ? undefined : nbOfLastMonths,
          aggregationLevel: getAggregationLevel(),
        },
        name ? `${name}.csv` : 'report.csv',
      ).catch((err) => {
        console.error(err);
        dispatch(displaySnackbar({ message: t('GenerateReportError'), severity: 'error' }));
      });
    } else {
      dispatch(changeDownloadAccessDenied(true));
    }
  };

  const downloadHistoricalDataFile = async () => {
    await downloadHistoricalData().catch((err) => {
      console.error(err);
      dispatch(displaySnackbar({ message: t('GenerateReportError'), severity: 'error' }));
    });
  };

  return { downloadTemplateFile, downloadReportFile, downloadHistoricalDataFile };
};
