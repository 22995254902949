import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Button from '../../../../common/components/atoms/Button';
import LoadingPromiseButton from '../../../../common/components/atoms/LoadingPromiseButton';
import { Steps } from '../enums/steps.enum';
import { useReportDownload } from '../hooks/useReportDownload';

export interface ReportActionsProps {
  currentStep: Steps;
  isEdit: boolean;
  onBack: Function;
  onNext: Function;
  onSave: Function;
}

export default function ReportActions(props: ReportActionsProps): ReactElement {
  const { currentStep, isEdit, onBack, onNext, onSave } = props;
  const { t } = useTranslation('Analyst');
  const { downloadReportFile } = useReportDownload();

  const handleNextButton = () => {
    if (currentStep === Steps.OptionsAndPreview) onSave();
    else onNext();
  };

  const nextButtonLabel = () => {
    if (currentStep === Steps.OptionsAndPreview && isEdit) return t('Update');
    if (currentStep === Steps.OptionsAndPreview) return t('Save');
    return t('Next');
  };

  return (
    <Stack direction="row" spacing={2} paddingRight={3}>
      {currentStep !== Steps.Dataset && (
        <Button
          variant="text"
          sx={{ textDecoration: 'underline' }}
          onClick={onBack}
          data-fullstory="AnalystReportWizardBack"
        >
          {t('Back')}
        </Button>
      )}

      {currentStep === Steps.OptionsAndPreview && (
        <StyledButton variant="outlined" fullstory="AnalystReportWizardSave" onClick={downloadReportFile}>
          {t('DownloadCSV')}
        </StyledButton>
      )}

      <Button variant="contained" onClick={handleNextButton} data-fullstory="AnalystReportWizardNext">
        {nextButtonLabel()}
      </Button>
    </Stack>
  );
}

const StyledButton = styled(LoadingPromiseButton)(({ theme }) => ({
  backgroundColor: 'white',
  color: theme.palette.primaryText[100],
  borderColor: theme.palette.primaryText[100],
  '&:hover': {
    borderColor: theme.palette.primaryText[100],
  },
}));
