import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { ModuleDescription } from '../../../core/entities/moduleDescription';
import { ExternalUrlIdentifier } from '../../../services/FullStoryService';
import { BasicCardActionProps } from '../atoms/BasicCardAction';
import BasicCard from './BasicCard';

export interface ModuleUpsellingProps {
  module: ModuleDescription;
  displayActions?: boolean;
}

export default function ModuleUpselling({ module, displayActions = true }: ModuleUpsellingProps): ReactElement {
  const { t } = useTranslation('Modules');

  const handleOnClick = () => {
    if (module.purchaseLink) {
      window.open(ExternalUrlIdentifier(module.purchaseLink), '_blank');
    }
  };

  let cardAction: BasicCardActionProps | undefined;

  if (displayActions) {
    cardAction = {
      title: t('Actions.Purchase'),
      tooltip: module.comingSoon ? t('ComingSoonTooltip') : undefined,
      onClick: handleOnClick,
      onClickType: 'void',
      variant: 'contained',
      disabled: !module.purchaseLink,
    };
  }

  return (
    <BasicCard
      image="/images/Locked.png"
      title={t(`Names.${module.translateKey}`)}
      tag={module.comingSoon ? t('ComingSoonTag') : undefined}
      text={t(`Descriptions.${module.translateKey}`)}
      action={cardAction}
    />
  );
}
