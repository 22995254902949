import { useEffect } from 'react';

import * as FullStory from '@fullstory/browser';

import { useGetAccountDetailsQuery, useGetUserQuery } from './common/hooks/backend';
import { Platform } from './core/enums/platform.enum';
import { useIsCurrentPlatform } from './core/platform/hooks';
import useEmail from './modules/common/auth/hooks/useEmail';
import { defaultFirstName, defaultLastName } from './modules/common/profile/constants';
import { HashFromEmail } from './services/FullStoryService';

export default function FullStoryTracking() {
  const email = useEmail();
  const isDdpPlatform = useIsCurrentPlatform(Platform.DDP);
  const { data: accountDetails } = useGetAccountDetailsQuery(undefined, { skip: !isDdpPlatform });
  const { data: userData } = useGetUserQuery();

  useEffect(() => {
    FullStory.init({ orgId: process.env.REACT_APP_FULLSTORY_ORG! });
  }, []);

  useEffect(() => {
    if (email) {
      const displayName = `${userData?.firstName === defaultFirstName ? '' : userData?.firstName} ${
        userData?.lastName === defaultLastName ? '' : userData?.lastName
      }`.trim();
      FullStory.identify(HashFromEmail(email), {
        displayName: displayName.length === 0 ? undefined : displayName,
        email,
        organization: accountDetails?.accountName.match(/Generic_/i) ? null : accountDetails?.accountName,
      });
    }
  }, [email, userData, accountDetails]);

  return null;
}
