import { BookmarkInfo, SlicerBookmark } from '../../../../common/utils/power-bi-bookmark';
import { SubscriptionType } from '../enums/subscriptionType.enum';

export const SubscriptionSlicerValues: Record<SubscriptionType, string> = {
  Main: 'Main',
  Regional: 'Regional',
  Unique: 'Unique',
};

export interface SubscriptionBookmarkInfo {
  slicerName: string;
  mainBookmarkName?: string;
  regionalBookmarkName?: string;
  uniqueBookmarkName?: string;
}

export function generateSlicerBookmark(infos: SubscriptionBookmarkInfo[]): SlicerBookmark | null {
  const slicerNames: string[] = [];
  const bookmarkInfos: BookmarkInfo[] = [];

  const mainBookmarkInfo: BookmarkInfo = { slicerValue: SubscriptionSlicerValues.Main, bookmarkNames: [] };
  const regionalBookmarkInfo: BookmarkInfo = {
    slicerValue: SubscriptionSlicerValues.Regional,
    bookmarkNames: [],
  };
  const uniqueBookmarkInfo: BookmarkInfo = { slicerValue: SubscriptionSlicerValues.Unique, bookmarkNames: [] };

  infos.forEach(
    ({
      slicerName: slicer,
      mainBookmarkName: bookmarkMain,
      regionalBookmarkName: bookmarkRegional,
      uniqueBookmarkName: bookmarkUnique,
    }) => {
      slicerNames.push(slicer);

      if (bookmarkMain !== undefined) mainBookmarkInfo.bookmarkNames.push(bookmarkMain);
      if (bookmarkRegional !== undefined) regionalBookmarkInfo.bookmarkNames.push(bookmarkRegional);
      if (bookmarkUnique !== undefined) uniqueBookmarkInfo.bookmarkNames.push(bookmarkUnique);
    },
  );

  if (slicerNames.length > 0) {
    if (mainBookmarkInfo.bookmarkNames.length > 0) bookmarkInfos.push(mainBookmarkInfo);
    if (regionalBookmarkInfo.bookmarkNames.length > 0) bookmarkInfos.push(regionalBookmarkInfo);
    if (uniqueBookmarkInfo.bookmarkNames.length > 0) bookmarkInfos.push(uniqueBookmarkInfo);

    if (bookmarkInfos.length > 0) {
      return {
        slicerNames,
        bookmarkInfos,
      };
    }
    return null;
  }
  return null;
}
