/* eslint-disable react/no-danger */
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { Stack, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { sanitize } from 'dompurify';
import { ReactElement } from 'react';
import { AppBarTitlePortal } from '../../../../../common/components/atoms/AppBarTitlePortal';
import { FontAwesomeSvgIcon } from '../../../../../common/components/atoms/FontAwesomeIcon';

interface DashboardHeaderProps {
  title: string;
  infoMessage?: string;
}

export default function DashboardHeader({ title, infoMessage }: DashboardHeaderProps): ReactElement {
  return (
    <AppBarTitlePortal>
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
        <h4>{title}</h4>
        {infoMessage !== undefined && (
          <HtmlTooltip title={<span dangerouslySetInnerHTML={{ __html: sanitize(infoMessage) }} />}>
            <FontAwesomeSvgIcon icon={faInfoCircle} fontSize="inherit" />
          </HtmlTooltip>
        )}
      </Stack>
    </AppBarTitlePortal>
  );
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fbfbfb',
    color: 'black',
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #bfc1c6',
    maxWidth: 400,
  },
}));
