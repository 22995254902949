export default class JsonCache<Data> {
  private size: number;

  private cache: Map<string, string>;

  constructor() {
    this.cache = new Map();
    this.size = 0;
  }

  public getData(key: string): Data | undefined {
    try {
      const stringifiedData = this.cache.get(key);
      if (stringifiedData) return JSON.parse(stringifiedData);
    } catch {
      this.delete(key);
    }

    return undefined;
  }

  public setData(key: string, data: Data): void {
    this.delete(key);
    this.set(key, data);
  }

  public deleteData(key: string): void {
    this.delete(key);
  }

  public reset(): void {
    this.cache.clear();
    this.size = 0;
  }

  public getSize(): number {
    return this.size;
  }

  private set(key: string, data: Data): void {
    try {
      const stringified = JSON.stringify(data);
      this.cache.set(key, stringified);
      this.size += stringified.length;
    } catch {
      this.cache.delete(key);
    }
  }

  private delete(key: string) {
    this.size -= this.cache.get(key)?.length ?? 0;
    this.cache.delete(key);
  }
}
