import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Button from '../../../../common/components/atoms/Button';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import useCustomerPortal from '../../pricing/hooks/useCustomerPortal';
import { DdpUrls } from '../../urls';

export interface TileActionsProps {
  url: string;
  moduleName: string;
  comingSoon?: boolean;
  accessStatus?: ModuleAccessStatus;
}

export default function TileActions(props: TileActionsProps): ReactElement {
  const { url, moduleName, comingSoon, accessStatus } = props;
  const { t } = useTranslation('Modules');
  const { customerPortalUrl } = useCustomerPortal();

  if (comingSoon) {
    return (
      <Button
        sx={{ textDecoration: 'underline', paddingY: 2 }}
        component={Link}
        variant="text"
        to={url}
        role="link"
        data-fullstory={`HomeLearn${moduleName}`}
      >
        {t('Actions.Learn')}
      </Button>
    );
  }
  return (
    <>
      <Button
        sx={{ paddingY: 2 }}
        component={Link}
        variant="contained"
        to={url}
        role="link"
        data-fullstory={`HomeOpen${moduleName}`}
      >
        {t('Actions.Open')}
      </Button>
      {accessStatus === undefined ? (
        <Button
          sx={{ mr: 2, paddingY: 2 }}
          variant="outlined"
          href={DdpUrls.Pricing}
          data-fullstory={`HomeBuy${moduleName}`}
        >
          {t('Actions.Purchase')}
        </Button>
      ) : (
        accessStatus !== ModuleAccessStatus.FreeAccess && (
          <Button
            sx={{ paddingY: 2 }}
            variant="outlined"
            href={customerPortalUrl}
            target="_blank"
            role="link"
            data-fullstory={`HomeOpen${moduleName}`}
          >
            {t('Actions.Manage')}
          </Button>
        )
      )}
    </>
  );
}
