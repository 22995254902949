import { ReactElement } from 'react';

import { IconDefinition, faArrowDown, faArrowUp } from '@fortawesome/pro-light-svg-icons';
import { Box, Typography } from '@mui/material';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { PeriodDate } from '../../../../core/entities/periodDate';

interface VariationProps {
  value?: number;
  date?: PeriodDate | null;
}

export default function Variation({ value, date }: VariationProps): ReactElement | null {
  if (value === undefined || date === undefined || date === null) return null;

  const color = getVariationColor(value);
  const icon = getVariationIcon(value);

  return (
    <Typography component="div" variant="pSmall" color={color} ml={1.5} fontWeight="bold">
      <Box display="flex" alignItems="center">
        <FontAwesomeSvgIcon icon={icon} fontSize="inherit" />
      </Box>
    </Typography>
  );
}

function getVariationColor(value: number): string {
  if (value < 0) return 'red';
  if (value === 0) return 'gray';
  return 'green';
}

function getVariationIcon(value: number): IconDefinition {
  if (value < 0) return faArrowDown;
  return faArrowUp;
}
