export enum AirportFileCategory {
  AirportPerformanceReport = 'Airport Performance Report',
  BenchmarkPerformanceReport = 'Benchmark Performance Report',
  AnnualSatisfactionAssessment = 'Annual Satisfaction Assessment',
  ComprehensiveCXReport = 'Comprehensive CX Report',
  TerminalReport = 'Terminal Report',
  GroupReport = 'Group Report',
  MonthlyReport = 'Monthly Report',
  MonthlyRawData = 'Monthly Raw Data',
  CustomizedReport = 'Customized Report',
  VerbatimsBestExperience = 'Verbatims Best Experience',
  VerbatimsWorstExperience = 'Verbatims Worst Experience',
  DataQualityComplianceReport = 'Data Quality Compliance Report',
}

export enum MemberFileCategory {
  DatabaseSPSSPlus = 'Database SPSS - Plus',
  DatabaseExcelPlus = 'Database Excel - Plus',
  DatabaseSPSSTablet = 'Database SPSS - Tablet',
  DatabaseExcelTablet = 'Database Excel - Tablet',
  DatabaseSPSSPaper = 'Database SPSS - Paper',
  DatabaseExcelPaper = 'Database Excel - Paper',
  DatabaseSPSS = 'Database SPSS',
  DatabaseExcel = 'Database Excel',
  ParticipatingAirports = 'Participating Airports',
  CleaningAndEditingRules = 'Cleaning And Editing Rules',
}

export enum PublicFileCategory {
  TrainingContent = 'Training Content',
  Barometer = 'Barometer',
  ResearchAndBestPracticeReports = 'Research and Best Practice Reports',
  Snapshots = 'Snapshots',
}
