import { ReactElement } from 'react';
import { useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import ExecutiveHomePage from '../pages/ExecutiveHomePage';

export default function ExecutiveContainer(): ReactElement {
  const { data: access, isLoading } = useModuleAccessesQuery();
  const moduleAccess = access?.filter((a) => a.moduleKey === ModuleKey.Executive);
  const hasAccess = !isLoading && moduleAccess?.length !== 0;
  const isFreeTrial = hasAccess && !!moduleAccess?.every((a) => a.status === ModuleAccessStatus.Trialing);

  return <ExecutiveHomePage hasAccess={hasAccess} isFreeTrial={isFreeTrial} />;
}
