import { ReactElement } from 'react';

import { TableCell, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '../../../../common/components/atoms/Button';
import TruncatedTypograhpy from '../../../../common/components/atoms/TruncatedTypography';
import { TransformedFileDto } from '../entities/File';
import { getCleanedExtension, getFileExtensionIcon } from '../helpers/FileHelpers';
import useFileAccessValidator from '../hooks/useFileAccessValidator';

interface SearchedFileRowProps {
  file: TransformedFileDto;
}

export default function SearchedFileRow({ file }: SearchedFileRowProps): ReactElement {
  const { t } = useTranslation('Library');

  const FileIcon = getFileExtensionIcon(file.extension);
  const formatedFileDate = new Date(file.lastModified).toISOString().split('T')[0];
  const { openFileDetails } = useFileAccessValidator();

  const handleReadMore = (selectedFile: TransformedFileDto) => {
    openFileDetails(selectedFile);
  };

  return (
    <>
      <TableCell component="th" scope="row">
        {file.name}
      </TableCell>
      <TableCell>
        <Typography display="flex" sx={{ textTransform: 'uppercase' }}>
          <FileIcon sx={{ color: 'inherit', mr: 1, height: 21 }} />
          {getCleanedExtension(file.extension)}
        </Typography>
      </TableCell>
      <TableCell>{formatedFileDate}</TableCell>
      <TableCell>
        <TruncatedTypograhpy shownLines={1}>{file.description}</TruncatedTypograhpy>
      </TableCell>
      <TableCell align="right">
        <Button sx={{ textDecoration: 'underline', minWidth: 100 }} onClick={() => handleReadMore(file)}>
          {t('ReadMore')}
        </Button>
      </TableCell>
    </>
  );
}
