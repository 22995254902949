import { Comparer } from '../../../../common/components/types/TableTypes';
import { generateValuesComparer, numberStringComparer, reverseComparer } from '../../../../common/utils/comparer';
import { AirportFile } from '../entities/AirportFile';
import BaseFile from '../entities/BaseFile';
import { MemberFile } from '../entities/MemberFile';
import { PublicFile } from '../entities/PublicFile';
import { AirportFileCategory, MemberFileCategory, PublicFileCategory } from '../enums/FileCategory.enum';
import FileModule from '../enums/FileModule.enum';
import FilePeriod from '../enums/FilePeriod.enum';

const periodValuesSorted = [
  FilePeriod.Annual,
  FilePeriod.Q4,
  FilePeriod.Q3,
  FilePeriod.Q2,
  FilePeriod.Q1,
  FilePeriod.Winter,
  FilePeriod.Summer,
];

const moduleValuesSorted = [FileModule.Departure, FileModule.Arrival, FileModule.Commercial];

const categoryValuesSorted = [
  AirportFileCategory.AirportPerformanceReport,
  AirportFileCategory.BenchmarkPerformanceReport,
  AirportFileCategory.AnnualSatisfactionAssessment,
  AirportFileCategory.ComprehensiveCXReport,
  AirportFileCategory.TerminalReport,
  AirportFileCategory.GroupReport,
  AirportFileCategory.MonthlyReport,
  AirportFileCategory.MonthlyRawData,
  AirportFileCategory.CustomizedReport,
  AirportFileCategory.VerbatimsBestExperience,
  AirportFileCategory.VerbatimsWorstExperience,
  AirportFileCategory.DataQualityComplianceReport,
  MemberFileCategory.DatabaseSPSSPlus,
  MemberFileCategory.DatabaseExcelPlus,
  MemberFileCategory.DatabaseSPSSTablet,
  MemberFileCategory.DatabaseExcelTablet,
  MemberFileCategory.DatabaseSPSSPaper,
  MemberFileCategory.DatabaseExcelPaper,
  MemberFileCategory.DatabaseSPSS,
  MemberFileCategory.DatabaseExcel,
  MemberFileCategory.ParticipatingAirports,
  MemberFileCategory.CleaningAndEditingRules,
  PublicFileCategory.TrainingContent,
  PublicFileCategory.Barometer,
  PublicFileCategory.ResearchAndBestPracticeReports,
  PublicFileCategory.Snapshots,
];

export const airportCodeComparer: Comparer<string> = (a, b) => a.localeCompare(b);

export const periodComparer = generateValuesComparer(periodValuesSorted);

export const yearComparer = reverseComparer(numberStringComparer);

export const moduleComparer = generateValuesComparer(moduleValuesSorted);

export const categoryComparer = generateValuesComparer(categoryValuesSorted);

export const airportCodeFileComparer: Comparer<AirportFile> = (fileLeft, fileRight) =>
  airportCodeComparer(fileLeft.airportCode, fileRight.airportCode);

export const periodFileComparer: Comparer<BaseFile> = (fileLeft, fileRight) =>
  periodComparer(fileLeft.period, fileRight.period);

export const yearFileComparer: Comparer<BaseFile> = (fileLeft, fileRight) =>
  yearComparer(fileLeft.year, fileRight.year);

export const moduleFileComparer: Comparer<AirportFile | MemberFile> = (fileLeft, fileRight) =>
  moduleComparer(fileLeft.module, fileRight.module);

export const categoryFileComparer: Comparer<BaseFile> = (fileLeft, fileRight) =>
  categoryComparer(fileLeft.category, fileRight.category);

export const titleComparer: Comparer<string> = (a, b) => a.localeCompare(b);

export const titleFileComparer: Comparer<PublicFile> = (fileLeft, fileRight) =>
  titleComparer(fileLeft.title, fileRight.title);
