/* eslint-disable no-bitwise */
import { StringDict } from '@azure/msal-common';
import { msalInstance } from '../core/Auth/MsalInstance';

export const ExternalUrlIdentifier = (url: string | undefined) => {
  const idToken = msalInstance.getAllAccounts()[0]?.idTokenClaims! as StringDict;
  const email = idToken?.emails[0];
  if (!email || !url) return url;
  const hash = HashFromEmail(email);
  return `${url}?fsuid=${hash}`;
};

export const HashFromEmail = (email: string) => {
  let hash = 0;
  let i;
  let chr;
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < email.length; i++) {
    chr = email.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash.toString();
};
