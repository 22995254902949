import { Container, Grid } from '@mui/material';
import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useDrawerOpen } from '../../../../common/components/context/DrawerContext';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import {
    useArrivalLatestStatisticQuery,
    useCommercialLatestStatisticQuery,
    useDepartureLatestStatisticQuery,
} from '../../../../common/hooks/backend';
import { useAsqAccess } from '../../../../common/utils/accessHelper';
import { AsqAccess } from '../../../../core/entities/asqAccess';
import { FeatureFlag } from '../../../../core/featureFlags/FeatureFlag';
import { FeatureFlagKey } from '../../../../core/featureFlags/featureFlagKeys';
import { ArrivalModule, CommercialModule, DepartureModule } from '../../asqModules';
import FileModule from '../../repository/enums/FileModule.enum';
import RepositoryLocationState from '../../repository/types/RepositoryLocationState';
import { AsqUrls } from '../../urls';
import ConfidentialityBanner from '../atoms/ConfidentialityBanner';
import { useHomePageFilter, useIsHomePageAiportFilterReady } from '../filter/hooks';
import ModuleTile from './ModuleTile';
import RepositoryTile from './RepositoryTile';

interface HomeTileFeature {
  TileComponent: React.FunctionComponent<HomeTileProps>;
  flagKey?: FeatureFlagKey | FeatureFlagKey[];
}

const tiles: HomeTileFeature[] = [
  { TileComponent: DepartureTile, flagKey: FeatureFlagKey.HomeDeparture },
  { TileComponent: ArrivalTile, flagKey: FeatureFlagKey.HomeArrival },
  { TileComponent: CommercialTile, flagKey: FeatureFlagKey.HomeCommercial },
  { TileComponent: RepositoryTileWrapper, flagKey: FeatureFlagKey.HomeRepository },
];

export default function TileGrid(): ReactElement {
  const { drawerOpen } = useDrawerOpen();
  const { access } = useAsqAccess();
  const { airportCode } = useHomePageFilter();
  const isAiportFilterReady = useIsHomePageAiportFilterReady();

  if (access === undefined || !isAiportFilterReady) {
    return <LoadingSpinner />;
  }

  return (
    <Container maxWidth="lg" disableGutters>
      <Grid container spacing={4} mt={1} mb={5} role="list" alignItems="stretch">
        {tiles.map(({ TileComponent, flagKey }) => (
          <FeatureFlag flagKey={flagKey} key={TileComponent.name}>
            <Grid item xs={12} md={drawerOpen ? 12 : 6} lg={6} role="listitem" container justifyContent="center">
              <TileComponent access={access} airportCode={airportCode} />
            </Grid>
          </FeatureFlag>
        ))}
        <Grid item xs={12} role="listitem" container justifyContent="center">
          <ConfidentialityBanner />
        </Grid>
      </Grid>
    </Container>
  );
}

interface HomeTileProps {
  access: AsqAccess;
  airportCode: string | null;
}

function DepartureTile({ access, airportCode }: HomeTileProps): ReactElement {
  const history = useHistory<RepositoryLocationState>();

  const hasAccess = airportCode !== null && (access.airportAccesses[airportCode]?.departure ?? false);

  const { data, isFetching } = useDepartureLatestStatisticQuery(airportCode ?? '', {
    skip: !hasAccess,
  });

  return (
    <ModuleTile
      module={DepartureModule}
      hasAccess={hasAccess}
      statistic={data}
      isStatisticLoading={isFetching || data === undefined}
      onRepositoryOpen={() => {
        history.push(AsqUrls.RepositoryAirportReports, {
          module: FileModule.Departure,
        });
      }}
    />
  );
}

function ArrivalTile({ access, airportCode }: HomeTileProps): ReactElement {
  const history = useHistory<RepositoryLocationState>();

  const hasAccess = airportCode !== null && (access.airportAccesses[airportCode]?.arrival ?? false);

  const { data, isFetching } = useArrivalLatestStatisticQuery(airportCode ?? '', {
    skip: !hasAccess,
  });

  return (
    <ModuleTile
      module={ArrivalModule}
      hasAccess={hasAccess}
      statistic={data}
      isStatisticLoading={isFetching || data === undefined}
      onRepositoryOpen={() => {
        history.push(AsqUrls.RepositoryAirportReports, {
          module: FileModule.Arrival,
        });
      }}
    />
  );
}

function CommercialTile({ access, airportCode }: HomeTileProps): ReactElement {
  const history = useHistory<RepositoryLocationState>();

  const hasAccess = airportCode !== null && (access.airportAccesses[airportCode]?.commercial ?? false);

  const { data, isFetching } = useCommercialLatestStatisticQuery(airportCode ?? '', {
    skip: !hasAccess,
  });

  return (
    <ModuleTile
      module={CommercialModule}
      hasAccess={hasAccess}
      statistic={data}
      isStatisticLoading={isFetching || data === undefined}
      onRepositoryOpen={() => {
        history.push(AsqUrls.RepositoryAirportReports, {
          module: FileModule.Commercial,
        });
      }}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function RepositoryTileWrapper({ access, airportCode }: HomeTileProps): ReactElement {
  return <RepositoryTile hasAccess={access.hasRepositoryAccess} />;
}
