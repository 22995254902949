import { useMemo } from 'react';
import { AsqAccess, AsqAirport, AsqAirportAccess } from '../../core/entities/asqAccess';
import { AsqAccessDto, AsqAirportAccessDto } from '../../core/entities/asqAccessDto';
import { AsqAdditionalAirportAccessDto } from '../../core/entities/asqAdditionalAirportAccessDto';
import { AsqAirportType } from '../../core/enums/asqAirportType.enum';
import { Module } from '../../core/enums/module.enum';
import {
  useAsqAccessQuery,
  useUserAvailableCommentsAirportsSubscriptionsQuery,
  useUserAvailableDissatisfiedPassengerAirportsSubscriptionsQuery,
} from '../hooks/backend';

class AsqAccessBuilder {
  private airportMap: Map<string, AsqAirport>;

  private accessDto: AsqAccessDto;

  private airportsDepartureAccessDto: AsqAirportAccessDto[];

  private airportsArrivalAccessDto: AsqAirportAccessDto[];

  private airportsCommercialAccessDto: AsqAirportAccessDto[];

  private groupsDepartureAccessDto: AsqAirportAccessDto[];

  private groupsArrivalAccessDto: AsqAirportAccessDto[];

  private groupsCommercialAccessDto: AsqAirportAccessDto[];

  private access: AsqAccess;

  constructor(
    accessDto: AsqAccessDto,
    commentsAnalysisAccess: AsqAdditionalAirportAccessDto,
    dissatisfiedPassengerAccess: AsqAdditionalAirportAccessDto,
  ) {
    this.airportMap = new Map<string, AsqAirport>();
    this.accessDto = accessDto;
    [this.groupsDepartureAccessDto, this.airportsDepartureAccessDto] = splitAirportsAndGroups(
      this.accessDto.departure.airportAccesses,
      (e) => e.type === AsqAirportType.Operator,
    );
    [this.groupsArrivalAccessDto, this.airportsArrivalAccessDto] = splitAirportsAndGroups(
      this.accessDto.arrival.airportAccesses,
      (e) => e.type === AsqAirportType.Operator,
    );
    [this.groupsCommercialAccessDto, this.airportsCommercialAccessDto] = splitAirportsAndGroups(
      this.accessDto.commercial.airportAccesses,
      (e) => e.type === AsqAirportType.Operator,
    );
    this.access = {
      hasDepartureGeneralAccess: false,
      hasArrivalGeneralAccess: false,
      hasCommercialGeneralAccess: false,
      hasRepositoryAccess: false,
      hasAccess: false,
      availableAirportsWithGroups: [],
      availableAirportsWithoutGroups: [],
      airportAccesses: {},
      additionalAccess: {
        commentsAnalysis: { ...commentsAnalysisAccess },
        dissatisfiedPassenger: { ...dissatisfiedPassengerAccess },
      },
    };
  }

  public build(): AsqAccess {
    this.generateGeneralAccesses();
    this.generateModuleAccesses();
    this.generateAvailableAirports();
    return this.access;
  }

  private generateGeneralAccesses(): void {
    this.access.hasDepartureGeneralAccess = this.airportsDepartureAccessDto.length > 0;
    this.access.hasArrivalGeneralAccess = this.airportsArrivalAccessDto.length > 0;
    this.access.hasCommercialGeneralAccess = this.airportsCommercialAccessDto.length > 0;
    const hasAccess =
      this.access.hasDepartureGeneralAccess ||
      this.access.hasArrivalGeneralAccess ||
      this.access.hasCommercialGeneralAccess ||
      this.groupsDepartureAccessDto.length > 0 ||
      this.groupsArrivalAccessDto.length > 0 ||
      this.groupsCommercialAccessDto.length > 0;
    this.access.hasRepositoryAccess = hasAccess;
    this.access.hasAccess = hasAccess;
  }

  private generateModuleAccesses(): void {
    this.generateAccessForModule(Module.Departure, this.accessDto.departure.airportAccesses);
    this.generateAccessForModule(Module.Arrival, this.accessDto.arrival.airportAccesses);
    this.generateAccessForModule(Module.Commercial, this.accessDto.commercial.airportAccesses);
  }

  private generateAccessForModule(module: Module, airportAccesses: AsqAirportAccessDto[]) {
    const moduleAccess: AsqAirportAccess = {
      departure: module === Module.Departure,
      arrival: module === Module.Arrival,
      commercial: module === Module.Commercial,
    };

    airportAccesses.forEach(({ airportCode, airportName, type }) => {
      if (!this.airportMap.has(airportCode))
        this.airportMap.set(airportCode, { code: airportCode, name: airportName, type });

      const existingModuleAccess = this.access.airportAccesses[airportCode];

      if (existingModuleAccess !== undefined) {
        existingModuleAccess.departure = existingModuleAccess.departure || moduleAccess.departure;
        existingModuleAccess.arrival = existingModuleAccess.arrival || moduleAccess.arrival;
        existingModuleAccess.commercial = existingModuleAccess.commercial || moduleAccess.commercial;
      } else {
        this.access.airportAccesses[airportCode] = { ...moduleAccess };
      }
    });
  }

  private generateAvailableAirports(): void {
    this.access.availableAirportsWithGroups = Array.from(this.airportMap.values());
    this.access.availableAirportsWithoutGroups = this.access.availableAirportsWithGroups.filter(
      (x) => x.type === AsqAirportType.Airport,
    );
  }
}

function splitAirportsAndGroups(array: AsqAirportAccessDto[], isValid: (elem: AsqAirportAccessDto) => boolean) {
  return array.reduce(([pass, fail], elem) => (isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]]), [
    [],
    [],
  ] as AsqAirportAccessDto[][]);
}

export function mapAsqAccessDto(
  accessDto: AsqAccessDto,
  commentsAnalysisAccess: AsqAdditionalAirportAccessDto,
  dissatisfiedPassengerAccess: AsqAdditionalAirportAccessDto,
): AsqAccess {
  return new AsqAccessBuilder(accessDto, commentsAnalysisAccess, dissatisfiedPassengerAccess).build();
}

export function hasAccessForAirportAndModule(access: AsqAccess, airportCode: string, module: Module): boolean {
  if (access.availableAirportsWithoutGroups.find(({ code }) => code === airportCode) === undefined) return false; // if airportCode is one of an operator

  switch (module) {
    case Module.Departure:
      return access.airportAccesses[airportCode]?.departure ?? false;
    case Module.Arrival:
      return access.airportAccesses[airportCode]?.arrival ?? false;
    case Module.Commercial:
      return access.airportAccesses[airportCode]?.commercial ?? false;
    default:
      return false;
  }
}

interface AsqAccessHookReturnType {
  access: AsqAccess | undefined;
  isLoading: boolean;
}

export function useAsqAccess(skip?: boolean): AsqAccessHookReturnType {
  const { data: accessDto, isLoading: isAccessLoading } = useAsqAccessQuery(undefined, { skip });
  const { data: commentsAnalysisAccess, isLoading: isCommentAnalysisLoading } =
    useUserAvailableCommentsAirportsSubscriptionsQuery(undefined, { skip });
  const { data: dissatisfiedPassengerAccess, isLoading: isDissatisfiedPassengerLoading } =
    useUserAvailableDissatisfiedPassengerAirportsSubscriptionsQuery(undefined, { skip });

  return useMemo<AsqAccessHookReturnType>(() => {
    if (accessDto === undefined || commentsAnalysisAccess === undefined || dissatisfiedPassengerAccess === undefined)
      return {
        access: undefined,
        isLoading: isAccessLoading || isCommentAnalysisLoading || isDissatisfiedPassengerLoading,
      };
    return {
      access: mapAsqAccessDto(accessDto, commentsAnalysisAccess, dissatisfiedPassengerAccess),
      isLoading: isAccessLoading || isCommentAnalysisLoading || isDissatisfiedPassengerLoading,
    };
  }, [
    accessDto,
    isAccessLoading,
    commentsAnalysisAccess,
    isCommentAnalysisLoading,
    dissatisfiedPassengerAccess,
    isDissatisfiedPassengerLoading,
  ]);
}
