import { useCallback, useReducer } from 'react';

import BaseFile, { FileId } from '../../entities/BaseFile';
import FileFilter from '../../types/FileFilter';
import { resetFilters, setFiles, setFilterValues } from './actions';
import { FileFilterSystemReducerType, reducer } from './reducer';
import { FileFilterSystemState } from './types';

interface FileFilterSystemHookReturnType<File extends BaseFile> {
  filters: FileFilter<File>[];
  filteredFiles: File[];
  getFile: (id: FileId) => File | undefined;
  setFiles: (files: File[]) => void;
  resetFilters: () => void;
}

export function useFileFilterSystem<File extends BaseFile>(
  initialState: FileFilterSystemState<File>,
  isFilterLoading: boolean = false,
): FileFilterSystemHookReturnType<File> {
  const [state, dispatch] = useReducer<FileFilterSystemReducerType<File>>(reducer, initialState);

  const filters: FileFilter<File>[] = state.filters.map((filter) => ({
    ...filter,
    isLoading: isFilterLoading,
    onChange: (values) => {
      dispatch(setFilterValues({ filterId: filter.id, values }));
    },
  }));

  const setFilesCallback = useCallback(
    (files: File[]) => {
      dispatch(setFiles(files));
    },
    [dispatch],
  );

  const resetFiltersCallback = useCallback(() => {
    dispatch(resetFilters());
  }, [dispatch]);

  const getFile = useCallback((id: FileId) => state.filesMap[id], [state.filesMap]);

  return {
    filters,
    getFile,
    filteredFiles: state.filteredFiles,
    setFiles: setFilesCallback,
    resetFilters: resetFiltersCallback,
  };
}
