import fileDownload from 'js-file-download';

import { downloadFileBlob } from '../../../../services/filesDownload';
import { AirportFile } from '../entities/AirportFile';
import BaseFile from '../entities/BaseFile';
import { MemberFile } from '../entities/MemberFile';
import { PublicFile } from '../entities/PublicFile';
import FileModule from '../enums/FileModule.enum';
import { getFileName } from './file';

export interface FileBlobData {
  data: Blob;
  filename: string;
  folder?: string;
}

function getAirportFileDownloadUrl(file: AirportFile): string | null {
  switch (file.module) {
    case FileModule.Departure:
      return `Departure/Files/Airport/Download/${file.id}`;
    case FileModule.Arrival:
      return `Arrival/Files/Airport/Download/${file.id}`;
    case FileModule.Commercial:
      return `Commercial/Files/Airport/Download/${file.id}`;
    default:
      return null;
  }
}

function getMemberFileDownloadUrl(file: MemberFile): string | null {
  switch (file.module) {
    case FileModule.Departure:
      return `Departure/Files/Member/Download/${file.id}`;
    case FileModule.Arrival:
      return `Arrival/Files/Member/Download/${file.id}`;
    case FileModule.Commercial:
      return `Commercial/Files/Member/Download/${file.id}`;
    default:
      return null;
  }
}

function getPublicFileDownloadUrl(file: PublicFile): string | null {
  return `Files/Asq/Download/${file.id}`;
}

function downloadBaseFileBlob(file: BaseFile, url: string | null, folder?: string): Promise<FileBlobData> {
  return new Promise<FileBlobData>((resolve, reject) => {
    if (url === null) {
      reject(new Error(`No url was found for the file.`));
    } else {
      downloadFileBlob(url)
        .then((blob) => {
          resolve({ data: blob, filename: getFileName(file), folder });
        })
        .catch(reject);
    }
  });
}

export function downloadAirportFileBlob(file: AirportFile): Promise<FileBlobData> {
  const url = getAirportFileDownloadUrl(file);
  const folder = `${file.airportCode}/${file.module}`;
  return downloadBaseFileBlob(file, url, folder);
}

export function downloadAirportFile(file: AirportFile): Promise<void> {
  return downloadAirportFileBlob(file).then(({ filename, data }) => fileDownload(data, filename));
}

export function downloadMemberFileBlob(file: MemberFile): Promise<FileBlobData> {
  const url = getMemberFileDownloadUrl(file);
  return downloadBaseFileBlob(file, url, file.module);
}

export function downloadMemberFile(file: MemberFile): Promise<void> {
  return downloadMemberFileBlob(file).then(({ filename, data }) => fileDownload(data, filename));
}

export function downloadPublicFileBlob(file: PublicFile): Promise<FileBlobData> {
  const url = getPublicFileDownloadUrl(file);
  return downloadBaseFileBlob(file, url);
}

export function downloadPublicFile(file: PublicFile): Promise<void> {
  return downloadPublicFileBlob(file).then(({ filename, data }) => fileDownload(data, filename));
}
