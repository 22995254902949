import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';

import { Link } from 'react-router-dom';

import { useCurrentPlatformHomeUrl } from '../../../core/platform/hooks';
import Button from '../atoms/Button';

export default function MaintenancePage(): ReactElement {
  const homeUrl = useCurrentPlatformHomeUrl();

  return (
    <Stack height="100%" justifyContent="center" alignItems="center" spacing={5}>
      <img alt="" src="images/Locked.png" width="200px" />

      <Typography textAlign="center" variant="h1" fontWeight="800" color="primary">
        Under maintenance!
      </Typography>
      <Typography textAlign="center" variant="h3" fontWeight="200" color="primary" m={0}>
        This section is under maintenance, please come back later.
      </Typography>

      <Button component={Link} to={homeUrl} variant="contained">
        Back to home
      </Button>
    </Stack>
  );
}
