import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { Module } from '../../../../core/enums/module.enum';
import { FeatureFlagKey } from '../../../../core/featureFlags/featureFlagKeys';
import { useFeatureFlag } from '../../../../core/featureFlags/hooks';
import TileActions, { ActionButtonProps } from './TileActions';

export interface ModuleTileActionsProps {
  hasAccess: boolean;
  url: string;
  moduleName: Module;
  onSecondaryActionClick?: () => void;
}

export default function ModuleTileActions({
  hasAccess,
  url,
  moduleName,
  onSecondaryActionClick,
}: ModuleTileActionsProps): ReactElement {
  const { t } = useTranslation('Home', { keyPrefix: 'ASQ' });
  const enableReportNavigation = useFeatureFlag(getReportFeatureFlagFromModuleName(moduleName));
  const enableRepositoryNavigation = useFeatureFlag(FeatureFlagKey.RepositoryAirport);

  let primaryAction: ActionButtonProps | undefined;
  let secondaryAction: ActionButtonProps | undefined;

  if (hasAccess) {
    if (enableReportNavigation) {
      primaryAction = {
        id: `HomeOpen${moduleName}`,
        title: t('Cards.Actions.Open'),
        variant: 'outlined',
        url,
        margin: true,
      };
    }

    if (enableRepositoryNavigation) {
      secondaryAction = {
        id: `HomeDownloadReport${moduleName}`,
        title: t('Cards.Actions.NavigateReports'),
        variant: 'outlined',
        onClick: onSecondaryActionClick,
      };
    }
  } else {
    primaryAction = {
      id: `HomeLearnMore${moduleName}`,
      title: t('Cards.Actions.LearnMore'),
      variant: 'outlined',
      url,
    };
  }

  return <TileActions primaryAction={primaryAction} secondaryAction={secondaryAction} />;
}

function getReportFeatureFlagFromModuleName(moduleName: Module): FeatureFlagKey | undefined {
  switch (moduleName) {
    case Module.Departure:
      return FeatureFlagKey.DepartureReports;
    case Module.Arrival:
      return FeatureFlagKey.ArrivalReports;
    case Module.Commercial:
      return FeatureFlagKey.CommercialReports;
    default:
      return undefined;
  }
}
