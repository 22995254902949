import { ReactElement } from 'react';

import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function FaqLinkText(): ReactElement {
  const { t } = useTranslation(['Analyst']);

  return (
    <Typography variant="pMedium" fontWeight="bold" color="primary">
      {t('CheckOur')}{' '}
      <Link color="inherit" href="/faq#question-5" target="_blank">
        {t('Faq')}
      </Link>{' '}
      {t('QuestionsAboutModule')}
    </Typography>
  );
}
