import {
  faFolder,
  faGraduationCap,
  faHome,
  faPlaneArrival,
  faPlaneDeparture,
  faShoppingBag,
} from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeSvgIcon } from '../../common/components/atoms/FontAwesomeIcon';
import { NavItem, NavItemType } from '../../common/types/NavItem';
import { AsqAccess } from '../../core/entities/asqAccess';
import {
  AsqArrivalModuleFlagKeys,
  AsqCommercialModuleFlagKeys,
  AsqDepartureModuleFlagKeys,
  AsqRepositoryModuleFlagKeys,
  FeatureFlagKey,
} from '../../core/featureFlags/featureFlagKeys';
import { ArrivalUrls, AsqUrls, CommercialUrls, DepartureUrls, RepositoryUrls } from './urls';

type AsqNavItem = NavItem<AsqAccess>;

const HomeNav: AsqNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faHome} />,
  url: AsqUrls.Home,
  translateKey: 'Home',
};

export const DepartureModuleNav: AsqNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faPlaneDeparture} />,
  url: AsqUrls.DepartureOverview,
  translateKey: 'DepartureModule',
  getType: () => NavItemType.Menu,
  isSelected: (pathname) => DepartureUrls.includes(pathname),
  featureFlagKey: AsqDepartureModuleFlagKeys,
  items: [
    {
      url: AsqUrls.DepartureOverview,
      translateKey: 'DepartureOverview',
      getHasAccess: (access) => access?.hasDepartureGeneralAccess ?? false,
    },
    {
      url: AsqUrls.DeparturePassengerJourney,
      translateKey: 'DeparturePassengerJourney',
      getHasAccess: (access) => access?.hasDepartureGeneralAccess ?? false,
    },
    {
      url: AsqUrls.DepartureBenchmarkingPerf,
      translateKey: 'DepartureBenchmarking',
      getHasAccess: (access) => access?.hasDepartureGeneralAccess ?? false,
      isSelected: (pathname) =>
        [
          AsqUrls.DepartureBenchmarkingPerf,
          AsqUrls.DepartureBenchmarkingTrendPerf,
          AsqUrls.DeparturePassProBenchmarking,
        ].includes(pathname),
    },
    {
      url: AsqUrls.DeparturePassProDemographics,
      translateKey: 'DeparturePassengerProfile',
      getHasAccess: (access) => access?.hasDepartureGeneralAccess ?? false,
      isSelected: (pathname) =>
        [
          AsqUrls.DeparturePassProDemographics,
          AsqUrls.DeparturePassProDemographicsBySegment,
          AsqUrls.DeparturePassProDemographicsTrendOvertime,
          AsqUrls.DeparturePassProTravelBehavior,
          AsqUrls.DeparturePassProTravelBehaviorBySegment,
          AsqUrls.DeparturePassProTravelBehaviorTrendOvertime,
          AsqUrls.DeparturePassProTravelProfile,
          AsqUrls.DeparturePassProTravelProfileBySegment,
          AsqUrls.DeparturePassProTravelProfileTrendOvertime,
        ].includes(pathname),
    },
    {
      url: AsqUrls.DepartureGapAnalysis,
      translateKey: 'DepartureGapAnalysis',
      getHasAccess: (access) => access?.hasDepartureGeneralAccess ?? false,
    },
    {
      url: AsqUrls.DepartureCustomAnalysis,
      translateKey: 'DepartureCustomAnalysis',
      getHasAccess: (access) => access?.hasDepartureGeneralAccess ?? false,
    },
    {
      url: AsqUrls.DepartureCommentsAnalysisOverview,
      translateKey: 'DepartureCommentsAnalysis',
      getHasAccess: (access) => access?.hasDepartureGeneralAccess ?? false,
      isLocked: (access) => (access?.additionalAccess?.commentsAnalysis?.departures?.length ?? 0) === 0,
      lockedTooltipTK: 'DepartureCommentsAnalysisLockedTooltip',
      isSelected: (pathname) =>
        [
          AsqUrls.DepartureCommentsAnalysisOverview,
          AsqUrls.DepartureCommentsAnalysisFrequency,
          AsqUrls.DepartureCommentsAnalysisTrendOverTime,
          AsqUrls.DepartureCommentsAnalysisWordCloud,
        ].includes(pathname),
    },
    {
      url: AsqUrls.DepartureDissatisfiedPassengerOverview,
      translateKey: 'DepartureDissatisfiedPassenger',
      featureFlagKey: FeatureFlagKey.DppReports,
      getHasAccess: (access) => access?.hasDepartureGeneralAccess ?? false,
      isLocked: (access) => (access?.additionalAccess?.dissatisfiedPassenger?.departures?.length ?? 0) === 0,
      lockedTooltipTK: 'DepartureDissatisfiedPassengerLockedTooltip',
      isSelected: (pathname) =>
        [
          AsqUrls.DepartureDissatisfiedPassengerOverview,
          AsqUrls.DepartureDissatisfiedPassengerFrequency,
          AsqUrls.DepartureDissatisfiedPassengerTrendOverTime,
          AsqUrls.DepartureDissatisfiedPassengerWordCloud,
        ].includes(pathname),
    },
  ],
};

export const ArrivalModuleNav: AsqNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faPlaneArrival} />,
  url: AsqUrls.ArrivalOverview,
  translateKey: 'ArrivalModule',
  getType: () => NavItemType.Menu,
  isSelected: (pathname) => ArrivalUrls.includes(pathname),
  featureFlagKey: AsqArrivalModuleFlagKeys,
  items: [
    {
      url: AsqUrls.ArrivalOverview,
      translateKey: 'ArrivalOverview',
      getHasAccess: (access) => access?.hasArrivalGeneralAccess ?? false,
    },
  ],
};

export const CommercialModuleNav: AsqNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faShoppingBag} />,
  url: AsqUrls.CommercialOverview,
  translateKey: 'CommercialModule',
  getType: () => NavItemType.Menu,
  isSelected: (pathname) => CommercialUrls.includes(pathname),
  featureFlagKey: AsqCommercialModuleFlagKeys,
  items: [
    {
      url: AsqUrls.CommercialOverview,
      translateKey: 'CommercialOverview',
      getHasAccess: (access) => access?.hasCommercialGeneralAccess ?? false,
    },
  ],
};

const RepositoryModuleNav: AsqNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faFolder} />,
  url: AsqUrls.RepositoryAirportReports,
  translateKey: 'RepositoryModule',
  getType: () => NavItemType.Menu,
  isSelected: (pathname) => RepositoryUrls.includes(pathname),
  featureFlagKey: AsqRepositoryModuleFlagKeys,
  items: [
    {
      url: AsqUrls.RepositoryAirportReports,
      translateKey: 'RepositoryAirport',
      featureFlagKey: FeatureFlagKey.RepositoryAirport,
      getHasAccess: (access) => access?.hasRepositoryAccess ?? false,
    },
    {
      url: AsqUrls.RepositoryMemberReports,
      translateKey: 'RepositoryMember',
      featureFlagKey: FeatureFlagKey.RepositoryMember,
      getHasAccess: (access) => access?.hasRepositoryAccess ?? false,
    },
    {
      url: AsqUrls.RepositoryPublicReports,
      translateKey: 'RepositoryPublic',
      featureFlagKey: FeatureFlagKey.RepositoryPublic,
      getHasAccess: (access) => access?.hasRepositoryAccess ?? false,
    },
  ],
};

const TrainingModuleNav: AsqNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faGraduationCap} />,
  url: AsqUrls.TrainingModule,
  translateKey: 'TrainingModule',
  featureFlagKey: FeatureFlagKey.TrainingModule,
};

const navItems: AsqNavItem[] = [
  HomeNav,
  DepartureModuleNav,
  ArrivalModuleNav,
  CommercialModuleNav,
  RepositoryModuleNav,
  TrainingModuleNav,
];

export default navItems;
