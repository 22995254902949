import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';

import { faCalendarDay } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { ModuleAccess } from '../../../../common/modules/purchase/entities/ModuleAccess';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import { ProductRecurringInterval } from '../../../DDP/pricing/enums/ProductReccuringInterval';
import ModuleAccessRowAction from './ModuleAccessRowAction';

export interface ModuleAccessRowProps {
  module: ModuleAccess;
}

export default function ModuleAccessRow({ module }: ModuleAccessRowProps): ReactElement | null {
  const { t } = useTranslation('Modules');
  const formatedRenewalDate = new Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  })
    .format(new Date(module.renewalDate))
    .replace(/(\w{3})/, '$1.');
  let accessTypeText;

  switch (module.status) {
    case ModuleAccessStatus.FreeAccess:
      accessTypeText = t('MemberAccess');
      break;
    case ModuleAccessStatus.Active:
      accessTypeText = t('SubscriptionAccess', { renewalDate: formatedRenewalDate });
      break;
    case ModuleAccessStatus.Trialing:
      accessTypeText = t('FreeTrialAccess', { renewalDate: formatedRenewalDate });
      break;
    default:
      accessTypeText = '';
  }

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Box>
        <Typography variant="pLarge">{module.moduleName}</Typography>
        {module.recurringInterval !== ProductRecurringInterval.None && (
          <Stack direction="row" gap={0.5}>
            <FontAwesomeSvgIcon fontSize="inherit" color="primary" icon={faCalendarDay} />
            <Typography variant="pSmall" sx={{ textTransform: 'uppercase' }} color="primary">
              {accessTypeText}
            </Typography>
          </Stack>
        )}
      </Box>
      <ModuleAccessRowAction accessStatus={module.status} recurringInterval={module.recurringInterval} />
    </Stack>
  );
}
