import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/material/styles';
import { Page } from 'powerbi-client';
import { ReactElement, useEffect, useState } from 'react';
import { AppBarTitlePortal } from '../../../../common/components/atoms/AppBarTitlePortal';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { changeCurrentPage } from '../executiveSlice';

export default function ExecutiveTabs(): ReactElement {
  const { currentReport } = useAppSelector((state) => state.executive);
  const [pages, setPages] = useState<Page[]>();
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState<string | null>(null);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(changeCurrentPage(newValue));
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (currentReport) {
      currentReport.on('rendered', async () => {
        const fetchedPages = await currentReport?.getPages();
        setPages(fetchedPages);
        const currentPage = await currentReport.getActivePage();
        setSelectedTab(currentPage.name);
      });
    }
  }, [currentReport]);

  if (!selectedTab) {
    return <></>;
  }

  return (
    <AppBarTitlePortal>
      <Box mb={2}>
        <Tabs value={selectedTab} onChange={handleChange} sx={{ textTransform: 'capitalize' }}>
          {pages?.map((page) => (
            <StyledTab key={page.name} value={page.name} label={page.displayName} />
          ))}
        </Tabs>
      </Box>
    </AppBarTitlePortal>
  );
}

const StyledTab = styled(Tab)`
  padding: 0px 10px;
  padding-bottom: 7px;
  min-width: 60px;
  &.MuiButtonBase-root {
    text-align: end;
    position: relative;
    justify-content: end;
  }
  & .MuiSvgIcon-root {
    position: absolute;
    right: 0;
    bottom: 10px;
  }
`;
