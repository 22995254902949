import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface BaseTermPageProps {
  title: React.ReactElement;
  content: React.ReactElement;
}

const BaseTermPage = ({ title, content }: BaseTermPageProps) => {
  const { t } = useTranslation('TermsAndConditions');

  return (
    <Stack m={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <img height="120px" src="/images/ACIVerticalLogo.jpg" alt="ACI Logo" />
        <Typography variant="h2">{t('ACICompanyName')}</Typography>
      </Stack>
      <Typography color="primary" variant="h3" mt={1} mb={2} textAlign="center">
        {title}
      </Typography>
      <Typography sx={{ textAlign: 'justify' }}>{content}</Typography>
    </Stack>
  );
};
export default BaseTermPage;
