import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { groupBy } from '../../../../common/utils/array';
import { DdpUrls } from '../../urls';
import { TransformedFileDto } from '../entities/File';
import SharepointRootFolders from '../enums/sharepointFolders.enum';
import LibrarySection, { FilterableGroupedFiles, GroupedFiles } from './LibrarySection';

export type LibraryRoutingProps = { files: TransformedFileDto[] };

export default function LibraryRouting({ files }: LibraryRoutingProps) {
  const { t } = useTranslation('Library');
  const filesByRootPath = groupBy(files, (file) => file.path[0]);

  const groupBySubfolders = (groupedFiles: GroupedFiles, depth: number) => {
    const filterableGroupedFiles: FilterableGroupedFiles = {};
    Object.entries(groupedFiles).forEach(([key, value]) => {
      filterableGroupedFiles[key] = groupBy(value, (file) => file.path[depth]);
    });
    return filterableGroupedFiles;
  };

  const toTrafficMappedFiles = (groupedFiles: TransformedFileDto[]) =>
    groupBySubfolders(
      groupBy(groupedFiles, (file) => file.path[1]),
      2,
    );

  const toMappedFiles = (groupedFiles: TransformedFileDto[]) => ({
    1: groupBy(groupedFiles, (file) => file.path[1]),
  });

  return (
    <Switch>
      <Redirect exact from={DdpUrls.LibraryModule} to={DdpUrls.LibraryModuleTraffic} />
      <Route path={DdpUrls.LibraryModuleTraffic}>
        <LibrarySection
          name={t('TrafficSectionTitle')}
          mappedFiles={toTrafficMappedFiles(filesByRootPath[SharepointRootFolders.Traffic])}
        />
      </Route>
      <Route path={DdpUrls.LibraryModuleForecast}>
        <LibrarySection
          name={t('ForecastSectionTitle')}
          mappedFiles={toMappedFiles(filesByRootPath[SharepointRootFolders.Forecast])}
        />
      </Route>
      <Route path={DdpUrls.LibraryModuleEconomics}>
        <LibrarySection
          name={t('EconomicsSectionTitle')}
          mappedFiles={toMappedFiles(filesByRootPath[SharepointRootFolders.Economics])}
        />
      </Route>
      <Route path={DdpUrls.LibraryModuleEnvironmentSustainability}>
        <LibrarySection
          name={t('EnvironmentSustainabilitySectionTitle')}
          mappedFiles={toMappedFiles(filesByRootPath[SharepointRootFolders.EnvironmentSustainability])}
        />
      </Route>
    </Switch>
  );
}
