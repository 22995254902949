import { Platform } from '../enums/platform.enum';
import { DdpUrls } from '../../modules/DDP/urls';
import { AsqUrls } from '../../modules/ASQ/urls';
import { AsqUrlPrefix, DdpUrlPrefix } from '../../common/constants/urls';

export function getPlatformFromLocation(url: string): Platform {
  const platformPrefix = url.split('/')[1];
  switch (platformPrefix) {
    case AsqUrlPrefix:
      return Platform.ASQ;
    case DdpUrlPrefix:
    default:
      return Platform.DDP;
  }
}

export function getHomeLocationFromPlatform(platform: Platform): string {
  switch (platform) {
    case Platform.ASQ:
      return AsqUrls.Home;
    case Platform.DDP:
    default:
      return DdpUrls.Home;
  }
}

export function getPlatformLogoSrc(platform: Platform): string {
  switch (platform) {
    case Platform.ASQ:
      return '/images/LogoASQ.png';
    case Platform.DDP:
    default:
      return '/images/LogoIHub.svg';
  }
}
