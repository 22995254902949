import { AxiosInstance } from 'axios';

import { CachedApiClient } from './CachedApiClient';
import { ICachedApiClientTransformer, QueryResult, ResponseTransformer, UrlGenerator } from './types';

export class CachedApiClientTransformer<QueryArgs, DataDto, Data>
  extends CachedApiClient<QueryArgs, DataDto>
  implements ICachedApiClientTransformer<QueryArgs, DataDto, Data>
{
  constructor(
    urlGenerator: UrlGenerator<QueryArgs>,
    getApiClient: () => Promise<AxiosInstance>,
    private transformResponse: ResponseTransformer<QueryArgs, DataDto, Data>,
  ) {
    super(urlGenerator, getApiClient);
  }

  public async getAsyncTransformed(queryArgs: QueryArgs): Promise<QueryResult<Data>> {
    const response = await this.getAsync(queryArgs);
    return this.transformResponse(response);
  }

  public async getListAsyncTransformed(queryArgsList: QueryArgs[]): Promise<QueryResult<Data[]>> {
    const responses = await this.getListAsync(queryArgsList);

    const data: Data[] = [];
    const errors: any[] = [];

    const results = responses.map(this.transformResponse);

    results.forEach((result) => {
      if (result.data !== undefined) data.push(result.data);
      if (result.error !== undefined) errors.push(result.error);
    });

    if (errors.length > 0) return { error: errors };
    return { data };
  }
}
