import { ReactElement, useState } from 'react';

import { TabContext, TabList, TabPanel } from '@mui/lab';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { Platform } from '../../../../core/enums/platform.enum';
import { useIsCurrentPlatform } from '../../../../core/platform/hooks';
import AccountDetailsForm from './AccountDetailsForm';
import ProfileForm from './ProfileForm';

export interface ProfileDrawerProps {
  open?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const defaultTabValue = 'Profile';

export default function ProfileDrawer({ open, onClose }: ProfileDrawerProps): ReactElement {
  const { t } = useTranslation('Profile');
  const [value, setValue] = useState(defaultTabValue);

  const displayDDPFeatures = useIsCurrentPlatform(Platform.DDP);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    setValue(defaultTabValue);
    if (onClose) onClose(event);
  };

  return (
    <>
      <Drawer anchor="right" open={open} onClose={handleClose} data-testid="profile-drawer">
        <Box p={5}>
          <Box display="flex" justifyContent="end">
            <IconButton onClick={handleClose} data-fullstory="ProfileClose">
              <FontAwesomeSvgIcon fontSize="inherit" icon={faTimes} />
            </IconButton>
          </Box>
          <TabContext value={value}>
            <TabList onChange={handleChange}>
              <Tab label={t('ProfileTitle')} value="Profile" data-fullstory="ProfileTabButton" />
              {displayDDPFeatures && (
                <Tab label={t('AccountDetailsTitle')} value="AccountDetails" data-fullstory="AccountDetailsTabButton" />
              )}
            </TabList>
            <TabPanel sx={{ p: 0 }} value="Profile">
              <ProfileForm />
            </TabPanel>
            {displayDDPFeatures && (
              <TabPanel sx={{ p: 0 }} value="AccountDetails">
                <AccountDetailsForm />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </Drawer>
    </>
  );
}

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '460px',
    boxShadow: Array.from(theme.shadows)[26],
  },
  '& .MuiBackdrop-root': {
    opacity: '0 !important',
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDrawer-paper': {
      width: '100%',
    },
  },
}));
