import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../../../store';
import { useStartFreeTrialMutation } from '../../../../hooks/usePricingEndpoints';
import FreeTrialInfo from '../../entities/FreeTrialInfo';
import { PurchaseDialogStep } from '../../entities/PurchaseDialogStep';
import usePaymentCard from '../../hooks/usePaymentCard';
import CongratulationsStep from '../molecules/CongratulationsStep';
import GetStartedStep from '../molecules/GetStartedStep';
import IdentificationStep from '../molecules/IdentificationStep';
import PaymentStep from '../molecules/PaymentStep';
import ReasonsForComingStep from '../molecules/ReasonsForComingStep';
import BasePurchaseDialog from './BasePurchaseDialog';

const PaymentStepCallback = () => <PaymentStep activateFreeTrialIfAvailable />;

export default function FreeTrialDialog() {
  const { t } = useTranslation('Pricing');
  const [startFreeTrial] = useStartFreeTrialMutation();
  const { handlePayment } = usePaymentCard();
  const {
    selectedProduct,
    currentCompany,
    currentTitle,
    currentContactNumber,
    currentReasonsForComing,
    currentOtherReason,
    isFreeTrialDialogOpen,
    termsAndConditionsAccepted,
    areBillingDetailsFilled,
    billingInformation,
  } = useAppSelector((state) => state.purchase);

  const steps: PurchaseDialogStep[] = [
    {
      component: GetStartedStep,
      nextButtonGradient: 'gradient',
      nextButtonText: t('FreeTrialDialog.GetStarted'),
      validForm: true,
      backButton: false,
      fullStoryName: 'Get Started',
    },
    {
      component: IdentificationStep,
      nextButtonGradient: 'contained',
      nextButtonText: t('FreeTrialDialog.Continue'),
      validForm: [currentCompany, currentTitle, currentContactNumber].every((v) => v),
      backButton: false,
      fullStoryName: 'Identification',
    },
    {
      component: ReasonsForComingStep,
      nextButtonGradient: 'contained',
      nextButtonText: t('FreeTrialDialog.Continue'),
      validForm: currentReasonsForComing.length !== 0,
      backButton: true,
      fullStoryName: 'Reasons For Coming',
    },
    {
      component: PaymentStepCallback,
      nextButtonGradient: 'contained',
      nextButtonText: t('PurchaseDialog.ConfirmPurchase'),
      validForm: areBillingDetailsFilled && termsAndConditionsAccepted,
      backButton: true,
      fullStoryName: 'Payment',
    },
    {
      component: CongratulationsStep,
      nextButtonGradient: 'gradient',
      nextButtonText: t('FreeTrialDialog.AccessDashboard'),
      validForm: true,
      backButton: false,
      fullStoryName: 'Congratulations',
    },
  ];

  const save = async () => {
    const info: FreeTrialInfo = {
      priceId: selectedProduct.priceId,
      company: currentCompany,
      title: currentTitle,
      contactNumber: currentContactNumber,
      reasonsForComing: currentReasonsForComing,
      otherReasonForComing: currentOtherReason,
      billingInformation: billingInformation!,
    };

    const saveInformationInSubscriptionCallback = () => startFreeTrial(info).unwrap();
    await handlePayment(saveInformationInSubscriptionCallback);
  };

  return (
    <BasePurchaseDialog
      isDialogOpen={isFreeTrialDialogOpen}
      steps={steps}
      onSave={save}
      fullstoryEventPrefix="Free Trial Flow"
    />
  );
}
