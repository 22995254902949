import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteRenderOptionState,
  Box,
  Checkbox,
  Divider,
  Paper,
  Skeleton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Fragment, ReactElement, SyntheticEvent } from 'react';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import TextField from '../../../../common/components/atoms/TextField';
import { FileFilterBase, FileFilterOption } from '../types/FileFilter';

export interface AutocompleteFilterProps extends Omit<FileFilterBase, 'labelTranslateKey'> {
  label: string;
  selectAllLabel: string;
  width: number;
}

const RenderOption =
  (allSelected: boolean, noneSelected: boolean) =>
  (
    props: React.HTMLAttributes<HTMLLIElement>,
    { key, label }: FileFilterOption,
    { selected }: AutocompleteRenderOptionState,
  ) => {
    const isAllOption = key === selectAllKey;

    const isSelected = allSelected || selected;
    const isIndeterminate = isAllOption && !allSelected && !noneSelected;

    return (
      <Fragment key={key}>
        {/* eslint-disable-next-line jsx-a11y/role-supports-aria-props */}
        <li {...props} aria-selected={isSelected}>
          <Checkbox checked={isSelected} indeterminate={isIndeterminate} />
          <Typography variant="pMedium">{label}</Typography>
        </li>
        {isAllOption && <Divider />}
      </Fragment>
    );
  };

const selectAllKey = '____SELECT_ALL____';

export default function AutocompleteFilter({
  id,
  label,
  options,
  values,
  selectAllLabel,
  onChange,
  width,
  isLoading,
}: AutocompleteFilterProps): ReactElement {
  const allSelected = values.length === options.length;
  const noneSelected = values.length === 0;

  const handleChangeValues = (newValues: FileFilterOption[]) => onChange(newValues);
  const handleSelectAll = () => onChange(options);
  const handleClearAll = () => onChange([]);
  const handleToggleAll = () => (allSelected ? handleClearAll() : handleSelectAll());

  const handleChange = (
    _event: SyntheticEvent<Element, Event>,
    newValues: FileFilterOption[],
    reason: AutocompleteChangeReason,
  ): void => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      const finalValues = newValues.filter((option) => option.key !== selectAllKey);
      const isAllOptionSelected = finalValues.length !== newValues.length;

      if (isAllOptionSelected) {
        handleToggleAll();
      } else {
        handleChangeValues(finalValues);
      }
    } else if (reason === 'clear') {
      handleClearAll();
    }
  };

  const selectAllOptions: FileFilterOption = {
    key: selectAllKey,
    label: selectAllLabel,
  };

  return (
    <Box>
      {isLoading ? (
        <Skeleton height={56} width={width} variant="rectangular" sx={{ borderRadius: '4px' }} />
      ) : (
        <Autocomplete
          id={id}
          value={values}
          options={[selectAllOptions, ...options]}
          renderInput={(params) => <TextField {...params} autoComplete="off" label={getLabel(label, values.length)} />}
          renderOption={RenderOption(allSelected, noneSelected)}
          PaperComponent={CustomPaper}
          onChange={handleChange}
          data-fullstory={id}
          renderTags={() => undefined}
          popupIcon={<FontAwesomeSvgIcon icon={faChevronDown} sx={{ fontSize: '0.6em' }} />}
          isOptionEqualToValue={(option, value) => option.key === value.key}
          disabled={options.length === 0}
          sx={{ width }}
          multiple
          disableCloseOnSelect
          disableClearable
        />
      )}
    </Box>
  );
}

const CustomPaper = (props: any) => <StyledCard sx={{ width: 500 }} {...props} />;

const StyledCard = styled(Paper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    padding: theme.spacing(0),
  },
  '& ul': {
    margin: 0,
  },
}));

function getLabel(label: string, count: number): string {
  if (count === 0) return label;
  return `${label} (${count})`;
}
