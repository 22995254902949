import { Box, Link, Stack, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import { useGetAllPricingProductsQuery, useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';
import MailToLink from '../../common/atoms/MailToLink';
import { ProductRecurringInterval } from '../enums/ProductReccuringInterval';
import useCustomerPortal from '../hooks/useCustomerPortal';
import AllPurchaseDialogs from '../molecules/AllPurchaseDialogs';
import ProductCard from '../molecules/ProductCard';

export default function PricingPage(): ReactElement {
  const { t } = useTranslation('Pricing');
  const [currentBillingFilter, setCurrentBillingFilter] = useState(ProductRecurringInterval.Yearly);
  const { data: pricingProducts, isLoading: areProductsLoading } = useGetAllPricingProductsQuery();
  const { data: moduleAccesses, isLoading: areAccessesLoading } = useModuleAccessesQuery();
  const { customerPortalUrl } = useCustomerPortal();

  const filteredProducts = useMemo(
    () =>
      pricingProducts?.filter((product) =>
        [currentBillingFilter, ProductRecurringInterval.None].includes(product.recurringInterval),
      ) ?? [],
    [pricingProducts, currentBillingFilter],
  );

  if (areProductsLoading || areAccessesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Stack direction="column" gap={2} pb={2}>
      <Typography variant="h2" fontWeight="bold">
        {t('ChooseTitle')}
      </Typography>
      <RadioGroup
        row
        aria-labelledby="billing-radio-buttons-group-label"
        defaultValue={ProductRecurringInterval.Yearly}
        name="billing-radio-buttons-group"
        sx={{ alignItems: 'center' }}
        onChange={(e) => setCurrentBillingFilter(Number((e.target as HTMLInputElement).value))}
      >
        <FormControlLabel
          value={ProductRecurringInterval.Yearly}
          sx={{ marginRight: 1 }}
          control={<Radio />}
          label="Annual plan"
        />
        <Box
          sx={{
            background: '#06AD02',
            color: 'white',
            mr: 3,
            p: 0.7,
            textAlign: 'center',
            textTransform: 'uppercase',
            fontSize: '12px',
          }}
        >
          {t('SaveUp')}
        </Box>
        <FormControlLabel value={ProductRecurringInterval.Monthly} control={<Radio />} label="Monthly" />
      </RadioGroup>
      <Box sx={{ overflow: 'auto', width: '74vw' }}>
        <Stack mb={1} spacing={3} direction="row" alignItems="flex-start">
          {filteredProducts.map((product) => (
            <ProductCard key={product.priceId} product={product} moduleAccesses={moduleAccesses!} />
          ))}
        </Stack>
      </Box>
      <AllPurchaseDialogs />
      <Typography>
        {t('ManageSubscriptions')}{' '}
        <Link href={customerPortalUrl} target="_blank">
          {t('Common:Here')}
        </Link>{' '}
        {t('AccessStripePortal')}
      </Typography>
      <Typography>
        {t('NeedQuote')} <MailToLink subjectType="quoting">{t('Common:Here')}</MailToLink>.
      </Typography>
    </Stack>
  );
}
