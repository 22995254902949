import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { FeatureFlagKey } from '../../../../core/featureFlags/featureFlagKeys';
import { useFeatureFlag } from '../../../../core/featureFlags/hooks';
import { AsqUrls } from '../../urls';

import TileActions from '../atoms/TileActions';
import BaseTile from './BaseTile';

interface RepositoryTileProps {
  hasAccess: boolean;
}

export default function RepositoryTile({ hasAccess }: RepositoryTileProps): ReactElement {
  const { t } = useTranslation(['Modules', 'Home']);
  const enableActions = useFeatureFlag(FeatureFlagKey.RepositoryPublic);

  let actions: ReactElement | undefined;

  if (enableActions) {
    if (hasAccess) {
      actions = (
        <TileActions
          primaryAction={{
            id: `HomeOpenRepository`,
            title: t('ASQ.Cards.Actions.NavigateReports', { ns: 'Home' }),
            variant: 'outlined',
            url: AsqUrls.RepositoryPublicReports,
          }}
        />
      );
    } else {
      actions = (
        <TileActions
          primaryAction={{
            id: `HomeOpenRepository`,
            title: t('ASQ.Cards.Actions.LearnMore', { ns: 'Home' }),
            variant: 'outlined',
            url: AsqUrls.RepositoryPublicReports,
          }}
        />
      );
    }
  }

  return (
    <BaseTile
      title={t(`Names.Repository`, { ns: 'Modules' })}
      imageUrl={hasAccess ? '/images/repository.png' : '/images/locked.png'}
      imageTitle={hasAccess ? 'repository icon' : 'lock icon'}
      description={t(`ShortDescriptions.Repository`, { ns: 'Modules' }).replace('<br>', '')}
      actions={actions}
    />
  );
}
