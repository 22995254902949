import { ReactElement } from 'react';

import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import BannerBox from '../../../../common/components/atoms/BannerBox';

export default function NoAccessBanner(): ReactElement {
  const { t } = useTranslation('Home', { keyPrefix: 'ASQ' });

  return (
    <BannerBox>
      <Typography component="div" variant="pLarge" color="black">
        <Trans t={t}>NoAccessMessage</Trans>
      </Typography>
    </BannerBox>
  );
}
