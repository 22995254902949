import { ReactElement } from 'react';

import { faLockAlt } from '@fortawesome/pro-light-svg-icons';
import { ListItemText, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavSubItemProps } from '../../types/NavItem';
import { FontAwesomeSvgIcon } from './FontAwesomeIcon';

interface ASQDrawerMenuLinkProps {
  navSubItem: NavSubItemProps;
  onClose: () => void;
  onClick: (navSubItem: NavSubItemProps) => void;
}

function ASQDrawerMenuLink({ navSubItem, onClose, onClick }: ASQDrawerMenuLinkProps): ReactElement | null {
  const { t } = useTranslation('Navigation');

  const handleClick = () => {
    if (navSubItem.locked) return;
    onClose();
    onClick(navSubItem);
  };

  const lockedTooltip = navSubItem.locked && navSubItem.lockedTooltipTK ? t(navSubItem.lockedTooltipTK) : '';

  return (
    <Tooltip title={lockedTooltip}>
      <div>
        <MenuItem
          onClick={handleClick}
          selected={navSubItem.selected}
          role="link"
          data-fullstory={`LeftNav${navSubItem.translateKey}`}
          disabled={navSubItem.locked}
        >
          <ListItemText
            sx={{ marginX: 1 }}
            primary={
              <Stack direction="row" spacing={1.5} alignItems="center">
                <Typography variant="pMedium">{t(`${navSubItem.translateKey}`)}</Typography>
                {navSubItem.locked ? <FontAwesomeSvgIcon fontSize="inherit" icon={faLockAlt} /> : undefined}
              </Stack>
            }
          />
        </MenuItem>
      </div>
    </Tooltip>
  );
}

export default ASQDrawerMenuLink;
