import { ReactElement } from 'react';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import { useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';
import FreeTrialForAccessDialog from '../../../../common/modules/purchase/components/dialogs/FreeTrialForAccessDialog';
import FullPlanForAccessDialog from '../../../../common/modules/purchase/components/dialogs/FullPlanForAccessDialog';
import PurchaseForAccessDialog from '../../../../common/modules/purchase/components/dialogs/PurchaseForAccessDialog';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import FileAccessRequirements from '../enums/fileAccessRequirements';
import { useAccessibleFilesList } from '../hooks/useAccessibleFilesList';
import FileExplorer from '../molecules/FileExplorer';

export default function LibraryContainer(): ReactElement {
  const { isLoading } = useModuleAccessesQuery();
  const { accessLevel: userAccessLevel } = useAccessibleFilesList();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {!userAccessLevel.includes(FileAccessRequirements.FreeTrial) ? (
        <>
          <PurchaseForAccessDialog moduleKey={ModuleKey.Library} />
          <FreeTrialForAccessDialog moduleKey={ModuleKey.Library} />
        </>
      ) : (
        !userAccessLevel.includes(FileAccessRequirements.ActiveSubscription) && (
          <FullPlanForAccessDialog moduleKey={ModuleKey.Library} />
        )
      )}
      <FileExplorer />
    </>
  );
}
