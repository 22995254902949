import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Trans, useTranslation } from 'react-i18next';

export interface QuestionRowContentProps {
  faqKey: string;
}

const QuestionRowContent = ({ faqKey }: QuestionRowContentProps) => {
  const { t } = useTranslation('Faq');

  const mapList = (component: { [key: string]: string }) => (
    <ul>
      {Object.entries(component).map(([bulletKey, bullet]) => (
        <React.Fragment key={bulletKey}>
          {typeof bullet === 'object' ? mapList(bullet) : <li>{ReactHtmlParser(bullet)}</li>}
        </React.Fragment>
      ))}
    </ul>
  );

  const generateComponentArray = () => {
    const translationsKeys = Object.keys(t(faqKey, { returnObjects: true }));
    const components = translationsKeys
      .filter(
        (translationsKey) =>
          translationsKey.includes('List') || translationsKey.includes('Block') || translationsKey.includes('Image'),
      )
      .map((translationsKey) => t(`${faqKey}.${translationsKey}`, { returnObjects: true }));

    return components.map((component: any) => {
      if (typeof component === 'object') {
        if (component.Text) {
          return <div>{ReactHtmlParser(component.Text)}</div>;
        }
        return mapList(component);
      }
      return <img src={`/images/Faq/ReshapeData/${component}.png`} alt={`Faq-ReshapeData-${component}`} />;
    });
  };

  return <Trans t={t} i18nKey={`${faqKey}.Text`} components={generateComponentArray()} />;
};

export default QuestionRowContent;
