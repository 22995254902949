import * as FullStory from '@fullstory/browser';
import {
  Box,
  Card,
  CardActionArea,
  CircularProgress,
  Icon,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TruncatedTypograhpy from '../../../../common/components/atoms/TruncatedTypography';
import { formatDateTranslate } from '../../../../common/utils/date';
import { displaySnackbar } from '../../../../core/notifications/snackbarSlice';
import { downloadDdpLibraryFile } from '../../../../services/filesDownload';
import { useAppDispatch } from '../../../../store';
import { TransformedFileDto } from '../entities/File';
import { getCleanedExtension, getFileExtensionIcon } from '../helpers/FileHelpers';
import { useAccessibleFilesList } from '../hooks/useAccessibleFilesList';
import useFileAccessValidator from '../hooks/useFileAccessValidator';

export type FileCardProps = { file: TransformedFileDto; dense?: boolean };
type StyledCardProps = { dense: boolean; noAccess: boolean };

export default function FileCard({ file, dense = false }: FileCardProps) {
  const { name, description, lastModified, extension, coverImageUrl, accessRequirements } = file;

  const { t } = useTranslation('Common');
  const dispatch = useAppDispatch();
  const { accessLevel: userAccessLevel } = useAccessibleFilesList();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { openFileDetails } = useFileAccessValidator();

  const hasAccess = userAccessLevel.includes(accessRequirements);

  const handleDownload = async (event: React.SyntheticEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsButtonLoading(true);
    if (!hasAccess) return;

    const FileEvent = { FileName: file.name, FileId: file.id };
    FullStory.event('LibraryDownloadFile', FileEvent);

    try {
      await downloadDdpLibraryFile(file.id, file.name, file.extension);
    } catch (err) {
      console.error(err);
      dispatch(displaySnackbar({ message: t('ErrorDownload', { ns: 'Common' }), severity: 'error' }));
    }
    setIsButtonLoading(false);
  };

  const handleInfo = (event: React.SyntheticEvent<HTMLElement>) => {
    event.stopPropagation();
    openFileDetails(file);
  };

  const FileIcon = getFileExtensionIcon(extension);

  return (
    <StyledCard dense={dense} noAccess={!hasAccess} sx={{ position: 'relative' }}>
      <CardActionArea sx={{ flex: 1, height: '100%' }} onClick={handleInfo} data-fullstory="LibraryInfo">
        <StyledImageContainer>
          {coverImageUrl && <StyledImage src={coverImageUrl} alt="File cover" />}
        </StyledImageContainer>

        <Stack
          position="absolute"
          top={0}
          bgcolor="white"
          left={(theme) => theme.spacing(2)}
          alignItems="center"
          justifyContent="center"
          direction="column"
          height="60px"
          width="40px"
        >
          <FileIcon />
          <Typography variant="pXSmall" mt={1}>
            {getCleanedExtension(extension)}
          </Typography>
        </Stack>

        <Stack p={3} pb={0} spacing={1} position="absolute" sx={{ bottom: dense ? 15 : 60 }}>
          <TruncatedTypograhpy shownLines={2} variant={dense ? 'h6' : 'h4'} color="white">
            {name}
          </TruncatedTypograhpy>
          <Box sx={{ backgroundColor: 'white', paddingY: 0.5, paddingX: 1, width: 'fit-content' }}>
            <Typography variant={dense ? 'pSmall' : undefined} fontWeight="700">
              {formatDateTranslate(t, 'FormattedDate', new Date(lastModified), 'Common')}
            </Typography>
          </Box>
          <TruncatedTypograhpy
            height={dense ? 30 : 70}
            variant={dense ? 'pSmall' : undefined}
            color="white"
            shownLines={dense ? 2 : 3}
          >
            {description}
          </TruncatedTypograhpy>
        </Stack>
      </CardActionArea>

      <Box
        position="absolute"
        right={(theme) => theme.spacing(1.5)}
        sx={{ top: dense ? 10 : undefined, bottom: dense ? undefined : 10 }}
      >
        <IconButton
          disabled={!hasAccess || isButtonLoading}
          size="small"
          aria-label="close"
          onClick={handleDownload}
          data-fullstory="LibraryDownload"
        >
          <Icon sx={{ width: dense ? 37 : 45, height: dense ? 37 : 45, transform: dense ? 'scale(0.7)' : undefined }}>
            {isButtonLoading ? (
              <CircularProgress size={30} sx={{ marginTop: 1 }} />
            ) : (
              <img alt="Download" src="/images/Library/Download.svg" />
            )}
          </Icon>
        </IconButton>
      </Box>
    </StyledCard>
  );
}
const StyledCard = styled(Card, {
  shouldForwardProp: (props) => props !== 'dense' && props !== 'noAccess',
})<StyledCardProps>((props) => ({
  ...(props.noAccess && {
    filter: 'grayscale(50%) blur(2px);',
    opacity: 0.7,
  }),
  flexShrink: 0,
  width: props.dense ? 200 : 340,
  height: props.dense ? 300 : 400,
  borderRadius: 0,
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
}));

const StyledImageContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  filter: 'brightness(50%)',
  backgroundColor: 'rgba(0,0,0,0.5)',
}));

const StyledImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
}));
