import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import BasicCard from '../../../../common/components/molecules/BasicCard';

export default function RepositoryUpselling(): ReactElement {
  const { t } = useTranslation('Modules');

  return <BasicCard image="/images/Locked.png" title={t(`Names.Repository`)} text={t(`Descriptions.Repository`)} />;
}
