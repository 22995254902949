import generateDashboard from '../../dashboards/generator';
import { DashboardConfig, DashboardPageConfig } from '../../dashboards/types';
import { AsqUrlPrefix } from '../../../../common/constants/urls';
import { DepartureDashboard } from './enum';
import {
  airportCodeSlicerSample,
  comparedPeriodQuarterSlicerSample,
  comparedPeriodYearSeasonSlicerSample,
  comparedPeriodYearSlicerSample,
  periodQuarterSlicerSample,
  periodYearSeasonSlicerSample,
  periodYearSlicerSample,
  subscriptionSlicerSample,
} from './slicerSamples';

export const DepartureDashboardsUrlPrefix = `/${AsqUrlPrefix}/departure`;

export const OverviewDashboardPageConfig: DashboardPageConfig = {
  url: 'overview',
  pageName: 'ReportSection',
  titleTranslateKey: 'Overview',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: 'bde489b2591d3592c01b',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: 'd535076b5c186723411d',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '8d4768517e95aabe91d0',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: 'af9cc1820817ac862051',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: 'af9cc1820817ac862051',
  },
  comparedPeriodYearSeasonSlicerInfo: {
    ...comparedPeriodYearSeasonSlicerSample,
    name: '00d6ae8249562de66e22',
  },
  comparedPeriodYearSlicerInfo: {
    ...comparedPeriodYearSlicerSample,
    name: '47b176c6d8780e63e3a2',
  },
  comparedPeriodQuarterSlicerInfo: {
    ...comparedPeriodQuarterSlicerSample,
    name: '47b176c6d8780e63e3a2',
  },
  subscriptionBookmarkInfo: {
    slicerName: 'd535076b5c186723411d',
    mainBookmarkName: 'Bookmark4aa542d4a7cf2141134e',
    regionalBookmarkName: 'Bookmark50164bc0be3d0e08eeeb',
    uniqueBookmarkName: 'Bookmark2f66f2f8223a341fa1dc',
  },
  infoMessageTranslateKey: 'OverviewInfoMessage',
};

export const PassengerJourneyDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-journey',
  pageName: 'ReportSection81902c43bc681cf4740e',
  titleTranslateKey: 'PassengerJourney',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '6ee98fdb8937b9301d62',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '7f01395177f1b3cb58e2',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: 'e8b2c7220e3f1d74322b',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '072ba7fdd337c6610e03',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '072ba7fdd337c6610e03',
  },
  comparedPeriodYearSeasonSlicerInfo: {
    ...comparedPeriodYearSeasonSlicerSample,
    name: '75953090359a827461bd',
  },
  comparedPeriodYearSlicerInfo: {
    ...comparedPeriodYearSlicerSample,
    name: '2df86aa09ad4151a2dba',
  },
  comparedPeriodQuarterSlicerInfo: {
    ...comparedPeriodQuarterSlicerSample,
    name: '2df86aa09ad4151a2dba',
  },
  subscriptionBookmarkInfo: {
    slicerName: '7f01395177f1b3cb58e2',
    mainBookmarkName: 'Bookmarkc28e2f6b981a7f6a3341',
    regionalBookmarkName: 'Bookmarke17d262c0f91343d2063',
    uniqueBookmarkName: 'Bookmark730c68734a8db60560f6',
  },
  infoMessageTranslateKey: 'PassengerJourneyInfoMessage',
};

export const BenchmarkingPerfDashboardPageConfig: DashboardPageConfig = {
  url: 'benchmark-performance',
  pageName: 'ReportSection3084daa2ff5d5b6afc19',
  titleTranslateKey: 'BenchmarkingPerf',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '905b643c8dd9aba7d803',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '6ac1f3db860104b6b11e',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '2f5398046de830c279a1',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '5c9707d0a51a55401bb3',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '5c9707d0a51a55401bb3',
  },
  subscriptionBookmarkInfo: {
    slicerName: '6ac1f3db860104b6b11e',
    mainBookmarkName: 'Bookmark6e94c07053d53c4c10e1',
    regionalBookmarkName: 'Bookmarka933af889db9e2b957c0',
    uniqueBookmarkName: 'Bookmark136ab67068e4b68e3cfe',
  },
  infoMessageTranslateKey: 'BenchmarkInfoMessage',
};

export const BenchmarkingTrendPerfDashboardPageConfig: DashboardPageConfig = {
  url: 'benchmark-trends',
  pageName: 'ReportSection95ba1bf0c85f3c67f3e4',
  titleTranslateKey: 'BenchmarkingTrendPerf',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: 'a905cb45bd47228f8313',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: 'd2d3bdc54ba118e71d8d',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '2ae931521a0b0af3ac16',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '03fca0f212dc66abba1f',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '03fca0f212dc66abba1f',
  },
  subscriptionBookmarkInfo: {
    slicerName: 'd2d3bdc54ba118e71d8d',
    mainBookmarkName: 'Bookmark7258d8a6750b5e1266d6',
    regionalBookmarkName: 'Bookmark816bc8003825798542b1',
    uniqueBookmarkName: 'Bookmarkb95c004827c11b1e69c0',
  },
  infoMessageTranslateKey: 'BenchmarkInfoMessage',
};

export const PassProBenchmarkingDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-profile-benchmarking',
  pageName: 'ReportSection76861eb1a02bc35b9ae7',
  titleTranslateKey: 'PassProBenchmarking',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: 'd69c4f69013200d7d9e1',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '33839d1ed942349862a6',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '6e75147fc925303cda04',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '9e61fc1e9566423d5d29',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '9e61fc1e9566423d5d29',
  },
  subscriptionBookmarkInfo: {
    slicerName: '33839d1ed942349862a6',
    mainBookmarkName: 'Bookmarkc5da89a1c7d0ca0290de',
    regionalBookmarkName: 'Bookmark7dbf00e72e45c6002235',
    uniqueBookmarkName: 'Bookmark53dea24760603de4710b',
  },
};

export const PassProDemographicsDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-profile-demographics',
  pageName: 'ReportSection271f72b4bc109ca53560',
  titleTranslateKey: 'PassProDemographics',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '7107df5c08bda070cb19',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '517684701a608bedcb67',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: 'bff2208d003e401080d3',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: 'a49b8b0d2ee144ca8d01',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: 'a49b8b0d2ee144ca8d01',
  },
  subscriptionBookmarkInfo: {
    slicerName: '517684701a608bedcb67',
    mainBookmarkName: 'Bookmark70f863e72d3b08d3de32',
    regionalBookmarkName: 'Bookmarkbd61e24be71900bb0b3d',
    uniqueBookmarkName: 'Bookmarkde8cf2f21b3e51148d51',
  },
};

export const PassProDemographicsBySegmentDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-profile-demographics-by-segment',
  pageName: 'ReportSectionc8b485d94f8c0d101308',
  titleTranslateKey: 'PassProDemographics',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '69e1b2b61b3e9f80030b',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '27622cabba48b91cc895',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '8fd162653f0b2b31cea0',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '148b8248172ffaa3e3a8',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '148b8248172ffaa3e3a8',
  },
  subscriptionBookmarkInfo: {
    slicerName: '27622cabba48b91cc895',
    mainBookmarkName: 'Bookmark2388797b07cd7c75f868',
    regionalBookmarkName: 'Bookmarkd642f99c7c0dc7b44a3e',
    uniqueBookmarkName: 'Bookmarkc62bde6f231081b67c9c',
  },
};

export const PassProDemographicsTrendOvertimeDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-profile-demographics-trend-overtime',
  pageName: 'ReportSectionca03ba0755ebbd94661a',
  titleTranslateKey: 'PassProDemographics',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '3c61c4b42df512ec6f7b',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '37fa7265a1f21e2c55db',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: 'de8809c0f71cd38262b2',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '8064aa939fd34cb071c3',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '8064aa939fd34cb071c3',
  },
  subscriptionBookmarkInfo: {
    slicerName: '37fa7265a1f21e2c55db',
    mainBookmarkName: 'Bookmark6fb813685e3ef2630f42',
    regionalBookmarkName: 'Bookmarkedbf034b0fe8a5e4dbe9',
    uniqueBookmarkName: 'Bookmark69dbcebd1231bd787c74',
  },
};

export const PassProTravelBehaviorDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-profile-travel-behavior',
  pageName: 'ReportSection735e1a470d07020c1a34',
  titleTranslateKey: 'PassProTravelBehavior',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '101f9773c007339e00b6',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: 'e0a23e6d889e193cb306',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '15234ee63473dd65a0c2',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: 'dda9fcde1a961d976da9',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: 'dda9fcde1a961d976da9',
  },
  subscriptionBookmarkInfo: {
    slicerName: 'e0a23e6d889e193cb306',
    mainBookmarkName: 'Bookmark33c093ed828210b76880',
    regionalBookmarkName: 'Bookmark2a8d9d72ca02b0009168',
    uniqueBookmarkName: 'Bookmarkac41d1616093f74344af',
  },
};

export const PassProTravelBehaviorBySegmentDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-profile-travel-behavior-by-segment',
  pageName: 'ReportSectionb3f4a3cccd951e9a6ed4',
  titleTranslateKey: 'PassProTravelBehavior',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '26bd14fea1b953516eb0',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '2c5999485ce37667a758',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '79a80c0e557c11216c66',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '5f8757cc819383f64bcb',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '5f8757cc819383f64bcb',
  },
  subscriptionBookmarkInfo: {
    slicerName: '2c5999485ce37667a758',
    mainBookmarkName: 'Bookmarkf829c89629588a314fc0',
    regionalBookmarkName: 'Bookmark8e3e7c0a168c538b9da0',
    uniqueBookmarkName: 'Bookmarkdbcfaa9728e582384563',
  },
};

export const PassProTravelBehaviorTrendOvertimeDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-profile-travel-behavior-trend-overtime',
  pageName: 'ReportSection5fd4ccacb1c6c37309b9',
  titleTranslateKey: 'PassProTravelBehavior',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '418a3dbc63bd9c69e95d',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '9de48c88ebb3c46c10f5',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '34ca9a35f19c3b195f70',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '9d047f9c27b3341c008b',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '9d047f9c27b3341c008b',
  },
  subscriptionBookmarkInfo: {
    slicerName: '9de48c88ebb3c46c10f5',
    mainBookmarkName: 'Bookmark71bc9ee7f8a022f631c0',
    regionalBookmarkName: 'Bookmarkc5c8e01ef0e2053adb43',
    uniqueBookmarkName: 'Bookmark89227b851f8e9134819f',
  },
};

export const PassProTravelProfileDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-profile-travel-profile',
  pageName: 'ReportSection8ce4f17adbdb08ad4a7b',
  titleTranslateKey: 'PassProTravelProfile',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: 'ed4e6dd756e4e0dea06e',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '0ba91c60e9e6aaebe738',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '3adc5ee2599e28046e47',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '63965d3f65db4e0d668e',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '63965d3f65db4e0d668e',
  },
  subscriptionBookmarkInfo: {
    slicerName: '0ba91c60e9e6aaebe738',
    mainBookmarkName: 'Bookmark6f446fb6c4000c522616',
    regionalBookmarkName: 'Bookmark9862eef071e61a0e3689',
    uniqueBookmarkName: 'Bookmarkedfef74320278fa0e045',
  },
};

export const PassProTravelProfileBySegmentDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-profile-travel-profile-by-segment',
  pageName: 'ReportSection8013ceaf1d3ffeb2b803',
  titleTranslateKey: 'PassProTravelProfile',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: 'be6fce43741ca798f503',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: 'e5098c082dcb9ad3bc98',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '3e1322bc98cc76d45693',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '1af1b6e62e677f84062e',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '1af1b6e62e677f84062e',
  },
  subscriptionBookmarkInfo: {
    slicerName: 'e5098c082dcb9ad3bc98',
    mainBookmarkName: 'Bookmark8895d0896ec36cfe23ad',
    regionalBookmarkName: 'Bookmarkb360259f89495374ba6b',
    uniqueBookmarkName: 'Bookmarkffaef60e9edd69a4f852',
  },
};

export const PassProTravelProfileTrendOvertimeDashboardPageConfig: DashboardPageConfig = {
  url: 'passenger-profile-travel-profile-trend-overtime',
  pageName: 'ReportSection0a8b5844fc9647f78b44',
  titleTranslateKey: 'PassProTravelProfile',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '6955c891785a57fce9f8',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '782ded575d3c0a4aec21',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: 'de6c73732ec4e4a9f687',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '239d2b792f5d62542c6a',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '239d2b792f5d62542c6a',
  },
  subscriptionBookmarkInfo: {
    slicerName: '782ded575d3c0a4aec21',
    mainBookmarkName: 'Bookmarkc3ada8bd5cf09eedce8e',
    regionalBookmarkName: 'Bookmark872cbf707a3bfc8015ad',
    uniqueBookmarkName: 'Bookmark95765442838c06cd519e',
  },
};

export const GapAnalysisDashboardPageConfig: DashboardPageConfig = {
  url: 'gap-analysis',
  pageName: 'ReportSection28f0ebdfc40cb644d322',
  titleTranslateKey: 'GapAnalysis',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '0a615b96a6f0c116cd3a',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '249a7e3d7fb58493b0be',
  },
  subscriptionBookmarkInfo: {
    slicerName: '249a7e3d7fb58493b0be',
    mainBookmarkName: 'Bookmark37eb334ae745059b2708',
    regionalBookmarkName: 'Bookmark65b9a1de02af8e0e882c',
    uniqueBookmarkName: 'Bookmark90d7b076c95406e91dc0',
  },
  airportCodeGroupASlicerInfo: {
    ...airportCodeSlicerSample,
    name: '18f5bc093e3779b9a473',
  },
  airportCodeGroupBSlicerInfo: {
    ...airportCodeSlicerSample,
    table: 'Airport Comp',
    name: '01c9ff67c191847ecc7e',
  },
  buttonGroupAId: '37aedb8a8ad657397c5e',
  buttonGroupBId: 'cc3e3f479493a9183708',
  periodYearSeasonGroupASlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: 'b60b7bfbf8db6da5cbd3',
  },
  periodYearSeasonGroupBSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    table: 'Date Rolling',
    name: 'e133cd26913575138734',
  },
  periodYearGroupASlicerInfo: {
    ...periodYearSlicerSample,
    name: 'ad24b4bb7e4c9a833c5c',
  },
  periodQuarterGroupASlicerInfo: {
    ...periodQuarterSlicerSample,
    name: 'ad24b4bb7e4c9a833c5c',
  },
  periodYearGroupBSlicerInfo: {
    ...periodYearSlicerSample,
    table: 'Date Rolling',
    name: '16baf8ab8ec1d8506ffa',
  },
  periodQuarterGroupBSlicerInfo: {
    ...periodQuarterSlicerSample,
    table: 'Date Rolling',
    name: '16baf8ab8ec1d8506ffa',
  },
  infoMessageTranslateKey: 'GapAnalysisInfoMessage',
};

export const CustomAnalysisDashboardPageConfig: DashboardPageConfig = {
  url: 'custom-analysis',
  pageName: 'ReportSection6ded2b708f20cdd6a32d',
  titleTranslateKey: 'CustomAnalysis',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: 'b1696d25f40d5a857417',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: '0eb8fb7d1d2aab7be64e',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: 'b6baac86e08116aba44b',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '298924160c2370564079',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '298924160c2370564079',
  },
  subscriptionBookmarkInfo: {
    slicerName: '0eb8fb7d1d2aab7be64e',
    mainBookmarkName: 'Bookmarkab89d37e1f383425f921',
    regionalBookmarkName: 'Bookmarkca10a551fcf58c283846',
    uniqueBookmarkName: 'Bookmarkda64ecaa6081d13d74c7',
  },
  airportCodeGroupASlicerInfo: {
    ...airportCodeSlicerSample,
    name: '62c9311a5725775367a9',
  },
  buttonGroupAId: 'a61d5732db01e4198d90',
  periodYearSeasonGroupASlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: 'b6baac86e08116aba44b',
  },
  periodYearGroupASlicerInfo: {
    ...periodYearSlicerSample,
    name: '298924160c2370564079',
  },
  periodQuarterGroupASlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '298924160c2370564079',
  },
  infoMessageTranslateKey: 'CustomAnalysisInfoMessage',
};

const dashboardConfig: DashboardConfig<typeof DepartureDashboard> = {
  urlPrefix: DepartureDashboardsUrlPrefix,
  pages: {
    Overview: OverviewDashboardPageConfig,
    PassengerJourney: PassengerJourneyDashboardPageConfig,
    BenchmarkingPerf: BenchmarkingPerfDashboardPageConfig,
    BenchmarkingTrendPerf: BenchmarkingTrendPerfDashboardPageConfig,
    PassProBenchmarking: PassProBenchmarkingDashboardPageConfig,
    PassProDemographics: PassProDemographicsDashboardPageConfig,
    PassProDemographicsBySegment: PassProDemographicsBySegmentDashboardPageConfig,
    PassProDemographicsTrendOvertime: PassProDemographicsTrendOvertimeDashboardPageConfig,
    PassProTravelBehavior: PassProTravelBehaviorDashboardPageConfig,
    PassProTravelBehaviorBySegment: PassProTravelBehaviorBySegmentDashboardPageConfig,
    PassProTravelBehaviorTrendOvertime: PassProTravelBehaviorTrendOvertimeDashboardPageConfig,
    PassProTravelProfile: PassProTravelProfileDashboardPageConfig,
    PassProTravelProfileBySegment: PassProTravelProfileBySegmentDashboardPageConfig,
    PassProTravelProfileTrendOvertime: PassProTravelProfileTrendOvertimeDashboardPageConfig,
    GapAnalysis: GapAnalysisDashboardPageConfig,
    CustomAnalysis: CustomAnalysisDashboardPageConfig,
  },
};

export const {
  useDashboard: useDepartureDashboard,
  pageUrls: DepartureDashboardUrls,
  routerPath: DepartureDashboardsRouterPath,
  getTitleTranslateKey: getDepartureTitleTranslateKey,
  getInfoMessageTranslateKey: getDepartureInfoMessageTranslateKey,
} = generateDashboard(dashboardConfig);
