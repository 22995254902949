import { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../store';
import { Platform } from '../enums/platform.enum';
import { useFeatureFlagPlatform } from '../featureFlags/hooks';
import { setCurrentPlatform } from './platformSlice';
import { getPlatformFromLocation } from './utils';

interface PlatformProviderProps {
  children: React.ReactNode;
}

function PlatformProvider({ children }: PlatformProviderProps): ReactElement {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const asqEnabled = useFeatureFlagPlatform(Platform.ASQ);

  useEffect(() => {
    if (asqEnabled) dispatch(setCurrentPlatform(getPlatformFromLocation(pathname)));
  }, [pathname, dispatch, asqEnabled]);

  return <>{children}</>;
}

export { PlatformProvider };
