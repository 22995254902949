import { CircularProgress, Stack } from '@mui/material';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import TextField from '../../../../components/atoms/TextField';
import { useFreeTrialIdentificationQuery } from '../../../../hooks/usePricingEndpoints';
import { changeCompany, changeContactNumber, changeModifiedForm, changeTitle } from '../../purchaseSlice';

export default function IdentificationStep(): ReactElement {
  const { t } = useTranslation('Pricing');
  const { currentCompany, currentTitle, currentContactNumber } = useAppSelector((state) => state.purchase);
  const dispatch = useAppDispatch();

  const { data: freeTrialIdentificationData, isLoading } = useFreeTrialIdentificationQuery();
  const { company, title, contactNumber } = freeTrialIdentificationData ?? {};

  useEffect(() => {
    if (company) dispatch(changeCompany(company));
    if (title) dispatch(changeTitle(title));
    if (contactNumber) dispatch(changeContactNumber(contactNumber));
  }, [dispatch, company, title, contactNumber]);

  const checkEmptyValue = (value: string) => value !== '' && value !== null && value !== undefined;

  const handleCompany = ({ target }: React.ChangeEvent<{ value: string }>) => {
    const { value } = target;
    dispatch(changeModifiedForm(true));
    dispatch(changeCompany(value));
  };

  const handleTitle = ({ target }: React.ChangeEvent<{ value: string }>) => {
    const { value } = target;
    dispatch(changeModifiedForm(true));
    dispatch(changeTitle(value));
  };

  const handleContactNumber = ({ target }: React.ChangeEvent<{ value: string }>) => {
    const { value } = target;
    dispatch(changeModifiedForm(true));
    dispatch(changeContactNumber(value));
  };

  return (
    <Stack mt={5} direction="column" sx={{ gap: 1.5, textAlign: 'center' }}>
      <Typography variant="h6">{t(`FreeTrialDialog.Step1`)}</Typography>
      <Divider sx={{ width: 30, marginX: 'auto' }} />
      <Typography variant="h4">{t(`FreeTrialDialog.Identification`)}</Typography>
      {isLoading ? (
        <CircularProgress sx={{ mt: 4, marginX: 'auto' }} />
      ) : (
        <>
          <TextField
            sx={{ width: 380, marginX: 'auto' }}
            onChange={handleCompany}
            data-fullstory="FreeTrialCompanyInput"
            label={t(`FreeTrialDialog.Company`)}
            disabled={checkEmptyValue(company!)}
            value={currentCompany}
          />
          <TextField
            sx={{ width: 380, marginX: 'auto' }}
            onChange={handleTitle}
            data-fullstory="FreeTrialTitleInput"
            label={t(`FreeTrialDialog.Title`)}
            disabled={checkEmptyValue(title!)}
            value={currentTitle}
          />
          <TextField
            sx={{ width: 380, marginX: 'auto' }}
            onChange={handleContactNumber}
            data-fullstory="FreeTrialContactNumberInput"
            label={t(`FreeTrialDialog.ContactNumber`)}
            disabled={checkEmptyValue(contactNumber!)}
            value={currentContactNumber}
          />
        </>
      )}
    </Stack>
  );
}
