import { ReactElement } from 'react';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import { useGetUserQuery } from '../../../../common/hooks/backend';

import HomePage from '../pages/HomePage';

export default function HomeContainer(): ReactElement {
  const { data: userData, isLoading: isLoadingUser } = useGetUserQuery();

  if (isLoadingUser) {
    return <LoadingSpinner />;
  }

  return <HomePage name={userData?.firstName} />;
}
