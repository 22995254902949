import styled from '@emotion/styled';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductRecurringInterval } from '../../../../../modules/DDP/pricing/enums/ProductReccuringInterval';
import { useAppSelector } from '../../../../../store';
import { useModuleAccessesQuery } from '../../../../hooks/usePricingEndpoints';
import { formatPriceWithTwoDecimals } from '../../../../utils/priceHelper';

const StyledStack = styled(Stack)({
  '& .MuiTypography-root': {
    color: 'white',
  },
});

interface ProductsSummaryProps {
  isUpgrade?: boolean;
}

export default function ProductsSummary(props: ProductsSummaryProps): ReactElement {
  const { isUpgrade } = props;
  const { selectedProduct, billingInformation } = useAppSelector((state) => state.purchase);
  const { t } = useTranslation('Pricing');
  const { data: moduleAccesses } = useModuleAccessesQuery();
  // const GstAmount = (selectedProduct.unitPrice * taxRates.GST) / 100;
  // const QstAmount = (selectedProduct.unitPrice * taxRates.QST) / 100;
  let licenceType = '';
  switch (selectedProduct.recurringInterval) {
    case ProductRecurringInterval.Monthly:
      licenceType = t('PurchaseDialog.MonthlyLicence');
      break;
    case ProductRecurringInterval.Yearly:
      licenceType = t('PurchaseDialog.YearlyLicence');
      break;
    default:
      licenceType = t('PurchaseDialog.PermanentLicence');
  }

  const isCanadian = billingInformation?.country === 'CA';

  return (
    <StyledStack
      direction="column"
      textAlign="left"
      gap={1}
      sx={{ backgroundColor: 'primary.main', padding: 3, mx: 2 }}
    >
      <Typography variant="pSmall" mb={1.5}>
        {t('PurchaseDialog.Summary').toUpperCase()}
      </Typography>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="pSmall">{selectedProduct.name}</Typography>
          <Typography variant="pMedium" fontWeight="bold" textAlign="right">
            {licenceType}
          </Typography>
        </Stack>
        {selectedProduct.includedProducts?.map((product) => (
          <Stack direction="row" key={product.priceId}>
            <Typography variant="pSmall" ml={3}>
              {isUpgrade && (
                <Typography component="span" sx={{ whiteSpace: 'pre-wrap' }}>
                  {moduleAccesses?.some((a) => a.priceId === product.priceId) ? '   ' : '+ '}
                </Typography>
              )}
              {product.productName}
            </Typography>
          </Stack>
        ))}
      </Box>
      {/* <Divider sx={{ background: 'white' }} />
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="pSmall">{t('PurchaseDialog.Subtotal')}</Typography>
          <Typography variant="pSmall" fontWeight="bold">
            {t('PurchaseDialog.Price', { price: formatPriceWithTwoDecimals(selectedProduct.unitPrice) })}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="pSmall">{t('PurchaseDialog.GST')}</Typography>
          <Typography variant="pSmall" fontWeight="bold">
            {t('PurchaseDialog.Price', { price: formatPriceWithTwoDecimals(GstAmount) })}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="pSmall">{t('PurchaseDialog.QST')}</Typography>
          <Typography variant="pSmall" fontWeight="bold">
            {t('PurchaseDialog.Price', { price: formatPriceWithTwoDecimals(QstAmount) })}
          </Typography>
        </Stack>
      </Box> */}
      <Divider sx={{ background: 'white' }} />
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="pSmall">{t('PurchaseDialog.Total')}</Typography>
        <Typography variant="pSmall" fontWeight="bold">
          {t('PurchaseDialog.Price', {
            price: formatPriceWithTwoDecimals(selectedProduct.unitPrice),
          })}{' '}
          {t('USD')}
        </Typography>
      </Stack>
      {isCanadian && (
        <Typography variant="pSmall" mt={1}>
          {t('PurchaseDialog.CanadianTaxNotice')}
        </Typography>
      )}
    </StyledStack>
  );
}
