import { ReactElement } from 'react';
import { FeatureFlagKey } from '../../core/featureFlags/featureFlagKeys';

export interface NavItem<AccessType> extends Omit<NavSubItem<AccessType>, 'isLocked' | 'lockedTooltipText'> {
  Icon: (props: any) => ReactElement;
  items?: NavSubItem<AccessType>[];
  getType?: (access: AccessType | undefined) => NavItemType;
  hideIfNoSubItems?: boolean;
}

export interface NavSubItem<AccessType> {
  translateKey: string;
  url: string;
  featureFlagKey?: FeatureFlagKey | FeatureFlagKey[];
  getHasAccess?: (access: AccessType | undefined) => boolean;
  isSelected?: (pathname: string) => boolean;
  isLocked?: (access: AccessType | undefined) => boolean;
  lockedTooltipTK?: string;
  thirdLevel?: boolean;
}

export enum NavItemType {
  Link,
  Menu,
}

export const DefaultNavItemType = NavItemType.Link;

export interface NavItemProps extends NavSubItemProps {
  Icon: (props: any) => ReactElement;
  type: NavItemType;
  items?: NavSubItemProps[];
}

export interface NavSubItemProps {
  translateKey: string;
  url: string;
  selected: boolean;
  locked?: boolean;
  lockedTooltipTK?: string;
  thirdLevel?: boolean;
}
