import { Tab, Tabs } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { FeatureFlagKey } from '../../../../core/featureFlags/featureFlagKeys';
import { useFeatureFlagCallback } from '../../../../core/featureFlags/hooks';
import RepositoryPage from '../enums/RepositoryPage.enum';
import { getPageFromPathname, getUrlFromPage } from '../routes/utils';

export default function RepositoryTabs(): ReactElement {
  const { t } = useTranslation('Repository');
  const { pathname } = useLocation();
  const history = useHistory();
  const isFeatureFlagEnable = useFeatureFlagCallback();

  const currentTab = getPageFromPathname(pathname);

  const handleChange = (_: React.SyntheticEvent, newPage: RepositoryPage) => {
    history.push(getUrlFromPage(newPage));
  };

  return (
    <Tabs value={currentTab} onChange={handleChange}>
      {isFeatureFlagEnable(FeatureFlagKey.RepositoryAirport) && (
        <Tab value={RepositoryPage.Airport} label={t(getNavigationKey(RepositoryPage.Airport))} />
      )}
      {isFeatureFlagEnable(FeatureFlagKey.RepositoryMember) && (
        <Tab value={RepositoryPage.Member} label={t(getNavigationKey(RepositoryPage.Member))} />
      )}
      {isFeatureFlagEnable(FeatureFlagKey.RepositoryPublic) && (
        <Tab value={RepositoryPage.Public} label={t(getNavigationKey(RepositoryPage.Public))} />
      )}
    </Tabs>
  );
}

function getNavigationKey(page: RepositoryPage): string {
  switch (page) {
    case RepositoryPage.Member:
      return 'Navigation.Member';
    case RepositoryPage.Public:
      return 'Navigation.Public';
    case RepositoryPage.Airport:
    default:
      return 'Navigation.Airport';
  }
}
