import fileDownload from 'js-file-download';
import { Template } from '../modules/DDP/analyst/entities/Template';
import ExtractColumns from '../modules/DDP/analyst/enums/extractColumns.enum';
import { FilterLevels } from '../modules/DDP/analyst/enums/filterLevels.enum';
import { getApiClient } from './api/apiClient';

export async function downloadCsv(request: DownloadTrafficCSVRequest, filename: string) {
  const fileClient = await getApiClient();

  return fileClient
    .post('/Traffic', request, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
}

export async function downloadTemplate({ id, name }: Template) {
  const fileClient = await getApiClient();

  return fileClient
    .post(`/Traffic/${id}`, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, `${name}.csv`);
    });
}

export async function downloadHistoricalData() {
  const fileClient = await getApiClient();

  return fileClient
    .get(`/Traffic/historical`, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, `HistoricalDatasetReport.csv`);
    });
}

export async function downloadDdpLibraryFile(fileId: string, filename: string, fileExtension?: string): Promise<void> {
  const name = fileExtension ? `${filename}.${fileExtension}` : filename;
  return downloadFileToClient(`/Files/Download/${fileId}`, name);
}

export async function downloadFileBlob(url: string): Promise<Blob> {
  const fileClient = await getApiClient();

  return fileClient
    .get(url, {
      responseType: 'blob',
    })
    .then(({ data }) => data as Blob);
}

export async function downloadFileToClient(url: string, filename: string): Promise<void> {
  return downloadFileBlob(url).then((blob) => {
    fileDownload(blob, filename);
  });
}

export async function downloadTutorial(fileId: string, filename: string) {
  const fileClient = await getApiClient();

  return fileClient
    .get(`/Tutorial/Download/${fileId}`, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, filename);
    });
}

interface DownloadTrafficCSVRequest {
  startYear?: string;
  endYear?: string;
  nbOfLastMonths?: number;
  airports: string[];
  countries: string[];
  countryGroups: string[];
  metrics: string[];
  periodType: number;
  timeFetchingType: number;
  regions: string[];
  columnBy: ExtractColumns;
  aggregationLevel: FilterLevels;
}
