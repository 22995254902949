import { useEffect, useMemo, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { useAsqAccess } from '../../../../../../../common/utils/accessHelper';
import { useHomePageFilter } from '../../../../../home/filter/hooks';

import { FileFilterBase } from '../../../../types/FileFilter';
import RepositoryLocationState from '../../../../types/RepositoryLocationState';
import { AirportFileQueryArgs } from '../fetchAirportFiles';
import { resetState, setAirportValues, setModuleValues } from './actions';
import { FetchingFiltersHelper } from './helper';
import { FetchingFiltersStateReducerType, reducer } from './reducer';

interface FetchingFiltersHookReturnType {
  airportsFilter: FileFilterBase;
  modulesFilter: FileFilterBase;
  isFilterLoading: boolean;
  queryArgsList: AirportFileQueryArgs[];
}

export function useFetchingFilters(): FetchingFiltersHookReturnType {
  const { access } = useAsqAccess();
  const { airportCode } = useHomePageFilter();
  const { state: locationState } = useLocation<RepositoryLocationState | undefined>();

  const initialState = useMemo(
    () => FetchingFiltersHelper.generateState(access, airportCode ?? undefined, locationState?.module),
    [access, airportCode, locationState],
  );

  const [state, dispatch] = useReducer<FetchingFiltersStateReducerType>(reducer, initialState);

  const isFilterLoading = state.airportsFilter.isLoading || state.modulesFilter.isLoading;

  const airportsFilter: FileFilterBase = {
    ...state.airportsFilter,
    onChange: (values) => {
      if (access !== undefined) dispatch(setAirportValues(values, access));
    },
  };

  const modulesFilter: FileFilterBase = {
    ...state.modulesFilter,
    onChange: (values) => {
      if (access !== undefined) dispatch(setModuleValues(values, access));
    },
  };

  useEffect(() => {
    dispatch(resetState(initialState));
  }, [initialState]);

  return {
    airportsFilter,
    modulesFilter,
    isFilterLoading,
    queryArgsList: state.airportFileQueryArgsList,
  };
}
