import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AsqAccess } from '../../core/entities/asqAccess';
import { Platform } from '../../core/enums/platform.enum';
import { useFeatureFlagCallback } from '../../core/featureFlags/hooks';
import { usePlatform } from '../../core/platform/hooks';
import asqNavItems from '../../modules/ASQ/navigation';
import ddpNavItems from '../../modules/DDP/navigation';
import { ModuleAccess } from '../modules/purchase/entities/ModuleAccess';
import { DefaultNavItemType, NavItem, NavItemProps, NavItemType } from '../types/NavItem';
import { useAsqAccess } from '../utils/accessHelper';
import { useModuleAccessesQuery } from './usePricingEndpoints';

type NavItemMapper<AccessType> = (navItem: NavItem<AccessType>) => NavItemProps | null;

function useTransformNavItem<AccessType>(access: AccessType | undefined): NavItemMapper<AccessType> {
  const { pathname } = useLocation();
  const isFeatureFlagEnable = useFeatureFlagCallback();

  return useCallback(
    ({
      Icon,
      items,
      getType,
      isSelected,
      hideIfNoSubItems = false,
      translateKey,
      url,
      featureFlagKey,
      getHasAccess,
    }: NavItem<AccessType>): NavItemProps | null => {
      const isFeatureEnabled = isFeatureFlagEnable(featureFlagKey);
      const hasAccess = getHasAccess?.(access) ?? true;

      if (!isFeatureEnabled || !hasAccess) return null;

      let type = getType?.(access) ?? DefaultNavItemType;

      const itemsProps = items
        ?.filter((navSubItem) => {
          const isFeatureEnabledSubItem = isFeatureFlagEnable(navSubItem.featureFlagKey);
          const hasAccessSubItem = navSubItem.getHasAccess?.(access) ?? true;
          return isFeatureEnabledSubItem && hasAccessSubItem;
        })
        .map(
          ({
            url: urlSubItem,
            isSelected: isSelectedSubItem,
            translateKey: translateKeySubItem,
            isLocked,
            lockedTooltipTK,
            thirdLevel,
          }) => ({
            url: urlSubItem,
            translateKey: translateKeySubItem,
            selected: pathname === urlSubItem || (isSelectedSubItem?.(pathname) ?? false),
            locked: isLocked?.(access),
            lockedTooltipTK,
            thirdLevel,
          }),
        );

      if (type === NavItemType.Menu && (itemsProps === undefined || itemsProps.length === 0)) {
        if (hideIfNoSubItems) return null;
        type = NavItemType.Link;
      }

      return {
        url,
        Icon,
        translateKey,
        type,
        selected: pathname === url || (isSelected?.(pathname) ?? false),
        items: itemsProps,
      };
    },
    [access, pathname, isFeatureFlagEnable],
  );
}

type NavItemTuple<AccessType> = [NavItem<AccessType>[], NavItemMapper<AccessType>];

function useDdpNavItems(skip?: boolean): NavItemTuple<ModuleAccess[]> {
  const { data: access } = useModuleAccessesQuery(undefined, { skip });
  const mapNavItem = useTransformNavItem(access);
  return [ddpNavItems, mapNavItem];
}

function useAsqNavItems(skip?: boolean): NavItemTuple<AsqAccess> {
  const { access } = useAsqAccess(skip);
  const mapNavItem = useTransformNavItem(access);
  return [asqNavItems, mapNavItem];
}

export function useNavItemsForPlatform(): NavItemProps[] {
  const { currentPlatform } = usePlatform();
  const [ddpNavItemsData, ddpNavItemsMapper] = useDdpNavItems(currentPlatform !== Platform.DDP);
  const [asqNavItemsData, asqNavItemsMapper] = useAsqNavItems(currentPlatform !== Platform.ASQ);

  return useMemo(() => {
    let result: (NavItemProps | null)[];

    switch (currentPlatform) {
      case Platform.DDP:
        result = ddpNavItemsData.map(ddpNavItemsMapper);
        break;
      case Platform.ASQ:
        result = asqNavItemsData.map(asqNavItemsMapper);
        break;
      default:
        result = [];
        break;
    }

    return result.filter((navItem) => navItem !== null) as NavItemProps[];
  }, [currentPlatform, ddpNavItemsData, ddpNavItemsMapper, asqNavItemsData, asqNavItemsMapper]);
}
