import generateDashboard from '../../dashboards/generator';
import { DashboardConfig, DashboardPageConfig } from '../../dashboards/types';
import { AsqUrlPrefix } from '../../../../common/constants/urls';
import {
  airportCodeSlicerSample,
  comparedPeriodQuarterSlicerSample,
  comparedPeriodYearSeasonSlicerSample,
  comparedPeriodYearSlicerSample,
  periodQuarterSlicerSample,
  periodYearSeasonSlicerSample,
  periodYearSlicerSample,
  subscriptionSlicerSample,
} from '../../departure/dashboards/slicerSamples';
import ArrivalDashboard from './enum';

export const ArrivalDashboardsUrlPrefix = `/${AsqUrlPrefix}/arrival`;

export const OverviewDashboardPageConfig: DashboardPageConfig = {
  url: 'overview',
  pageName: 'ReportSection',
  titleTranslateKey: 'Overview',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: 'bde489b2591d3592c01b',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerSample,
    name: 'd535076b5c186723411d',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '8d4768517e95aabe91d0',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: 'af9cc1820817ac862051',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: 'af9cc1820817ac862051',
  },
  comparedPeriodYearSeasonSlicerInfo: {
    ...comparedPeriodYearSeasonSlicerSample,
    name: '00d6ae8249562de66e22',
  },
  comparedPeriodYearSlicerInfo: {
    ...comparedPeriodYearSlicerSample,
    name: '47b176c6d8780e63e3a2',
  },
  comparedPeriodQuarterSlicerInfo: {
    ...comparedPeriodQuarterSlicerSample,
    name: '47b176c6d8780e63e3a2',
  },
  subscriptionBookmarkInfo: {
    slicerName: 'd535076b5c186723411d',
    mainBookmarkName: 'Bookmark4aa542d4a7cf2141134e',
    regionalBookmarkName: 'Bookmark50164bc0be3d0e08eeeb',
    uniqueBookmarkName: 'Bookmark2f66f2f8223a341fa1dc',
  },
  infoMessageTranslateKey: 'OverviewInfoMessage',
};

const dashboardConfig: DashboardConfig<typeof ArrivalDashboard> = {
  urlPrefix: ArrivalDashboardsUrlPrefix,
  pages: {
    Overview: OverviewDashboardPageConfig,
  },
};

export const {
  useDashboard: useArrivalDashboard,
  pageUrls: ArrivalDashboardUrls,
  routerPath: ArrivalDashboardsRouterPath,
  getTitleTranslateKey: getArrivalTitleTranslateKey,
  getInfoMessageTranslateKey: getArrivalInfoMessageTranslateKey,
} = generateDashboard(dashboardConfig);
