import { ReactElement } from 'react';

import { Divider, Stack, Typography } from '@mui/material';

export interface ColumnValuesListProps {
  information: Map<string, string>;
}

export default function ColumnValuesList(props: ColumnValuesListProps): ReactElement {
  const { information } = props;

  return (
    <Stack
      paddingY={1}
      direction="row"
      spacing={2}
      divider={<Divider orientation="vertical" sx={{ bgcolor: '#77797E' }} flexItem />}
    >
      {Array.from(information.entries()).map(([key, value]) => (
        <Stack key={key} direction="column" spacing={0.5}>
          <Typography variant="pSmall" color="#77797E" sx={{ textTransform: 'uppercase' }}>
            {key}
          </Typography>
          <Typography color="primary">{value}</Typography>
        </Stack>
      ))}
    </Stack>
  );
}
