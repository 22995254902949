import { faAnalytics, faBooks, faChartBar, faChartPie, faHome } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeSvgIcon } from '../../common/components/atoms/FontAwesomeIcon';
import { ModuleAccess } from '../../common/modules/purchase/entities/ModuleAccess';
import { NavItem, NavItemType } from '../../common/types/NavItem';
import { DdpUrls } from './urls';

type DdpNavItem = NavItem<ModuleAccess[]>;

const HomeNav: DdpNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faHome} />,
  url: DdpUrls.Home,
  translateKey: 'Home',
};

const ExecutiveModuleNav: DdpNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faChartPie} />,
  url: DdpUrls.ExecutiveModuleReports,
  translateKey: 'Executive',
};

const LibraryModuleNav: DdpNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faBooks} />,
  url: DdpUrls.LibraryModule,
  translateKey: 'Library',
  getType: () => NavItemType.Menu,
  items: [
    {
      translateKey: 'AirportTraffic',
      url: `${DdpUrls.LibraryModuleTraffic}`,
    },
    {
      translateKey: 'AirportForecast',
      url: `${DdpUrls.LibraryModuleForecast}`,
    },
    {
      translateKey: 'AirportEconomics',
      url: `${DdpUrls.LibraryModuleEconomics}`,
    },
    {
      translateKey: 'AirportEnvironmentSustainability',
      url: `${DdpUrls.LibraryModuleEnvironmentSustainability}`,
    },
  ],
};

const AnalystModuleNav: DdpNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faChartBar} />,
  url: DdpUrls.AnalystModule,
  translateKey: 'Analyst',
  getType: () => NavItemType.Menu,
  items: [
    {
      translateKey: 'AnalystMonthlyData',
      url: `${DdpUrls.AnalystModuleReportCreation}`,
    },
    {
      translateKey: 'AnalystTemplates',
      url: `${DdpUrls.AnalystModuleTemplates}`,
      thirdLevel: true,
    },
    {
      translateKey: 'AnalystHistoricalData',
      url: `${DdpUrls.AnalystModuleHistoricalData}`,
    },
  ],
};

const BenchmarkModuleNav: DdpNavItem = {
  Icon: () => <FontAwesomeSvgIcon fontSize="inherit" icon={faAnalytics} />,
  url: DdpUrls.BenchmarkModule,
  translateKey: 'Benchmark',
};

const navItems: DdpNavItem[] = [HomeNav, ExecutiveModuleNav, LibraryModuleNav, AnalystModuleNav, BenchmarkModuleNav];

export default navItems;
