import { combineComparers } from '../../../../../../common/utils/comparer';
import { categoryFileComparer, periodFileComparer, yearFileComparer, titleFileComparer } from '../../../utils/comparer';

export const periodHeaderComparer = combineComparers(
  periodFileComparer,
  yearFileComparer,
  categoryFileComparer,
  titleFileComparer,
);

export const yearHeaderComparer = combineComparers(
  yearFileComparer,
  periodFileComparer,
  categoryFileComparer,
  titleFileComparer,
);

export const categoryHeaderComparer = combineComparers(
  categoryFileComparer,
  yearFileComparer,
  periodFileComparer,
  titleFileComparer,
);

export const titleHeaderComparer = combineComparers(
  titleFileComparer,
  yearFileComparer,
  periodFileComparer,
  categoryFileComparer,
);

export const defaultComparer = yearHeaderComparer;
