import { ReactElement } from 'react';

import Typography, { TypographyProps } from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { useGetAccountDetailsQuery } from '../../hooks/backend';

export default function OrganizationTypography<C extends React.ElementType>(
  props: TypographyProps<C, { component?: C }>,
): ReactElement {
  const { t } = useTranslation('Home');
  const { data: accountDetails } = useGetAccountDetailsQuery();

  return (
    <>
      {accountDetails?.accountName && (
        <Typography variant="h5" color="primary" {...props}>
          {accountDetails?.accountName.match(/Generic_/i)
            ? t('GenericGroup')
            : t('Group', { organisation: accountDetails?.accountName })}
        </Typography>
      )}
    </>
  );
}
