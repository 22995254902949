import { Box, CircularProgress, Divider } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ReactElement } from 'react';
import OrganizationTypography from '../../../../common/components/atoms/OrganizationTypography';
import { useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';
import ModuleAccessRow from '../atoms/ModuleAccessRow';

export default function AccountDetailsForm(): ReactElement {
  const { data: accesses, isLoading } = useModuleAccessesQuery();
  return (
    <Stack pt={6} role="form">
      <OrganizationTypography />
      <Stack pt={5} direction="column" spacing={2} divider={<Divider sx={{ bgcolor: 'primary.light' }} />}>
        {isLoading ? (
          <CircularProgress sx={{ marginX: 'auto' }} />
        ) : (
          accesses?.map((module) => (
            <Box key={module.moduleKey}>
              <ModuleAccessRow module={module} />
            </Box>
          ))
        )}
      </Stack>
    </Stack>
  );
}
