import { ReactElement } from 'react';

import { Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatPeriodDate } from '../../../../common/utils/date';
import { formatVariation } from '../../../../common/utils/number';
import { PeriodDate } from '../../../../core/entities/periodDate';
import Data from './Data';
import Variation from './Variation';

interface StatisticWithVariationProps {
  title: string;
  value?: number;
  variation?: number;
  variationDate?: PeriodDate | null;
  isLoading: boolean;
}

export default function StatisticWithVariation({
  title,
  value,
  variation,
  variationDate,
  isLoading,
}: StatisticWithVariationProps): ReactElement {
  const { t } = useTranslation('Home', { keyPrefix: 'ASQ' });
  const tooltipText =
    isLoading || variation === undefined || variationDate === undefined || variationDate === null
      ? ''
      : t('Cards.Since', {
          variation: formatVariation(variation),
          date: formatPeriodDate(variationDate),
        });
  const popperModifiers = [{ name: 'offset', options: { offset: [0, -16] } }];
  return (
    <Tooltip title={tooltipText} PopperProps={{ modifiers: popperModifiers }}>
      <Stack direction="column" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={0.5}>
          <Data value={value} isLoading={isLoading} />
          {!isLoading && <Variation value={variation} date={variationDate} />}
        </Stack>
        <Typography variant="pSmall" color="primary">
          {title}
        </Typography>
      </Stack>
    </Tooltip>
  );
}
