import { useCallback, useEffect, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import NoFilesFound from '../../../../../../common/components/atoms/NoFilesFound';
import { formatDateTranslate } from '../../../../../../common/utils/date';
import { displaySnackbar } from '../../../../../../core/notifications/snackbarSlice';
import { useAppDispatch } from '../../../../../../store';
import { MemberFile } from '../../../entities/MemberFile';
import { DetailsDrawerProps } from '../../../molecules/DetailsDrawer';
import { FileExplorerProps } from '../../../molecules/FileExplorer';
import { BulkFileBlobDownloader } from '../../../utils/bulkDownload';
import { downloadMemberFile, downloadMemberFileBlob } from '../../../utils/downloadFile';
import { getFileName } from '../../../utils/file';
import { useFileFilterSystem } from '../../fileFilterSystem/hook';
import { useDetailsFile } from '../../hooks/detailsFile';
import { defaultComparer } from './comparer';
import { useFetchMemberFiles } from './fetchMemberFiles';
import { useFetchingFilters } from './fetchingFilters/hook';
import { generateInitialState } from './filters';
import { getHeaders } from './headers';
import { generateRow } from './rows';

interface MemberFileExplorerProps extends FileExplorerProps<MemberFile, MemberFile> {}

export function useMemberFileExplorer(): MemberFileExplorerProps {
  const { t } = useTranslation('Repository');
  const dispatch = useAppDispatch();

  const { modulesFilter, isFilterLoading, queryArgsList } = useFetchingFilters();

  const { files, isLoading: isFilesLoading } = useFetchMemberFiles(queryArgsList);

  const initialFileFilterSystemState = useMemo(() => generateInitialState(), []);

  const { filters, filteredFiles, getFile, setFiles, resetFilters } = useFileFilterSystem<MemberFile>(
    initialFileFilterSystemState,
    isFilterLoading || isFilesLoading,
  );

  useEffect(() => {
    if (files !== undefined) setFiles(files);
  }, [files, setFiles]);

  const headers = useMemo(() => getHeaders(t), [t]);

  const onDownloadFile = useCallback(
    (file: MemberFile) => () =>
      new Promise<void>((resolve) =>
        downloadMemberFile(file)
          .finally(() => resolve())
          .catch((err) => {
            console.error(err);
            dispatch(
              displaySnackbar({
                message: t('Errors.DownloadFileError'),
                severity: 'error',
              }),
            );
          }),
      ),
    [t, dispatch],
  );

  const getDetailsDataFile = useCallback<(file: MemberFile) => DetailsDrawerProps['data']>(
    (file: MemberFile) => [
      { title: t('FileExplorer.Details.FileName'), value: getFileName(file), marginBottom: 5 },
      {
        title: t('FileExplorer.Details.FileDescription'),
        value: file.description ?? t('FileExplorer.Details.FileDescriptionEmpty'),
        dense: true,
        marginBottom: 5,
      },
      { title: t('FileExplorer.Details.FileExtension'), value: file.extension, marginBottom: 1 },
      { title: t('FileExplorer.Details.FileSize'), value: file.size, marginBottom: 1 },
      {
        title: t('FileExplorer.Details.FilePublishingDate'),
        value: formatDateTranslate(t, 'FileExplorer.Details.FormattedDate', file.publishedDate),
        marginBottom: 5,
      },
    ],
    [t],
  );

  const { onDetails, detailsDrawerProps } = useDetailsFile(onDownloadFile, getDetailsDataFile);

  const generateRowCallback = useCallback<MemberFileExplorerProps['generateRow']>(
    (file: MemberFile) => {
      const onDownload = onDownloadFile(file);
      const onInfos = () => {
        onDetails(file);
      };

      return generateRow(file, t, onDownload, onInfos);
    },
    [t, onDownloadFile, onDetails],
  );

  const bulkFileBlobDownloader = useCallback<BulkFileBlobDownloader>(
    (fileIds) => {
      const filesToDownload = fileIds.map((id) => getFile(id)).filter((file) => file !== undefined) as MemberFile[];
      return filesToDownload.map(downloadMemberFileBlob);
    },
    [getFile],
  );

  const emptyFileTableComponent = <NoFilesFound title={t(getEmptyTitleTranslateKey(modulesFilter.values.length))} />;

  return {
    files: filteredFiles,
    totalFilesCount: files?.length ?? 0,
    isFilesLoading: isFilesLoading || (files === undefined && isFilterLoading),
    headers,
    generateRow: generateRowCallback,
    defaultComparer,
    bulkFileBlobDownloader,
    filters: [modulesFilter, ...filters],
    resetFilters,
    emptyFileTableComponent,
    detailsDrawerProps,
  };
}

function getEmptyTitleTranslateKey(moduleCount: number): string {
  if (moduleCount === 0) return 'FileExplorer.Table.SelectModuleTitle';
  return 'FileExplorer.Table.NoFilesTitle';
}
