import { ReactElement } from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { faCalendarDay } from '@fortawesome/pro-light-svg-icons';

import CardCornerLabel from '../../../../common/components/atoms/CardCornerLabel';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import TruncatedTypograhpy from '../../../../common/components/atoms/TruncatedTypography';
import { ModuleAccess } from '../../../../common/modules/purchase/entities/ModuleAccess';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import { ModuleDescription } from '../../../../core/entities/moduleDescription';
import TileActions from './TileActions';

export interface TileProps {
  module: ModuleDescription;
  access: ModuleAccess | undefined;
}

export default function Tile({ module, access }: TileProps): ReactElement | null {
  const { t } = useTranslation('Modules');
  const accessOpacity = module.comingSoon || !access ? '50%' : '100%';

  const formattedDate = () => {
    if (!access) {
      return t('Inactive');
    }

    if (access.status === ModuleAccessStatus.FreeAccess) {
      return t('MemberAccess');
    }

    if ([ModuleAccessStatus.Active, ModuleAccessStatus.Trialing].includes(access.status)) {
      return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
        .format(new Date(access?.renewalDate))
        .replace(/(\w{3})/, '$1.');
    }

    return t('Inactive');
  };

  return (
    <StyledCard sx={{ borderRadius: 2.5 }} role="menuitem">
      {module.comingSoon && <CardCornerLabel informationText={t('ComingSoonTag')} />}
      <ResponsiveFlex>
        <ModuleIconBox>
          <ModuleIconImage src={`/images/${module.name}.svg`} alt="module logo" />
        </ModuleIconBox>
        <Box sx={{ flex: 1 }}>
          <CardContent sx={{ paddingLeft: 3, paddingTop: 3, opacity: accessOpacity }}>
            <Typography gutterBottom variant="h4">
              {t(`Names.${module.translateKey}`)}
            </Typography>
            <Typography component="div" sx={{ pb: 2 }} variant="pXSmall" color="primary" display="inline-block">
              <Box
                padding={0.5}
                display="flex"
                border="1px solid"
                borderColor="primary"
                sx={{ textTransform: 'uppercase' }}
              >
                <FontAwesomeSvgIcon sx={{ width: 13, height: 13, marginRight: 0.5 }} icon={faCalendarDay} />
                {formattedDate()}
              </Box>
            </Typography>
            <TruncatedTypograhpy sx={{ minHeight: 68 }} variant="pMedium">
              {t(`ShortDescriptions.${module.translateKey}`).replace('<br>', '')}
            </TruncatedTypograhpy>
          </CardContent>
          <CardActions sx={{ p: 3, pt: 0 }}>
            <TileActions
              accessStatus={access?.status}
              comingSoon={module.comingSoon}
              url={module.url}
              moduleName={module.name}
            />
          </CardActions>
        </Box>
      </ResponsiveFlex>
    </StyledCard>
  );
}

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 330,
  height: '100%',
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    maxWidth: 650,
    minWidth: 330,
  },
}));

const ResponsiveFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

const ModuleIconImage = styled('img')(() => ({
  display: 'block',
  margin: 'auto',
}));

const ModuleIconBox = styled(Box)(() => ({
  backgroundColor: '#99B0D11A',
  minWidth: '150px',
  minHeight: '150px',
  alignItems: 'center',
  display: 'flex',
}));
