import { OneTimePayment } from '../../modules/DDP/pricing/entities/OneTimePayment';
import { PricingProduct } from '../../modules/DDP/pricing/entities/PricingProduct';
import PurchaseItem from '../../modules/DDP/pricing/entities/PurchaseItem';
import { Subscription } from '../../modules/DDP/pricing/entities/Subscription';
import FreeTrialInfo from '../modules/purchase/entities/FreeTrialInfo';
import { ModuleAccess } from '../modules/purchase/entities/ModuleAccess';
import { FreeTrialAvailabilityDto } from '../modules/purchase/entities/freeTrialAvailabilityDto';
import { FreeTrialIdentificationDto } from '../modules/purchase/entities/freeTrialIdentificationDto';
import { ReasonForComing } from '../modules/purchase/entities/reasonForComing';
import { backend } from './backend';

export const pricingEndpoints = backend.injectEndpoints({
  endpoints: (builder) => ({
    getAllPricingProducts: builder.query<PricingProduct[], void>({
      query: () => 'Pricing/products',
    }),
    getPricingConfiguration: builder.query<{ publishableKey: string }, void>({
      query: () => 'Pricing/config',
    }),
    moduleAccesses: builder.query<ModuleAccess[], void>({
      query: () => 'Pricing/ModuleAccesses',
      providesTags: ['Subscription'],
    }),
    freeTrialAvailability: builder.query<FreeTrialAvailabilityDto, void>({
      query: () => 'Pricing/FreeTrial/Availability',
      providesTags: ['Subscription'],
    }),
    freeTrialIdentification: builder.query<FreeTrialIdentificationDto, void>({
      query: () => 'Pricing/FreeTrial/Identification',
    }),
    freeTrialReasonsForComing: builder.query<ReasonForComing[], void>({
      query: () => 'Pricing/FreeTrial/ReasonsForComing',
    }),
    startFreeTrial: builder.mutation<Subscription, FreeTrialInfo>({
      query: (body) => ({
        url: 'Pricing/FreeTrial',
        method: 'POST',
        body,
      }),
    }),
    purchaseSubscriptionItem: builder.mutation<Subscription, PurchaseItem>({
      query: (body) => ({
        url: 'Pricing/subscriptions',
        method: 'POST',
        body,
      }),
    }),
    purchaseOneTimePaymentItem: builder.mutation<OneTimePayment, PurchaseItem>({
      query: (body) => ({
        url: 'Pricing/oneTimePayments',
        method: 'POST',
        body,
      }),
    }),
    upgradeToAnnual: builder.mutation<void, { productId: string }>({
      query: (body) => ({
        url: 'Pricing/subscriptions/UpgradeToAnnual',
        method: 'PATCH',
        body,
      }),
    }),
    upgradeToFullPlan: builder.mutation<void, void>({
      query: () => ({
        url: 'Pricing/FreeTrial/End',
        method: 'PATCH',
      }),
    }),
    upgradeToBundle: builder.mutation<void, { priceId: string }>({
      query: (body) => ({
        url: 'Pricing/subscriptions/UpgradeToBundle',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetAllPricingProductsQuery,
  useGetPricingConfigurationQuery,
  useModuleAccessesQuery,
  useFreeTrialAvailabilityQuery,
  useFreeTrialIdentificationQuery,
  useFreeTrialReasonsForComingQuery,
  useStartFreeTrialMutation,
  usePurchaseSubscriptionItemMutation,
  usePurchaseOneTimePaymentItemMutation,
  useUpgradeToAnnualMutation,
  useUpgradeToFullPlanMutation,
  useUpgradeToBundleMutation,
} = pricingEndpoints;
