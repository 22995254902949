import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { AccordionDetails, Box, Card, CardContent, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';
import CardCornerLabel from '../../../../common/components/atoms/CardCornerLabel';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { ModuleAccess } from '../../../../common/modules/purchase/entities/ModuleAccess';
import { PricingProduct } from '../entities/PricingProduct';
import PriceRecurringIntervalLabel from './PriceRecurringIntervalLabel';
import ProductFeatureList from './ProductFeatureList';
import ProductPurchaseActionButton from './ProductPurchaseActionButton';

const StyledAccordion = styled(Accordion)(() => ({
  boxShadow: 'unset',
  '&:before': {
    display: 'none',
  },
}));

interface ProductCardProps {
  product: PricingProduct;
  moduleAccesses: ModuleAccess[];
}

export default function ProductCard(props: ProductCardProps) {
  const { product, moduleAccesses } = props;
  const { t } = useTranslation('Pricing');

  return (
    <Card sx={{ minWidth: 210, maxWidth: 210, position: 'relative' }}>
      {product.mostPopular && <CardCornerLabel informationText={t('MostPopular')} />}
      <CardContent sx={{ pt: 5, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <Typography variant="h5">{product.displayName}</Typography>
          <Typography variant="pXSmall" sx={{ minHeight: 16 }}>
            {product.productSubtitle}
          </Typography>
        </Box>
        <Typography variant="pSmall" sx={{ minHeight: 100 }}>
          {product.description}
        </Typography>
        <Divider sx={{ width: 20, background: 'black' }} />
        <PriceRecurringIntervalLabel unitPrice={product.unitPrice} recurringInterval={product.recurringInterval} />
        <ProductPurchaseActionButton product={product} moduleAccesses={moduleAccesses} />
        <StyledAccordion disableGutters>
          <AccordionSummary
            expandIcon={<FontAwesomeSvgIcon icon={faAngleDown} />}
            aria-controls="panel-content"
            id="panel-header"
            sx={{ px: 0.5, fontStyle: 'italic' }}
          >
            <Typography variant="pSmall">{t('ProductInclusions')}</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingLeft: 1 }}>
            <ProductFeatureList features={product.features} />
          </AccordionDetails>
        </StyledAccordion>
      </CardContent>
    </Card>
  );
}
