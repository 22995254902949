import React, { ReactElement } from 'react';

export interface NewTabLinkProps {
  link: string;
  children?: React.ReactNode;
}

export default function NewTabLink({ link, children }: NewTabLinkProps): ReactElement {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      {children}
    </a>
  );
}
