import { Box, Stack } from '@mui/material';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';

import { useSelectItemIds } from '../../hooks/selectItemIds';
import ASQPaperTableHeader from '../atoms/ASQPaperTableHeader';
import { Comparer, HeaderDef, RowDef, SortingState, SortingType } from '../types/TableTypes';
import ASQPaperList from './ASQPaperList';

export interface ASQPaperTableProps<T> {
  headers: HeaderDef<T>[];
  rows: RowDef<T>[];
  onSelectedRowsChange?: (rows: string[]) => void;
  empty?: React.ReactElement;
  defaultComparer?: Comparer<T>;
  isLoading?: boolean;
}

export default function ASQPaperTable<T>({
  headers,
  rows,
  onSelectedRowsChange,
  empty,
  defaultComparer,
  isLoading,
}: ASQPaperTableProps<T>): ReactElement {
  const { sortedRows, sortingState, onSortChange, resetSortState } = useSortRows(rows, defaultComparer);

  const { selectedItemIds, isItemSelected, updateItemState, toggleAllItems, unselectAllItems } = useSelectItemIds(rows);
  const ratioRowsSelected = selectedItemIds.length / rows.length;

  useEffect(() => {
    unselectAllItems();
  }, [isLoading, unselectAllItems]);

  useEffect(() => {
    resetSortState();
  }, [headers, resetSortState]);

  useEffect(() => {
    onSelectedRowsChange?.(selectedItemIds);
  }, [selectedItemIds, onSelectedRowsChange]);

  return (
    <Stack height="100%">
      <ASQPaperTableHeader
        onSortChange={onSortChange}
        isLoading={isLoading}
        headers={headers}
        sortingState={sortingState}
        ratioRowsSelected={ratioRowsSelected}
        onToggleAllRows={toggleAllItems}
      />

      {!isLoading && (
        <Box flexGrow={1}>
          {rows.length === 0 && empty}
          {rows.length > 0 && (
            <ASQPaperList
              headers={headers}
              rows={sortedRows}
              isRowSelected={isItemSelected}
              onRowSelected={updateItemState}
            />
          )}
        </Box>
      )}
    </Stack>
  );
}

interface SortRowsHookReturnType<T> {
  sortedRows: RowDef<T>[];
  sortingState: SortingState<T> | undefined;
  onSortChange: (headerId: string, comparer: Comparer<T>) => void;
  resetSortState: () => void;
}

function useSortRows<T>(rows: RowDef<T>[], defaultComparer?: Comparer<T>): SortRowsHookReturnType<T> {
  const [sortingState, setSortingState] = useState<SortingState<T>>();

  const onSortChange = useCallback<SortRowsHookReturnType<T>['onSortChange']>((headerId, comparer) => {
    setSortingState((state) => {
      const sortingType =
        state?.headerId === headerId && state?.sortingType === SortingType.Sort
          ? SortingType.Reverse
          : SortingType.Sort;

      return {
        headerId,
        comparer,
        sortingType,
      };
    });
  }, []);

  const resetSortState = useCallback(() => setSortingState(undefined), []);

  const sortedRows = useMemo(() => {
    const rowsToSort = [...rows];

    if (sortingState !== undefined) {
      const { sortingType, comparer } = sortingState;

      if (sortingType === SortingType.Sort) {
        rowsToSort.sort((a, b) => comparer(a.data, b.data));
      } else if (sortingType === SortingType.Reverse) {
        rowsToSort.sort((a, b) => -comparer(a.data, b.data));
      }
    } else if (defaultComparer !== undefined) {
      rowsToSort.sort((a, b) => defaultComparer(a.data, b.data));
    }

    return rowsToSort;
  }, [rows, sortingState, defaultComparer]);

  return {
    sortedRows,
    sortingState,
    onSortChange,
    resetSortState,
  };
}
