import { ReactElement, useEffect, useState } from 'react';

import { Box, ButtonProps, CircularProgress } from '@mui/material';

import { cancellablePromise } from '../../utils/promise';
import Button from './Button';

interface LoadingPromiseButtonProps extends Omit<ButtonProps, 'onClick'> {
  onClick: () => Promise<void>;
  onError?: (error: any) => void;
  fullstory?: string;
}

export default function LoadingPromiseButton({
  onClick,
  onError,
  fullstory,
  ...props
}: LoadingPromiseButtonProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [promise, setPromise] = useState<Promise<void>>();

  useEffect(() => {
    let cleanup: void | (() => void);

    if (promise !== undefined) {
      setIsLoading(true);

      const { promise: resultPromise, cancel, isCancelled } = cancellablePromise(promise);

      resultPromise
        .then(() => {
          if (!isCancelled()) setIsLoading(false);
        })
        .catch((error) => {
          if (!isCancelled()) {
            setIsLoading(false);
            onError?.(error);
          }
        });

      cleanup = cancel;
    }

    return cleanup;
  }, [promise, onError]);

  const handleClick = () => {
    setPromise(onClick());
  };

  const disabled = isLoading || (props.disabled ?? false);

  return (
    <Box sx={{ position: 'relative' }} display="inline-block">
      <Button {...props} data-fullstory={fullstory} onClick={handleClick} disabled={disabled}>
        {props.children}
      </Button>
      {isLoading && (
        <CircularProgress
          size={25}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
}
