import { Page, Report } from 'powerbi-client';
import { useCallback, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { SlicerState } from '../../../common/components/molecules/PowerBIReport';
import {
  useLazyAirportsForCustomPanelQuery,
  useLazyAirportSubscriptionAndPeriodQuery,
  useUserAirportsSubscriptionsQuery,
  useUserAvailableCommentsAirportsSubscriptionsQuery,
  useUserAvailableDissatisfiedPassengerAirportsSubscriptionsQuery,
} from '../../../common/hooks/backend';
import {
  extractPeriodDateFromRegionalFormatting,
  formatPeriodDateForRegional,
  periodDateSorter,
  stringToPeriod,
} from '../../../common/utils/date';
import { synchronizeSlicerBookmark } from '../../../common/utils/power-bi-bookmark';
import {
  getPeriodHierarchySlicerSelectedValues,
  getSlicerSelectedValues,
  HierachyValues,
  HierarchySlicerInfos,
  setHierarchySlicerValue,
  setSlicerValue,
  SlicerInfos,
} from '../../../common/utils/power-bi-slicers';
import {
  AsqAdditionalAirportAccessDto,
  AsqAirportSubscriptionAccessDto,
} from '../../../core/entities/asqAdditionalAirportAccessDto';
import { AsqAirportSubscriptionDto } from '../../../core/entities/asqAirportSubscriptionDto';
import { AsqUserAirportsSubscriptionDto } from '../../../core/entities/asqUserAirportsSubscriptionDto';
import { PeriodDate } from '../../../core/entities/periodDate';
import { AsqPeriod } from '../../../core/enums/asqPeriod.enum';
import { AsqSubscriptionType } from '../../../core/enums/asqSubscription.enum';
import { Module } from '../../../core/enums/module.enum';
import {
  CommentsAnalaysisDashboardPrefix,
  DissatisfiedPassengerDashboardPrefix,
} from '../departure/dashboards/constants';
import { SubscriptionType } from './enums/subscriptionType.enum';
import { DashboardPageConfig, DashboardConfig, StringEnumGeneric, DashboardHookReturnType, Dashboard } from './types';
import { generateSlicerBookmark, SubscriptionBookmarkInfo } from './utils/bookmarks';
import { useDefaultAirportCodeForDashboard } from './utils/hooks';

export const DashboardUrlParam = 'dashboard';
export type RouterDashboardParams = { [DashboardUrlParam]: string };

interface AirportWithPeriodsDetail {
  airportCode: string;
  currentPeriod: PeriodDate;
  previousPeriod?: PeriodDate;
  subscription: AsqSubscriptionType;
}

interface GroupSlicerInfos {
  periodYearSeasonSlicer?: SlicerInfos;
  airportCodeSlicer?: SlicerInfos;
  periodYearSlicer?: HierarchySlicerInfos;
  periodQuarterSlicer?: HierarchySlicerInfos;
}

export default function generateDashboard<StringEnum extends StringEnumGeneric>({
  urlPrefix,
  pages,
}: DashboardConfig<StringEnum>): Dashboard<StringEnum> {
  const EnumValues = Object.keys(pages);

  const routerPath = `${urlPrefix}/:${DashboardUrlParam}(${Object.values(pages)
    .map(({ url }) => url)
    .join('|')})`;

  const pageUrls = Object.entries<DashboardPageConfig>(pages).reduce(
    (obj, [id, { url }]) => ({ ...obj, [id]: `${urlPrefix}/${url}` }),
    {} as Record<keyof StringEnum, string>,
  );

  const dashboardSlicerBookmark = generateSlicerBookmark(
    Object.values(pages)
      .map(({ subscriptionBookmarkInfo }) => subscriptionBookmarkInfo)
      .filter((value) => value) as SubscriptionBookmarkInfo[],
  );

  function getUrlFromDashboard(dashboard: keyof StringEnum): string {
    return pageUrls[dashboard];
  }

  function getParamFromDashboard(dashboard: keyof StringEnum): string {
    return pages[dashboard].url;
  }

  function getDashboardFromParam(param: string): keyof StringEnum | null {
    const dashboard = Object.entries<DashboardPageConfig>(pages).find(([, { url }]) => url === param)?.[0];

    if (dashboard && EnumValues.includes(dashboard)) {
      return dashboard as keyof StringEnum;
    }

    return null;
  }

  function getDashboardFromPageName(pageName: string): keyof StringEnum | null {
    const dashboard = Object.entries<DashboardPageConfig>(pages).find(
      ([, config]) => config.pageName === pageName,
    )?.[0];

    if (dashboard && EnumValues.includes(dashboard)) {
      return dashboard as keyof StringEnum;
    }

    return null;
  }

  function getPageNameFromDashboard(dashboard: keyof StringEnum): string {
    return pages[dashboard].pageName;
  }

  function getDashboardUrlFromPageName(pageName: string): string | null {
    const dashboard = getDashboardFromPageName(pageName);
    if (dashboard !== null) return getUrlFromDashboard(dashboard);
    return null;
  }

  function getAirportCodeSlicerInfosFromDashboard(dashboard: keyof StringEnum): SlicerInfos | undefined {
    return pages[dashboard].airportCodeSlicerInfo;
  }

  function getAirportCodeGroupASlicerInfosFromDashboard(dashboard: keyof StringEnum): SlicerInfos | undefined {
    return pages[dashboard].airportCodeGroupASlicerInfo;
  }

  function getAirportCodeGroupBSlicerInfosFromDashboard(dashboard: keyof StringEnum): SlicerInfos | undefined {
    return pages[dashboard].airportCodeGroupBSlicerInfo;
  }

  function getPeriodYearSeasonGroupASlicerInfosFromDashboard(dashboard: keyof StringEnum): SlicerInfos | undefined {
    return pages[dashboard].periodYearSeasonGroupASlicerInfo;
  }

  function getPeriodYearSeasonGroupBSlicerInfosFromDashboard(dashboard: keyof StringEnum): SlicerInfos | undefined {
    return pages[dashboard].periodYearSeasonGroupBSlicerInfo;
  }

  function getPeriodQuarterGroupASlicerInfosFromDashboard(
    dashboard: keyof StringEnum,
  ): HierarchySlicerInfos | undefined {
    return pages[dashboard].periodQuarterGroupASlicerInfo;
  }

  function getPeriodQuarterGroupBSlicerInfosFromDashboard(
    dashboard: keyof StringEnum,
  ): HierarchySlicerInfos | undefined {
    return pages[dashboard].periodQuarterGroupBSlicerInfo;
  }

  function getPeriodYearGroupASlicerInfosFromDashboard(dashboard: keyof StringEnum): HierarchySlicerInfos | undefined {
    return pages[dashboard].periodYearGroupASlicerInfo;
  }

  function getPeriodYearGroupBSlicerInfosFromDashboard(dashboard: keyof StringEnum): HierarchySlicerInfos | undefined {
    return pages[dashboard].periodYearGroupBSlicerInfo;
  }

  function getSubscriptionSlicerInfosFromDashboard(dashboard: keyof StringEnum): SlicerInfos | undefined {
    return pages[dashboard].subscriptionSlicerInfo;
  }

  function getPeriodYearSlicerInfosFromDashboard(dashboard: keyof StringEnum): HierarchySlicerInfos | undefined {
    return pages[dashboard].periodYearSlicerInfo;
  }

  function getPeriodQuarterSlicerInfosFromDashboard(dashboard: keyof StringEnum): HierarchySlicerInfos | undefined {
    return pages[dashboard].periodQuarterSlicerInfo;
  }

  function getPeriodYearSeasonSlicerInfosFromDashboard(dashboard: keyof StringEnum): SlicerInfos | undefined {
    return pages[dashboard].periodYearSeasonSlicerInfo;
  }

  function getComparedPeriodYearSeasonSlicerInfosFromDashboard(dashboard: keyof StringEnum): SlicerInfos | undefined {
    return pages[dashboard].comparedPeriodYearSeasonSlicerInfo;
  }

  function getComparedPeriodYearSlicerInfosFromDashboard(
    dashboard: keyof StringEnum,
  ): HierarchySlicerInfos | undefined {
    return pages[dashboard].comparedPeriodYearSlicerInfo;
  }

  function getComparedPeriodQuarterSlicerInfosFromDashboard(
    dashboard: keyof StringEnum,
  ): HierarchySlicerInfos | undefined {
    return pages[dashboard].comparedPeriodQuarterSlicerInfo;
  }

  function getTitleTranslateKey(dashboard: keyof StringEnum): string {
    return pages[dashboard].titleTranslateKey;
  }

  function getInfoMessageTranslateKey(dashboard: keyof StringEnum | null): string | null {
    if (dashboard === null) return null;
    return pages[dashboard].infoMessageTranslateKey ?? null;
  }

  function getButtonGroupAIdFromDashboard(dashboard: keyof StringEnum): string | undefined {
    return pages[dashboard].buttonGroupAId;
  }

  function getButtonGroupBIdFromDashboard(dashboard: keyof StringEnum): string | undefined {
    return pages[dashboard].buttonGroupBId;
  }

  function getPreviousPeriod(sortedPeriodDates: PeriodDate[]): PeriodDate {
    return sortedPeriodDates.length > 1 ? sortedPeriodDates[1] : sortedPeriodDates[0];
  }

  function hierachyValuesToPeriodDate(values: HierachyValues<string, string>[]): PeriodDate[] {
    const periodDates: PeriodDate[] = [];
    values.forEach((keyValue) =>
      keyValue.values.forEach((quarterValue) => {
        const period = stringToPeriod(quarterValue);
        if (period !== undefined) {
          periodDates.push({ year: +keyValue.key, period });
        }
      }),
    );
    return periodDates;
  }

  function regionalStringValuesToPeriodDate(values: string[]): PeriodDate[] {
    const periodDates: PeriodDate[] = [];
    values.forEach((value) => {
      const split = value.split(' ');
      if (split.length === 2) {
        const period = stringToPeriod(split[1].trim());
        if (period) periodDates.push({ year: +split[0], period });
      }
    });
    return periodDates;
  }

  function findAirportAvailableInGivenSubscription(
    subscriptionType: AsqSubscriptionType,
    asqAirportSubscriptionDto: AsqAirportSubscriptionDto[],
  ): AirportWithPeriodsDetail | undefined {
    let airport: AsqAirportSubscriptionDto | undefined;
    let airportWithPeriod: AirportWithPeriodsDetail | undefined;
    if (subscriptionType === AsqSubscriptionType.Main) {
      airport = asqAirportSubscriptionDto.find((elem) => elem.subscriptionsDictionary.Main);
      if (airport && airport.subscriptionsDictionary.Main) {
        const mainPeriodDates = [...airport.subscriptionsDictionary.Main].sort(periodDateSorter);
        airportWithPeriod = {
          airportCode: airport.airportCode,
          currentPeriod: mainPeriodDates[0],
          previousPeriod: getPreviousPeriod(mainPeriodDates),
          subscription: AsqSubscriptionType.Main,
        };
      }
    } else if (subscriptionType === AsqSubscriptionType.Regional) {
      airport = asqAirportSubscriptionDto.find((elem) => elem.subscriptionsDictionary.Regional);
      if (airport && airport.subscriptionsDictionary.Regional) {
        const regionalPeriodDates = [...airport.subscriptionsDictionary.Regional].sort(periodDateSorter);
        airportWithPeriod = {
          airportCode: airport.airportCode,
          currentPeriod: regionalPeriodDates[0],
          previousPeriod: getPreviousPeriod(regionalPeriodDates),
          subscription: AsqSubscriptionType.Regional,
        };
      }
    } else if (subscriptionType === AsqSubscriptionType.Unique) {
      airport = asqAirportSubscriptionDto.find((elem) => elem.subscriptionsDictionary.Unique);
      if (airport && airport.subscriptionsDictionary.Unique) {
        const uniquePeriodDates = [...airport.subscriptionsDictionary.Unique].sort(periodDateSorter);
        airportWithPeriod = {
          airportCode: airport.airportCode,
          currentPeriod: uniquePeriodDates[0],
          previousPeriod: getPreviousPeriod(uniquePeriodDates),
          subscription: AsqSubscriptionType.Unique,
        };
      }
    }

    return airportWithPeriod;
  }

  function findAvailableAirportForSubscriptionAccess(
    asqAirportSubscriptionAccessDto: AsqAirportSubscriptionAccessDto[] | undefined,
    subscriptionType: AsqSubscriptionType,
  ): AirportWithPeriodsDetail | undefined {
    const airport = asqAirportSubscriptionAccessDto?.find((elem) => elem.subscription === subscriptionType);
    if (airport)
      return {
        airportCode: airport.airportCode,
        currentPeriod: airport.periodDate,
        subscription: airport.subscription,
      };
    return undefined;
  }

  function onSubscriptionSlicerChange(
    slicerState: SlicerState,
    report: Report,
    userAirportsSubscriptionAndPeriod: AsqUserAirportsSubscriptionDto | undefined,
    userAvailableCommentsAirports: AsqAdditionalAirportAccessDto | undefined,
    userAvailableDissatisfiedPassengerAirports: AsqAdditionalAirportAccessDto | undefined,
    dashboard: keyof StringEnum,
    module: Module,
  ): void {
    if (userAirportsSubscriptionAndPeriod === undefined) return;

    const airportCodeSlicerInfo = getAirportCodeSlicerInfosFromDashboard(dashboard);
    const periodYearSlicerInfo = getPeriodYearSlicerInfosFromDashboard(dashboard);
    const periodQuarterSlicerInfo = getPeriodQuarterSlicerInfosFromDashboard(dashboard);
    const periodYearSeasonSlicerInfo = getPeriodYearSeasonSlicerInfosFromDashboard(dashboard);
    const comparedPeriodQuarterSlicerInfo = getComparedPeriodQuarterSlicerInfosFromDashboard(dashboard);
    const comparedPeriodYearSlicerInfo = getComparedPeriodYearSlicerInfosFromDashboard(dashboard);
    const comparedPeriodYearSeasonSlicerInfo = getComparedPeriodYearSeasonSlicerInfosFromDashboard(dashboard);
    const airportCodeGroupASlicer = getAirportCodeGroupASlicerInfosFromDashboard(dashboard);
    const airportCodeGroupBSlicer = getAirportCodeGroupBSlicerInfosFromDashboard(dashboard);

    let airportWithPeriodsDetail: AirportWithPeriodsDetail | undefined;
    const subscriptionType: AsqSubscriptionType =
      AsqSubscriptionType[slicerState.value as keyof typeof AsqSubscriptionType];

    if (getParamFromDashboard(dashboard).includes(CommentsAnalaysisDashboardPrefix)) {
      // eslint-disable-next-line default-case
      switch (module) {
        case Module.Departure:
          airportWithPeriodsDetail = findAvailableAirportForSubscriptionAccess(
            userAvailableCommentsAirports?.departures,
            subscriptionType,
          );
          break;
        case Module.Arrival:
          airportWithPeriodsDetail = findAvailableAirportForSubscriptionAccess(
            userAvailableCommentsAirports?.arrivals,
            subscriptionType,
          );
          break;
        case Module.Commercial:
          airportWithPeriodsDetail = findAvailableAirportForSubscriptionAccess(
            userAvailableCommentsAirports?.commercials,
            subscriptionType,
          );
          break;
      }
    } else if (getParamFromDashboard(dashboard).includes(DissatisfiedPassengerDashboardPrefix)) {
      // eslint-disable-next-line default-case
      switch (module) {
        case Module.Departure:
          airportWithPeriodsDetail = findAvailableAirportForSubscriptionAccess(
            userAvailableDissatisfiedPassengerAirports?.departures,
            subscriptionType,
          );
          break;
        case Module.Arrival:
          airportWithPeriodsDetail = findAvailableAirportForSubscriptionAccess(
            userAvailableDissatisfiedPassengerAirports?.arrivals,
            subscriptionType,
          );
          break;
        case Module.Commercial:
          airportWithPeriodsDetail = findAvailableAirportForSubscriptionAccess(
            userAvailableDissatisfiedPassengerAirports?.commercials,
            subscriptionType,
          );
          break;
      }
    } else {
      // eslint-disable-next-line default-case
      switch (module) {
        case Module.Departure:
          airportWithPeriodsDetail = findAirportAvailableInGivenSubscription(
            subscriptionType,
            userAirportsSubscriptionAndPeriod.departures,
          );
          break;
        case Module.Arrival:
          airportWithPeriodsDetail = findAirportAvailableInGivenSubscription(
            subscriptionType,
            userAirportsSubscriptionAndPeriod.arrivals,
          );
          break;
        case Module.Commercial:
          airportWithPeriodsDetail = findAirportAvailableInGivenSubscription(
            subscriptionType,
            userAirportsSubscriptionAndPeriod.commercials,
          );
          break;
      }
    }

    if (dashboardSlicerBookmark) {
      synchronizeSlicerBookmark(slicerState, [dashboardSlicerBookmark], report).then(() => {
        if (airportCodeGroupASlicer) {
          setSlicerValue(report, airportCodeGroupASlicer);
        }
        if (airportCodeGroupBSlicer) {
          setSlicerValue(report, airportCodeGroupBSlicer);
        }
        if (airportCodeSlicerInfo && airportWithPeriodsDetail) {
          setSlicerValue(report, airportCodeSlicerInfo, airportWithPeriodsDetail.airportCode);
          if (
            airportWithPeriodsDetail.subscription === AsqSubscriptionType.Main ||
            airportWithPeriodsDetail.subscription === AsqSubscriptionType.Unique
          ) {
            if (periodYearSlicerInfo && periodQuarterSlicerInfo) {
              setHierarchySlicerValue(
                report,
                [periodYearSlicerInfo, periodQuarterSlicerInfo],
                [
                  {
                    key: airportWithPeriodsDetail.currentPeriod.year,
                    values: [AsqPeriod[airportWithPeriodsDetail.currentPeriod.period]],
                  },
                ],
              );
            }
            if (
              comparedPeriodQuarterSlicerInfo &&
              comparedPeriodYearSlicerInfo &&
              airportWithPeriodsDetail.previousPeriod
            ) {
              setHierarchySlicerValue(
                report,
                [comparedPeriodYearSlicerInfo, comparedPeriodQuarterSlicerInfo],
                [
                  {
                    key: airportWithPeriodsDetail.previousPeriod.year,
                    values: [AsqPeriod[airportWithPeriodsDetail.previousPeriod.period]],
                  },
                ],
              );
            }
          } else if (airportWithPeriodsDetail.subscription === AsqSubscriptionType.Regional) {
            if (periodYearSeasonSlicerInfo)
              setSlicerValue(
                report,
                periodYearSeasonSlicerInfo,
                formatPeriodDateForRegional(airportWithPeriodsDetail.currentPeriod),
              );
            if (comparedPeriodYearSeasonSlicerInfo && airportWithPeriodsDetail.previousPeriod)
              setSlicerValue(
                report,
                comparedPeriodYearSeasonSlicerInfo,
                formatPeriodDateForRegional(airportWithPeriodsDetail.previousPeriod),
              );
          }
        }
      });
    }
  }

  async function onAirportCodeSlicerChange(
    report: Report,
    airportsSubscriptionsAndPeriods: AsqAirportSubscriptionDto,
    dashboard: keyof StringEnum,
  ): Promise<void> {
    const periodYearSlicerInfo = getPeriodYearSlicerInfosFromDashboard(dashboard);
    const periodQuarterSlicerInfo = getPeriodQuarterSlicerInfosFromDashboard(dashboard);
    const periodYearSeasonSlicerInfo = getPeriodYearSeasonSlicerInfosFromDashboard(dashboard);
    const comparedPeriodQuarterSlicerInfo = getComparedPeriodQuarterSlicerInfosFromDashboard(dashboard);
    const comparedPeriodYearSlicerInfo = getComparedPeriodYearSlicerInfosFromDashboard(dashboard);
    const comparedPeriodYearSeasonSlicerInfo = getComparedPeriodYearSeasonSlicerInfosFromDashboard(dashboard);
    const subscriptionSlicerInfo = getSubscriptionSlicerInfosFromDashboard(dashboard);
    if (!subscriptionSlicerInfo) return;
    const selectedSubscriptions = await getSlicerSelectedValues(report, subscriptionSlicerInfo.name);
    const selectedSubscription = AsqSubscriptionType[selectedSubscriptions[0] as keyof typeof AsqSubscriptionType];
    if (
      selectedSubscription === AsqSubscriptionType.Main &&
      airportsSubscriptionsAndPeriods.subscriptionsDictionary.Main
    ) {
      if (periodYearSlicerInfo && periodQuarterSlicerInfo) {
        const hierarchySelectedPeriods = await getPeriodHierarchySlicerSelectedValues(
          report,
          periodQuarterSlicerInfo.name,
        );
        const selectedPeriods = hierachyValuesToPeriodDate(hierarchySelectedPeriods);
        setHierarchySlicerAccordingToAirportAvailableData(
          report,
          airportsSubscriptionsAndPeriods.subscriptionsDictionary.Main,
          selectedPeriods,
          periodYearSlicerInfo,
          periodQuarterSlicerInfo,
        );
      }

      if (comparedPeriodYearSlicerInfo && comparedPeriodQuarterSlicerInfo) {
        const hierarchySelectedComparedPeriods = await getPeriodHierarchySlicerSelectedValues(
          report,
          comparedPeriodQuarterSlicerInfo.name,
        );
        const selectedComparedPeriods = hierachyValuesToPeriodDate(hierarchySelectedComparedPeriods);
        setHierarchySlicerAccordingToAirportAvailableData(
          report,
          airportsSubscriptionsAndPeriods.subscriptionsDictionary.Main,
          selectedComparedPeriods,
          comparedPeriodYearSlicerInfo,
          comparedPeriodQuarterSlicerInfo,
          true,
        );
      }
    } else if (
      selectedSubscription === AsqSubscriptionType.Unique &&
      airportsSubscriptionsAndPeriods.subscriptionsDictionary.Unique
    ) {
      if (periodYearSlicerInfo && periodQuarterSlicerInfo) {
        const hierarchySelectedPeriods = await getPeriodHierarchySlicerSelectedValues(
          report,
          periodQuarterSlicerInfo.name,
        );
        const selectedPeriods = hierachyValuesToPeriodDate(hierarchySelectedPeriods);
        setHierarchySlicerAccordingToAirportAvailableData(
          report,
          airportsSubscriptionsAndPeriods.subscriptionsDictionary.Unique,
          selectedPeriods,
          periodYearSlicerInfo,
          periodQuarterSlicerInfo,
        );
      }

      if (comparedPeriodYearSlicerInfo && comparedPeriodQuarterSlicerInfo) {
        const hierarchySelectedComparedPeriods = await getPeriodHierarchySlicerSelectedValues(
          report,
          comparedPeriodQuarterSlicerInfo.name,
        );
        const selectedComparedPeriods = hierachyValuesToPeriodDate(hierarchySelectedComparedPeriods);
        setHierarchySlicerAccordingToAirportAvailableData(
          report,
          airportsSubscriptionsAndPeriods.subscriptionsDictionary.Unique,
          selectedComparedPeriods,
          comparedPeriodYearSlicerInfo,
          comparedPeriodQuarterSlicerInfo,
          true,
        );
      }
    } else if (
      selectedSubscription === AsqSubscriptionType.Regional &&
      airportsSubscriptionsAndPeriods.subscriptionsDictionary.Regional
    ) {
      if (periodYearSeasonSlicerInfo) {
        const selectedStringPeriods = await getSlicerSelectedValues(report, periodYearSeasonSlicerInfo.name);
        const selectedPeriods = regionalStringValuesToPeriodDate(selectedStringPeriods);
        setBasicSlicerAccordingToAirportAvailableData(
          report,
          airportsSubscriptionsAndPeriods.subscriptionsDictionary.Regional,
          selectedPeriods,
          periodYearSeasonSlicerInfo,
        );
      }

      if (comparedPeriodYearSeasonSlicerInfo) {
        const selectedComparedStringPeriods = await getSlicerSelectedValues(
          report,
          comparedPeriodYearSeasonSlicerInfo.name,
        );
        const selectedComparedPeriods = regionalStringValuesToPeriodDate(selectedComparedStringPeriods);
        setBasicSlicerAccordingToAirportAvailableData(
          report,
          airportsSubscriptionsAndPeriods.subscriptionsDictionary.Regional,
          selectedComparedPeriods,
          comparedPeriodYearSeasonSlicerInfo,
          true,
        );
      }
    }
  }

  function setBasicSlicerAccordingToAirportAvailableData(
    report: Report,
    periodDates: PeriodDate[],
    selectedPeriods: PeriodDate[] | undefined,
    slicerInfo: SlicerInfos,
    isComparedPeriod: boolean = false,
  ) {
    if (!selectedPeriods) {
      return;
    }

    const selectedAndAvailableIntersect = selectedPeriods.filter((value) =>
      periodDates.some((periodDate) => periodDate.year === value.year && periodDate.period === value.period),
    );

    if (selectedAndAvailableIntersect.length < 1) {
      const sortedPeriodDates = [...periodDates].sort(periodDateSorter);
      const periodToInsert = isComparedPeriod ? getPreviousPeriod(sortedPeriodDates) : sortedPeriodDates[0];
      selectedAndAvailableIntersect.push(periodToInsert);
    }

    setSlicerValue(
      report,
      slicerInfo,
      ...selectedAndAvailableIntersect.map((value) => formatPeriodDateForRegional(value)),
    );
  }

  function setHierarchySlicerAccordingToAirportAvailableData(
    report: Report,
    periodDates: PeriodDate[],
    selectedPeriods: PeriodDate[] | undefined,
    yearSlicerInfo: HierarchySlicerInfos,
    quarterSlicerInfo: HierarchySlicerInfos,
    isComparedPeriod: boolean = false,
  ) {
    if (!selectedPeriods) {
      return;
    }

    const selectedAndAvailableIntersect = selectedPeriods.filter((value) =>
      periodDates.some((periodDate) => periodDate.year === value.year && periodDate.period === value.period),
    );

    if (selectedAndAvailableIntersect.length < 1) {
      const sortedPeriodDates = [...periodDates].sort(periodDateSorter);
      const periodToInsert = isComparedPeriod ? getPreviousPeriod(sortedPeriodDates) : sortedPeriodDates[0];
      selectedAndAvailableIntersect.push(periodToInsert);
    }

    const yearToPeriods: HierachyValues<number, string>[] = [];
    selectedAndAvailableIntersect.forEach((item) => {
      const { year, period } = item;
      const index = yearToPeriods.findIndex((elem) => elem.key === year);
      if (index < 0) {
        yearToPeriods.push({ key: year, values: [AsqPeriod[period]] });
      } else {
        yearToPeriods[index].values.push(AsqPeriod[period]);
      }
    });

    setHierarchySlicerValue(report, [yearSlicerInfo, quarterSlicerInfo], yearToPeriods);
  }

  function getGroupSlicerInfos(dashboard: keyof StringEnum, buttonId: string): GroupSlicerInfos | undefined {
    if (getButtonGroupAIdFromDashboard(dashboard) === buttonId) {
      return {
        airportCodeSlicer: getAirportCodeGroupASlicerInfosFromDashboard(dashboard),
        periodQuarterSlicer: getPeriodQuarterGroupASlicerInfosFromDashboard(dashboard),
        periodYearSlicer: getPeriodYearGroupASlicerInfosFromDashboard(dashboard),
        periodYearSeasonSlicer: getPeriodYearSeasonGroupASlicerInfosFromDashboard(dashboard),
      };
    }

    if (getButtonGroupBIdFromDashboard(dashboard) === buttonId) {
      return {
        airportCodeSlicer: getAirportCodeGroupBSlicerInfosFromDashboard(dashboard),
        periodQuarterSlicer: getPeriodQuarterGroupBSlicerInfosFromDashboard(dashboard),
        periodYearSlicer: getPeriodYearGroupBSlicerInfosFromDashboard(dashboard),
        periodYearSeasonSlicer: getPeriodYearSeasonGroupBSlicerInfosFromDashboard(dashboard),
      };
    }

    return undefined;
  }

  function OnLoadedSyncForAdditionnalAccess(
    dashboard: keyof StringEnum,
    module: Module,
    report: Report,
    asqAdditionalAirportAccess: AsqAdditionalAirportAccessDto | undefined,
  ): void {
    const airportCodeSlicerInfo = getAirportCodeSlicerInfosFromDashboard(dashboard);
    const subscriptionSlicerInfo = getSubscriptionSlicerInfosFromDashboard(dashboard);
    const periodYearSlicerInfo = getPeriodYearSlicerInfosFromDashboard(dashboard);
    const periodQuarterSlicerInfo = getPeriodQuarterSlicerInfosFromDashboard(dashboard);
    const periodYearSeasonSlicerInfo = getPeriodYearSeasonSlicerInfosFromDashboard(dashboard);

    let asqAirportSubscriptionAccess = asqAdditionalAirportAccess?.departures;
    // eslint-disable-next-line default-case
    switch (module) {
      case Module.Departure:
        asqAirportSubscriptionAccess = asqAdditionalAirportAccess?.departures;
        break;
      case Module.Arrival:
        asqAirportSubscriptionAccess = asqAdditionalAirportAccess?.arrivals;
        break;
      case Module.Commercial:
        asqAirportSubscriptionAccess = asqAdditionalAirportAccess?.commercials;
        break;
    }

    if (!asqAirportSubscriptionAccess) return;
    let pickedAirport = asqAirportSubscriptionAccess?.filter((x) => x.subscription === AsqSubscriptionType.Main)[0];
    let pickedSubscription = AsqSubscriptionType.Main;
    if (!pickedAirport) {
      pickedAirport = asqAirportSubscriptionAccess?.filter((x) => x.subscription === AsqSubscriptionType.Regional)[0];
      pickedSubscription = AsqSubscriptionType.Regional;
      if (!pickedAirport) {
        pickedAirport = asqAirportSubscriptionAccess?.filter((x) => x.subscription === AsqSubscriptionType.Unique)[0];
        pickedSubscription = AsqSubscriptionType.Unique;
        if (!pickedAirport) return;
      }
    }

    if (airportCodeSlicerInfo) setSlicerValue(report, airportCodeSlicerInfo, pickedAirport.airportCode);

    if (subscriptionSlicerInfo) {
      if (pickedSubscription === AsqSubscriptionType.Main) {
        setSlicerValue(report, subscriptionSlicerInfo, SubscriptionType.Main);
        if (dashboardSlicerBookmark) {
          synchronizeSlicerBookmark(
            { name: subscriptionSlicerInfo.name, value: SubscriptionType.Main },
            [dashboardSlicerBookmark],
            report,
          ).then(() => {
            if (periodYearSlicerInfo && periodQuarterSlicerInfo) {
              setHierarchySlicerValue(
                report,
                [periodYearSlicerInfo, periodQuarterSlicerInfo],
                [
                  {
                    key: pickedAirport.periodDate.year,
                    values: [AsqPeriod[pickedAirport.periodDate.period]],
                  },
                ],
              );
            }
          });
        }
      } else if (pickedSubscription === AsqSubscriptionType.Regional) {
        setSlicerValue(report, subscriptionSlicerInfo, SubscriptionType.Regional);
        if (dashboardSlicerBookmark) {
          synchronizeSlicerBookmark(
            { name: subscriptionSlicerInfo.name, value: SubscriptionType.Main },
            [dashboardSlicerBookmark],
            report,
          ).then(() => {
            if (periodYearSeasonSlicerInfo) {
              setSlicerValue(report, periodYearSeasonSlicerInfo, formatPeriodDateForRegional(pickedAirport.periodDate));
            }
          });
        }
      } else if (pickedSubscription === AsqSubscriptionType.Unique) {
        setSlicerValue(report, subscriptionSlicerInfo, SubscriptionType.Unique);
        if (dashboardSlicerBookmark) {
          synchronizeSlicerBookmark(
            { name: subscriptionSlicerInfo.name, value: SubscriptionType.Unique },
            [dashboardSlicerBookmark],
            report,
          ).then(() => {
            if (periodYearSlicerInfo && periodQuarterSlicerInfo) {
              setHierarchySlicerValue(
                report,
                [periodYearSlicerInfo, periodQuarterSlicerInfo],
                [
                  {
                    key: pickedAirport.periodDate.year,
                    values: [AsqPeriod[pickedAirport.periodDate.period]],
                  },
                ],
              );
            }
          });
        }
      }
    }
  }

  function OnLoadedSyncForNormalAccesses(
    dashboard: keyof StringEnum,
    homePageAirportCode: string | null,
    report: Report,
    airportSubscriptionAndPeriod: AsqAirportSubscriptionDto | undefined,
  ): void {
    const airportCodeSlicerInfo = getAirportCodeSlicerInfosFromDashboard(dashboard);
    const subscriptionSlicerInfo = getSubscriptionSlicerInfosFromDashboard(dashboard);
    const periodYearSlicerInfo = getPeriodYearSlicerInfosFromDashboard(dashboard);
    const periodQuarterSlicerInfo = getPeriodQuarterSlicerInfosFromDashboard(dashboard);
    const periodYearSeasonSlicerInfo = getPeriodYearSeasonSlicerInfosFromDashboard(dashboard);
    const comparedPeriodQuarterSlicerInfo = getComparedPeriodQuarterSlicerInfosFromDashboard(dashboard);
    const comparedPeriodYearSlicerInfo = getComparedPeriodYearSlicerInfosFromDashboard(dashboard);
    const comparedPeriodYearSeasonSlicerInfo = getComparedPeriodYearSeasonSlicerInfosFromDashboard(dashboard);

    if (airportCodeSlicerInfo && homePageAirportCode)
      setSlicerValue(report, airportCodeSlicerInfo, homePageAirportCode);
    if (subscriptionSlicerInfo && airportSubscriptionAndPeriod?.subscriptionsDictionary) {
      if (airportSubscriptionAndPeriod.subscriptionsDictionary.Main) {
        const mainPeriodDates = [...airportSubscriptionAndPeriod.subscriptionsDictionary.Main].sort(periodDateSorter);
        setSlicerValue(report, subscriptionSlicerInfo, SubscriptionType.Main);
        if (dashboardSlicerBookmark) {
          synchronizeSlicerBookmark(
            { name: subscriptionSlicerInfo.name, value: SubscriptionType.Main },
            [dashboardSlicerBookmark],
            report,
          ).then(() => {
            if (periodYearSlicerInfo && periodQuarterSlicerInfo) {
              setHierarchySlicerValue(
                report,
                [periodYearSlicerInfo, periodQuarterSlicerInfo],
                [
                  {
                    key: mainPeriodDates[0].year,
                    values: [AsqPeriod[mainPeriodDates[0].period]],
                  },
                ],
              );
            }

            if (comparedPeriodYearSlicerInfo && comparedPeriodQuarterSlicerInfo) {
              const previousPeriodDate = getPreviousPeriod(mainPeriodDates);
              setHierarchySlicerValue(
                report,
                [comparedPeriodYearSlicerInfo, comparedPeriodQuarterSlicerInfo],
                [
                  {
                    key: previousPeriodDate.year,
                    values: [AsqPeriod[previousPeriodDate.period]],
                  },
                ],
              );
            }
          });
        }
      } else if (airportSubscriptionAndPeriod.subscriptionsDictionary.Regional) {
        const regionalPeriodDates = [...airportSubscriptionAndPeriod.subscriptionsDictionary.Regional].sort(
          periodDateSorter,
        );
        setSlicerValue(report, subscriptionSlicerInfo, SubscriptionType.Regional);
        if (dashboardSlicerBookmark) {
          synchronizeSlicerBookmark(
            { name: subscriptionSlicerInfo.name, value: SubscriptionType.Regional },
            [dashboardSlicerBookmark],
            report,
          ).then(() => {
            if (periodYearSeasonSlicerInfo) {
              setSlicerValue(report, periodYearSeasonSlicerInfo, formatPeriodDateForRegional(regionalPeriodDates[0]));
            }
            if (comparedPeriodYearSeasonSlicerInfo) {
              const previousPeriodDate = getPreviousPeriod(regionalPeriodDates);
              setSlicerValue(
                report,
                comparedPeriodYearSeasonSlicerInfo,
                formatPeriodDateForRegional(previousPeriodDate),
              );
            }
          });
        }
      } else if (airportSubscriptionAndPeriod.subscriptionsDictionary.Unique) {
        const uniquePeriodDates = [...airportSubscriptionAndPeriod.subscriptionsDictionary.Unique].sort(
          periodDateSorter,
        );
        setSlicerValue(report, subscriptionSlicerInfo, SubscriptionType.Unique);
        if (dashboardSlicerBookmark) {
          synchronizeSlicerBookmark(
            { name: subscriptionSlicerInfo.name, value: SubscriptionType.Unique },
            [dashboardSlicerBookmark],
            report,
          ).then(() => {
            if (periodYearSlicerInfo && periodQuarterSlicerInfo) {
              setHierarchySlicerValue(
                report,
                [periodYearSlicerInfo, periodQuarterSlicerInfo],
                [
                  {
                    key: uniquePeriodDates[0].year,
                    values: [AsqPeriod[uniquePeriodDates[0].period]],
                  },
                ],
              );
            }
            if (comparedPeriodYearSlicerInfo && comparedPeriodQuarterSlicerInfo) {
              const previousPeriodDate = getPreviousPeriod(uniquePeriodDates);
              setHierarchySlicerValue(
                report,
                [comparedPeriodYearSlicerInfo, comparedPeriodQuarterSlicerInfo],
                [
                  {
                    key: previousPeriodDate.year,
                    values: [AsqPeriod[previousPeriodDate.period]],
                  },
                ],
              );
            }
          });
        }
      }
    }
  }

  function useDashboard(module: Module): DashboardHookReturnType<StringEnum> {
    const history = useHistory();
    const { pathname } = useLocation();
    const defaultAirportCode = useDefaultAirportCodeForDashboard(module);
    const [lazyAirportSubscriptionAndPeriodQuery, { data: airportSubscriptionAndPeriod }] =
      useLazyAirportSubscriptionAndPeriodQuery();
    const [lazyAirportsForCustomPanelQuery] = useLazyAirportsForCustomPanelQuery();

    const { data: userAirportsSubscriptionAndPeriod } = useUserAirportsSubscriptionsQuery();
    const { data: userAvailableCommentsAirports } = useUserAvailableCommentsAirportsSubscriptionsQuery();
    const { data: userAvailableDissatisfiedPassengerAirports } =
      useUserAvailableDissatisfiedPassengerAirportsSubscriptionsQuery();
    const param = useParams<RouterDashboardParams>()[DashboardUrlParam];

    const dashboard = getDashboardFromParam(param);
    const pageName = dashboard === null ? null : getPageNameFromDashboard(dashboard);

    useEffect(() => {
      lazyAirportSubscriptionAndPeriodQuery([defaultAirportCode ?? '', module]);
    }, [lazyAirportSubscriptionAndPeriodQuery, defaultAirportCode, module]);

    const onPageChange = useCallback(
      (page: Page) => {
        const url = getDashboardUrlFromPageName(page.name);
        if (url !== null && pathname !== url) history.push(url);
      },
      [history, pathname],
    );

    const onSlicerChange = useCallback(
      (slicerState: SlicerState, report: Report) => {
        if (dashboard === null) return;
        if (getSubscriptionSlicerInfosFromDashboard(dashboard)?.name === slicerState.name) {
          onSubscriptionSlicerChange(
            slicerState,
            report,
            userAirportsSubscriptionAndPeriod,
            userAvailableCommentsAirports,
            userAvailableDissatisfiedPassengerAirports,
            dashboard,
            module,
          );
        } else if (getAirportCodeSlicerInfosFromDashboard(dashboard)?.name === slicerState.name) {
          lazyAirportSubscriptionAndPeriodQuery([slicerState.value, module])
            .unwrap()
            .then((data: AsqAirportSubscriptionDto) => {
              if (data) {
                onAirportCodeSlicerChange(report, data, dashboard);
              }
            });
        }
      },
      [
        dashboard,
        lazyAirportSubscriptionAndPeriodQuery,
        module,
        userAirportsSubscriptionAndPeriod,
        userAvailableCommentsAirports,
        userAvailableDissatisfiedPassengerAirports,
      ],
    );

    const onButtonClick = useCallback(
      async (buttonId: string, report: Report) => {
        if (dashboard === null) return;
        const airportCodeSlicerInfo = getAirportCodeSlicerInfosFromDashboard(dashboard);
        const subscriptionSlicerInfo = getSubscriptionSlicerInfosFromDashboard(dashboard);
        const groupSlicerInfos = getGroupSlicerInfos(dashboard, buttonId);
        if (!airportCodeSlicerInfo || !subscriptionSlicerInfo || !groupSlicerInfos) return;

        const selectedAirport = (await getSlicerSelectedValues(report, airportCodeSlicerInfo?.name))[0];
        const selectedSubscriptionString = (await getSlicerSelectedValues(report, subscriptionSlicerInfo?.name))[0];
        const selectedSubscription =
          AsqSubscriptionType[selectedSubscriptionString as keyof typeof AsqSubscriptionType];
        if (selectedSubscription === AsqSubscriptionType.Regional) {
          if (!groupSlicerInfos.periodYearSeasonSlicer) return;
          const selectedGroupPeriods = await getSlicerSelectedValues(
            report,
            groupSlicerInfos.periodYearSeasonSlicer.name,
          );
          if (selectedGroupPeriods.length !== 1) return;
          const periodDate = extractPeriodDateFromRegionalFormatting(selectedGroupPeriods[0]);
          lazyAirportsForCustomPanelQuery({ airportCode: selectedAirport, periodDate, asqModule: module })
            .unwrap()
            .then((airportCodes: string[]) => {
              if (groupSlicerInfos.airportCodeSlicer) {
                setSlicerValue(report, groupSlicerInfos.airportCodeSlicer, ...airportCodes.map((x) => x.toUpperCase()));
              }
            });
        } else if (
          selectedSubscription === AsqSubscriptionType.Unique ||
          selectedSubscription === AsqSubscriptionType.Main
        ) {
          if (!groupSlicerInfos.periodYearSlicer || !groupSlicerInfos.periodQuarterSlicer) return;
          const selectedGroupPeriods = await getPeriodHierarchySlicerSelectedValues(
            report,
            groupSlicerInfos.periodQuarterSlicer.name,
          );
          if (selectedGroupPeriods.length !== 1 || selectedGroupPeriods[0].values.length !== 1) return;
          const selectedQuarter = stringToPeriod(selectedGroupPeriods[0].values[0]);
          if (selectedQuarter === undefined) return;
          const selectedDatePeriod: PeriodDate = {
            year: +selectedGroupPeriods[0].key,
            period: selectedQuarter,
          };
          lazyAirportsForCustomPanelQuery({
            airportCode: selectedAirport,
            periodDate: selectedDatePeriod,
            asqModule: module,
          })
            .unwrap()
            .then((airportCodes: string[]) => {
              if (groupSlicerInfos.airportCodeSlicer) {
                setSlicerValue(report, groupSlicerInfos.airportCodeSlicer, ...airportCodes.map((x) => x.toUpperCase()));
              }
            });
        }
      },
      [dashboard, lazyAirportsForCustomPanelQuery, module],
    );

    const synchronizeAirportCodeAndSubscriptionAndPeriod = useCallback(
      (report: Report) => {
        if (dashboard === null) return;
        if (getParamFromDashboard(dashboard).includes(CommentsAnalaysisDashboardPrefix)) {
          OnLoadedSyncForAdditionnalAccess(dashboard, module, report, userAvailableCommentsAirports);
        } else if (getParamFromDashboard(dashboard).includes(DissatisfiedPassengerDashboardPrefix)) {
          OnLoadedSyncForAdditionnalAccess(dashboard, module, report, userAvailableDissatisfiedPassengerAirports);
        } else {
          OnLoadedSyncForNormalAccesses(dashboard, defaultAirportCode, report, airportSubscriptionAndPeriod);
        }
      },
      [
        dashboard,
        module,
        userAvailableCommentsAirports,
        userAvailableDissatisfiedPassengerAirports,
        defaultAirportCode,
        airportSubscriptionAndPeriod,
      ],
    );

    return {
      dashboard,
      pageName,
      onPageChange,
      onSlicerChange,
      onButtonClick,
      synchronizeAirportCodeAndSubscriptionAndPeriod,
    };
  }

  return {
    routerPath,
    pageUrls,
    useDashboard,
    getDashboardUrlFromPageName,
    getDashboardFromPageName,
    getPageNameFromDashboard,
    getUrlFromDashboard,
    getDashboardFromParam,
    getParamFromDashboard,
    getAirportCodeSlicerInfosFromDashboard,
    getTitleTranslateKey,
    getInfoMessageTranslateKey,
  };
}
