import { ReactElement, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { changePasswordPolicy } from '../../../../authConfig';
import Button from '../../../../common/components/atoms/Button';
import TextField from '../../../../common/components/atoms/TextField';
import { useGetUserQuery, useUpdateUserMutation } from '../../../../common/hooks/backend';
import { displaySnackbar } from '../../../../core/notifications/snackbarSlice';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { defaultFirstName, defaultLastName } from '../constants';
import User from '../entities/User';
import { setIsSavingProfile } from '../profileSlice';

export default function ProfileForm(): ReactElement {
  const { email, isSavingProfile } = useAppSelector((state) => state.profile);

  const { data } = useGetUserQuery();
  const [user, setUser] = useState<User>({ firstName: '', lastName: '' });
  const [isTouched, setIsTouched] = useState(false);
  const [updateUser] = useUpdateUserMutation();

  const { t } = useTranslation('Profile');
  const dispatch = useAppDispatch();

  useEffect(() => {
    setUser({ firstName: data?.firstName, lastName: data?.lastName });
  }, [data]);

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, firstName: event.target.value });
    setIsTouched(true);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, lastName: event.target.value });
    setIsTouched(true);
  };

  const handleChangePassword = () => {
    window.location.href = `${changePasswordPolicy}&email_hint=${email}`;
  };

  const handleSaveClick = () => {
    dispatch(setIsSavingProfile(true));

    updateUser({ firstName: user?.firstName, lastName: user?.lastName })
      .catch((err) => {
        console.error(err);
        dispatch(displaySnackbar({ message: t('SavePasswordError'), severity: 'error' }));
      })
      .finally(() => {
        dispatch(setIsSavingProfile(false));
      });
  };

  return (
    <Stack pt={6} role="form">
      <TextField
        label={t('FirstName')}
        value={user?.firstName === defaultFirstName ? '' : user?.firstName}
        type="outlined"
        onChange={handleFirstNameChange}
        data-fullstory="ProfileFirstName"
      />
      <TextField
        sx={{ mt: 5 }}
        label={t('LastName')}
        value={user?.lastName === defaultLastName ? '' : user?.lastName}
        type="outlined"
        onChange={handleLastNameChange}
        data-fullstory="ProfileLastName"
      />
      <TextField
        sx={{ mt: 5 }}
        label={t('Email')}
        value={email}
        type="outlined"
        disabled
        data-fullstory="ProfileEmail"
      />

      <Box mt={3} ml={-1}>
        <Button onClick={handleChangePassword} data-fullstory="ProfileChangePassword">
          <Typography color="primary" variant="pMedium">
            {t('ChangePassword')}
          </Typography>
        </Button>
      </Box>

      <Box mt={4}>
        <Button
          variant="contained"
          disabled={isSavingProfile || !isTouched}
          onClick={handleSaveClick}
          data-fullstory="ProfileSave"
        >
          {isSavingProfile ? <CircularProgress size={24} /> : t('Save')}
        </Button>
      </Box>
    </Stack>
  );
}
