import { ReactElement, useState } from 'react';

import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useTranslation } from 'react-i18next';

import Button from '../../../../common/components/atoms/Button';
import DialogCloseButton from '../../../../common/components/atoms/DialogCloseButton';
import TextField from '../../../../common/components/atoms/TextField';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { changeReportTitle } from '../analystSlice';

export interface ReportTitleDialogProps {
  onCancel: () => void;
  onSave: () => void;
  isOpen: boolean;
}

export default function ReportTitleDialog(props: ReportTitleDialogProps): ReactElement {
  const { onCancel, onSave, isOpen } = props;
  const { t } = useTranslation('Analyst');
  const dispatch = useAppDispatch();
  const { name } = useAppSelector((state) => state.analyst.currentTemplate);
  const [isValidated, setIsValidated] = useState(true);

  const handleTemplateNameChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeReportTitle(value));
  };

  const handleOnSave = () => {
    setIsValidated(true);
    if (!name) {
      setIsValidated(false);
    } else {
      onSave();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent sx={{ width: 480, textAlign: 'center', paddingBottom: 0 }}>
        <DialogCloseButton closeDialog={onCancel} data-fullstory="ReportTitleDialogClose" />
        <Typography variant="h4" pt={6}>
          {t('EnterTemplateName')}
        </Typography>
        <TextField
          error={!isValidated && !name}
          helperText={!isValidated && !name ? t('Required', { ns: 'Common' }) : ' '}
          sx={{ width: 380, marginX: 'auto', paddingTop: 3 }}
          onChange={handleTemplateNameChange}
          data-fullstory="ReportTitleInput"
          placeholder={t(`ReportTitleLabel`)}
          value={name}
        />
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 4, marginX: 'auto' }}>
        <Button sx={{ mr: 1, textDecoration: 'underline' }} variant="text" onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button variant="contained" onClick={handleOnSave}>
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
