import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import FileDto, { TransformedFileDto } from './entities/File';

export interface LibraryState {
  currentFolder: Partial<FileDto> | null;
  detailsOpen: boolean;
  selectedFile: TransformedFileDto | null;
}

const initialState: LibraryState = {
  currentFolder: null,
  detailsOpen: false,
  selectedFile: null,
};

const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    changeCurrentFolder: (state, action: PayloadAction<Partial<FileDto> | null>) => ({
      ...state,
      currentFolder: action.payload,
    }),
    setDetailsOpen: (state, action: PayloadAction<boolean>) => ({
      ...state,
      detailsOpen: action.payload,
    }),
    setSelectedFile: (state, action: PayloadAction<TransformedFileDto>) => ({
      ...state,
      selectedFile: action.payload,
    }),
    setFolderStack: (state, action: PayloadAction<Partial<FileDto>[]>) => ({
      ...state,
      folderStack: action.payload,
      currentFolder: action.payload[action.payload.length - 1] ?? null,
    }),
  },
});

export const { changeCurrentFolder, setDetailsOpen, setSelectedFile, setFolderStack } = librarySlice.actions;

export default librarySlice.reducer;
