import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DdpUrls } from '../../../../../modules/DDP/urls';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { ModuleKey } from '../../enums/moduleKey';
import { closeDialog } from '../../purchaseSlice';
import BaseNoAccessDialog from './BaseNoAccessDialog';

export interface PurchaseForAccessDialogProps {
  moduleKey: ModuleKey;
}

export default function PurchaseForAccessDialog({ moduleKey }: PurchaseForAccessDialogProps) {
  const { t } = useTranslation('Pricing');
  const { isPurchaseForAccessDialogOpen } = useAppSelector((state) => state.purchase);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handlePurchase = () => {
    dispatch(closeDialog());
    history.push(DdpUrls.Pricing);
  };

  return (
    <BaseNoAccessDialog
      moduleKey={moduleKey}
      isOpen={isPurchaseForAccessDialogOpen}
      handleAccept={handlePurchase}
      fullStory="PurchaseForAccess"
      buttonText={t('NoAccessDialog.BuyModule')}
    />
  );
}
