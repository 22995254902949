import { ReactElement } from 'react';

import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { Trans, useTranslation } from 'react-i18next';

import Button from '../../../../common/components/atoms/Button';
import DialogCloseButton from '../../../../common/components/atoms/DialogCloseButton';

export interface ConfirmationDialogDialogProps {
  onClose: (isConfirm: boolean) => void;
  isOpen: boolean;
  textKey: string;
  textArgs?: string;
}

export default function ConfirmationDialog(props: ConfirmationDialogDialogProps): ReactElement {
  const { onClose, isOpen, textKey, textArgs } = props;
  const { t } = useTranslation('Analyst');

  const handleOnCancel = () => {
    onClose(false);
  };

  const handleOnConfirm = () => {
    onClose(true);
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent sx={{ width: 480, textAlign: 'center' }}>
        <DialogCloseButton closeDialog={handleOnCancel} data-fullstory="FreeTrialInformationPopupClose" />
        <Typography variant="h4" pt={6}>
          {t(`${textKey}.Title`)}
        </Typography>
        <Typography pt={5} variant="pSmall">
          <Trans tOptions={{ args: textArgs }} t={t}>{`${textKey}.Text`}</Trans>
        </Typography>
      </DialogContent>
      <DialogActions sx={{ marginX: 'auto', paddingY: 4 }}>
        <Button sx={{ mr: 1, textDecoration: 'underline' }} variant="text" onClick={handleOnCancel}>
          {t('Cancel')}
        </Button>
        <Button variant="contained" onClick={handleOnConfirm}>
          {t(`${textKey}.ConfirmButton`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
