import { ReactElement } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { DdpUrls } from '../urls';

interface FaqRedirectionProps extends RouteProps {}

function FaqRedirection({ path }: FaqRedirectionProps): ReactElement {
  const { hash } = useLocation();
  const newUrl = `${DdpUrls.Faq}${hash}`;
  return (
    <Route exact path={path}>
      <Redirect to={newUrl} />
    </Route>
  );
}

export default FaqRedirection;
