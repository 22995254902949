import { arraysIntersection } from '../../../../common/utils/array';
import { PeriodFilterId, YearFilterId } from '../constants/ids';
import { FileFilterValuesSelector } from '../types/FileFilter';
import { periodFileFilterOptionComparer } from './filter';

export const yearValuesSelector: FileFilterValuesSelector = (options) => {
  if (options.length === 0) return [];

  let maxYearOption = options[0];

  options.forEach((current) => {
    if (Number(maxYearOption.label) < Number(current.label)) maxYearOption = current;
  });

  return [maxYearOption];
};

export const periodValuesSelector: FileFilterValuesSelector = (options, filters) => {
  if (options.length > 0) {
    const yearFilter = filters.find(({ id }) => id === YearFilterId);
    const periodFilter = filters.find(({ id }) => id === PeriodFilterId);

    if (yearFilter !== undefined && periodFilter !== undefined) {
      const maxYear = yearValuesSelector(yearFilter.options, filters)[0]?.key as string | undefined;

      if (maxYear !== undefined) {
        const idsForYear = yearFilter.optionsIdsMap[maxYear];

        if (idsForYear !== undefined) {
          const sortedPeriodOptions = [...options].sort(periodFileFilterOptionComparer);

          // eslint-disable-next-line no-restricted-syntax
          for (const periodOption of sortedPeriodOptions) {
            const idsForPeriod = periodFilter.optionsIdsMap[periodOption.key];

            if (idsForPeriod !== undefined) {
              const intersection = arraysIntersection(idsForYear, idsForPeriod);
              if (intersection.length > 0) return [periodOption];
            }
          }
        }
      }
    }
  }

  return [];
};
