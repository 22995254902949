import { useAsqAccess } from '../../../../common/utils/accessHelper';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { resetAirportCode, setAirportCode } from './filterSlice';
import { HomePageFilterState } from './types';

export function useHomePageFilter(): HomePageFilterState {
  return useAppSelector((state) => state.homeFilter);
}

export function useIsHomePageAiportFilterReady(): boolean {
  const { access, isLoading } = useAsqAccess();
  const { airportCode } = useHomePageFilter();
  const dispatch = useAppDispatch();

  if (isLoading || access === undefined) return false;

  const { availableAirportsWithoutGroups: availableAirports } = access;

  if (availableAirports.length === 0) {
    if (airportCode !== null) {
      dispatch(resetAirportCode());
      return false;
    }
    return true;
  }

  const defaultCode = availableAirports[0].code;

  if (airportCode === null) {
    dispatch(setAirportCode(defaultCode));
    return false;
  }

  const airportExist = availableAirports.find((airport) => airport.code === airportCode) !== undefined;
  if (!airportExist) {
    dispatch(setAirportCode(defaultCode));
    return false;
  }

  return true;
}
