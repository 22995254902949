import { ReactElement } from 'react';

import { IconButton, styled } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';

import { faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';

import { useTranslation } from 'react-i18next';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';

interface FileSearchbarProps {
  value: string;
  onChange: (searchTerm: string) => void;
}

export default function FileSearchbar({ value, onChange }: FileSearchbarProps): ReactElement {
  const { t } = useTranslation('Library');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.value);

  return (
    <>
      <FormControl variant="outlined">
        <StyledSearchBar
          id="library-search"
          startAdornment={
            <InputAdornment position="start">
              <FontAwesomeSvgIcon fontSize="inherit" icon={faSearch} />
            </InputAdornment>
          }
          value={value}
          placeholder={t('SearchLabel')}
          onChange={handleChange}
          data-fullstory="LibrarySearch"
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={() => onChange('')}>
                <FontAwesomeSvgIcon fontSize="inherit" icon={faTimes} sx={{ color: '#0000008a' }} />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
}

const StyledSearchBar = styled(OutlinedInput)(({ theme }) => ({
  borderRadius: theme.spacing(3),
  height: theme.spacing(5),
  width: theme.spacing(45),
  boxShadow: Array.from(theme.shadows)[25],
  backgroundColor: 'white',
  '&:not(:hover, .Mui-focused)>fieldset': {
    border: 'none',
  },
  '& input': {
    padding: 4,
  },
}));
