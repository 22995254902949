import { ReactElement } from 'react';

import MuiButton, { ButtonProps } from '@mui/material/Button';
import { CSSObject, Theme, styled } from '@mui/material/styles';

export default function Button<C extends React.ElementType>(props: ButtonProps<C, { component?: C }>): ReactElement {
  return <StyledButton {...props} />;
}

const containedMixin = (theme: Theme): CSSObject => ({
  backgroundColor: theme.palette.primary.main,
  '&.Mui-disabled': {
    backgroundColor: '#B3C4DD',
    color: 'white',
  },
});

const outlinedMixin = (theme: Theme, color: string | undefined): CSSObject => ({
  border: `1px solid ${(theme as any).palette[color ?? 'primary'].main}`,
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '&.Mui-disabled': {
    color: '#B3C4DD',
    borderColor: '#B3C4DD',
  },
});

const textMixin = (theme: Theme): CSSObject => ({
  '&:hover': {
    backgroundColor: theme.palette.primary.light,
  },
  '&.Mui-disabled': {
    color: '#B3C4DD',
  },
});

const StyledButton = styled(MuiButton)(({ variant, color, theme }) => ({
  minWidth: '100px',
  whiteSpace: 'nowrap',
  textTransform: 'none',
  ...(variant === 'contained' && {
    ...containedMixin(theme),
  }),
  ...(variant === 'outlined' && {
    ...outlinedMixin(theme, color),
  }),
  ...(variant === 'text' && {
    ...textMixin(theme),
  }),
}));
