/* eslint-disable no-case-declarations */
import { produce } from 'immer';

import {
  ActionType,
  FetchingFiltersAction,
  ResetStateActionPayload,
  SetAirportValuesActionPayload,
  SetModuleValuesActionPayload,
} from './actions';
import { FetchingFiltersHelper } from './helper';
import { FetchingFiltersState } from './types';

export type FetchingFiltersStateReducerType = typeof reducer;

export function reducer(state: FetchingFiltersState, { type, payload }: FetchingFiltersAction): FetchingFiltersState {
  return produce(state, (draft: FetchingFiltersState) => {
    switch (type) {
      case ActionType.SetAirportValues:
        const { values: airportValues, access: access1 } = payload as SetAirportValuesActionPayload;
        FetchingFiltersHelper.setAirportValues(draft, airportValues, access1);
        break;
      case ActionType.SetModuleValues:
        const { values: moduleValues, access: access2 } = payload as SetModuleValuesActionPayload;
        FetchingFiltersHelper.setModuleValues(draft, moduleValues, access2);
        break;
      case ActionType.ResetState:
        const newState = payload as ResetStateActionPayload;
        FetchingFiltersHelper.resetState(draft, newState);
        break;
      default:
        break;
    }
  });
}
