import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import { Redirect, Route, Switch } from 'react-router-dom';
import MaintenancePage from '../common/components/pages/MaintenancePage';
import ModuleMaintenancePage from '../common/components/pages/ModuleMaintenancePage';
import { NotFoundPage } from '../common/components/pages/NotFoundPage';
import { AsqUrlPrefix, DdpUrlPrefix } from '../common/constants/urls';
import { RedirectDefinition } from '../core/entities/redirectDefinition';
import { RouteDefinition } from '../core/entities/routeDefinition';
import { Platform } from '../core/enums/platform.enum';
import { FeatureFlag } from '../core/featureFlags/FeatureFlag';
import { FeatureFlagKey } from '../core/featureFlags/featureFlagKeys';
import { useFeatureFlag, useFeatureFlagPlatform } from '../core/featureFlags/hooks';
import asqRoutes from '../modules/ASQ/routes';
import FaqRedirection from '../modules/DDP/faq/FaqRedirection';
import redirections from '../modules/DDP/redirections';
import ddpRoutes from '../modules/DDP/routes';
import { DdpUrls } from '../modules/DDP/urls';
import RedirectPage from '../modules/common/auth/pages/Redirect';
import appRoutes from './routes';

const ddpRoutesMemo = ddpRoutes.map(generateRoute);
const asqRoutesMemo = asqRoutes.map(generateRoute);
const appRoutesMemo = appRoutes.map(generateRoute);

const ddpRedirectionsMemo = [
  ...redirections.map(generateRedirection),
  <FaqRedirection path={DdpUrls.FaqDeprecated} key={DdpUrls.FaqDeprecated} />,
];

export default function AppContent() {
  const asqEnabled = useFeatureFlagPlatform(Platform.ASQ);
  const asqMaintenance = useFeatureFlag(FeatureFlagKey.ASQMaintenanceMode);
  const ddpMaintenance = useFeatureFlag(FeatureFlagKey.DDPMaintenanceMode);

  return (
    <Switch>
      {!ddpMaintenance && ddpRoutesMemo}
      {!asqMaintenance && asqEnabled && asqRoutesMemo}
      {ddpRedirectionsMemo}

      {appRoutesMemo}

      {asqMaintenance && (
        <Route path={`/${AsqUrlPrefix}`}>
          <ModuleMaintenancePage module="ASQ Reporting Portal" />
        </Route>
      )}

      {ddpMaintenance && (
        <Route path={`/${DdpUrlPrefix}`}>
          <ModuleMaintenancePage module="ACI Intelligence Hub" />
        </Route>
      )}

      <Route exact path="/maintenance">
        <Helmet>
          <title>ACI Intelligence Hub | Maintenance</title>
        </Helmet>
        <MaintenancePage />
      </Route>

      <Route path="/redirect">
        <RedirectPage />
      </Route>

      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
}

function generateRoute({ title, path, Component, featureFlagKey }: RouteDefinition): ReactElement {
  return (
    <Route exact path={path} key={path}>
      <FeatureFlag flagKey={featureFlagKey} fallback={<NotFoundPage />}>
        <>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <Component />
        </>
      </FeatureFlag>
    </Route>
  );
}

function generateRedirection({ newUrl, oldUrl }: RedirectDefinition): ReactElement {
  return (
    <Route exact path={oldUrl} key={oldUrl}>
      <Redirect to={newUrl} />
    </Route>
  );
}
