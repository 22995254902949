import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { TransformedFileDto } from '../entities/File';
import FilesCarousel from './FilesCarousel';

export type GroupedFiles = Record<string, TransformedFileDto[]>;
export type FilterableGroupedFiles = Record<string, GroupedFiles>;
export type LibrarySectionProps = { name: string; mappedFiles: FilterableGroupedFiles };

export default function LibrarySection({ name, mappedFiles }: LibrarySectionProps) {
  const [filteredFiles, setFilteredFiles] = useState(Object.values(mappedFiles)[0]);

  const availableFilters = Object.keys(mappedFiles);

  const changeFilters = (_event: React.ChangeEvent<HTMLInputElement>, value: string) =>
    setFilteredFiles(mappedFiles[value]);

  const trimFileNumber = (file: string): string => file.replace(/^(\d+\.?)*/g, '').trimStart();

  return (
    <Box mb={2}>
      <Box mb={3}>
        <Typography variant="h5" color="primary" mb={2}>
          {name.toUpperCase()}
        </Typography>
        {availableFilters.length > 1 && (
          <RadioGroup
            onChange={changeFilters}
            defaultValue={availableFilters[0]}
            sx={{ flexDirection: 'row', mb: 2 }}
            name="files-filter"
          >
            {availableFilters.map((filter) => (
              <FormControlLabel key={filter} value={filter} control={<Radio />} label={trimFileNumber(filter)} />
            ))}
          </RadioGroup>
        )}
      </Box>
      {Object.entries(filteredFiles)
        .sort(([a], [b]) => Number(b) - Number(a))
        .map(([label, files]) => (
          <Box key={label} mb={4}>
            <Typography variant="h4" color="primary" mb={2}>
              {trimFileNumber(label)}
            </Typography>
            <FilesCarousel dense files={files} />
          </Box>
        ))}
    </Box>
  );
}
