import Chip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { ReactElement } from 'react';

export default function Chips(props: ChipProps): ReactElement {
  return <StyledChip {...props} />;
}

const StyledChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  borderRadius: 4,
}));
