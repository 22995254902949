import RepositoryPage from '../enums/RepositoryPage.enum';
import { RepositoryPagesUrls } from '../urls';

export function getPageFromPathname(pathname: string): RepositoryPage {
  switch (pathname) {
    case RepositoryPagesUrls.Member:
      return RepositoryPage.Member;
    case RepositoryPagesUrls.Public:
      return RepositoryPage.Public;
    case RepositoryPagesUrls.Airport:
    default:
      return RepositoryPage.Airport;
  }
}

export function getUrlFromPage(page: RepositoryPage): string {
  return RepositoryPagesUrls[page];
}
