import { useCallback, useEffect, useMemo, useState } from 'react';

import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import AutoComplete, { AutoCompleteOption } from '../../../../common/components/atoms/AutoComplete';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { useAirportFilterQuery } from '../../../../common/hooks/backend';
import { useAppDispatch } from '../../../../store';
import { setSelectedAggregations } from '../analystSlice';
import Airport from '../entities/Airport';
import { FilterLevels } from '../enums/filterLevels.enum';
import { useAggregationFilters } from '../hooks/useAggregationFilters';

interface AirportsFiltersProps {
  value: string[];
  onValueChange: (newValue: string[]) => void;
}

export default function AirportsFilters(props: AirportsFiltersProps) {
  const { value, onValueChange } = props;

  const { data: filters = [], isLoading } = useAirportFilterQuery();

  const { t } = useTranslation('Analyst');

  const [selectedOptions, setSelectedOptions] = useState<AutoCompleteOption[]>([]);
  const dispatch = useAppDispatch();

  const { getClosestFilters } = useAggregationFilters();
  const closestFilters = getClosestFilters(FilterLevels.Airport);

  const filteredOptions = useMemo(() => {
    if (closestFilters.closestFilterLevels.includes(FilterLevels.Country)) {
      return filters.filter((a) => closestFilters.countries?.includes(a.countryCode));
    }
    if (
      closestFilters.closestFilterLevels.includes(FilterLevels.Subregion) ||
      closestFilters.closestFilterLevels.includes(FilterLevels.Region) ||
      closestFilters.closestFilterLevels.includes(FilterLevels.CountryGroup)
    ) {
      return filters.filter(
        (a) =>
          closestFilters.countryGroups!.some((g) => g.countries.includes(a.countryCode)) ||
          closestFilters.fullRegions!.some((s) => s.countries.includes(a.countryCode)),
      );
    }

    return filters;
  }, [filters, closestFilters]);

  const changeSelectedOptions = (newvalue: AutoCompleteOption[]) => {
    setSelectedOptions(newvalue);
    onValueChange(newvalue.map((option) => option.key));
  };

  const toAutoCompleteOptions = useCallback(
    (airports: Airport[]) =>
      airports.map((airport) => ({
        label: `${airport.airportName} (${airport.airportCode})`,
        key: airport.airportCode,
      })),
    [],
  );

  useEffect(() => {
    if (filters.length > 0) {
      setSelectedOptions(toAutoCompleteOptions(filters.filter((filter) => value.includes(filter.airportCode))));
    }
  }, [filters, toAutoCompleteOptions, value]);

  useEffect(() => {
    const newAirports: AutoCompleteOption[] = selectedOptions.filter((o) =>
      filteredOptions.map((f) => f.airportCode).includes(o.key),
    );

    if (newAirports.length !== selectedOptions.length) {
      dispatch(
        setSelectedAggregations({
          key: FilterLevels.Airport,
          value: newAirports.map((a) => a.key),
        }),
      );
    }
  }, [selectedOptions, dispatch, filteredOptions]);

  return (
    <Grid item width="20%">
      <AutoComplete
        loading={isLoading}
        options={toAutoCompleteOptions(filteredOptions)}
        width="100%"
        label={t('Airport', { count: 2 })}
        value={selectedOptions}
        onChange={changeSelectedOptions}
        fullStoryTag="AnalystAggregationLocationAirport"
        icon={<FontAwesomeSvgIcon icon={faAngleDown} />}
      />
    </Grid>
  );
}
