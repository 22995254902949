import React from 'react';
import { Platform } from '../enums/platform.enum';
import { ConditionalRenderer, RendererProps } from '../../common/components/atoms/ConditionalRenderer';
import { usePlatform } from './hooks';

interface PlatformFeatureProps extends RendererProps {
  platform: Platform;
}

function PlatformFeature({
  platform,
  children,
  fallback,
  reverse = false,
}: PlatformFeatureProps): React.ReactElement | null {
  const { currentPlatform } = usePlatform();

  return (
    <ConditionalRenderer display={currentPlatform === platform} fallback={fallback} reverse={reverse}>
      {children}
    </ConditionalRenderer>
  );
}

function DdpPlatformFeature(props: RendererProps): React.ReactElement | null {
  return <PlatformFeature {...props} platform={Platform.DDP} />;
}

function AsqPlatformFeature(props: RendererProps): React.ReactElement | null {
  return <PlatformFeature {...props} platform={Platform.ASQ} />;
}

function withPlatform<Props>(
  Component: React.FunctionComponent<Props>,
  platform: Platform,
): React.FunctionComponent<Props & { fallback?: React.ReactElement; reverse?: boolean }> {
  return function FeatureFlagHighOrderComponent({
    fallback,
    reverse = false,
    ...props
  }: Props & { fallback?: React.ReactElement; reverse?: boolean }) {
    return (
      <PlatformFeature platform={platform} fallback={fallback} reverse={reverse}>
        <Component {...(props as Props & JSX.IntrinsicAttributes)} />
      </PlatformFeature>
    );
  };
}

export { PlatformFeature, AsqPlatformFeature, DdpPlatformFeature, withPlatform };
