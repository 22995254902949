import { faChevronDoubleLeft, faChevronDoubleRight, faTimes } from '@fortawesome/pro-light-svg-icons';
import IconButton from '@mui/material/IconButton';
import { Theme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { Link } from 'react-router-dom';
import { Platform } from '../../../core/enums/platform.enum';
import { usePlatform } from '../../../core/platform/hooks';
import { getHomeLocationFromPlatform, getPlatformLogoSrc } from '../../../core/platform/utils';
import { useDrawerOpen } from '../context/DrawerContext';
import { FontAwesomeSvgIcon } from './FontAwesomeIcon';

export interface DrawerHeaderProps {
  onCollapseClick?: React.MouseEventHandler<HTMLButtonElement>;
  onExpandClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function DrawerHeader({ onCollapseClick, onExpandClick }: DrawerHeaderProps) {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { drawerOpen } = useDrawerOpen();
  const { currentPlatform } = usePlatform();

  const homeUrl = getHomeLocationFromPlatform(currentPlatform);
  const logoSrc = getPlatformLogoSrc(currentPlatform);
  const isASQ = currentPlatform === Platform.ASQ;
  const logoHeight = isASQ ? '54px' : '46px';
  const iconColor = isASQ ? undefined : 'white';

  return (
    <StyledDrawerHeader sx={{ margin: (theme) => theme.spacing(5, 2.75, isASQ ? 3 : 2, 2.75) }}>
      {drawerOpen && (
        <>
          <IconButton size="small" onClick={onCollapseClick}>
            {!matches ? (
              <FontAwesomeSvgIcon fontSize="inherit" icon={faChevronDoubleLeft} htmlColor={iconColor} />
            ) : (
              <FontAwesomeSvgIcon fontSize="inherit" icon={faTimes} htmlColor={iconColor} />
            )}
          </IconButton>
          <Link to={homeUrl}>
            <img role="heading" aria-level={3} alt="ACI Intelligence Hub" height={logoHeight} src={logoSrc} />
          </Link>
        </>
      )}
      {!drawerOpen && (
        <IconButton size="small" onClick={onExpandClick}>
          <FontAwesomeSvgIcon fontSize="inherit" icon={faChevronDoubleRight} htmlColor={iconColor} />
        </IconButton>
      )}
    </StyledDrawerHeader>
  );
}

const StyledDrawerHeader = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: 68,
}));

export default DrawerHeader;
