import generateDashboard from '../../dashboards/generator';
import { DashboardConfig, DashboardPageConfig } from '../../dashboards/types';
import { DepartureCommentsAnalysisDashboard } from './enum';
import {
  airportCodeSlicerSample,
  periodQuarterSlicerSample,
  periodYearSeasonSlicerSample,
  periodYearSlicerSample,
} from './slicerSamples';
import { SlicerInfos } from '../../../../common/utils/power-bi-slicers';
import { DepartureDashboardsUrlPrefix } from '.';
import { CommentsAnalaysisDashboardPrefix } from './constants';

const subscriptionSlicerCommentsSample: Pick<SlicerInfos, 'table' | 'column'> = {
  table: 'Comments',
  column: 'Subscription Code',
};

export const CommentsAnalysisOverviewDashboardPageConfig: DashboardPageConfig = {
  url: `${CommentsAnalaysisDashboardPrefix}`,
  pageName: 'ReportSection',
  titleTranslateKey: 'CommentsAnalysis',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: 'bde489b2591d3592c01b',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerCommentsSample,
    name: 'd535076b5c186723411d',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: 'af9cc1820817ac862051',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: 'af9cc1820817ac862051',
  },
  subscriptionBookmarkInfo: {
    slicerName: 'd535076b5c186723411d',
    mainBookmarkName: 'Bookmarkdad49fbe06b6cbefb762',
    regionalBookmarkName: 'Bookmarkf9b773593aee2a64fe3e',
    uniqueBookmarkName: 'Bookmark53170468454b1f584b88',
  },
};

export const CommentsAnalysisFrequencyDashboardPageConfig: DashboardPageConfig = {
  url: `${CommentsAnalaysisDashboardPrefix}-frequency`,
  pageName: 'ReportSection1fa5ffc36a4fb6fa6d09',
  titleTranslateKey: 'CommentsAnalysis',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '7029e884725787a35ca1',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerCommentsSample,
    name: '41b03d4218da783c18fa',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: 'e8ea8f651ed64c3ab314',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: 'e8ea8f651ed64c3ab314',
  },
  subscriptionBookmarkInfo: {
    slicerName: '41b03d4218da783c18fa',
    mainBookmarkName: 'Bookmarkf54d1e846ef16909ac63',
    regionalBookmarkName: 'Bookmark3f352a5bcb1c260ec175',
    uniqueBookmarkName: 'Bookmark808eee065784a205bc2a',
  },
};

export const CommentsAnalysisTrendOverTimeDashboardPageConfig: DashboardPageConfig = {
  url: `${CommentsAnalaysisDashboardPrefix}-overtime`,
  pageName: 'ReportSection8b5cf0cd5e5545bbe73a',
  titleTranslateKey: 'CommentsAnalysis',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: 'c1e0f04b169d05f57d2e',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerCommentsSample,
    name: '5629f3e92f6d42ada1f9',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '5b2b6ce52050af0a807c',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '5b2b6ce52050af0a807c',
  },
  subscriptionBookmarkInfo: {
    slicerName: '5629f3e92f6d42ada1f9',
    mainBookmarkName: 'Bookmark27089e340d4a5467dc1d',
    regionalBookmarkName: 'Bookmark0421128dfa67e65d5156',
    uniqueBookmarkName: 'Bookmark4a73c7e1580a2a39f00f',
  },
};

export const CommentsAnalysisWordCloudDashboardPageConfig: DashboardPageConfig = {
  url: `${CommentsAnalaysisDashboardPrefix}-word-cloud`,
  pageName: 'ReportSection27adfad98ecd5f372e2f',
  titleTranslateKey: 'CommentsAnalysis',
  airportCodeSlicerInfo: {
    ...airportCodeSlicerSample,
    name: '6ed9cf845691ee1c2301',
  },
  subscriptionSlicerInfo: {
    ...subscriptionSlicerCommentsSample,
    name: 'a4b1359a51359bd62f71',
  },
  periodYearSeasonSlicerInfo: {
    ...periodYearSeasonSlicerSample,
    name: '',
  },
  periodYearSlicerInfo: {
    ...periodYearSlicerSample,
    name: '5ea968cfc898453ee5e0',
  },
  periodQuarterSlicerInfo: {
    ...periodQuarterSlicerSample,
    name: '5ea968cfc898453ee5e0',
  },
  subscriptionBookmarkInfo: {
    slicerName: 'a4b1359a51359bd62f71',
    mainBookmarkName: 'Bookmark41e745a88dd6b824115e',
    regionalBookmarkName: 'Bookmark774de4157cd95b25e297',
    uniqueBookmarkName: 'Bookmarkd610393f0063252ef146',
  },
};

const dashboardConfig: DashboardConfig<typeof DepartureCommentsAnalysisDashboard> = {
  urlPrefix: DepartureDashboardsUrlPrefix,
  pages: {
    CommentsAnalysisOverview: CommentsAnalysisOverviewDashboardPageConfig,
    CommentsAnalysisFrequency: CommentsAnalysisFrequencyDashboardPageConfig,
    CommentsAnalysisTrendOverTime: CommentsAnalysisTrendOverTimeDashboardPageConfig,
    CommentsAnalysisWordCloud: CommentsAnalysisWordCloudDashboardPageConfig,
  },
};

export const {
  useDashboard: useDepartureCommentsAnalysisDashboard,
  pageUrls: DepartureCommentsAnalysisDashboardUrls,
  routerPath: DepartureCommentsAnalysisDashboardsRouterPath,
  getTitleTranslateKey: getDepartureCommentsAnalysisTitleTranslateKey,
  getInfoMessageTranslateKey: getDepartureCommentsAnalysisInfoMessageTranslateKey,
} = generateDashboard(dashboardConfig);
