import { ReactElement } from 'react';

import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import TruncatedTypograhpy from '../../../../common/components/atoms/TruncatedTypography';

interface BaseTileProps {
  title: string;
  imageUrl: string;
  imageTitle: string;
  description: string;
  content?: ReactElement;
  actions?: ReactElement;
}

export default function BaseTile({
  title,
  imageUrl,
  imageTitle,
  description,
  content,
  actions,
}: BaseTileProps): ReactElement {
  return (
    <StyledCard sx={{ borderRadius: 2.5 }} role="menuitem">
      <ResponsiveFlex>
        <StyleCardMedia image={imageUrl} title={imageTitle} />
        {content && (
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <CardContent sx={{ p: 3, pb: 1.5 }}>
              <Typography variant="h3" color="primary">
                {title}
              </Typography>
              {content}
              <TruncatedTypograhpy sx={{ mt: 1.5, mb: 1.5 }} variant="pMedium" color="text.secondary" shownLines={3}>
                {description}
              </TruncatedTypograhpy>
            </CardContent>
            {actions && <CardActions sx={{ p: 3, pt: 0 }}>{actions}</CardActions>}
          </Box>
        )}
        {!content && (
          <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <CardContent sx={{ p: 3, pb: 1, pt: 7 }}>
              <Typography variant="h3" color="primary">
                {title}
              </Typography>
              <TruncatedTypograhpy sx={{ mt: 1.5, mb: 1.5 }} variant="pMedium" color="text.secondary">
                {description}
              </TruncatedTypograhpy>
            </CardContent>
            {actions && <CardActions sx={{ p: 3, pt: 0 }}>{actions}</CardActions>}
          </Box>
        )}
      </ResponsiveFlex>
    </StyledCard>
  );
}

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 330,
  height: '100%',
  position: 'relative',
  [theme.breakpoints.up('lg')]: {
    maxWidth: 650,
    minWidth: 330,
  },
}));

const ResponsiveFlex = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
  },
}));

const StyleCardMedia = styled(CardMedia)(({ theme }) => ({
  backgroundSize: '140px 140px',
  height: 200,
  width: 'auto',
  [theme.breakpoints.up('lg')]: {
    width: 200,
    height: 'auto',
  },
}));
