import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ExternalUrls from '../../../../common/constants/externalUrls';
import { AppUrls } from '../../../../routes/urls';
import MailToLink from '../../../DDP/common/atoms/MailToLink';
import NewTabLink from '../../../DDP/common/atoms/NewTabLink';
import { TermsAnchor } from '../../termsAndConditions/models/TermsAnchor';
import BaseTermPage from '../../termsAndConditions/molecules/BaseTermsPage';

interface TermsOfSaleProps {
  asSection?: boolean;
}

const TermsOfSale = ({ asSection = false }: TermsOfSaleProps) => {
  const { t } = useTranslation('TermsAndConditions');

  const linkIHub = <NewTabLink link={ExternalUrls.IntelligenceHub} />;
  const linkPrivacyPolicy = <NewTabLink link={ExternalUrls.PrivacyPolicy} />;
  const linkTermsOfUse = asSection ? <Link to={TermsAnchor.TermsOfUse} /> : <NewTabLink link={AppUrls.TermsOfUse} />;
  const linkMailTo = <MailToLink subjectType="termsOfSale" />;

  return (
    <BaseTermPage
      title={t('TermsOfSale.Title')}
      content={
        <Trans t={t} components={{ linkIHub, linkPrivacyPolicy, linkTermsOfUse, linkMailTo }}>
          TermsOfSale.Content
        </Trans>
      }
    />
  );
};

export default TermsOfSale;
