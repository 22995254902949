import { AxiosInstance } from 'axios';

import JsonCache from '../../common/utils/JsonCache';
import { ICachedApiClient, QueryResponse, UrlGenerator } from './types';

export class CachedApiClient<QueryArgs, Data> implements ICachedApiClient<QueryArgs, Data> {
  private responseCache = new JsonCache<Data>();

  private promiseCache = new Map<string, Promise<QueryResponse<QueryArgs, Data>>>();

  constructor(private urlGenerator: UrlGenerator<QueryArgs>, private getApiClient: () => Promise<AxiosInstance>) {}

  public async getAsync(queryArgs: QueryArgs): Promise<QueryResponse<QueryArgs, Data>> {
    const url = this.urlGenerator(queryArgs);

    const data = this.fetchCache(url);
    if (data !== undefined) return { queryArgs, url, data };

    return this.fetchAsync(url, queryArgs, this.getApiClient());
  }

  public async getListAsync(queryArgsList: QueryArgs[]): Promise<QueryResponse<QueryArgs, Data>[]> {
    const cacheDataAttempt: QueryResponse<QueryArgs, Data>[] = queryArgsList.map((queryArgs) => {
      const url = this.urlGenerator(queryArgs);
      const data = this.fetchCache(url);

      return { queryArgs, url, data };
    });

    const dataToFetch = cacheDataAttempt.filter(({ data }) => data === undefined);

    if (dataToFetch.length === 0) {
      return cacheDataAttempt;
    }

    // we do this just now to ensure we don't need to make this async if data are already in the cache
    const apiClientPromise = this.getApiClient();

    const cacheResult = cacheDataAttempt.filter(({ data }) => data !== undefined);

    const promises = dataToFetch.map(({ queryArgs, url }) => this.fetchAsync(url, queryArgs, apiClientPromise));

    const fetchResult = await Promise.all(promises);

    return [...cacheResult, ...fetchResult];
  }

  public clearCache(): void {
    this.responseCache.reset();
    this.promiseCache.clear();
  }

  private fetchCache(url: string): Data | undefined {
    return this.responseCache.getData(url);
  }

  private async fetchAsync(
    url: string,
    queryArgs: QueryArgs,
    apiClientPromise: Promise<AxiosInstance>,
  ): Promise<QueryResponse<QueryArgs, Data>> {
    const cachedData = this.fetchCache(url);
    if (cachedData !== undefined) return { queryArgs, url, data: cachedData };

    const existingPromise = this.promiseCache.get(url);
    if (existingPromise !== undefined) return existingPromise;

    const promise = new Promise<QueryResponse<QueryArgs, Data>>((resolve) => {
      apiClientPromise
        .then((apiClient) => {
          apiClient
            .get<Data>(url, { responseType: 'json' })
            .then(({ data }) => {
              this.promiseCache.delete(url);
              this.responseCache.setData(url, data);
              resolve({ queryArgs, url, data });
            })
            .catch((error) => {
              this.promiseCache.delete(url);
              resolve({ queryArgs, url, error });
            });
        })
        .catch((error) => {
          this.promiseCache.delete(url);
          resolve({ queryArgs, url, error });
        });
    });

    this.promiseCache.set(url, promise);
    return promise;
  }
}
