import { faSort, faSortDown, faSortUp } from '@fortawesome/pro-light-svg-icons';
import { Checkbox, styled } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { ActionWidth, Comparer, HeaderDef, HeaderType, SortingState, SortingType } from '../types/TableTypes';
import { FontAwesomeSvgIcon } from './FontAwesomeIcon';

export interface PaperTableHeaderProps<T> {
  headers: HeaderDef<T>[];
  onSortChange: (headerId: string, comparer: Comparer<T>) => void;
  sortingState?: SortingState<T>;
  ratioRowsSelected?: number;
  onToggleAllRows?: () => void;
  isLoading?: boolean;
}

export default function PaperTableHeader<T>({
  headers,
  onSortChange,
  sortingState,
  ratioRowsSelected,
  onToggleAllRows,
  isLoading,
}: PaperTableHeaderProps<T>) {
  const handleClick = (header: HeaderDef<T>) => () => {
    if (header.type === HeaderType.Data && header.comparer) onSortChange(header.id, header.comparer);
  };

  const getSortIcon = (header: HeaderDef<T>): ReactElement | null => {
    if (header.id === sortingState?.headerId) {
      if (sortingState.sortingType === SortingType.Sort) {
        return <SortIcon sx={{ color: 'primary.main' }} icon={faSortDown} />;
      }
      if (sortingState.sortingType === SortingType.Reverse) {
        return <SortIcon sx={{ color: 'primary.main' }} icon={faSortUp} />;
      }
      return <SortIcon icon={faSort} />;
    }
    return null;
  };

  return (
    <Paper
      sx={{
        mb: 2,
        textTransform: 'uppercase',
        position: 'relative',
        boxShadow: '0px 0px 0px 0px',
        background: 'transparent',
      }}
    >
      <Box color="primary.main" display="flex" p={3} height={(theme) => theme.spacing(8.25)}>
        {headers.map((header) => {
          if (header.type === HeaderType.Select) {
            return (
              <Box key={header.id} display="flex" alignItems="center" flex={header.flexWidth}>
                <Checkbox
                  disabled={isLoading}
                  checked={ratioRowsSelected === 1}
                  indeterminate={ratioRowsSelected !== undefined && ratioRowsSelected > 0 && ratioRowsSelected < 1}
                  onChange={onToggleAllRows}
                />
              </Box>
            );
          }

          if (React.isValidElement(header.label)) {
            return (
              <Box
                key={header.id}
                display="flex"
                alignItems="center"
                flex={header.flexWidth}
                minWidth={header.type === HeaderType.Action ? header.maxActions * ActionWidth : undefined}
              >
                {header.label}
              </Box>
            );
          }
          return (
            <Header
              key={header.id}
              sx={{
                cursor: header.type === HeaderType.Data && header.comparer ? 'pointer' : 'cursor',
                fontWeight: 'bold',
              }}
              variant="pLarge"
              flex={header.flexWidth}
              minWidth={header.type === HeaderType.Action ? header.maxActions * ActionWidth : undefined}
              onClick={handleClick(header)}
            >
              {header.label}
              {getSortIcon(header)}
            </Header>
          );
        })}
      </Box>
      {isLoading && <LinearProgress sx={{ position: 'absolute', bottom: '0', width: '100%' }} />}
    </Paper>
  );
}

const Header = styled(Typography)(() => ({
  color: 'inherit',
}));

const SortIcon = styled(FontAwesomeSvgIcon)(({ theme }) => ({
  fontSize: 'inherit',
  marginLeft: theme.spacing(2),
}));
