import { PublicClientApplication } from '@azure/msal-browser';
import msalConfig from '../../authConfig';

export const msalInstance: PublicClientApplication = new PublicClientApplication(msalConfig);

export async function getToken() {
  const account = msalInstance.getAllAccounts()[0];
  let token = '';

  const tokenRequest = {
    scopes: [`${process.env.REACT_APP_MSAL_SCOPE}`],
  };

  try {
    const response = await msalInstance.acquireTokenSilent({
      account,
      ...tokenRequest,
    });

    token = response.idToken;
  } catch {
    return token;
  }

  return token;
}
