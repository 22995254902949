import { ReactElement } from 'react';

import { useParams } from 'react-router-dom';
import PowerBIReport from '../../../../common/components/molecules/PowerBIReport';
import { useExecutiveReportQuery } from '../../../../common/hooks/backend';
import { useAppSelector } from '../../../../store';
import ExecutiveTabs from '../molecules/ExecutiveTabs';

interface ParamProps {
  id: string;
}

export default function ExecutiveReportPage(): ReactElement {
  const params: ParamProps = useParams();
  const { data: embedRequest, isLoading } = useExecutiveReportQuery(params.id);
  const { currentPage } = useAppSelector((state) => state.executive);

  return (
    <>
      <ExecutiveTabs />
      <PowerBIReport embedRequest={embedRequest} isLoading={isLoading} pageName={currentPage} />
    </>
  );
}
