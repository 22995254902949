import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ASQPowerBIReport from '../../../../common/components/molecules/ASQPowerBIReport';
import { expirationTokenInterval, useDepartureCommentsAnalysisReportsQuery } from '../../../../common/hooks/backend';
import { useAsqAccess } from '../../../../common/utils/accessHelper';
import { getReportChangeListenerForFiltersAndSlicers } from '../../../../common/utils/power-bi-filters';
import { Module } from '../../../../core/enums/module.enum';
import { withFeatureFlag } from '../../../../core/featureFlags/FeatureFlag';
import { FeatureFlagKey } from '../../../../core/featureFlags/featureFlagKeys';
import { useSetCurrentPowerBiFilters } from '../../../../core/powerBi/hooks';
import DashboardHeader from '../../dashboards/components/molecules/DashboardHeader';
import {
  getDepartureCommentsAnalysisInfoMessageTranslateKey,
  getDepartureCommentsAnalysisTitleTranslateKey,
  useDepartureCommentsAnalysisDashboard,
} from '../dashboards/commentsAnalysis';

function DashboardsCommentsAnalysis(): ReactElement {
  const { access } = useAsqAccess();
  const { t } = useTranslation('Departure');

  const { dashboard, pageName, onPageChange, onSlicerChange, synchronizeAirportCodeAndSubscriptionAndPeriod } =
    useDepartureCommentsAnalysisDashboard(Module.Departure);

  const { data: embedRequest, isLoading } = useDepartureCommentsAnalysisReportsQuery(undefined, {
    pollingInterval: expirationTokenInterval,
  });

  const infoMessageTranslateKey = getDepartureCommentsAnalysisInfoMessageTranslateKey(dashboard);

  const [, setCurrentPowerBiFilters] = useSetCurrentPowerBiFilters();
  const onRendered = getReportChangeListenerForFiltersAndSlicers(setCurrentPowerBiFilters);

  if (!access?.additionalAccess?.commentsAnalysis?.departures?.length) {
    return <></>;
  }

  return (
    <>
      {dashboard !== null && (
        <DashboardHeader
          title={t(getDepartureCommentsAnalysisTitleTranslateKey(dashboard))}
          infoMessage={infoMessageTranslateKey !== null ? t(infoMessageTranslateKey) : undefined}
        />
      )}
      <ASQPowerBIReport
        embedRequest={embedRequest}
        isLoading={isLoading}
        pageName={pageName ?? undefined}
        onPageChange={onPageChange}
        onLoaded={synchronizeAirportCodeAndSubscriptionAndPeriod}
        onSlicerChange={onSlicerChange}
        onRendered={onRendered}
        showFilters
      />
    </>
  );
}

const DashboardsCommentsAnalysisFeature = withFeatureFlag(DashboardsCommentsAnalysis, FeatureFlagKey.DepartureReports);

export default DashboardsCommentsAnalysisFeature;
