import { getApiClient } from '../../../../../../services/api/apiClient';
import { CachedApiClientTransformer } from '../../../../../../services/api/CachedApiClientTransformer';
import { ResponseTransformer, UrlGenerator } from '../../../../../../services/api/types';
import { AirportFile, AirportFileDto } from '../../../entities/AirportFile';
import FileModule from '../../../enums/FileModule.enum';
import { useFetchFiles } from '../../hooks/fetchFiles';
import { mapAirportFileDto } from '../../../utils/mapper';

export interface AirportFileQueryArgs {
  airportCode: string;
  module: FileModule;
}

const airportFileUrlGenerator: UrlGenerator<AirportFileQueryArgs> = ({ airportCode, module }) => {
  switch (module) {
    case FileModule.Departure:
      return `Departure/Files/Airport?airportCode=${airportCode}`;
    case FileModule.Arrival:
      return `Arrival/Files/Airport?airportCode=${airportCode}`;
    case FileModule.Commercial:
      return `Commercial/Files/Airport?airportCode=${airportCode}`;
    default:
      throw new Error(`The file module ${module} doesn't exist.`);
  }
};

const airportFilesResponseTransformer: ResponseTransformer<AirportFileQueryArgs, AirportFileDto[], AirportFile[]> = ({
  queryArgs: { module },
  data,
  error,
}) => {
  if (error !== undefined) return { error };
  return { data: data?.map((fileDto) => mapAirportFileDto(fileDto, module)) };
};

const generateAirportFileApiClient = () =>
  new CachedApiClientTransformer<AirportFileQueryArgs, AirportFileDto[], AirportFile[]>(
    airportFileUrlGenerator,
    getApiClient,
    airportFilesResponseTransformer,
  );

export function useFetchAirportFiles(queryArgsList?: AirportFileQueryArgs[]) {
  return useFetchFiles(generateAirportFileApiClient, queryArgsList);
}
