import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Box, Stack } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { format, parseISO } from 'date-fns';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '../../../../common/components/atoms/TextField';
import RadioCard from '../../../../common/components/molecules/RadioCard';
import { useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  changeDatasetType,
  changeFromYear,
  changeLastYear,
  changeReportTitle,
  changeTimeType,
  changeToYear,
} from '../analystSlice';
import NoAccessAlert from '../atoms/NoAccessAlert';
import PeriodType from '../enums/periodType.enum';
import TimeFetchingType from '../enums/timeFetchingType.enum';

export interface DatasetStepProps {
  validated: boolean;
}

const freeTrialMinimumDate = new Date(2021, 0, 1);
const freeTrialMaximumDate = new Date(2021, 11, 31);
const earliestAvailableDate = new Date(2019, 0, 1);
const maximumDateIfAnnual = new Date();
maximumDateIfAnnual.setFullYear(maximumDateIfAnnual.getFullYear() - 1);
maximumDateIfAnnual.setMonth(maximumDateIfAnnual.getMonth() - 6);

export default function DatasetStep({ validated }: DatasetStepProps): ReactElement {
  const { t } = useTranslation(['Analyst', 'Common']);
  const { data: access } = useModuleAccessesQuery();
  const { periodType, startYear, endYear, nbOfLastMonths, timeFetchingType, name, id } = useAppSelector(
    (state) => state.analyst.currentTemplate,
  );
  const [nbOfLastMonthsDisplay, setNbOfLastMonthsDisplay] = useState(
    periodType === PeriodType.Annually ? Math.ceil(nbOfLastMonths / 12) : nbOfLastMonths,
  );
  const [oldValidValue, setOldValidValue] = useState(String(nbOfLastMonthsDisplay));
  const dispatch = useAppDispatch();

  const isFreeTrialAccess =
    access?.find((a) => a.moduleKey === ModuleKey.Analyst)?.status === ModuleAccessStatus.Trialing;
  const minimumDate = isFreeTrialAccess ? freeTrialMinimumDate : earliestAvailableDate;
  const maximumDate = useMemo(() => {
    if (isFreeTrialAccess) {
      return freeTrialMaximumDate;
    }
    if (periodType === PeriodType.Annually) {
      return maximumDateIfAnnual;
    }
    return new Date();
  }, [isFreeTrialAccess, periodType]);

  const nbOfLastMonthsDisplayError =
    nbOfLastMonths >
    new Date().getMonth() - minimumDate.getMonth() + 12 * (new Date().getFullYear() - minimumDate.getFullYear());

  const handleSubscriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeDatasetType(Number(event.target.value) as PeriodType));
  };

  const handleRadioCardClick = (value: string) => {
    const newPeriodType = Number(value) as PeriodType;
    dispatch(changeDatasetType(newPeriodType));
    const dateString = formatDateBasedOnPeriodType(
      newPeriodType === PeriodType.Annually ? maximumDateIfAnnual : new Date(),
      newPeriodType,
    );
    dispatch(changeToYear(dateString));
  };

  const handleTimeTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const timeType = Number(event.target.value) as TimeFetchingType;
    dispatch(changeTimeType(timeType));
  };

  const handleFromYear = (date: Date | null) => {
    if (date?.getTime()) {
      const dateString = formatDateBasedOnPeriodType(date, periodType);
      dispatch(changeFromYear(dateString));
    }
  };

  const handleToYear = (date: Date | null) => {
    if (date?.getTime()) {
      const dateString = formatDateBasedOnPeriodType(date, periodType);
      dispatch(changeToYear(dateString));
    }
  };

  const formatDateBasedOnPeriodType = (date: Date, currentPeriodType: PeriodType) =>
    currentPeriodType === PeriodType.Annually ? format(date, 'yyyy') : format(date, 'yyyy-MM');

  const handleTitleChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeReportTitle(value));
  };

  const handleLastYear = ({ target }: React.ChangeEvent<{ value: string }>) => {
    let { value } = target;

    if (Number.isNaN(+value)) {
      value = oldValidValue;
    } else {
      if (+value < 1 && +value >= 0) {
        value = '';
      } else {
        value = String(Math.floor(Number(value)));
      }
      setOldValidValue(value);
    }
    setNbOfLastMonthsDisplay(Number(value));
    dispatch(changeLastYear(Number(value)));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Typography variant="h6" pt={4} pb={2}>
        {t('Dataset')}
      </Typography>

      {id && (
        <StyledTextField
          error={validated && !name}
          helperText={validated && !name ? t('Required', { ns: 'Common' }) : ' '}
          sx={{ mb: 0.5, width: 500 }}
          label={t('ReportTitleLabel')}
          variant="outlined"
          onChange={handleTitleChange}
          value={name}
          data-fullstory="AnalystEditReportTitle"
        />
      )}
      <Box sx={{ width: 750, px: 0 }}>
        <RadioGroup
          aria-label="datasetType"
          name="datasetType"
          value={String(periodType)}
          onChange={handleSubscriptionChange}
        >
          <Grid container spacing={5}>
            <Grid item xs={6}>
              <RadioCard
                onClick={handleRadioCardClick}
                selected={periodType === PeriodType.Monthly}
                label={t('MonthlyRadioLabel')}
                value={`${PeriodType.Monthly}`}
                description={t('MonthlyDescription')}
                datafullstory="AnalystMonthlyPeriodType"
              />
            </Grid>
            <Grid item xs={6}>
              <RadioCard
                onClick={handleRadioCardClick}
                selected={periodType === PeriodType.Annually}
                label={t('AnnualRadioLabel')}
                value={`${PeriodType.Annually}`}
                description={t('AnnuallyDescription')}
                datafullstory="AnalystAnnualPeriodType"
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Box>
      <Typography paddingTop={4} variant="h6">
        {t('Time')}
      </Typography>
      <Typography pt={1} pb={3} variant="pSmall">
        {t('TimeDescription')}
      </Typography>

      <Stack direction="row">
        <RadioGroup value={timeFetchingType} aria-label="time" name="time" onChange={handleTimeTypeChange}>
          <StyledGrid>
            <FormControlLabel
              value={TimeFetchingType.From}
              control={<Radio />}
              label={t('From')}
              data-fullstory="AnalystFromTimeSelector"
            />
            <StyledItem>
              <DatePicker
                views={periodType === PeriodType.Annually ? ['year'] : ['year', 'month']}
                value={parseISO(startYear)}
                disableFuture
                minDate={minimumDate}
                maxDate={maximumDate}
                onChange={handleFromYear}
                renderInput={(params) => (
                  <StyledTextField
                    sx={{ maxWidth: 200 }}
                    {...params}
                    helperText={null}
                    data-fullstory="AnalystFromDatePicker"
                  />
                )}
              />
              <Typography px={1} variant="pMedium">
                {t('To')}
              </Typography>
              <DatePicker
                views={periodType === PeriodType.Annually ? ['year'] : ['year', 'month']}
                value={parseISO(endYear)}
                disableFuture
                minDate={minimumDate}
                maxDate={maximumDate}
                onChange={handleToYear}
                renderInput={(params) => (
                  <StyledTextField
                    sx={{ maxWidth: 200 }}
                    {...params}
                    helperText={null}
                    data-fullstory="AnalystToDatePicker"
                  />
                )}
              />
            </StyledItem>
            <FormControlLabel
              value={TimeFetchingType.Latest}
              control={<Radio disabled={isFreeTrialAccess} />}
              label={t('Latest')}
              data-fullstory="AnalystLastTimeSelector"
            />
            <StyledItem>
              <StyledTextField
                disabled={isFreeTrialAccess}
                sx={{ maxWidth: 200 }}
                onChange={handleLastYear}
                value={nbOfLastMonthsDisplay}
                error={nbOfLastMonthsDisplayError}
                data-fullstory="AnalystLastTimeSelectorInput"
              />
              <Typography pl={1} variant="pMedium">
                {periodType === PeriodType.Monthly
                  ? t('Month', { count: nbOfLastMonthsDisplay })
                  : t('Year', { count: nbOfLastMonthsDisplay })}
              </Typography>
            </StyledItem>
          </StyledGrid>
        </RadioGroup>
        {isFreeTrialAccess && (
          <Box sx={{ marginLeft: 5, width: 400 }}>
            <NoAccessAlert alwaysShow />
          </Box>
        )}
      </Stack>
    </LocalizationProvider>
  );
}

const StyledGrid = styled('div')(({ theme }) => ({
  display: 'grid',
  rowGap: theme.spacing(2),
  justifyContent: 'stretch',
  gridTemplateColumns: 'auto 1fr',
}));

const StyledItem = styled('div')(() => ({
  display: 'flex',
  gridColumnStart: '2',
  alignSelf: 'center',
  alignItems: 'center',
}));

const StyledTextField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
  },
  '& fieldset': {
    borderRadius: 0,
    borderColor: '#081F3D',
  },
}));
