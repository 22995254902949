import { AirportFile, AirportFileDto } from '../entities/AirportFile';
import { DefaultCategory, DefaultPeriod, DefaultYear, NoTitle } from '../entities/BaseFile';
import { MemberFile, MemberFileDto } from '../entities/MemberFile';
import { PublicFile, PublicFileDto } from '../entities/PublicFile';
import FileModule from '../enums/FileModule.enum';
import { formatAirportFileCategory, formatAirportFilePeriod } from './format';

export function mapAirportFileDto(fileDto: AirportFileDto, module: FileModule): AirportFile {
  return {
    ...fileDto,
    module,
    year: fileDto.year?.toString() ?? DefaultYear,
    period: formatAirportFilePeriod(fileDto.period ?? DefaultPeriod, fileDto.subPeriod),
    category: formatAirportFileCategory(fileDto.category ?? DefaultCategory, fileDto.subCategory),
    lastModified: new Date(fileDto.lastModified),
    publishedDate: new Date(fileDto.publishedDate),
  };
}

export function mapMemberFileDto(fileDto: MemberFileDto, module: FileModule): MemberFile {
  return {
    ...fileDto,
    module,
    year: fileDto.year?.toString() ?? DefaultYear,
    period: formatAirportFilePeriod(fileDto.period ?? DefaultPeriod, fileDto.subPeriod),
    category: formatAirportFileCategory(fileDto.category ?? DefaultCategory, fileDto.subCategory),
    lastModified: new Date(fileDto.lastModified),
    publishedDate: new Date(fileDto.publishedDate),
  };
}

export function mapPublicFileDto(fileDto: PublicFileDto): PublicFile {
  return {
    ...fileDto,
    year: fileDto.year?.toString() ?? DefaultYear,
    period: formatAirportFilePeriod(fileDto.period ?? DefaultPeriod, fileDto.subPeriod),
    category: formatAirportFileCategory(fileDto.category ?? DefaultCategory, fileDto.subCategory),
    lastModified: new Date(fileDto.lastModified),
    publishedDate: new Date(fileDto.publishedDate),
    title: fileDto.title ?? NoTitle,
  };
}
