import { AlertColor } from '@mui/material';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import React from 'react';

interface State {
  message: string;
  open: boolean;
  severity: AlertColor;
  icon?: React.ReactElement;
}

export const initialState: State = {
  message: '',
  severity: 'success',
  open: false,
  icon: undefined,
};

// Actions
const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    displaySnackbar: (
      state,
      action: PayloadAction<{ message: string; severity?: AlertColor; icon?: React.ReactElement }>,
    ) => ({
      message: action.payload.message,
      open: true,
      severity: action.payload.severity || state.severity,
      icon: action.payload.icon,
    }),
    hideSnackbar: (state) => ({
      ...state,
      open: false,
    }),
  },
});

export const { displaySnackbar, hideSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
