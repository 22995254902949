/* eslint-env browser */
import React from 'react';

import CssBaseline from '@mui/material/CssBaseline';

import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import { PlatformThemeProvider } from './core/theme/PlatformThemeProvider';
import './i18n';
import store, { persistor } from './store';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={<>loading...</>} persistor={persistor}>
          <PlatformThemeProvider>
            <CssBaseline/>
            <App/>
          </PlatformThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
