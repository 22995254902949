import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';

import { ModuleDescription } from '../../../../core/entities/moduleDescription';
import { StatisticDto } from '../../../../core/entities/statisticDto';
import ModuleTileActions from '../atoms/ModuleTileActions';
import TileStatistic from '../atoms/TileStatistic';
import BaseTile from './BaseTile';

interface ModuleTileProps {
  module: ModuleDescription;
  hasAccess: boolean;
  statistic?: StatisticDto;
  isStatisticLoading?: boolean;
  onRepositoryOpen?: () => void;
}

export default function ModuleTile({
  module,
  hasAccess,
  statistic,
  isStatisticLoading = false,
  onRepositoryOpen,
}: ModuleTileProps): ReactElement {
  const { t } = useTranslation('Modules');

  return (
    <BaseTile
      title={t(`Names.${module.translateKey}`)}
      imageUrl={hasAccess ? `/images/${module.name}.png` : '/images/locked.png'}
      imageTitle={hasAccess ? `${module.name} icon` : 'lock icon'}
      description={t(`ShortDescriptions.${module.translateKey}`).replace('<br>', '')}
      content={hasAccess ? <TileStatistic statistic={statistic} isLoading={isStatisticLoading} /> : undefined}
      actions={
        <ModuleTileActions
          hasAccess={hasAccess}
          url={module.url}
          moduleName={module.name}
          onSecondaryActionClick={onRepositoryOpen}
        />
      }
    />
  );
}
