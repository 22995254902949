import { ReactElement } from 'react';

import { Box, BoxProps } from '@mui/material';

export default function BannerBox({ children, ...boxProps }: BoxProps): ReactElement {
  return (
    <Box my={2} bgcolor="#F8CBAD" p={3} border="1px solid black" {...boxProps}>
      {children}
    </Box>
  );
}
