export const metricTypes = [
  {
    groupName: 'AircraftMovements',
    childs: [
      { value: 'Passenger and Combi Aircraft', label: 'PassengerAndCombiAircraft' },
      { value: 'All-Cargo Aircraft', label: 'AllCargoAircraft' },
      { value: 'Total Air Transport Movements', label: 'TotalAirTransportMovements' },
      { value: 'Other Aircraft Movements', label: 'OtherAircraftMovements' },
      { value: 'Total Aircraft Movements', label: 'TotalAircraftMovements' },
    ],
  },
  {
    groupName: 'CommercialPassengers',
    childs: [
      { value: 'International Passengers', label: 'InternationalPassengers' },
      { value: 'Domestic Passengers', label: 'DomesticPassengers' },
      { value: 'Total Terminal Passengers', label: 'TotalTerminalPassengers' },
      { value: 'Direct Transit Passengers', label: 'DirectTransitPassengers' },
      { value: 'Total Passengers', label: 'TotalPassengers' },
    ],
  },
  {
    groupName: 'CargoFeightMail',
    childs: [
      { value: 'International Freight', label: 'InternationalFreight' },
      { value: 'Domestic Freight', label: 'DomesticFreight' },
      { value: 'Total Freight', label: 'TotalFreight' },
      { value: 'Mail', label: 'Mail' },
      { value: 'Total Cargo', label: 'TotalCargo' },
    ],
  },
];
