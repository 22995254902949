import { ReactElement, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { AppBarPortalId } from '../molecules/AppBar';

interface AppBarTitleProps {
  children: React.ReactElement;
}

function AppBarTitlePortal({ children }: AppBarTitleProps): ReactElement | null {
  const [portal, setPortal] = useState<React.ReactPortal | null>(null);

  const node = document.getElementById(AppBarPortalId) as Element;

  useEffect(() => {
    if (node != null) setPortal(createPortal(<>{children}</>, node));
  }, [node, children, setPortal]);

  return portal;
}

export { AppBarTitlePortal };

