import { RedirectDefinition } from '../../core/entities/redirectDefinition';
import { DdpUrls } from './urls';

const redirections: RedirectDefinition[] = [
  {
    newUrl: DdpUrls.Home,
    oldUrl: DdpUrls.HomeDeprecated,
  },
  {
    newUrl: DdpUrls.ExecutiveModuleReports,
    oldUrl: DdpUrls.ExecutiveModuleDeprecated,
  },
  {
    newUrl: DdpUrls.LibraryModule,
    oldUrl: DdpUrls.LibraryModuleDeprecated,
  },
  {
    newUrl: DdpUrls.AnalystModule,
    oldUrl: DdpUrls.AnalystModuleDeprecated,
  },
  {
    newUrl: DdpUrls.BenchmarkModule,
    oldUrl: DdpUrls.BenchmarkModuleDeprecated,
  },
  {
    newUrl: DdpUrls.AnalystModuleReportCreation,
    oldUrl: DdpUrls.AnalystModule,
  },
];

export default redirections;
