import { combineComparers } from '../../../../../../common/utils/comparer';
import {
  airportCodeFileComparer,
  categoryFileComparer,
  moduleFileComparer,
  periodFileComparer,
  yearFileComparer,
} from '../../../utils/comparer';

export const airportCodeHeaderComparer = combineComparers(
  airportCodeFileComparer,
  yearFileComparer,
  periodFileComparer,
  moduleFileComparer,
  categoryFileComparer,
);

export const periodHeaderComparer = combineComparers(
  periodFileComparer,
  yearFileComparer,
  moduleFileComparer,
  categoryFileComparer,
  airportCodeFileComparer,
);

export const yearHeaderComparer = combineComparers(
  yearFileComparer,
  periodFileComparer,
  moduleFileComparer,
  categoryFileComparer,
  airportCodeFileComparer,
);

export const moduleHeaderComparer = combineComparers(
  moduleFileComparer,
  yearFileComparer,
  periodFileComparer,
  categoryFileComparer,
  airportCodeFileComparer,
);

export const categoryHeaderComparer = combineComparers(
  categoryFileComparer,
  yearFileComparer,
  periodFileComparer,
  moduleFileComparer,
  airportCodeFileComparer,
);

export const defaultComparer = yearHeaderComparer;
