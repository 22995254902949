import { AsqUrlPrefix } from '../../common/constants/urls';
import { ArrivalDashboardUrls, ArrivalDashboardsRouterPath } from './arrival/dashboards';
import { CommercialDashboardsRouterPath, CommercialDashboardUrls } from './commercial/dashboards';
import { DepartureDashboardUrls, DepartureDashboardsRouterPath } from './departure/dashboards';
import { DepartureCommentsAnalysisDashboardUrls } from './departure/dashboards/commentsAnalysis';
import { DepartureDissatisfiedPassengerDashboardUrls } from './departure/dashboards/dissatisfiedPassenger';
import { RepositoryRouterPath, RepositoryPagesUrls } from './repository/urls';

const AsqUrls = {
  Home: `/${AsqUrlPrefix}`,
  DepartureOverview: DepartureDashboardUrls.Overview,
  DeparturePassengerJourney: DepartureDashboardUrls.PassengerJourney,
  DepartureBenchmarkingPerf: DepartureDashboardUrls.BenchmarkingPerf,
  DepartureBenchmarkingTrendPerf: DepartureDashboardUrls.BenchmarkingTrendPerf,
  DeparturePassProBenchmarking: DepartureDashboardUrls.PassProBenchmarking,
  DeparturePassProDemographics: DepartureDashboardUrls.PassProDemographics,
  DeparturePassProDemographicsBySegment: DepartureDashboardUrls.PassProDemographicsBySegment,
  DeparturePassProDemographicsTrendOvertime: DepartureDashboardUrls.PassProDemographicsTrendOvertime,
  DeparturePassProTravelBehavior: DepartureDashboardUrls.PassProTravelBehavior,
  DeparturePassProTravelBehaviorBySegment: DepartureDashboardUrls.PassProTravelBehaviorBySegment,
  DeparturePassProTravelBehaviorTrendOvertime: DepartureDashboardUrls.PassProTravelBehaviorTrendOvertime,
  DeparturePassProTravelProfile: DepartureDashboardUrls.PassProTravelProfile,
  DeparturePassProTravelProfileBySegment: DepartureDashboardUrls.PassProTravelProfileBySegment,
  DeparturePassProTravelProfileTrendOvertime: DepartureDashboardUrls.PassProTravelProfileTrendOvertime,
  DepartureGapAnalysis: DepartureDashboardUrls.GapAnalysis,
  DepartureCustomAnalysis: DepartureDashboardUrls.CustomAnalysis,
  DepartureCommentsAnalysisOverview: DepartureCommentsAnalysisDashboardUrls.CommentsAnalysisOverview,
  DepartureCommentsAnalysisFrequency: DepartureCommentsAnalysisDashboardUrls.CommentsAnalysisFrequency,
  DepartureCommentsAnalysisTrendOverTime: DepartureCommentsAnalysisDashboardUrls.CommentsAnalysisTrendOverTime,
  DepartureCommentsAnalysisWordCloud: DepartureCommentsAnalysisDashboardUrls.CommentsAnalysisWordCloud,
  DepartureDissatisfiedPassengerOverview: DepartureDissatisfiedPassengerDashboardUrls.DissatisfiedPassengerOverview,
  DepartureDissatisfiedPassengerFrequency: DepartureDissatisfiedPassengerDashboardUrls.DissatisfiedPassengerFrequency,
  DepartureDissatisfiedPassengerTrendOverTime:
    DepartureDissatisfiedPassengerDashboardUrls.DissatisfiedPassengerTrendOverTime,
  DepartureDissatisfiedPassengerWordCloud: DepartureDissatisfiedPassengerDashboardUrls.DissatisfiedPassengerWordCloud,
  ArrivalOverview: ArrivalDashboardUrls.Overview,
  CommercialOverview: CommercialDashboardUrls.Overview,
  RepositoryAirportReports: RepositoryPagesUrls.Airport,
  RepositoryMemberReports: RepositoryPagesUrls.Member,
  RepositoryPublicReports: RepositoryPagesUrls.Public,
  TrainingModule: `/${AsqUrlPrefix}/training`,
};

const DepartureUrls: string[] = [
  AsqUrls.DepartureOverview,
  AsqUrls.DeparturePassengerJourney,
  AsqUrls.DepartureBenchmarkingPerf,
  AsqUrls.DepartureBenchmarkingTrendPerf,
  AsqUrls.DeparturePassProBenchmarking,
  AsqUrls.DeparturePassProDemographics,
  AsqUrls.DeparturePassProDemographicsBySegment,
  AsqUrls.DeparturePassProDemographicsTrendOvertime,
  AsqUrls.DeparturePassProTravelBehavior,
  AsqUrls.DeparturePassProTravelBehaviorBySegment,
  AsqUrls.DeparturePassProTravelBehaviorTrendOvertime,
  AsqUrls.DeparturePassProTravelProfile,
  AsqUrls.DeparturePassProTravelProfileBySegment,
  AsqUrls.DeparturePassProTravelProfileTrendOvertime,
  AsqUrls.DepartureGapAnalysis,
  AsqUrls.DepartureCustomAnalysis,
  AsqUrls.DepartureCommentsAnalysisOverview,
  AsqUrls.DepartureCommentsAnalysisFrequency,
  AsqUrls.DepartureCommentsAnalysisTrendOverTime,
  AsqUrls.DepartureCommentsAnalysisWordCloud,
  AsqUrls.DepartureDissatisfiedPassengerOverview,
  AsqUrls.DepartureDissatisfiedPassengerFrequency,
  AsqUrls.DepartureDissatisfiedPassengerTrendOverTime,
  AsqUrls.DepartureDissatisfiedPassengerWordCloud,
];

const ArrivalUrls: string[] = [AsqUrls.ArrivalOverview];

const CommercialUrls: string[] = [AsqUrls.CommercialOverview];

const RepositoryUrls: string[] = [
  AsqUrls.RepositoryAirportReports,
  AsqUrls.RepositoryMemberReports,
  AsqUrls.RepositoryPublicReports,
];

export {
  AsqUrls,
  DepartureUrls,
  DepartureDashboardsRouterPath,
  ArrivalDashboardsRouterPath,
  ArrivalUrls,
  CommercialDashboardsRouterPath,
  CommercialUrls,
  RepositoryRouterPath,
  RepositoryUrls,
};
