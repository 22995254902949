import { ReactElement } from 'react';

import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Platform } from '../../../core/enums/platform.enum';
import { usePlatform } from '../../../core/platform/hooks';

const platforms: Platform[] = [Platform.ASQ, Platform.DDP];

interface PlatformSelectProps {
  onChange: (platform: Platform) => void;
}

export default function PlatformSelect({ onChange }: PlatformSelectProps): ReactElement {
  const { t } = useTranslation('Common');
  const { currentPlatform } = usePlatform();

  const handlePlatformChange = (event: SelectChangeEvent<string>) => {
    const platform = Number(event.target.value) as Platform;
    onChange(platform);
  };

  return (
    <Select value={currentPlatform.toString()} onChange={handlePlatformChange} fullWidth>
      {platforms.map((platform) => (
        <MenuItem key={platform} value={platform}>
          {t(`Platform.${Platform[platform]}`)}
        </MenuItem>
      ))}
    </Select>
  );
}
