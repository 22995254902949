import { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { BasicCardActionProps } from '../../../../common/components/atoms/BasicCardAction';
import BasicCard from '../../../../common/components/molecules/BasicCard';
import PurchaseForAccessDialog from '../../../../common/modules/purchase/components/dialogs/PurchaseForAccessDialog';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import { openPurchaseForAccessDialog } from '../../../../common/modules/purchase/purchaseSlice';
import { useAppDispatch } from '../../../../store';
import { useReportDownload } from '../hooks/useReportDownload';

export interface HistoricalDataCardProps {
  hasAccess?: boolean;
}

export default function HistoricalDataCard(props: HistoricalDataCardProps): ReactElement {
  const { hasAccess } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation('Analyst');
  const { downloadHistoricalDataFile } = useReportDownload();

  const handleOnClick = async () => {
    if (hasAccess) {
      await downloadHistoricalDataFile();
    } else {
      dispatch(openPurchaseForAccessDialog());
    }
  };

  const cardAction: BasicCardActionProps = {
    title: t('HistoricalDataSet.Download'),
    onClick: handleOnClick,
    onClickType: 'promise',
    variant: 'contained',
  };

  return (
    <>
      <PurchaseForAccessDialog moduleKey={ModuleKey.AnalystHistorical} />
      <BasicCard
        image="/images/analyst.svg"
        title={t('HistoricalDataSet.Title')}
        text={t('HistoricalDataSet.Description')}
        action={cardAction}
      />
    </>
  );
}
