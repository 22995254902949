import { ReactElement, useCallback, useEffect, useState } from 'react';

import styled from '@emotion/styled';
import { faAngleDown, faAngleUp } from '@fortawesome/pro-light-svg-icons';
import { Box, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '../../../../common/components/atoms/Button';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import NoFilesFound from '../../../../common/components/atoms/NoFilesFound';
import { TransformedFileDto } from '../entities/File';
import { useAccessibleFilesList } from '../hooks/useAccessibleFilesList';
import SearchedFileRow from './SearchedFileRow';

interface SearchedFileTableProps {
  files: TransformedFileDto[];
  loading: boolean;
  searchTerm: string;
}

export default function SearchedFileTable({ files, loading, searchTerm }: SearchedFileTableProps): ReactElement {
  const [sortValue, setSortValue] = useState('');
  const [sortDescending, setSortDescending] = useState(false);
  const { t } = useTranslation('Library');
  const { accessLevel: userAccessLevel } = useAccessibleFilesList();

  const headCells = [
    {
      id: 'name',
      disablePadding: true,
      label: t('HeaderName'),
      columnWidth: '30%',
      comparer: (a: TransformedFileDto, b: TransformedFileDto) => a.name.localeCompare(b.name),
    },
    {
      id: 'file',
      disablePadding: false,
      label: t('HeaderFile'),
      columnWidth: '10%',
      comparer: (a: TransformedFileDto, b: TransformedFileDto) => a.extension.localeCompare(b.extension),
    },
    {
      id: 'date',
      disablePadding: false,
      label: t('HeaderDate'),
      columnWidth: '12%',
      comparer: (a: TransformedFileDto, b: TransformedFileDto) =>
        new Date(a.lastModified).getTime() - new Date(b.lastModified).getTime(),
    },
    {
      id: 'description',
      disablePadding: false,
      label: t('HeaderDocumentDescription'),
      columnWidth: '36%',
      comparer: (a: TransformedFileDto, b: TransformedFileDto) => a.description.localeCompare(b.description),
    },
  ];

  const setOrderBy = (newSortValue: string) => {
    if (newSortValue === sortValue) {
      setSortDescending(!sortDescending);
    } else {
      setSortDescending(false);
      setSortValue(newSortValue);
    }
  };

  const getHeaderSortIcon = (headerId: string) => {
    if (headerId === sortValue) {
      if (sortDescending) {
        return <FontAwesomeSvgIcon sx={{ marginLeft: 1 }} icon={faAngleUp} color="inherit" />;
      }
      return <FontAwesomeSvgIcon sx={{ marginLeft: 1 }} icon={faAngleDown} color="inherit" />;
    }
    return <SortIcon alt="sort" src="/images/Library/angles-up-down-regular.svg" />;
  };

  useEffect(() => {
    setSortValue('');
    setSortDescending(false);
  }, [searchTerm]);

  const fileAccessibilityComparer = useCallback(
    (a: TransformedFileDto, b: TransformedFileDto) => {
      const aIsPresent = userAccessLevel.includes(a.accessRequirements);
      const bIsPresent = userAccessLevel.includes(b.accessRequirements);
      if (aIsPresent && !bIsPresent) {
        return -1;
      }
      if (!aIsPresent && bIsPresent) {
        return 1;
      }
      return 0;
    },
    [userAccessLevel],
  );

  const sortOrderCoefficient = sortDescending ? -1 : 1;
  const columnComparerFn = headCells.find((a) => a.id === sortValue)?.comparer ?? (() => 0);

  files.sort((a, b) => fileAccessibilityComparer(a, b) || columnComparerFn(a, b) * sortOrderCoefficient);

  return (
    <Box>
      <Typography variant="h5" color="primary">
        {t('SearchResult', { searchResultWord: searchTerm })}
      </Typography>
      <Table aria-label="simple table" sx={{ marginY: 3 }}>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'rgba(0,0,0,0.04)' }}>
            {headCells.map((headCell) => (
              <TableCell key={headCell.id} sx={{ padding: 0, width: headCell.columnWidth }}>
                <Button
                  sx={{ width: '100%', height: '100%', justifyContent: 'left', padding: 2 }}
                  onClick={() => setOrderBy(headCell.id)}
                >
                  <Typography display="flex" fontWeight="bold" alignItems="center">
                    {headCell.label}
                    {getHeaderSortIcon(headCell.id)}
                  </Typography>
                </Button>
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        {loading ? (
          <LinearProgress sx={{ position: 'absolute', width: '100%' }} />
        ) : (
          <TableBody>
            {files.map((file, index) => (
              <TableRow
                key={file.id}
                sx={{
                  backgroundColor: index % 2 === 0 ? 'white' : '',
                }}
              >
                <SearchedFileRow file={file} />
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
      {!loading && files.length === 0 && (
        <NoFilesFound title={t('EmptyMessageTitle')} description={t('EmptyMessageDescription')} />
      )}
    </Box>
  );
}

const SortIcon = styled('img')(() => ({
  height: 15,
  width: 15,
  marginLeft: 12,
}));
