import { ReactElement } from 'react';

import Typography from '@mui/material/Typography';

import { Box } from '@mui/material';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useTranslation } from 'react-i18next';

import Button from '../../../../components/atoms/Button';
import DialogCloseButton from '../../../../components/atoms/DialogCloseButton';

export interface FreeTrialActionDialogProps {
  onClose: (isConfirm: boolean) => void;
  isOpen: boolean;
}

export default function ExitConfirmationDialog(props: FreeTrialActionDialogProps): ReactElement {
  const { onClose, isOpen } = props;
  const { t } = useTranslation('Pricing');

  const handleOnCancel = () => {
    onClose(false);
  };

  const handleOnConfirm = () => {
    onClose(true);
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent sx={{ width: 480 }}>
        <DialogCloseButton closeDialog={handleOnCancel} data-fullstory="ConfirmationDialogClose" />
        <Container sx={{ alignSelf: 'center', px: 0, textAlign: 'center' }}>
          <Typography variant="h4" pt={5} pb={5}>
            {t(`DialogExitConfirmation.ExitTitle`)}
          </Typography>
          <Typography variant="pMedium">{t(`DialogExitConfirmation.Text`)}</Typography>
        </Container>
      </DialogContent>
      <Box sx={{ p: 4, textAlign: 'center' }}>
        <Button
          sx={{ mr: 1, textDecoration: 'underline', minWidth: 110, borderRadius: 0 }}
          variant="text"
          onClick={handleOnCancel}
        >
          {t(`DialogExitConfirmation.Cancel`)}
        </Button>
        <Button variant="contained" onClick={handleOnConfirm} sx={{ minWidth: 110, borderRadius: 0 }}>
          {t(`DialogExitConfirmation.ExitButton`)}
        </Button>
      </Box>
    </Dialog>
  );
}
