import React from 'react';
import { FeatureFlagKey } from './featureFlagKeys';
import { ConditionalRenderer, RendererProps } from '../../common/components/atoms/ConditionalRenderer';
import { useFeatureFlag } from './hooks';

interface FeatureFlagProps extends RendererProps {
  flagKey?: FeatureFlagKey | FeatureFlagKey[];
}

function FeatureFlag({ flagKey, children, fallback, reverse = false }: FeatureFlagProps): React.ReactElement | null {
  const isEnabled = useFeatureFlag(flagKey);

  return (
    <ConditionalRenderer display={isEnabled} fallback={fallback} reverse={flagKey !== undefined && reverse}>
      {children}
    </ConditionalRenderer>
  );
}

function withFeatureFlag<Props>(
  Component: React.FunctionComponent<Props>,
  flagKey: FeatureFlagKey | FeatureFlagKey[],
): React.FunctionComponent<Props & { fallback?: React.ReactElement; reverse?: boolean }> {
  return function FeatureFlagHighOrderComponent({
    fallback,
    reverse = false,
    ...props
  }: Props & { fallback?: React.ReactElement; reverse?: boolean }) {
    return (
      <FeatureFlag flagKey={flagKey} fallback={fallback} reverse={reverse}>
        <Component {...(props as Props & JSX.IntrinsicAttributes)} />
      </FeatureFlag>
    );
  };
}

export { FeatureFlag, withFeatureFlag };
