import { ReactElement, useEffect, useState } from 'react';

import { faArrowToBottom, faCheck, faPencil, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import PaperTable from '../../../../common/components/molecules/PaperTable';
import { ActionDef, HeaderDef, HeaderType, RowDef } from '../../../../common/components/types/TableTypes';
import { useDeleteTemplateMutation } from '../../../../common/hooks/backend';
import { formatDateTranslate } from '../../../../common/utils/date';
import { displaySnackbar } from '../../../../core/notifications/snackbarSlice';
import { useAppDispatch } from '../../../../store';
import { DdpUrls } from '../../urls';
import { changeDownloadAccessDenied, setCurrentTemplate } from '../analystSlice';
import FaqLinkText from '../atoms/FaqLinkText';
import NoAccessAlert from '../atoms/NoAccessAlert';
import PeriodTypeChip from '../atoms/PeriodTypeChip';
import { Template } from '../entities/Template';
import { useReportDownload } from '../hooks/useReportDownload';
import ConfirmationDialog from '../molecules/ConfirmationDialog';

interface TemplateTableData extends Omit<Template, 'lastExtraction' | 'periodType'> {
  periodType: ReactElement;
  lastExtraction: string;
}

interface TemplatePageProps {
  templates: Template[] | undefined;
}

export default function TemplatePage(props: TemplatePageProps): ReactElement {
  const history = useHistory();
  const { templates } = props;
  const { t } = useTranslation(['Analyst', 'Common']);
  const dispatch = useAppDispatch();
  const [deleteTemplate] = useDeleteTemplateMutation();
  const [templateToDelete, setTemplateToDelete] = useState<Template | null>(null);
  const { downloadTemplateFile } = useReportDownload();

  const callbackCloseDialog = (userConfirmation: boolean) => {
    if (userConfirmation && templateToDelete) {
      deleteTemplate(templateToDelete);
      dispatch(
        displaySnackbar({
          message: t('Common:SuccessDelete'),
          severity: 'success',
          icon: <FontAwesomeSvgIcon icon={faCheck} />,
        }),
      );
    }
    setTemplateToDelete(null);
  };

  const headers: HeaderDef<TemplateTableData>[] = [
    {
      id: 'name',
      flexWidth: 30,
      label: t('Template.Headers.Name'),
      key: 'name',
      type: HeaderType.Data,
    },
    {
      id: 'periodType',
      flexWidth: 29.4,
      label: t('Template.Headers.Dataset'),
      key: 'periodType',
      type: HeaderType.Data,
    },
    {
      id: 'lastExtraction',
      flexWidth: 29.4,
      label: t('Template.Headers.LastExtraction'),
      key: 'lastExtraction',
      type: HeaderType.Data,
    },
    {
      id: 'actions',
      flexWidth: 13.6,
      label: t('Template.Headers.Options'),
      maxActions: 3,
      type: HeaderType.Action,
    },
  ];

  const generateTemplateActions = (template: Template): ActionDef[] => {
    const handleDeleteClick = () => {
      setTemplateToDelete(template);
    };

    const handleEditClick = () => {
      dispatch(setCurrentTemplate(template));
      history.push(`${DdpUrls.AnalystModuleReportEdit}${template.id}`);
    };

    const handleDownloadClick = () => downloadTemplateFile(template);

    return [
      {
        id: 'Download',
        toolTip: t('DownloadTooltip'),
        onClick: handleDownloadClick,
        icon: faArrowToBottom,
        fullStoryTag: 'AnalystDownload',
      },
      {
        id: 'Edit',
        toolTip: t('EditTooltip'),
        onClick: handleEditClick,
        icon: faPencil,
        fullStoryTag: 'AnalystEdit',
      },
      {
        id: 'Delete',
        toolTip: t('DeleteTooltip'),
        onClick: handleDeleteClick,
        icon: faTrashAlt,
        fullStoryTag: 'AnalystDelete',
      },
    ];
  };

  const generateTemplateRow = (template: Template): RowDef<TemplateTableData> => ({
    id: template.id ?? '',
    data: {
      ...template,
      periodType: <PeriodTypeChip>{template.periodType}</PeriodTypeChip>,
      lastExtraction: formatDateTranslate(
        t,
        'FormattedDate',
        template.lastExtraction ? new Date(template.lastExtraction) : new Date(),
        'Common',
      ),
    },
    actions: generateTemplateActions(template),
  });

  const mappedTemplates = templates?.map((template) => generateTemplateRow(template));

  useEffect(() => {
    dispatch(changeDownloadAccessDenied(false));
  }, [dispatch]);

  return (
    <>
      <ConfirmationDialog
        isOpen={!!templateToDelete}
        onClose={callbackCloseDialog}
        textKey="DeleteTemplate"
        textArgs={templateToDelete?.name}
      />
      <Stack position="inherit" height="100%" paddingX={3}>
        <Box mb={1}>
          <Button
            component={Link}
            to={DdpUrls.AnalystModuleReportCreation}
            sx={{ px: 3 }}
            variant="contained"
            data-fullstory="AnalystCreateReport"
          >
            {t('Template.CreateButton')}
          </Button>
        </Box>
        <NoAccessAlert />
        <Box flexGrow={1}>
          {mappedTemplates && (
            <PaperTable
              rows={mappedTemplates}
              headers={headers}
              empty={
                <Typography variant="h3" color="primary.main" textAlign="center" marginTop={12}>
                  {t('Template.NoTemplateSaved')}
                </Typography>
              }
            />
          )}
        </Box>
        <FaqLinkText />
      </Stack>
    </>
  );
}
