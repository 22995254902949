import { ModuleKey } from '../../common/modules/purchase/enums/moduleKey';
import { ModuleDescription } from '../../core/entities/moduleDescription';
import { Module } from '../../core/enums/module.enum';
import { DdpUrls } from './urls';

const ExecutiveModule: ModuleDescription = {
  url: DdpUrls.ExecutiveModuleReports,
  name: Module.Executive,
  translateKey: 'Executive',
  purchaseLink: `${process.env.REACT_APP_COMMERCE_BASE_URL}product/executive-module/`,
  moduleKey: ModuleKey.Executive,
};

const LibraryModule: ModuleDescription = {
  url: DdpUrls.LibraryModule,
  name: Module.Library,
  translateKey: 'Library',
  purchaseLink: `${process.env.REACT_APP_COMMERCE_BASE_URL}product/library-module/`,
  moduleKey: ModuleKey.Library,
};

const AnalystModule: ModuleDescription = {
  url: DdpUrls.AnalystModuleReportCreation,
  name: Module.Analyst,
  translateKey: 'Analyst',
  purchaseLink: `${process.env.REACT_APP_COMMERCE_BASE_URL}product/analyst-module/`,
  moduleKey: ModuleKey.Analyst,
};

const BenchmarkModule: ModuleDescription = {
  url: DdpUrls.BenchmarkModule,
  name: Module.Benchmark,
  translateKey: 'Benchmark',
  comingSoon: true,
};

const ddpModules: ModuleDescription[] = [ExecutiveModule, LibraryModule, AnalystModule, BenchmarkModule];

export { AnalystModule, BenchmarkModule, ExecutiveModule, LibraryModule, ddpModules };
