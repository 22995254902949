import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useFreeTrialAvailabilityQuery, useGetAllPricingProductsQuery } from '../../../../hooks/usePricingEndpoints';
import { openFreeTrialDialog, openUpgradeToFullPlanDialog } from '../../purchaseSlice';
import FreeTrialDialog from '../dialogs/FreeTrialDialog';
import UpgradeToFullPlanDialog from '../dialogs/UpgradeToFullPlanDialog';

const FreeTrialButton = () => {
  const { t } = useTranslation('Navigation');
  const { data: freeTrialAvailabilityData, isLoading: isFreeTrialDataLoading } = useFreeTrialAvailabilityQuery();
  const { data: pricingProducts, isLoading: isPricingProductsLoading } = useGetAllPricingProductsQuery();
  const dispatch = useAppDispatch();
  const { isUpgradeToFullPlanDialogOpen, isFreeTrialDialogOpen } = useAppSelector((state) => state.purchase);

  const freeTrialButtonText = freeTrialAvailabilityData?.upgradeAvailable ? t(`Upgrade`) : t(`FreeTrial`);

  if (
    isFreeTrialDataLoading ||
    isPricingProductsLoading ||
    (!freeTrialAvailabilityData?.freeTrialAvailable && !isUpgradeToFullPlanDialogOpen)
  ) {
    return null;
  }

  const handleOpenDialog = () => {
    const freeTrialProduct = pricingProducts?.find(
      (product) => product.priceId === freeTrialAvailabilityData?.freeTrialPriceId,
    );
    if (freeTrialProduct) {
      if (freeTrialAvailabilityData?.upgradeAvailable) {
        dispatch(openUpgradeToFullPlanDialog(freeTrialProduct));
      } else {
        dispatch(openFreeTrialDialog(freeTrialProduct));
      }
    }
  };

  return (
    <>
      {freeTrialAvailabilityData?.upgradeAvailable && (
        <Typography mr={1}>{t('FreeTrialDaysLeft', { count: freeTrialAvailabilityData?.daysRemaining })}</Typography>
      )}
      {isFreeTrialDialogOpen && <FreeTrialDialog />}
      {isUpgradeToFullPlanDialogOpen && <UpgradeToFullPlanDialog />}

      {freeTrialAvailabilityData?.freeTrialAvailable && (
        <Button
          sx={{
            borderRadius: '0px',
            height: '100%',
          }}
          variant="gradient"
          onClick={handleOpenDialog}
        >
          {freeTrialButtonText}
        </Button>
      )}
    </>
  );
};

export default FreeTrialButton;
