import fileDownload from 'js-file-download';
import JSZip from 'jszip';

import { PromiseProgress, promisesWithProgress } from '../../../../common/utils/promise';
import { FileId } from '../entities/BaseFile';
import { FileBlobData } from './downloadFile';

export enum BulkDownloadState {
  DownloadFiles,
  ZipFiles,
}

export interface BulkDownloadProgress extends PromiseProgress {
  state: BulkDownloadState;
}

export interface BulkDownloadError {
  state: BulkDownloadState;
  error: any;
}

export type BulkFileBlobDownloader = (ids: FileId[]) => Promise<FileBlobData>[];
export type BulkDownloadProgressHandler = (progress: BulkDownloadProgress) => void;
export type BulkDownloadSuccessHandler = (zipFilename: string, fileIds: FileId[]) => void;
export type BulkDownloadErrorHandler = (error: BulkDownloadError) => void;

export function bulkDownloadFiles(
  zipFilename: string,
  fileIds: FileId[],
  bulkFileBlobDownloader: BulkFileBlobDownloader,
  onProgressChange?: BulkDownloadProgressHandler,
  onSuccess?: BulkDownloadSuccessHandler,
  onError?: BulkDownloadErrorHandler,
): () => void {
  onProgressChange?.({
    resolved: 0,
    rejected: 0,
    total: fileIds.length,
    state: BulkDownloadState.DownloadFiles,
  });

  const promises = bulkFileBlobDownloader(fileIds);

  const onDownloadFileProgressChange = (progress: PromiseProgress) => {
    onProgressChange?.({ ...progress, state: BulkDownloadState.DownloadFiles });
  };

  const { promise, cancel, isCancelled } = promisesWithProgress(promises, onDownloadFileProgressChange);

  promise
    .then((values) => {
      onProgressChange?.({
        resolved: fileIds.length,
        rejected: 0,
        total: fileIds.length,
        state: BulkDownloadState.ZipFiles,
      });

      const zip = new JSZip();

      values.forEach(({ data, filename, folder }) => {
        const path = folder ? `${zipFilename}/${folder}/${filename}` : `${zipFilename}/${filename}`;
        zip.file(path, data);
      });

      zip
        .generateAsync({ type: 'blob' })
        .then((blob) => {
          if (!isCancelled()) {
            const zipFilenameWithExt = `${zipFilename}.zip`;
            fileDownload(blob, zipFilenameWithExt);
            onSuccess?.(zipFilenameWithExt, fileIds);
          }
        })
        .catch((error) => {
          if (!isCancelled()) {
            onError?.({
              state: BulkDownloadState.ZipFiles,
              error,
            });
          }
        });
    })
    .catch((error) => {
      if (!isCancelled()) {
        onError?.({
          state: BulkDownloadState.DownloadFiles,
          error,
        });
      }
    });

  return cancel;
}

export function getDatedFilename(prefix: string): string {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${prefix} ${year}-${month}-${day}`;
}
