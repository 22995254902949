import { ModuleDescription } from '../../core/entities/moduleDescription';
import { Module } from '../../core/enums/module.enum';
import { AsqUrls } from './urls';

const DepartureModule: ModuleDescription = {
  url: AsqUrls.DepartureOverview,
  name: Module.Departure,
  translateKey: 'Departure',
};

const ArrivalModule: ModuleDescription = {
  url: AsqUrls.ArrivalOverview,
  name: Module.Arrival,
  translateKey: 'Arrival',
};

const CommercialModule: ModuleDescription = {
  url: AsqUrls.CommercialOverview,
  name: Module.Commercial,
  translateKey: 'Commercial',
};

const TrainingModule: ModuleDescription = {
  url: AsqUrls.TrainingModule,
  name: Module.Training,
  translateKey: 'Training',
  comingSoon: true,
};

const asqModules: ModuleDescription[] = [DepartureModule, ArrivalModule, CommercialModule, TrainingModule];

export { DepartureModule, ArrivalModule, CommercialModule, TrainingModule, asqModules };
