import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Accordion, AccordionSummary } from '@mui/material';
import MuiListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { NavItemProps, NavItemType } from '../../types/NavItem';
import DrawerAccordionDetails from './DrawerAccordionDetails';
import { FontAwesomeSvgIcon } from './FontAwesomeIcon';

type DrawerLinkVariant = 'small' | 'large';

const StyledDrawerLink = styled(MuiListItem, {
  shouldForwardProp: (prop) => !['variant'].includes(String(prop)),
})<{ variant: DrawerLinkVariant }>(({ theme, variant }) => ({
  padding: theme.spacing(0),
  '& .MuiAccordion-root': {
    width: '100%',
    backgroundColor: 'unset',

    '& .MuiTypography-root, & .MuiSvgIcon-root': {
      color: 'white',
    },
    '& .MuiAccordionSummary-root': {
      padding: theme.spacing(variant === 'large' ? 2 : 0.5, 3),
      minHeight: 0,

      '& .MuiAccordionSummary-content': {
        margin: 0,
        alignItems: 'center',
        '& .MuiListItemIcon-root': {
          minWidth: 0,
          justifyContent: 'center',
          '& .MuiSvgIcon-root': {
            fontSize: 24,
          },
        },
        '& .MuiListItemText-root': {
          marginLeft: theme.spacing(1),
        },
      },
      '& .MuiAccordionSummary-expandIconWrapper .MuiListItemIcon-root': {
        minWidth: 24,
        maxWidth: 24,
      },
    },
  },
  '&:hover':
    variant === 'large'
      ? {
          backgroundColor: theme.palette.primary.light,
          '& .MuiTypography-root, & .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
          '& .MuiAccordionSummary-content .MuiTypography-root': {
            fontWeight: 'bold',
          },
        }
      : {
          '& .MuiTypography-root': {
            fontWeight: 'bold',
          },
        },

  '&.selected':
    variant === 'large'
      ? {
          backgroundColor: theme.palette.primary.light,
          '&:hover': {
            backgroundColor: theme.palette.primary.light,
          },
          '& .MuiTypography-root, & .MuiSvgIcon-root': {
            color: theme.palette.primary.main,
          },
          '& .MuiAccordionSummary-content .MuiTypography-root': {
            fontWeight: 'bold',
          },
        }
      : {
          '& .MuiTypography-root': {
            fontWeight: 'bold',
          },
        },
}));

export interface DrawerLinkProps {
  navItem: NavItemProps;
  onClick: () => void;
  drawerExpanded: boolean;
  variant: DrawerLinkVariant;
}

function DrawerLink({ navItem, drawerExpanded, onClick, variant }: DrawerLinkProps) {
  const history = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation('Navigation');
  const navSubItemSelected = (url: string) => pathname.endsWith(url);
  const navItemSelected =
    (navItem.url && pathname.endsWith(navItem.url)) || !!navItem.items?.some((i) => navSubItemSelected(i.url));
  const [accordionExpanded, setAccordionExpanded] = useState(navItemSelected);

  useEffect(() => {
    if (navItemSelected && !accordionExpanded) {
      setAccordionExpanded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleClick = () => {
    if (navItem.type === NavItemType.Link || (navItem.type === NavItemType.Menu && !drawerExpanded)) {
      openUrl(navItem.url);
    } else {
      setAccordionExpanded(!accordionExpanded);
    }
  };

  const openUrl = (url: string) => {
    onClick();
    history.push(url);
  };

  return (
    <StyledDrawerLink variant={variant} className={[navItemSelected && 'selected'].filter((x) => !!x).join(' ')}>
      <Accordion disableGutters expanded={accordionExpanded} defaultExpanded={navItemSelected}>
        <AccordionSummary
          onClick={handleClick}
          expandIcon={
            navItem.type === NavItemType.Menu &&
            drawerExpanded && (
              <ListItemIcon>
                <FontAwesomeSvgIcon icon={faChevronDown} />
              </ListItemIcon>
            )
          }
        >
          <ListItemIcon>
            <navItem.Icon />
          </ListItemIcon>
          {drawerExpanded && (
            <ListItemText
              data-fullstory={`LeftNav${navItem.translateKey}`}
              primary={<Typography variant="pMedium">{t(`${navItem.translateKey}`)}</Typography>}
            />
          )}
        </AccordionSummary>
        {drawerExpanded && navItem.type === NavItemType.Menu && (
          <DrawerAccordionDetails navItem={navItem} openUrl={openUrl} navSubItemSelected={navSubItemSelected} />
        )}
      </Accordion>
    </StyledDrawerLink>
  );
}

export default DrawerLink;
