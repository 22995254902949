enum FeatureFlagKey {
  FullMaintenanceMode = 'FullMaintenanceMode',
  DDPMaintenanceMode = 'DDPMaintenanceMode',
  ASQMaintenanceMode = 'ASQMaintenanceMode',
  PreReleaseBanner = 'PreReleaseBanner',
  HomeDeparture = 'HomeDeparture',
  HomeArrival = 'HomeArrival',
  HomeCommercial = 'HomeCommercial',
  HomeRepository = 'HomeRepository',
  DepartureReports = 'DepartureReports',
  ArrivalReports = 'ArrivalReports',
  CommercialReports = 'CommercialReports',
  RepositoryAirport = 'RepositoryAirport',
  RepositoryMember = 'RepositoryMember',
  RepositoryPublic = 'RepositoryPublic',
  TrainingModule = 'TrainingModule',
  DppReports = 'DppReports',
}

const AsqHomeFlagKeys: FeatureFlagKey[] = [
  FeatureFlagKey.HomeDeparture,
  FeatureFlagKey.HomeArrival,
  FeatureFlagKey.HomeCommercial,
  FeatureFlagKey.HomeRepository,
];

const AsqDepartureModuleFlagKeys: FeatureFlagKey[] = [FeatureFlagKey.DepartureReports];

const AsqArrivalModuleFlagKeys: FeatureFlagKey[] = [FeatureFlagKey.ArrivalReports];

const AsqCommercialModuleFlagKeys: FeatureFlagKey[] = [FeatureFlagKey.CommercialReports];

const AsqRepositoryModuleFlagKeys: FeatureFlagKey[] = [
  FeatureFlagKey.RepositoryAirport,
  FeatureFlagKey.RepositoryMember,
  FeatureFlagKey.RepositoryPublic,
];

export {
  FeatureFlagKey,
  AsqHomeFlagKeys,
  AsqDepartureModuleFlagKeys,
  AsqArrivalModuleFlagKeys,
  AsqCommercialModuleFlagKeys,
  AsqRepositoryModuleFlagKeys,
};
