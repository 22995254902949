import { ModuleAccess } from '../../../../common/modules/purchase/entities/ModuleAccess';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import { PricingProduct } from '../entities/PricingProduct';
import { ProductPurchaseAction } from '../enums/ProductPurchaseAction.enum';
import { ProductRecurringInterval } from '../enums/ProductReccuringInterval';

export const getProductPurchaseAction = (
  product: PricingProduct,
  allProducts: PricingProduct[],
  moduleAccesses: ModuleAccess[],
): ProductPurchaseAction => {
  const subscriptionForCurrentProduct = moduleAccesses.find((s) => s.priceId === product.priceId);
  const isCurrentProductInFreeTrial = subscriptionForCurrentProduct?.status === ModuleAccessStatus.Trialing;

  const isCurrentProductABundle = !!product.includedProducts;
  const activeBundleAccesses = moduleAccesses.filter((s) => s.isBundleAccess);
  const isABundleBought = activeBundleAccesses.some((a) => a.recurringInterval === product.recurringInterval);
  const isBundleInFreeTrial = product.includedProducts?.every((p) =>
    moduleAccesses.some((s) => s.priceId === p.priceId && s.status === ModuleAccessStatus.Trialing),
  );

  if ((isCurrentProductInFreeTrial && !isABundleBought) || (isCurrentProductABundle && isBundleInFreeTrial)) {
    return ProductPurchaseAction.UpgradeToFullPlan;
  }

  const anyIndividualProductBought = moduleAccesses.some((a) => a.recurringInterval === product.recurringInterval);
  const canUserUpgradeToBundle = isCurrentProductABundle && !isABundleBought && anyIndividualProductBought;

  if (canUserUpgradeToBundle) {
    return ProductPurchaseAction.UpgradeToBundle;
  }

  const isProductMonthly = product.recurringInterval === ProductRecurringInterval.Monthly;
  if (isProductMonthly) {
    const annualPriceForSameProduct = allProducts.find(
      (a) => a.id === product.id && a.recurringInterval === ProductRecurringInterval.Yearly,
    );
    const isAnnualPricePurchased = moduleAccesses.some((a) => a.priceId === annualPriceForSameProduct?.priceId);

    if (isAnnualPricePurchased) {
      return ProductPurchaseAction.CurrentlyActiveAnnual;
    }

    const canCurrentProductBeUpgradedToAnnual =
      !!subscriptionForCurrentProduct && !isAnnualPricePurchased && !isABundleBought;
    if (canCurrentProductBeUpgradedToAnnual) {
      return ProductPurchaseAction.UpgradeToAnnual;
    }
  }

  const isCurrentProductAPurchasedBundle = isCurrentProductABundle && isABundleBought;
  const isCurrentProductPurchased = !!subscriptionForCurrentProduct || isCurrentProductAPurchasedBundle;
  if (isCurrentProductPurchased) {
    return ProductPurchaseAction.CurrentlyActive;
  }

  return ProductPurchaseAction.BuyNow;
};
