import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';

interface PageProps {
  module: string;
}

export default function ModuleMaintenancePage({ module }: PageProps): ReactElement {
  return (
    <Stack height="100%" justifyContent="center" alignItems="center" spacing={5}>
      <img alt="" src="images/Locked.png" width="200px" />

      <Typography textAlign="center" variant="h2" fontWeight="800" color="primary">
        {module} is under maintenance, please come back later.
      </Typography>
      <Typography textAlign="center" variant="h4" fontWeight="200" color="primary" m={0}>
        We apologize for any inconvenience. The maintenance period should be short, but please contact us at&nbsp;
        <a href="mailto:asqsupport@aci.aero">asqsupport@aci.aero</a> for urgent needs.
      </Typography>
    </Stack>
  );
}
