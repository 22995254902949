import { ReactElement } from 'react';

import { styled } from '@material-ui/system';
import { Divider, Toolbar } from '@mui/material';
import RepositoryTabs from '../atoms/RepositoryTabs';

export const RepositoryTopBarHeightThemeSpacing = 5;

export default function RepositoryTopBar(): ReactElement {
  return (
    <>
      <Divider />
      <StyledToolbar disableGutters>
        <RepositoryTabs />
      </StyledToolbar>
      <Divider />
    </>
  );
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: theme.spacing(RepositoryTopBarHeightThemeSpacing),
  padding: theme.spacing(0, 3),
  backgroundColor: 'white',
}));
