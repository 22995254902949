import { RouteDefinition } from '../../core/entities/routeDefinition';
import AnalystHistoricalContainer from './analyst/containers/AnalystHistoricalContainer';
import AnalystTemplatesContainer from './analyst/containers/AnalystTemplatesContainer';
import ReportPage from './analyst/page/ReportPage';
import BenchmarkContainer from './benchmarking/container/BenchmarkContainer';
import ExecutiveContainer from './executive/container/ExecutiveContainer';
import ExecutiveReportPage from './executive/pages/ExecutiveReportPage';
import FaqPage from './faq/pages/FaqPage';
import HomeContainer from './home/containers/HomeContainer';
import LibraryContainer from './library/container/LibraryContainer';
import PricingPage from './pricing/page/PricingPage';
import { DdpUrls } from './urls';

const HomeRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Home',
  path: DdpUrls.Home,
  Component: HomeContainer,
};

const ExecutiveModuleRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Executive',
  path: DdpUrls.ExecutiveModuleReports,
  Component: ExecutiveContainer,
};

const ExecutiveModuleReportRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Executive',
  path: `${DdpUrls.ExecutiveModuleReport}:id`,
  Component: ExecutiveReportPage,
};

const LibraryModuleRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Library',
  path: DdpUrls.LibraryModule,
  Component: LibraryContainer,
};

const LibraryModuleTrafficRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Library',
  path: DdpUrls.LibraryModuleTraffic,
  Component: LibraryContainer,
};

const LibraryModuleForecastRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Library',
  path: DdpUrls.LibraryModuleForecast,
  Component: LibraryContainer,
};

const LibraryModuleEconomicsRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Library',
  path: DdpUrls.LibraryModuleEconomics,
  Component: LibraryContainer,
};

const LibraryModuleEnvironmentSustainabilityRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Library',
  path: DdpUrls.LibraryModuleEnvironmentSustainability,
  Component: LibraryContainer,
};

const AnalystModuleReportCreationRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Analyst',
  path: DdpUrls.AnalystModuleReportCreation,
  Component: ReportPage,
};

const AnalystModuleHistoricalRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Analyst',
  path: DdpUrls.AnalystModuleHistoricalData,
  Component: AnalystHistoricalContainer,
};

const AnalystModuleReportEditRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Analyst',
  path: `${DdpUrls.AnalystModuleReportEdit}:id`,
  Component: ReportPage,
};

const AnalystModuleTemplatesRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Analyst',
  path: DdpUrls.AnalystModuleTemplates,
  Component: AnalystTemplatesContainer,
};

const BenchmarkModuleRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Benchmark',
  path: DdpUrls.BenchmarkModule,
  Component: BenchmarkContainer,
};

const FaqRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | FAQ',
  path: DdpUrls.Faq,
  Component: FaqPage,
};

const PricingRoute: RouteDefinition = {
  title: 'ACI Intelligence Hub | Pricing',
  path: DdpUrls.Pricing,
  Component: PricingPage,
};

const routes: RouteDefinition[] = [
  HomeRoute,
  ExecutiveModuleRoute,
  LibraryModuleRoute,
  LibraryModuleTrafficRoute,
  LibraryModuleForecastRoute,
  LibraryModuleEconomicsRoute,
  LibraryModuleEnvironmentSustainabilityRoute,
  AnalystModuleReportCreationRoute,
  AnalystModuleHistoricalRoute,
  AnalystModuleReportEditRoute,
  AnalystModuleTemplatesRoute,
  BenchmarkModuleRoute,
  FaqRoute,
  PricingRoute,
  ExecutiveModuleReportRoute,
];

export default routes;
