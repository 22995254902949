import { AuthError, AuthenticationResult, EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { StringDict } from '@azure/msal-common';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { Suspense, useEffect } from 'react';
import CacheBuster from 'react-cache-buster';
import { HelmetProvider } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import FullStoryTracking from './FullStoryTracking';
import Layout from './common/components/pages/Layout';
import FreeTrialInformationPopup from './common/modules/purchase/components/molecules/FreeTrialInformationPopup';
import { isStagingOrProduction } from './common/utils/env';
import { msalInstance } from './core/Auth/MsalInstance';
import { GlobalErrorBoundaryWithRouter } from './core/Errors/GlobalErrorBoundary';
import { FeatureFlagContextProvider } from './core/featureFlags/context';
import SnackbarProvider from './core/notifications/SnackbarProvider';
import { PlatformProvider } from './core/platform/PlatformProvider';
import { updateIdClaims } from './modules/common/profile/profileSlice';
import TermsAndConditionsDialog from './modules/common/termsAndConditions/molecules/TermsAndConditionsDialog';
import { useAppDispatch } from './store';

function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (location.pathname !== '/redirect') {
      window.sessionStorage.setItem('redirect_route', JSON.stringify(location));
    }
  }, [location]);

  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((message: EventMessage) => {
      if (message.eventType === EventType.LOGIN_FAILURE) {
        if (message.error instanceof AuthError) {
          msalInstance.loginRedirect();
        }
      }

      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const payload = message.payload as AuthenticationResult;
        const claims = payload?.idTokenClaims as StringDict;
        dispatch(updateIdClaims(claims));
      }
    });

    return () => {
      if (callbackId !== null) msalInstance.removeEventCallback(callbackId);
    };
  }, [dispatch]);

  return (
    <HelmetProvider>
      <GlobalErrorBoundaryWithRouter>
        <CacheBuster
          currentVersion={process.env.REACT_APP_VERSION}
          isEnabled={isStagingOrProduction()}
          isVerboseMode={false}
          loadingComponent={<Suspense fallback="loading..." />} // If not pass, nothing appears at the time of new version check.
        >
          <MsalProvider instance={msalInstance}>
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
              <FullStoryTracking />
              <Suspense fallback="loading...">
                <FeatureFlagContextProvider fallback="loading...">
                  <PlatformProvider>
                    <TermsAndConditionsDialog />
                    <FreeTrialInformationPopup />
                    <SnackbarProvider />
                    <Layout />
                  </PlatformProvider>
                </FeatureFlagContextProvider>
              </Suspense>
            </MsalAuthenticationTemplate>
          </MsalProvider>
        </CacheBuster>
      </GlobalErrorBoundaryWithRouter>
    </HelmetProvider>
  );
}

export default App;
