import { ReactElement } from 'react';

import IconButton from '@mui/material/IconButton';

import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeSvgIcon } from './FontAwesomeIcon';

export interface DialogCloseButtonProps {
    closeDialog: () => void;
    'data-fullstory': string;
}

export default function DialogCloseButton(props: DialogCloseButtonProps): ReactElement {
  const { closeDialog, 'data-fullstory': dataFullStory } = props;

  return (
    <IconButton
      sx={{ float: 'right' }}
      aria-label="close"
      onClick={closeDialog}
      data-fullstory={dataFullStory}>
        <FontAwesomeSvgIcon fontSize="inherit" icon={faTimes} />
    </IconButton>
  );
}