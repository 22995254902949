import { ReactElement } from 'react';

import { faCalendarDay } from '@fortawesome/pro-light-svg-icons';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { formatPeriodDate } from '../../../../common/utils/date';
import { PeriodDate } from '../../../../core/entities/periodDate';

interface TilePeriodProps {
  date?: PeriodDate | null;
  isLoading: boolean;
}

export default function TilePeriod({ date, isLoading }: TilePeriodProps): ReactElement {
  return (
    <>
      <Typography component="div" variant="pMedium" color="primary">
        <Stack direction="row">
          <FontAwesomeSvgIcon icon={faCalendarDay} fontSize="small" />
          <Box sx={{ pl: 1.5, pr: 0.5 }}>
            <TilePeriodData date={date} isLoading={isLoading} />
          </Box>
        </Stack>
      </Typography>
    </>
  );
}

function TilePeriodData({ date, isLoading }: TilePeriodProps): ReactElement {
  const { t } = useTranslation('Home', { keyPrefix: 'ASQ' });

  if (isLoading) return <Skeleton width="100px" />;
  if (date === undefined || date === null) return <>{t('Cards.Period.NoData')}</>;
  return (
    <>
      {formatPeriodDate(date)} {t('Cards.Period.Help')}
    </>
  );
}
