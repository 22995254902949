import { ReactElement } from 'react';

import { Box, Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { Comparer, HeaderDef, RowDef } from '../../../../common/components/types/TableTypes';
import { LayoutPaddingBox } from '../../../../common/constants/margin';
import { themeSpacingToPx } from '../../../../common/utils/theme';
import BulkDownloadButton from '../atoms/BulkDownloadButton';
import { useBulkDownload } from '../core/hooks/bulkDownload';
import BaseFile from '../entities/BaseFile';
import { FileFilterBase } from '../types/FileFilter';
import { BulkFileBlobDownloader } from '../utils/bulkDownload';
import DetailsDrawer, { DetailsDrawerProps } from './DetailsDrawer';
import FileFilters from './FileFilters';
import FileTable from './FileTable';

export interface FileExplorerProps<File extends BaseFile, FileData> {
  files: File[] | undefined;
  totalFilesCount: number;
  isFilesLoading: boolean;
  headers: HeaderDef<FileData>[];
  generateRow: (file: File) => RowDef<FileData>;
  defaultComparer?: Comparer<FileData>;
  bulkFileBlobDownloader: BulkFileBlobDownloader;
  filters: FileFilterBase[];
  resetFilters: () => void;
  emptyFileTableComponent?: React.ReactElement;
  detailsDrawerProps: DetailsDrawerProps;
}

export default function FileExplorer<File extends BaseFile, FileData>({
  files,
  totalFilesCount,
  isFilesLoading,
  headers,
  generateRow,
  defaultComparer,
  bulkFileBlobDownloader,
  filters,
  resetFilters,
  emptyFileTableComponent,
  detailsDrawerProps,
}: FileExplorerProps<File, FileData>): ReactElement {
  const { t } = useTranslation('Repository');

  const { selectedFilesCount, setSelectedFiles, started, progress, startDownload } =
    useBulkDownload(bulkFileBlobDownloader);

  const filteredFilesCount = files?.length;

  let fileCountLabel: string = '';

  if (filteredFilesCount !== undefined) {
    fileCountLabel = t('FileExplorer.FileCount', {
      count: filteredFilesCount,
      total: totalFilesCount,
    });
  }

  return (
    <>
      <FileFilters resetFilters={resetFilters} filters={filters} />
      <Stack direction="row" alignItems="center" justifyContent="space-between" mx={LayoutPaddingBox + 2} my={2}>
        <Box>
          <Typography variant="pMedium" color="primaryText.800">
            {!isFilesLoading && files !== undefined && fileCountLabel}
          </Typography>
        </Box>
        <BulkDownloadButton
          fileCount={selectedFilesCount}
          progress={progress}
          isLoading={started}
          disabled={isFilesLoading}
          onClick={startDownload}
        />
      </Stack>
      <MarginApplier>
        <FileTable
          files={files}
          headers={headers}
          generateRow={generateRow}
          onSelectedFilesChange={setSelectedFiles}
          defaultComparer={defaultComparer}
          emptyComponent={emptyFileTableComponent}
          isLoading={isFilesLoading}
        />
      </MarginApplier>
      <DetailsDrawer {...detailsDrawerProps} />
    </>
  );
}

const MarginApplier = styled('div')(({ theme }) => ({
  height: `calc(100% - ${themeSpacingToPx(25)}px)`,
  width: `calc(100%)`,
  padding: theme.spacing(0, LayoutPaddingBox, LayoutPaddingBox),
}));
