import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Platform } from '../enums/platform.enum';

export interface PlatformState {
  currentPlatform: Platform;
}

export const defaultPlatform = Platform.ASQ;

export const initialState: PlatformState = { currentPlatform: defaultPlatform };

// Actions
const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    togglePlatform: ({ currentPlatform }) => ({
      currentPlatform: currentPlatform === Platform.DDP ? Platform.ASQ : Platform.DDP,
    }),
    setAsqPlatform: () => ({
      currentPlatform: Platform.ASQ,
    }),
    setDdpPlatform: () => ({
      currentPlatform: Platform.DDP,
    }),
    setCurrentPlatform: (_, { payload }: PayloadAction<Platform>) => ({
      currentPlatform: payload,
    }),
  },
});

export const { togglePlatform, setAsqPlatform, setDdpPlatform, setCurrentPlatform } = platformSlice.actions;

export default platformSlice.reducer;
