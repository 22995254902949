export type FileId = string;

interface BaseFile {
  id: FileId;
  name: string;
  extension: string;
  year: string;
  period: string;
  category: string;
  description: string | null;
  lastModified: Date;
  publishedDate: Date;
  size: string;
}

export default BaseFile;

export const DefaultYear = 'No year';
export const DefaultPeriod = 'No period';
export const DefaultCategory = 'Unknown';
export const NoTitle = 'No title';
