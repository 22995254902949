/* eslint-disable @typescript-eslint/no-shadow */
import { styled } from '@mui/material/styles';
import { ReactElement, useEffect, useState } from 'react';

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MuiStepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { v4 as Guid } from 'uuid';

import { Box, Stack } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import {
  useCreateTemplateMutation,
  useTemplateQuery,
  useUpdateTemplateMutation,
} from '../../../../common/hooks/backend';
import { useModuleAccessesQuery } from '../../../../common/hooks/usePricingEndpoints';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { DdpUrls } from '../../urls';
import { changeActiveStep, newReport, setCurrentTemplate } from '../analystSlice';
import FaqLinkText from '../atoms/FaqLinkText';
import { Steps } from '../enums/steps.enum';
import TimeFetchingType from '../enums/timeFetchingType.enum';
import { useAggregationFilters } from '../hooks/useAggregationFilters';
import AggregationStep from '../molecules/AggregationStep';
import ConfirmationDialog from '../molecules/ConfirmationDialog';
import DatasetStep from '../molecules/DatasetStep';
import MetricsStep from '../molecules/MetricsStep';
import OptionsAndPreviewStep from '../molecules/OptionsAndPreviewStep';
import ReportActions from '../molecules/ReportActions';
import ReportTitleDialog from '../molecules/ReportTitleDialog';

interface ParamProps {
  id: string;
}

export default function ReportPage(): ReactElement {
  const { t } = useTranslation(['Analyst', 'Common']);
  const history = useHistory();
  const { data: templates, isLoading } = useTemplateQuery();
  const params: ParamProps = useParams();
  const selectedTemplate = templates?.find((t) => t.id === params.id);
  const { currentStep } = useAppSelector((state) => state.analyst);
  const [isTemplateNameDialogOpen, setIsTemplateNameDialogOpen] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const { currentTemplate } = useAppSelector((state) => state.analyst);
  const [createTemplate] = useCreateTemplateMutation();
  const [updateTemplate] = useUpdateTemplateMutation();
  const { getAggregationLevel } = useAggregationFilters();
  const dispatch = useAppDispatch();
  const { data: access } = useModuleAccessesQuery();
  const isFreeTrialAccess =
    access?.find((a) => a.moduleKey === ModuleKey.Analyst)?.status === ModuleAccessStatus.Trialing;
  const minimumDate = isFreeTrialAccess ? new Date(2020, 11, 31) : new Date(2018, 11, 31);
  const maximumDate = isFreeTrialAccess ? new Date(2021, 11, 31) : new Date();
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [allowLeaving, setAllowLeaving] = useState(false);
  const [redirectionPath, setRedirectionPath] = useState('');

  const steps = [
    {
      label: 'Dataset',
      value: Steps.Dataset,
      fullStoryTag: 'AnalystStepperDataset',
      isErrored:
        (currentTemplate.nbOfLastMonths >
          maximumDate.getMonth() -
            minimumDate.getMonth() +
            12 * (maximumDate.getFullYear() - minimumDate.getFullYear()) -
            1 &&
          currentTemplate.timeFetchingType === TimeFetchingType.Latest) ||
        (currentTemplate.id && !currentTemplate.name) ||
        ((new Date(currentTemplate.startYear) < minimumDate ||
          new Date(currentTemplate.endYear) < minimumDate ||
          new Date(currentTemplate.startYear) > maximumDate ||
          new Date(currentTemplate.endYear) > maximumDate) &&
          currentTemplate.timeFetchingType === TimeFetchingType.From),
    },
    {
      label: 'Aggregation',
      value: Steps.Aggregation,
      fullStoryTag: 'AnalystStepperAggregation',
      isErrored: false,
    },
    {
      label: 'Metrics',
      value: Steps.Metrics,
      fullStoryTag: 'AnalystStepperMetrics',
      isErrored: currentTemplate.metrics.length === 0,
    },
    {
      label: 'OptionsAndPreview',
      value: Steps.OptionsAndPreview,
      fullStoryTag: 'AnalystStepperOptionsAndPreview',
      isErrored: false,
    },
  ];

  const handleNext = () => {
    setIsValidated(true);
    if (currentStep < Steps.OptionsAndPreview && !steps[currentStep].isErrored) {
      dispatch(changeActiveStep(currentStep + 1));
      setIsValidated(false);
    }
  };

  const handleBack = () => {
    setIsValidated(true);
    if (currentStep > Steps.Dataset && !steps[currentStep].isErrored) {
      dispatch(changeActiveStep(currentStep - 1));
      setIsValidated(false);
    }
  };

  const handleGoto = (step: Steps) => {
    if (steps[currentStep].isErrored) {
      setIsValidated(true);
    } else {
      dispatch(changeActiveStep(step));
      setIsValidated(false);
    }
  };

  const handleOnCancelTemplateNameDialog = () => {
    setIsTemplateNameDialogOpen(false);
  };

  const handleFinish = () => {
    if (currentTemplate.id) handleEditTemplate();
    else setIsTemplateNameDialogOpen(true);
  };

  const handleSaveTemplate = () => {
    createTemplate({ ...currentTemplate, id: Guid(), aggregationLevel: getAggregationLevel() });
    setAllowLeaving(true);
    setIsTemplateNameDialogOpen(false);
    history.push(DdpUrls.AnalystModuleTemplates);
  };

  const handleEditTemplate = () => {
    updateTemplate({ ...currentTemplate, aggregationLevel: getAggregationLevel() });
    setAllowLeaving(true);
    history.push(DdpUrls.AnalystModuleTemplates);
  };

  const handleConfirmationDialog = (hasConfirmed: boolean) => {
    setIsConfirmationDialogOpen(false);
    if (hasConfirmed) {
      setAllowLeaving(true);
    }
  };

  useEffect(() => {
    if (selectedTemplate) {
      dispatch(setCurrentTemplate(selectedTemplate));
      dispatch(changeActiveStep(Steps.Dataset));
    } else {
      dispatch(newReport(isFreeTrialAccess));
    }
  }, [dispatch, selectedTemplate, isFreeTrialAccess]);

  useEffect(() => {
    if (allowLeaving) {
      history.push(redirectionPath);
    }

    const unblock = currentTemplate.modified
      ? history.block((prompt) => {
          setIsConfirmationDialogOpen(true);
          setRedirectionPath(prompt.pathname);
          return false;
        })
      : history.block(true);

    return () => {
      unblock();
    };
  }, [currentTemplate, history, allowLeaving, redirectionPath]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <ConfirmationDialog isOpen={isConfirmationDialogOpen} onClose={handleConfirmationDialog} textKey="LeaveDialog" />
      <ReportTitleDialog
        isOpen={isTemplateNameDialogOpen}
        onCancel={handleOnCancelTemplateNameDialog}
        onSave={handleSaveTemplate}
      />
      <Stack height="100%" flex={1}>
        <Typography variant="h2" my={2} marginLeft={5}>
          {currentTemplate.id ? t('EditReport') : t('CreateNewReport')}
        </Typography>
        <Box sx={{ width: 540 }}>
          <Stepper alternativeLabel activeStep={currentStep}>
            {steps.map((x) => (
              <Step key={x.label}>
                <StyledStepLabel
                  onClick={() => handleGoto(x.value)}
                  sx={{ cursor: 'pointer' }}
                  data-fullstory={x.fullStoryTag}
                >
                  {t(x.label)}
                </StyledStepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <Box marginLeft={6}>
          {currentStep === Steps.Dataset && <DatasetStep validated={isValidated} />}
          {currentStep === Steps.Aggregation && <AggregationStep />}
          {currentStep === Steps.Metrics && <MetricsStep validated={isValidated} />}
          {currentStep === Steps.OptionsAndPreview && <OptionsAndPreviewStep />}
        </Box>

        <Stack
          marginTop="auto"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginLeft={6}
          paddingY={1}
        >
          <FaqLinkText />
          <ReportActions
            currentStep={currentStep}
            isEdit={!!currentTemplate.id}
            onBack={handleBack}
            onNext={handleNext}
            onSave={handleFinish}
          />
        </Stack>
      </Stack>
    </>
  );
}

const Stepper = styled(MuiStepper)(({ theme }) => ({
  '.Mui-disabled .MuiSvgIcon-root': {
    color: theme.palette.secondary[100],
    '.MuiStepIcon-text': {
      fill: theme.palette.primary[300],
    },
  },
  '.MuiStepConnector-line': {
    borderColor: theme.palette.secondary[100],
  },
  '.Mui-completed': {
    '.MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
  '.Mui-active': {
    '.MuiStepConnector-line': {
      borderColor: theme.palette.primary.main,
    },
  },
  '.MuiStepIcon-root': {
    fontSize: theme.spacing(4),
    '.MuiStepIcon-text': {
      fontWeight: 'bold',
    },
    '&.Mui-completed': {
      color: 'green',
    },
  },
  '.MuiStepConnector-root': {
    left: `calc(-50% + ${theme.spacing(2)})`,
    right: `calc(50% + ${theme.spacing(2)})`,
    top: theme.spacing(2),
  },
  '.MuiStepLabel-label': {
    fontSize: theme.typography.pSmall.fontSize,
    color: theme.palette.primaryText[800],
    '&.Mui-active': {
      color: theme.palette.primaryText[100],
    },
    '&.Mui-completed': {
      color: theme.palette.primaryText[100],
    },
  },
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
}));

const StyledStepLabel = styled(StepLabel)(({ theme }) => ({
  color: theme.palette.primaryText[100],
  '&.Mui-disabled': {
    cursor: 'pointer',
  },
}));
