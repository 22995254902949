import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { Stack, Tooltip, styled } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import OptionButton from '../../../../common/components/atoms/OptionButton';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { changeMetrics } from '../analystSlice';
import { metricTypes } from '../metricTypes';

export interface MetricsStepProps {
  validated: boolean;
}

export default function MetricsStep({ validated }: MetricsStepProps): ReactElement {
  const { t } = useTranslation('Analyst');

  const allMetrics = metricTypes.flatMap((c) => c.childs).map((x) => x.value);

  const { metrics } = useAppSelector((state) => state.analyst.currentTemplate);
  const dispatch = useAppDispatch();

  const handleMetricChecked = (value: string) => {
    if (metrics.includes(value)) {
      dispatch(changeMetrics(metrics.filter((v) => v !== value)));
    } else {
      dispatch(changeMetrics([...metrics, value]));
    }
  };

  const handleCheckAll = () => {
    if (isAllMetricChecked()) {
      dispatch(changeMetrics([]));
    } else {
      dispatch(changeMetrics(allMetrics));
    }
  };

  const isAllMetricChecked = () => allMetrics.filter((x) => !metrics.includes(x)).length === 0;
  const isCheckAllIndeterminate = () => !isAllMetricChecked() && metrics.length > 0;

  const isGroupAllChecked = (index: number) => {
    const groupMetrics = metricTypes[index].childs.map((x) => x.value);
    return groupMetrics.filter((x) => !metrics.includes(x)).length === 0;
  };

  const isGroupAllIndeterminate = (index: number) => {
    const groupMetrics = metricTypes[index].childs.map((x) => x.value);
    return !isGroupAllChecked(index) && metrics.filter((x) => groupMetrics.includes(x)).length > 0;
  };

  const handleGroupChecked = (index: number) => {
    const groupMetrics = metricTypes[index].childs.map((x) => x.value);
    if (isGroupAllChecked(index)) {
      dispatch(changeMetrics(metrics.filter((v) => !groupMetrics.includes(v))));
    } else {
      dispatch(changeMetrics([...metrics.filter((v) => !groupMetrics.includes(v)), ...groupMetrics]));
    }
  };

  const handleMetricsInfoClick = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}faq#question-6`, '_blank')?.focus();
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack direction="row" mt={1.5}>
            <Typography mt={2.5} variant="h6">
              {t('Metrics')}
            </Typography>
            <Tooltip title={<Trans t={t}>AnalystToolTip</Trans>} placement="top-start">
              <OptionButton
                sx={{ mx: 1, mt: 1.7 }}
                onClick={handleMetricsInfoClick}
                data-fullstory="AnalystMetricsToolTip"
              >
                <FontAwesomeSvgIcon sx={{ fontSize: 19 }} icon={faInfoCircle} />
              </OptionButton>
            </Tooltip>
          </Stack>
          <Typography pb={1} variant="pSmall" color={validated && metrics.length === 0 ? 'error' : 'primaryText.100'}>
            {t('MetricsDescription')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <StyledCheckBox
                onChange={handleCheckAll}
                checked={isAllMetricChecked()}
                indeterminate={isCheckAllIndeterminate()}
                data-testid="check-all"
              />
            }
            label={<StyledTypography>{t('AllMetricsSelected')}</StyledTypography>}
          />
        </Grid>
        {metricTypes.map((metric, idx) => (
          <Grid item xs={4} key={metric.groupName}>
            <FormControlLabel
              control={
                <StyledCheckBox
                  checked={isGroupAllChecked(idx)}
                  onChange={() => handleGroupChecked(idx)}
                  indeterminate={isGroupAllIndeterminate(idx)}
                  data-testid={`${metric.groupName}`}
                />
              }
              label={<StyledTypography>{t(metric.groupName)}</StyledTypography>}
            />
            <Container>
              {metric.childs.map((el) => (
                <Container key={el.value}>
                  <FormControlLabel
                    control={
                      <StyledCheckBox
                        checked={metrics.includes(el.value)}
                        onChange={() => handleMetricChecked(el.value)}
                        data-testid={`checkbox_${el.value}`}
                      />
                    }
                    label={<StyledTypography>{t(el.label)}</StyledTypography>}
                  />
                </Container>
              ))}
            </Container>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledTypography = styled(Typography)(() => ({
  color: '#77797E',
}));
