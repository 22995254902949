/* eslint-disable no-restricted-syntax */
export function arraysIntersection(...arrays: string[][]): string[] {
  const countArrayMap: Partial<Record<string, number[]>> = {};

  arrays.forEach((array, arrayIndex) =>
    array.forEach((elem) => {
      let count = countArrayMap[elem];
      if (count === undefined) {
        count = new Array(arrays.length).fill(0) as number[];
        countArrayMap[elem] = count;
      }

      count[arrayIndex] += 1;
    }),
  );

  const result: string[] = [];

  for (const [elem, countArray] of Object.entries(countArrayMap)) {
    if (countArray !== undefined) {
      let valid = true;
      for (const count of countArray) {
        if (count === 0) {
          valid = false;
          break;
        }
      }

      if (valid) result.push(elem);
    }
  }

  return result;
}

type MapFunc<T = any> = (val: T, index?: number, arr?: T[]) => string;

export const groupBy = <T = any>(items: T[], fn: MapFunc<T>) =>
  items?.reduce((result, item) => {
    const prop = fn(item);
    return {
      ...result,
      [prop]: [...(result[prop] ?? []), item],
    };
  }, {} as Record<string, T[]>);
