export function formatPriceWithTwoOrNoDecimals(price: number): string {
  return price.toLocaleString(undefined, {
    minimumFractionDigits: Number.isInteger(price) ? 0 : 2,
    maximumFractionDigits: 2,
  });
}

export function formatPriceWithTwoDecimals(price: number): string {
  return price.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
