import { ThemeProvider } from '@mui/material';
import { ReactElement, forwardRef } from 'react';
import { Platform } from '../enums/platform.enum';
import { usePlatform } from '../platform/hooks';
import ASQTheme from './ASQTheme';
import LightTheme from './LightTheme';

export interface PlatformThemeProviderProps {
  children?: React.ReactNode
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PlatformThemeProvider = forwardRef<unknown, PlatformThemeProviderProps>((props: PlatformThemeProviderProps, ref): ReactElement => {
  const { children } = props;
  const { currentPlatform } = usePlatform();
  const themeValue = currentPlatform === Platform.ASQ ? ASQTheme : LightTheme;

  return (
    <ThemeProvider theme={themeValue}>{children}</ThemeProvider>
  );
})