import { Trans, useTranslation } from 'react-i18next';

import { Stack, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../../../../store';
import { getListSeparatedByCommaAndAnd } from '../../../../utils/stringHelper';

export default function GetStartedStep() {
  const { t } = useTranslation(['Pricing', 'Common']);
  const { selectedProduct } = useAppSelector((state) => state.purchase);
  const andLabel = ` ${t('Common:And')} `;

  if (!selectedProduct) {
    return null;
  }

  const productNames = selectedProduct.includedProducts
    ? selectedProduct.includedProducts.map((p) => p.productName)
    : [selectedProduct.displayName];
  const productNamesInOneString = getListSeparatedByCommaAndAnd(productNames, andLabel);

  const CenteredImage = styled('img')(() => ({
    display: 'block',
    margin: 'auto',
  }));

  return (
    <Stack mt={5} direction="column" gap={1} sx={{ px: 2, textAlign: 'center' }}>
      <Typography variant="h4">{t(`Pricing:FreeTrialDialog.TryFreeAccess`)}</Typography>
      <Typography sx={{ fontWeight: 'fontWeightMedium' }}>
        <Trans
          t={t}
          tOptions={{
            modules: productNamesInOneString,
          }}
        >
          Pricing:FreeTrialDialog.ExploreFor14Days
        </Trans>
      </Typography>
      <CenteredImage src="/images/FreeTrialStart.svg" width="186" height="112" alt="logo" sx={{ marginY: 3 }} />
      <Typography>{t(`Pricing:FreeTrialDialog.AccessToAirportData`)}</Typography>
    </Stack>
  );
}
