import { createSlice } from '@reduxjs/toolkit';

interface State {
  isDialogOpen: boolean;
}

export const initialState: State = {
  isDialogOpen: false,
};

// Actions
const termsAndConditionsSlice = createSlice({
  name: 'termsAndConditions',
  initialState,
  reducers: {
    openDialog: (state) => ({
      ...state,
      isDialogOpen: true,
    }),
    closeDialog: () => ({
      ...initialState,
    }),
  },
});

export const { closeDialog, openDialog } = termsAndConditionsSlice.actions;

export default termsAndConditionsSlice.reducer;
