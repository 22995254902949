import { ReactElement } from 'react';

import { PaperProps, Select, SelectProps } from '@mui/material';

interface CustomSelectProps<T> extends SelectProps<T> {
  maxItems?: number;
}

export default function CustomSelect<T>({
  maxItems,
  MenuProps,
  children,
  ...props
}: CustomSelectProps<T>): ReactElement {
  let finalMenuProps = MenuProps;

  if (maxItems !== undefined) {
    finalMenuProps = {
      ...MenuProps,
      PaperProps: generatePaperProps(maxItems),
    };
  }

  return (
    <Select MenuProps={finalMenuProps} {...props}>
      {children}
    </Select>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function generatePaperProps(maxItems: number): Partial<PaperProps<'div', {}>> {
  return {
    style: {
      maxHeight: ITEM_HEIGHT * maxItems + ITEM_PADDING_TOP,
    },
  };
}
