import { CircularProgress, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import { useFreeTrialReasonsForComingQuery } from '../../../../hooks/usePricingEndpoints';
import { ReasonForComing } from '../../entities/reasonForComing';
import { changeModifiedForm, changeOtherReason, changeReasonsForComing } from '../../purchaseSlice';

export default function ReasonsForComingStep(): ReactElement {
  const { t } = useTranslation('Pricing');
  const { currentReasonsForComing, currentOtherReason } = useAppSelector((state) => state.purchase);
  const [otherChecked, setOtherChecked] = useState(false);
  const dispatch = useAppDispatch();
  const { data: reasonsForComing, isLoading } = useFreeTrialReasonsForComingQuery();

  const handleChangeReasons = (value: number) => {
    dispatch(changeModifiedForm(true));
    let newReasons: number[] = Array.from(currentReasonsForComing);

    if (newReasons.includes(value)) {
      newReasons = newReasons.filter((x) => x !== value);
      if (value === reasonsForComing?.find((res) => res.reason === 'Others')?.id) {
        setOtherChecked(false);
        dispatch(changeOtherReason(''));
      }
    } else {
      newReasons.push(value);
      if (value === reasonsForComing?.find((res) => res.reason === 'Others')?.id) {
        setOtherChecked(true);
      }
    }

    dispatch(changeReasonsForComing(newReasons));
  };

  const reasonButtonBackground = (value: number) => (currentReasonsForComing.includes(value) ? '#DDE9F4' : 'white');

  const handleOtherReason = ({ target }: React.ChangeEvent<{ value: string }>) => {
    const { value } = target;
    dispatch(changeOtherReason(value));
  };

  return (
    <Stack mt={5} direction="column" sx={{ gap: 1.5, textAlign: 'center' }}>
      <Typography variant="h6">{t(`FreeTrialDialog.Step2`)}</Typography>
      <Divider sx={{ width: 30, marginX: 'auto' }} />
      <Typography variant="h4">{t(`FreeTrialDialog.ReasonsForComing`)}</Typography>
      <Typography>{t(`FreeTrialDialog.MoreThan1`)}</Typography>
      {isLoading ? (
        <CircularProgress sx={{ mt: 4, marginX: 'auto' }} />
      ) : (
        reasonsForComing!.map((x: ReasonForComing) => (
          <Button
            variant="outlined"
            key={x.id}
            sx={{
              background: reasonButtonBackground(x.id),
              width: 390,
              height: 48,
              borderRadius: 0,
              fontSize: 12.5,
              marginX: 'auto',
            }}
            onClick={() => handleChangeReasons(x.id)}
          >
            {x.reason}
          </Button>
        ))
      )}
      {otherChecked && (
        <TextField
          multiline
          sx={{ width: 390, marginX: 'auto' }}
          onChange={handleOtherReason}
          data-fullstory="FreeTrialOtherReasonInput"
          placeholder={t(`FreeTrialDialog.OtherReason`)}
          value={currentOtherReason}
        />
      )}
    </Stack>
  );
}
