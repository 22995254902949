import { ReactElement, useMemo } from 'react';

import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { MenuItem, SelectChangeEvent, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../../common/components/atoms/CustomSelect';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { useAsqAccess } from '../../../../common/utils/accessHelper';
import { alphaAirportComparer } from '../../../../common/utils/airport';
import { useAppDispatch } from '../../../../store';
import { setAirportCode } from '../filter/filterSlice';
import { useHomePageFilter, useIsHomePageAiportFilterReady } from '../filter/hooks';

export default function AirportCodeFilter(): ReactElement | null {
  const { t } = useTranslation('Home');
  const { access, isLoading } = useAsqAccess();
  const { airportCode } = useHomePageFilter();
  const isAiportFilterReady = useIsHomePageAiportFilterReady();
  const dispatch = useAppDispatch();

  const airports = useMemo(
    () => (access ? [...access.availableAirportsWithoutGroups].sort(alphaAirportComparer) : undefined),
    [access],
  );

  if (isLoading || !isAiportFilterReady) {
    return <Skeleton width="300px" />;
  }

  if (airports === undefined) {
    return null;
  }

  if (airports.length === 0) {
    return (
      <Typography variant="h6" color="primary">
        {t('ASQ.Filters.NoAirport')}
      </Typography>
    );
  }

  const handleAirportCodeChange = (event: SelectChangeEvent<string>) => {
    dispatch(setAirportCode(event.target.value));
  };

  return (
    <Stack direction="row" spacing={2}>
      <Typography variant="h6" color="primary">
        {t('ASQ.Filters.Airport')}
      </Typography>
      <CustomSelect
        value={airportCode!}
        onChange={handleAirportCodeChange}
        variant="standard"
        sx={{
          color: 'primary.main',
          fontWeight: 'bold',
          '& .MuiSvgIcon-root': {
            color: 'primary.main',
          },
        }}
        IconComponent={(props) => (
          <FontAwesomeSvgIcon {...props} icon={faChevronDown} fontSize="inherit" color="primary" />
        )}
        maxItems={5}
      >
        {airports.map(({ code, name }) => (
          <MenuItem key={code} value={code}>
            {code} - {name}
          </MenuItem>
        ))}
      </CustomSelect>
    </Stack>
  );
}
