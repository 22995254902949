import { IReportEmbedConfiguration, Page, Report, models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import { ReactElement, useEffect, useState } from 'react';

import { styled } from '@material-ui/styles';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { EmbedRequest } from '../../../core/entities/embedRequest';
import { changeCurrentReport } from '../../../modules/DDP/executive/executiveSlice';
import { useAppDispatch } from '../../../store';
import MarginCanceller from '../atoms/MarginCanceller';

const defaultEmbedType = 'report';

const defaultEmbedConfig: IReportEmbedConfiguration = {
  type: defaultEmbedType,
  tokenType: models.TokenType.Embed,
};

export type PageChangeListener = (page: Page, report: Report) => void;
export type PowerBIListener = (report: Report) => void;

export interface SlicerState {
  name: string;
  value: string;
}

export type ReportChangeListener = (report: Report) => void;

export type SlicerChangeListener = (slicerState: SlicerState, report: Report) => void;

export type ButtonClickListener = (buttonId: string, report: Report) => void;

interface PowerBIReportProps {
  embedRequest?: EmbedRequest;
  isLoading: boolean;
  pageName?: string;
  onPageChange?: PageChangeListener;
  onLoaded?: PowerBIListener;
  onSlicerChange?: SlicerChangeListener;
  onButtonClick?: ButtonClickListener;
  onRendered?: ReportChangeListener;
  showFilters?: boolean;
}

export default function PowerBIReport({
  embedRequest,
  isLoading,
  pageName,
  onPageChange,
  onLoaded,
  onSlicerChange,
  onButtonClick,
  onRendered,
  showFilters = false,
}: PowerBIReportProps): ReactElement {
  const [reportConfig, setReportConfig] = useState<IReportEmbedConfiguration>(defaultEmbedConfig);
  const [report, setReport] = useState<Report | null>(null);
  const { t } = useTranslation('Common');
  const dispatch = useAppDispatch();

  useEffect(() => {
    setReportConfig({
      type: defaultEmbedType,
      tokenType: models.TokenType.Embed,
      embedUrl: embedRequest?.embedUrl,
      accessToken: embedRequest?.embedToken,
      id: embedRequest?.reportId,
      settings: {
        panes: {
          filters: {
            visible: showFilters,
          },
          pageNavigation: {
            visible: false,
          },
        },
      },
      pageName,
    });
  }, [embedRequest, pageName, showFilters]);

  useEffect(() => {
    if (report !== null && onPageChange) {
      report.on<any>('pageChanged', (event) => {
        const page = event?.detail?.newPage as Page | undefined;
        if (page) onPageChange(page, report);
      });
    }

    return () => report?.off('pageChanged');
  }, [report, onPageChange]);

  useEffect(() => {
    if (report !== null && onSlicerChange) {
      report.on<any>('dataSelected', (event) => {
        const slicerName = event?.detail?.visual?.name as string | undefined;
        const slicerValue = event?.detail?.dataPoints?.[0]?.identity?.[0]?.equals as string | undefined;
        if (slicerName && slicerValue) onSlicerChange({ name: slicerName, value: slicerValue }, report);
      });
    }

    return () => report?.off('dataSelected');
  }, [report, onSlicerChange]);

  useEffect(() => {
    if (report !== null && onButtonClick) {
      report.on<any>('buttonClicked', (event) => {
        const buttonId = event?.detail?.id as string | undefined;
        if (buttonId) onButtonClick(buttonId, report);
      });
    }
    return () => report?.off('buttonClicked');
  }, [report, onButtonClick]);

  useEffect(() => {
    if (report !== null && onLoaded) {
      report.on<any>('loaded', () => {
        onLoaded(report);
      });
    }

    return () => report?.off('loaded');
  }, [report, onLoaded]);

  useEffect(() => {
    if (report !== null && onRendered) {
      report.on<any>('rendered', () => {
        onRendered(report);
      });
    }

    return () => report?.off('rendered');
  }, [report, onRendered]);

  if (!embedRequest && !isLoading) {
    return (
      <Box display="flex" mt={10} justifyContent="center">
        {t('NoReport')}
      </Box>
    );
  }

  return (
    <MarginCanceller>
      <EmbedContainer>
        <PowerBIEmbed
          embedConfig={reportConfig}
          cssClassName={cssClassName}
          getEmbeddedComponent={(embed) => {
            setReport(embed as Report);
            dispatch(changeCurrentReport(embed as Report));
          }}
        />
      </EmbedContainer>
    </MarginCanceller>
  );
}

const cssClassName = 'report-style-class';

const EmbedContainer = styled('div')(() => ({
  height: '100%',
  width: '100%',
  [`& .${cssClassName}`]: {
    '& iframe': {
      border: 0,
    },
    height: '100%',
  },
}));
