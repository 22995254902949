/* eslint-disable max-len */
import { ReactElement } from 'react';

import { Box, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';

import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import OptionButton from '../../../../common/components/atoms/OptionButton';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { setSelectedAggregations } from '../analystSlice';
import AirportsFilters from '../atoms/AirportsFilters';
import CountriesFilters from '../atoms/CountriesFilters';
import CountryGroupsFilters from '../atoms/CountryGroupsFilter';
import RegionFilters from '../atoms/RegionsFilters';
import SubregionFilters from '../atoms/SubregionFilters';
import { FilterLevels } from '../enums/filterLevels.enum';
import ChipsContainer from './ChipsContainer';

export default function AggregationStep(): ReactElement {
  const { t } = useTranslation('Analyst');

  const dispatch = useAppDispatch();

  const { countries, airports, regions, countryGroups } = useAppSelector((state) => state.analyst.currentTemplate);

  const handleSampleInfoClick = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}faq#question-6`, '_blank')?.focus();
  };

  const changeSelection = (key: FilterLevels, value: string[]) => dispatch(setSelectedAggregations({ key, value }));

  const handleRegionChange = (value: string[]) => changeSelection(FilterLevels.Region, value);
  const handleCountryChange = (value: string[]) => changeSelection(FilterLevels.Country, value);
  const handleCountryGroupChange = (value: string[]) => changeSelection(FilterLevels.CountryGroup, value);
  const handleAirportChange = (value: string[]) => changeSelection(FilterLevels.Airport, value);
  const handleSubregionChange = (value: string[]) => changeSelection(FilterLevels.Subregion, value);

  return (
    <>
      <Grid item xs={9}>
        <Box display="flex" pt={4} pb={2} alignItems="center">
          <Typography variant="h6">{t('SampleLabel')}</Typography>
          <Tooltip title={<Trans t={t}>AnalystToolTip</Trans>} placement="top-start">
            <OptionButton
              sx={{ mx: 1, pt: 0.4 }}
              onClick={handleSampleInfoClick}
              data-fullstory="AnalystAggregationLocationToolTip"
            >
              <FontAwesomeSvgIcon sx={{ fontSize: 19 }} icon={faInfoCircle} />
            </OptionButton>
          </Tooltip>
        </Box>

        <Grid container direction="row" spacing={1}>
          <RegionFilters value={regions} onValueChange={handleRegionChange} />

          <SubregionFilters value={regions} onValueChange={handleSubregionChange} />

          <CountryGroupsFilters value={countryGroups} onValueChange={handleCountryGroupChange} />

          <CountriesFilters value={countries} onValueChange={handleCountryChange} />

          <AirportsFilters value={airports} onValueChange={handleAirportChange} />
        </Grid>
        <Grid item xs={12} sx={{ pt: 1 }}>
          <ChipsContainer countries={countries} airports={airports} regions={regions} countryGroups={countryGroups} />
        </Grid>
      </Grid>
    </>
  );
}
