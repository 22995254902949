import { TFunction } from 'i18next';
import { PeriodDate } from '../../core/entities/periodDate';
import { AsqPeriod } from '../../core/enums/asqPeriod.enum';

export function formatPeriodDate(date: PeriodDate): string {
  if (date.period === AsqPeriod.Winter) return `${formatPeriod(date.period)} ${date.year - 1}`;
  return `${formatPeriod(date.period)} ${date.year}`;
}

export function periodDateSorter(a: PeriodDate, b: PeriodDate): number {
  if (a.year === b.year) {
    return b.period - a.period;
  }
  return b.year - a.year;
}

export function formatPeriodDateForRegional(periodDate: PeriodDate): string {
  return `${periodDate.year} ${formatPeriod(periodDate.period)}`;
}

export function extractPeriodDateFromRegionalFormatting(periodDate: string): PeriodDate {
  const split = periodDate.split(' ');
  if (split.length !== 2) throw new Error('Wrong string format. Expected something like 2022 Summer');

  const period = stringToPeriod(split[1]);
  if (period === undefined) throw new Error('not found period. Expected Summer or Winter');

  return { year: +split[0], period };
}

const periodMap: Partial<Record<string, AsqPeriod>> = {
  q1: AsqPeriod.Q1,
  q2: AsqPeriod.Q2,
  q3: AsqPeriod.Q3,
  q4: AsqPeriod.Q4,
  summer: AsqPeriod.Summer,
  winter: AsqPeriod.Winter,
};

export const stringToPeriod = (period: string): AsqPeriod | undefined => periodMap[period.toLowerCase()];

export function formatPeriod(period: AsqPeriod): string {
  switch (period) {
    case AsqPeriod.Q1:
      return 'Q1';
    case AsqPeriod.Q2:
      return 'Q2';
    case AsqPeriod.Q3:
      return 'Q3';
    case AsqPeriod.Q4:
      return 'Q4';
    case AsqPeriod.Summer:
      return 'Summer';
    case AsqPeriod.Winter:
      return 'Winter';
    default:
      return '';
  }
}

export function formatDateTranslate(t: TFunction, tKey: string, date: Date, ns?: string): string {
  return t(tKey, {
    ns,
    date,
    formatParams: {
      date: {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
      },
    },
  });
}
