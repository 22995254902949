import { AccordionDetails, Stack, styled } from '@mui/material';
import { NavItemProps } from '../../types/NavItem';
import DrawerAccordionDetailsLink from './DrawerAccordionDetailsLink';

const StyledAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0, 0, 1, 0),
}));

interface DrawerAccordionDetailsProps {
  navItem: NavItemProps;
  openUrl: (url: string) => void;
  navSubItemSelected: (url: string) => boolean;
}

const DrawerAccordionDetails = ({ navItem, openUrl, navSubItemSelected }: DrawerAccordionDetailsProps) => (
  <StyledAccordionDetails>
    <Stack>
      {navItem.items?.map((navSubItem) => (
        <DrawerAccordionDetailsLink
          key={navSubItem.translateKey}
          navItemTranslateKey={navItem.translateKey}
          navSubItem={navSubItem}
          openUrl={openUrl}
          navSubItemSelected={navSubItemSelected}
        />
      ))}
    </Stack>
  </StyledAccordionDetails>
);

export default DrawerAccordionDetails;
