import { ReactElement, useMemo } from 'react';

import ASQPaperTable from '../../../../common/components/molecules/ASQPaperTable';
import { Comparer, HeaderDef, RowDef } from '../../../../common/components/types/TableTypes';
import BaseFile from '../entities/BaseFile';

interface FileTableProps<File extends BaseFile, FileData> {
  files: File[] | undefined;
  headers: HeaderDef<FileData>[];
  generateRow: (file: File) => RowDef<FileData>;
  onSelectedFilesChange: (fileIds: string[]) => void;
  defaultComparer?: Comparer<FileData>;
  emptyComponent?: React.ReactElement;
  isLoading?: boolean;
}

export default function FileTable<File extends BaseFile, FileData>({
  files,
  headers,
  generateRow,
  onSelectedFilesChange,
  defaultComparer,
  emptyComponent,
  isLoading = false,
}: FileTableProps<File, FileData>): ReactElement {
  const rows = useMemo(() => files?.map((file) => generateRow(file)) ?? [], [files, generateRow]);

  return (
    <ASQPaperTable
      isLoading={isLoading}
      headers={headers}
      rows={rows}
      onSelectedRowsChange={onSelectedFilesChange}
      defaultComparer={defaultComparer}
      empty={emptyComponent}
    />
  );
}
