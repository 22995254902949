import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactElement, useEffect, useState } from 'react';
import { Platform } from '../../../core/enums/platform.enum';
import { FeatureFlagKey } from '../../../core/featureFlags/featureFlagKeys';
import { useFeatureFlag } from '../../../core/featureFlags/hooks';
import { usePlatform } from '../../../core/platform/hooks';
import AppContent from '../../../routes/main.routes';
import { useAppSelector } from '../../../store';
import { LayoutPaddingBox } from '../../constants/margin';
import { useGetUserQuery } from '../../hooks/backend';
import { DrawerOpenContext } from '../context/DrawerContext';
import ASQLeftNav from '../molecules/ASQLeftNav';
import AppBar from '../molecules/AppBar';
import LeftNav from '../molecules/LeftNav';
import ModuleMaintenancePage from './ModuleMaintenancePage';

export interface Props {}

export default function Layout(): ReactElement {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { isDialogOpen } = useAppSelector((state) => state.termsAndConditions);
  const [drawerOpen, setDrawerOpen] = useState(isDesktop);
  const { isLoading } = useGetUserQuery();
  const { currentPlatform } = usePlatform();

  const isMaintenance = useFeatureFlag(FeatureFlagKey.FullMaintenanceMode);

  useEffect(() => setDrawerOpen(isDesktop), [isDesktop]);

  const displayAppContent = !isDialogOpen && !isLoading;

  if (isMaintenance) return <ModuleMaintenancePage module="The website" />;

  return (
    <>
      <DrawerOpenContext.Provider value={{ drawerOpen, setDrawerOpen }}>
        <AppBar />
        <Box display="flex" flex={1} overflow="auto">
          {currentPlatform === Platform.ASQ ? <ASQLeftNav /> : <LeftNav />}
          <Box component="main" flexGrow={1} p={LayoutPaddingBox} position="relative">
            {displayAppContent && <AppContent />}
          </Box>
        </Box>
      </DrawerOpenContext.Provider>
    </>
  );
}
