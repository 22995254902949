import { compareDesc, parseISO } from 'date-fns';
import { TransformedFileDto } from '../entities/File';
import FilesCarousel from './FilesCarousel';

export type RecommendedSectionProps = { files: TransformedFileDto[] };

export default function RecommendedSection({ files }: RecommendedSectionProps) {
  const recommendedFiles = files
    .sort((a, b) => compareDesc(parseISO(a.lastModified), parseISO(b.lastModified)))
    .slice(0, 3);
  return <FilesCarousel files={recommendedFiles} />;
}
