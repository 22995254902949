import { createTheme, Theme } from '@mui/material';
import { ASQPrimaryText, ErrorColor, Primary, Success } from './color';
import Secondary from './color/Secondary';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    gradient: true;
  }
}

export const ThemeSpacing = 8; // default recommended value

const ASQTheme = createTheme({
  spacing: ThemeSpacing,
  breakpoints: {
    values: {
      xs: 0,
      md: 750,
      lg: 1200,
    },
  },

  palette: {
    primary: {
      main: Primary[100],
      light: Primary[10],
      900: Primary[90],
      800: Primary[80],
      300: Primary[30],
      100: Primary[10],
    },
    primaryText: {
      900: ASQPrimaryText[90],
      800: ASQPrimaryText[80],
      300: ASQPrimaryText[30],
      100: ASQPrimaryText[10],
    },
    secondary: {
      main: '#FFFFFF',
      100: Secondary[10],
      200: Secondary[20],
      contrastText: Primary[100],
    },
    success: {
      main: Success[100],
    },
    error: {
      main: ErrorColor[100],
    },
  },

  typography: {
    fontFamily: ['Nunito Sans'].join(','),
    h1: {
      fontSize: 84,
      fontWeight: 800,
    },
    h2: {
      fontSize: 40,
      fontWeight: 200,
    },
    h3: {
      fontSize: 32,
      fontWeight: 200,
    },
    h4: {
      fontSize: 24,
      fontWeight: 800,
    },
    h5: {
      fontSize: 20,
      fontWeight: 800,
    },
    h6: {
      fontSize: 18,
      fontWeight: 800,
    },

    pXSmall: {
      fontSize: 9,
      fontWeight: 400,
    },
    pSmall: {
      fontSize: 13,
      fontWeight: 400,
    },
    pMedium: {
      fontSize: 15,
      fontWeight: 400,
    },
    pLarge: {
      fontSize: 20,
      fontWeight: 400,
    },
    pXLarge: {
      fontSize: 32,
      fontWeight: 400,
    },

    buttonLarge: {
      fontSize: 16,
      fontWeight: 600,
    },
    button: {
      fontSize: 14,
      fontWeight: 600,
    },
    buttonSmall: {
      fontSize: 12,
      fontWeight: 600,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: '#F4F6FA',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 16,
          textTransform: 'none',
          borderRadius: 8,
        },
      },
      defaultProps: {
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: 'gradient' },
          style: {
            background:
              'linear-gradient(to right bottom, #003A8C 12.5%, #05398B 25%, #143788 37.5%, #2D3484 50%, #50307E 62.5%, #7C2B76 75%, #B2246C 87.5%, #EC1E63 100%)',
            color: 'white',
            textTransform: 'uppercase',
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 20,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'h6',
          subtitle2: 'h6',
          pXSmall: 'p',
          pSmall: 'p',
          pMedium: 'p',
          pLarge: 'p',
          pXLarge: 'p',
        },
        color: 'primaryText.100',
      },
    },
    MuiAlert: {
      styleOverrides: {
        outlinedError: {
          backgroundColor: ErrorColor[10],
          color: ErrorColor[100],
        },
        outlinedSuccess: {
          backgroundColor: Success[10],
          color: Success[100],
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
        },
      },
    },
  },
});

ASQTheme.shadows = Array<string>(25).fill('0px 0px 26px rgba(0, 0, 0, 0.06)') as Theme['shadows'];
ASQTheme.shadows.push('0px 0px 26px rgba(0, 0, 0, 0.06)');
ASQTheme.shadows.push('3px 0px 26px rgba(0, 0, 0, 0.06)');

declare module '@mui/material/styles' {
  interface TypographyVariants {
    pXSmall: React.CSSProperties;
    pSmall: React.CSSProperties;
    pMedium: React.CSSProperties;
    pLarge: React.CSSProperties;
    pXLarge: React.CSSProperties;

    buttonSmall: React.CSSProperties;
    buttonLarge: React.CSSProperties;
  }

  interface Palette {
    primaryText: Palette['primary'];
  }

  interface PaletteOptions {
    primaryText: PaletteOptions['primary'];
  }

  interface PaletteColor {
    100: string;
    200: string;
    300: string;
    800: string;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    pXSmall?: React.CSSProperties;
    pSmall?: React.CSSProperties;
    pMedium?: React.CSSProperties;
    pLarge?: React.CSSProperties;
    pXLarge?: React.CSSProperties;

    buttonSmall?: React.CSSProperties;
    buttonLarge?: React.CSSProperties;
  }

  interface BreakpointOverrides {
    sm: false;
    xl: false;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    pXSmall: true;
    pSmall: true;
    pMedium: true;
    pLarge: true;
    pXLarge: true;

    buttonSmall: true;
    buttonLarge: true;
  }
}

export default ASQTheme;
