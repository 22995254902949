import { ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useCurrentPlatformHomeUrl } from '../../../core/platform/hooks';
import Button from '../atoms/Button';

export interface ErrorPageProps {
  code: number;
}

export default function ErrorPage({ code }: ErrorPageProps): ReactElement {
  const { t } = useTranslation('Error');
  const homeUrl = useCurrentPlatformHomeUrl();

  return (
    <Stack height="100%" justifyContent="center" alignItems="center" gap={5}>
      <img alt="" src={code === 404 ? 'images/Library/Empty.png' : 'images/Error.png'} width="200px" />
      <Stack gap={1}>
        <Typography textAlign="center" variant="h1" fontWeight="800">
          {t('Title', { errorCode: code })}
        </Typography>
        <Typography variant="h4" fontWeight="bold" color="primary" m={0}>
          {t(`ErrorDescriptions.${code}`)}
        </Typography>
      </Stack>

      <Button component={Link} to={homeUrl} variant="contained">
        {t('BackHomeButton')}
      </Button>
    </Stack>
  );
}
