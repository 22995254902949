import { Fragment, PropsWithChildren, ReactElement } from 'react';

import { Box, Divider, Stack, styled, Toolbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

import Button from '../../../../common/components/atoms/Button';
import AutocompleteFilter from '../atoms/AutocompleteFilter';
import { FileFilterBase } from '../types/FileFilter';

interface FileFiltersProps {
  filters: FileFilterBase[];
  resetFilters: () => void;
}

const filterSize = 150;

export default function FileFilters({ filters, resetFilters }: FileFiltersProps): ReactElement {
  const { t } = useTranslation('Repository');

  const isLoading = filters.filter((filter) => filter.isLoading).length > 0;

  return (
    <FileFiltersContainer>
      {filters.map((filter) => (
        <Fragment key={filter.id}>
          <Box mr={filter.spacing ? undefined : 1}>
            <AutocompleteFilter
              id={filter.id}
              label={t(filter.labelTranslateKey)}
              options={filter.options}
              values={filter.values}
              onChange={filter.onChange}
              selectAllLabel={t('FileExplorer.Filters.SelectAll')}
              width={filterSize}
              spacing={filter.spacing}
              isLoading={filter.isLoading}
            />
          </Box>
          {filter.spacing && <Divider orientation="vertical" flexItem sx={{ mx: 3 }} />}
        </Fragment>
      ))}
      <Button
        onClick={resetFilters}
        data-fullstory="RepositoryResetFilters"
        sx={{ color: 'primaryText.800' }}
        disabled={isLoading}
      >
        {t('FileExplorer.Filters.Reset')}
      </Button>
    </FileFiltersContainer>
  );
}

function FileFiltersContainer({ children }: PropsWithChildren<unknown>): ReactElement {
  return (
    <>
      <StyledToolbar disableGutters>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" width="100%">
          {children}
        </Stack>
      </StyledToolbar>
      <Divider />
    </>
  );
}

export const FileFiltersHeightThemeSpacing = 10;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: theme.spacing(FileFiltersHeightThemeSpacing),
  padding: theme.spacing(0, 3),
  backgroundColor: 'white',
}));
