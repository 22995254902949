import { Box, styled } from '@mui/material';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';

import { ReactElement } from 'react';
import { useAppSelector } from '../../../../../store';

export default function CongratulationsStep(): ReactElement {
  const { t } = useTranslation('Pricing');
  const { isFreeTrialDialogOpen } = useAppSelector((state) => state.purchase);

  const CenteredImage = styled('img')(() => ({
    display: 'block',
    margin: '45px auto',
  }));

  return (
    <Box mt={5} sx={{ textAlign: 'center' }}>
      <Typography variant="h4">{t(`Dialog.Congratulations`)}</Typography>
      <CenteredImage src="/images/PurchaseCongratulations.svg" alt="logo" />
      <Typography>
        {t(`Dialog.ThankYou`)} {isFreeTrialDialogOpen && t(`FreeTrialDialog.StartTrial`)}
      </Typography>
    </Box>
  );
}
