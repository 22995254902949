import { useCountryGroupFilterQuery, useRegionFilterQuery } from '../../../../common/hooks/backend';
import { useAppSelector } from '../../../../store';
import Filters from '../entities/Filters';
import { FilterLevels } from '../enums/filterLevels.enum';

export const useAggregationFilters = () => {
  const {
    countries: selectedCountries,
    countryGroups: selectedCountryGroups,
    regions: selectedRegions,
  } = useAppSelector((state) => state.analyst.currentTemplate);

  const { data: regions = [] } = useRegionFilterQuery();
  const { data: countryGroups = [] } = useCountryGroupFilterQuery();

  const fullSelectedCountryGroups = countryGroups.filter((g) => selectedCountryGroups.includes(g.countryGroupCode));
  const fullSelectedSubregions = regions.filter(
    (r) => selectedRegions.includes(r.regionCode) && r.parentRegionCode !== null,
  );
  const fullSelectedRegions = regions.filter(
    (r) => selectedRegions.includes(r.regionCode) && r.parentRegionCode === null,
  );

  const getAggregationLevel = (): FilterLevels => FilterLevels.Airport;

  const getClosestFilters = (aggregationLevel: FilterLevels): Filters => {
    switch (aggregationLevel) {
      case FilterLevels.Subregion: {
        if (fullSelectedRegions.length !== 0) {
          return {
            closestFilterLevels: [FilterLevels.Region],
            regionCodes: fullSelectedRegions.map((r) => r.regionCode),
          } as Filters;
        }
        return { closestFilterLevels: [] } as Filters;
      }
      case FilterLevels.Country: {
        const filters: Filters = { closestFilterLevels: [], countryGroups: [], fullRegions: [] } as Filters;
        if (selectedCountryGroups.length !== 0) {
          filters.closestFilterLevels.push(FilterLevels.CountryGroup);
          filters.countryGroups = fullSelectedCountryGroups;
        }
        if (fullSelectedSubregions.length !== 0) {
          filters.closestFilterLevels.push(FilterLevels.Subregion);
          filters.fullRegions = fullSelectedSubregions;
        } else if (selectedRegions.length !== 0) {
          filters.closestFilterLevels.push(FilterLevels.Region);
          filters.fullRegions = fullSelectedRegions;
        }
        return filters;
      }
      case FilterLevels.Airport: {
        if (selectedCountries.length !== 0) {
          return { closestFilterLevels: [FilterLevels.Country], countries: selectedCountries } as Filters;
        }
        return getClosestFilters(FilterLevels.Country);
      }
      default:
        return { closestFilterLevels: [] } as Filters;
    }
  };

  return {
    getClosestFilters,
    getAggregationLevel,
    fullSelectedRegions,
  };
};
