import { ReactElement } from 'react';

import * as FullStory from '@fullstory/browser';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../store';
import LoadingPromiseButton from '../../../../components/atoms/LoadingPromiseButton';
import { pricingEndpoints } from '../../../../hooks/usePricingEndpoints';
import { PurchaseDialogStep } from '../../entities/PurchaseDialogStep';
import { changeModifiedForm } from '../../purchaseSlice';

export interface PurchaseDialogActionsProps {
  fullstoryEventPrefix: string;
  currentStep: number;
  steps: PurchaseDialogStep[];
  onNext: () => void;
  onClose: () => void;
  onSave: () => void | Promise<void>;
  onBack: () => void;
}

export default function PurchaseDialogActions(props: PurchaseDialogActionsProps): ReactElement {
  const { currentStep, steps, onNext, onClose, onSave, onBack, fullstoryEventPrefix } = props;
  const { t } = useTranslation('Pricing');
  const dispatch = useAppDispatch();

  const handleNextButton = async () => {
    FullStory.event(`${fullstoryEventPrefix} - ${steps[currentStep].fullStoryName} Step Completed`, { currentStep });
    if (currentStep === steps.length - 1) {
      onClose();
    } else {
      if (currentStep === steps.length - 2) {
        try {
          await onSave();
          dispatch(changeModifiedForm(false));
          dispatch({
            type: `${pricingEndpoints.reducerPath}/invalidateTags`,
            payload: ['Subscription'],
          });
        } catch {
          return;
        }
      }
      onNext();
    }
  };

  return (
    <Box sx={{ pt: 3, marginX: 'auto', height: 120 }}>
      {steps[currentStep].backButton && (
        <Button
          variant="text"
          sx={{ textDecoration: 'underline', minWidth: 159, marginRight: 5 }}
          onClick={onBack}
          data-fullstory="PurchaseDialogBack"
        >
          {t(`Back`)}
        </Button>
      )}
      <LoadingPromiseButton
        variant={steps[currentStep].nextButtonGradient}
        disabled={!steps[currentStep].validForm}
        sx={{ minWidth: 159 }}
        onClick={handleNextButton}
        data-fullstory="PurchaseDialogNext"
      >
        {steps[currentStep].nextButtonText}
      </LoadingPromiseButton>
    </Box>
  );
}
