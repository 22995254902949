import { Suspense, useState } from 'react';

import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

import { faBars, faUser } from '@fortawesome/pro-light-svg-icons';

import { Platform } from '../../../core/enums/platform.enum';
import { DdpPlatformFeature } from '../../../core/platform/PlatformFeature';
import { usePlatform } from '../../../core/platform/hooks';
import ProfileDrawer from '../../../modules/common/profile/molecules/ProfileDrawer';
import FreeTrialButton from '../../modules/purchase/components/molecules/FreeTrialButton';
import { FontAwesomeSvgIcon } from '../atoms/FontAwesomeIcon';
import { useDrawerOpen } from '../context/DrawerContext';

interface StyledRootProps {
  drawerOpen: boolean;
}

export const AppBarPortalId = 'AppBarPortalId';

export const AppBarHeightThemeSpacing = 7;

export const StyledMuiAppBar = styled(MuiAppBar)(({ theme }) => ({
  height: theme.spacing(AppBarHeightThemeSpacing),
  '& .MuiToolbar-root': {
    minHeight: theme.spacing(AppBarHeightThemeSpacing),
    padding: theme.spacing(0, 3),
  },
  '& .MuiButtonBase-root': {
    margin: theme.spacing(0, 0.5),
  },
}));

const DrawerWidthSpace = styled(Box)<StyledRootProps>(({ theme, drawerOpen }) => ({
  width: drawerOpen ? theme.spacing(30) : theme.spacing(9),
  transition: theme.transitions.create(['width']),
}));

export interface AppBarProps {}

function AppBar() {
  const [profileOpen, setProfileOpen] = useState(false);
  const { drawerOpen, setDrawerOpen } = useDrawerOpen();
  const { currentPlatform } = usePlatform();
  const userIconColor = currentPlatform === Platform.DDP ? "primary" : undefined;

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleProfileClick = () => {
    setProfileOpen(true);
  };

  const handleProfileClose = () => {
    setProfileOpen(false);
  };

  return (
    <StyledMuiAppBar position="static" color="secondary" role="banner">
      <Toolbar disableGutters sx={{ px: 3 }}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{ mr: 2, display: { xs: 'block', md: 'none' } }}
          onClick={handleOpenDrawer}
        >
          <FontAwesomeSvgIcon fontSize="inherit" icon={faBars} />
        </IconButton>
        <DrawerWidthSpace display={{ xs: 'none', md: 'block' }} drawerOpen={drawerOpen} />
        <Box id={AppBarPortalId} />
        <Box sx={{ flexGrow: 1 }} />
        <DdpPlatformFeature>
          <FreeTrialButton />
        </DdpPlatformFeature>
        <Box sx={{ display: 'flex' }}>
          <Suspense fallback="loading....">
            <IconButton onClick={handleProfileClick} data-fullstory="AppBarProfile">
              <FontAwesomeSvgIcon color={userIconColor} fontSize="inherit" icon={faUser} />
            </IconButton>
            <ProfileDrawer open={profileOpen} onClose={handleProfileClose} />
          </Suspense>
        </Box>
      </Toolbar>
    </StyledMuiAppBar>
  );
}

export default AppBar;
