import BaseFile from '../../entities/BaseFile';
import { FileFilterOption } from '../../types/FileFilter';

export enum ActionType {
  SetFiles = 'SetFiles',
  SetFilterValues = 'SetFilterValues',
  ResetFilters = 'ResetFilters',
}

export interface SetFilesAction<File extends BaseFile> {
  type: ActionType.SetFiles;
  payload: File[];
}

export type SetFilesActionPayload<File extends BaseFile> = SetFilesAction<File>['payload'];

export interface SetFilterValuesAction {
  type: ActionType.SetFilterValues;
  payload: {
    filterId: string;
    values: FileFilterOption[];
  };
}

export type SetFilterValuesActionPayload = SetFilterValuesAction['payload'];

export interface ResetFiltersAction {
  type: ActionType.ResetFilters;
  payload: null;
}

export type FileFilterSystemAction<File extends BaseFile> =
  | SetFilesAction<File>
  | SetFilterValuesAction
  | ResetFiltersAction;

export function setFiles<File extends BaseFile>(payload: SetFilesActionPayload<File>): SetFilesAction<File> {
  return {
    type: ActionType.SetFiles,
    payload,
  };
}

export function setFilterValues(payload: SetFilterValuesActionPayload): SetFilterValuesAction {
  return {
    type: ActionType.SetFilterValues,
    payload,
  };
}

export function resetFilters(): ResetFiltersAction {
  return {
    type: ActionType.ResetFilters,
    payload: null,
  };
}
