import React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';

interface ErrorState {
  hasError: boolean;
}

// todo : we should rework this error boundary catcher because it's currently useless (displays white screen on error)
export default class GlobalErrorBoundary extends React.Component<RouteComponentProps, ErrorState> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch() {
    const { history } = this.props;

    console.warn('Unexpected error, redirecting to the error page');

    history.push('/');
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (!hasError) {
      return <>{children}</>;
    }
    return <></>;
  }
}

export const GlobalErrorBoundaryWithRouter = withRouter(GlobalErrorBoundary);
