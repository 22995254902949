import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { HomePageFilterState } from './types';

export const initialState: HomePageFilterState = {
  airportCode: null,
};

const homePageFilterSlice = createSlice({
  name: 'homeFilter',
  initialState,
  reducers: {
    setAirportCode: (state, { payload: airportCode }: PayloadAction<string | null>) => ({
      ...state,
      airportCode,
    }),
    resetAirportCode: (state) => ({
      ...state,
      airportCode: null,
    }),
  },
});

const homePageFilterSliceReducer = homePageFilterSlice.reducer;

const persistConfig = {
  key: 'homepage-filter',
  storage,
};

const persistedHomePageFilterSliceReducer = persistReducer(persistConfig, homePageFilterSliceReducer);

export const { setAirportCode, resetAirportCode } = homePageFilterSlice.actions;

export default persistedHomePageFilterSliceReducer;
