import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const TruncatedTypograhpy = styled(Typography, { shouldForwardProp: (prop) => prop !== 'shownLines' })<{
  shownLines?: number;
}>`
  display: -webkit-box;
  -webkit-line-clamp: ${(p) => p.shownLines ?? 3};
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export default TruncatedTypograhpy;
