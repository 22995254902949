import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import { backend } from '../common/hooks/backend';
import purchaseReducer from '../common/modules/purchase/purchaseSlice';
import snackbarReducer from '../core/notifications/snackbarSlice';
import platformSlice from '../core/platform/platformSlice';
import powerBiFiltersSlice from '../core/powerBi/powerBiFiltersSlice';
import persistedHomePageFilterSliceReducer from '../modules/ASQ/home/filter/filterSlice';
import analystReducer from '../modules/DDP/analyst/analystSlice';
import executiveReducer from '../modules/DDP/executive/executiveSlice';
import libraryReducer from '../modules/DDP/library/librarySlice';
import tutorialReducer from '../modules/DDP/tutorial/tutorialSlice';
import profileReducer from '../modules/common/profile/profileSlice';
import termsAndConditionsReducer from '../modules/common/termsAndConditions/termsAndConditionsSlice';
import { useAppDispatch, useAppSelector } from './hooks';
import { rtkQueryErrorLogger } from './logger.middleware';

const store = configureStore({
  reducer: {
    [backend.reducerPath]: backend.reducer,
    analyst: analystReducer,
    library: libraryReducer,
    profile: profileReducer,
    snackbar: snackbarReducer,
    tutorial: tutorialReducer,
    termsAndConditions: termsAndConditionsReducer,
    platform: platformSlice,
    powerBiFilters: powerBiFiltersSlice,
    homeFilter: persistedHomePageFilterSliceReducer,
    purchase: purchaseReducer,
    executive: executiveReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(backend.middleware, rtkQueryErrorLogger),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
export { persistor, useAppDispatch, useAppSelector };
