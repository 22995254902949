import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

type SubjectType = 'quoting' | 'termsOfUse' | 'termsOfSale' | 'generic';

export interface MailToLinkProps {
  subjectType?: SubjectType;
  children?: React.ReactNode;
}

export default function MailToLink({ subjectType = 'generic', children }: MailToLinkProps): ReactElement {
  const { t } = useTranslation('Common');

  const getSubjectKey = (type: SubjectType) => {
    switch (type) {
      case 'quoting':
        return 'QuotingSubject';
      case 'termsOfUse':
        return 'TermsOfUseSubject';
      case 'termsOfSale':
        return 'TermsOfSaleSubject';
      case 'generic':
      default:
        return 'GenericSubject';
    }
  };

  const recipient = 'ihub@aci.aero';
  const subject = t(`MailTo.${getSubjectKey(subjectType)}`);
  const mailToLink = `mailto:${recipient}?subject=${encodeURI(subject)}`;

  return <a href={mailToLink}>{children}</a>;
}
