import { Report } from 'powerbi-client';
import { SlicerState } from '../components/molecules/PowerBIReport';

export interface BookmarkInfo {
  slicerValue: string;
  bookmarkNames: string[];
}

export interface SlicerBookmark {
  slicerNames: string[];
  bookmarkInfos: BookmarkInfo[];
}

export async function synchronizeSlicerBookmark(
  slicerState: SlicerState,
  slicerBookmarks: SlicerBookmark[],
  report: Report,
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const bookmarksToApply: string[] = [];

    slicerBookmarks.forEach(({ slicerNames, bookmarkInfos }) => {
      if (slicerNames.includes(slicerState.name)) {
        bookmarkInfos.forEach(({ slicerValue, bookmarkNames }) => {
          if (slicerValue === slicerState.value) {
            bookmarksToApply.push(...bookmarkNames);
          }
        });
      }
    });

    const promises = bookmarksToApply.map((bookmark) => report.bookmarksManager.apply(bookmark));

    Promise.all(promises)
      .then(() => resolve())
      .catch((reason) => {
        console.error(`An error occured trying to apply bookmarks: ${bookmarksToApply.join(', ')}`, reason);
        reject(reason);
      });
  });
}
