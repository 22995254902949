import { ReactElement } from 'react';
import { Helmet } from 'react-helmet-async';
import ErrorPage from './ErrorPage';

function NotFoundPage(): ReactElement {
  return (
    <>
      <Helmet>
        <title>ACI Intelligence Hub | Not found</title>
      </Helmet>
      <ErrorPage code={404} />
    </>
  );
}

export { NotFoundPage };
