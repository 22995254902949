import { styled } from '@mui/material';
import { LayoutPaddingBox } from '../../constants/margin';
import { themeSpacingToPx } from '../../utils/theme';

const MarginCanceller = styled('div')(({ theme }) => ({
  height: `calc(100% + ${themeSpacingToPx(LayoutPaddingBox * 2)}px)`,
  width: `calc(100% + ${themeSpacingToPx(LayoutPaddingBox * 2)}px)`,
  margin: theme.spacing(-LayoutPaddingBox, -LayoutPaddingBox),
  overflow: 'hidden', // to don't display a scrollbar on the height because of the +80px
}));

export default MarginCanceller;
