import { faTimes } from '@fortawesome/pro-light-svg-icons';
import * as FullStory from '@fullstory/browser';
import { Box, Dialog, DialogContent, IconButton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Button from '../../../../common/components/atoms/Button';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { formatDateTranslate } from '../../../../common/utils/date';
import { displaySnackbar } from '../../../../core/notifications/snackbarSlice';
import { downloadDdpLibraryFile } from '../../../../services/filesDownload';
import { useAppDispatch } from '../../../../store';
import FileDto from '../entities/File';
import { getCleanedExtension, getFileExtensionIcon } from '../helpers/FileHelpers';
import { useAccessibleFilesList } from '../hooks/useAccessibleFilesList';

export type FileDetailsDialogProps = {
  file: FileDto;
  open?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
};
export default function FileDetailsDialog({ file, open, onClose }: any) {
  const { t } = useTranslation(['Library', 'Common']);
  const dispatch = useAppDispatch();

  const { accessLevel: userAccessLevel } = useAccessibleFilesList();

  const handleClose = () => {
    onClose();
  };

  if (!file) return null;

  const hasAccess = userAccessLevel.includes(file.accessRequirements);

  const handleDownload = () => {
    if (!hasAccess) return;

    FullStory.event('LibraryDownloadFile', {
      FileName: file.name,
      FileId: file.id,
    });

    downloadDdpLibraryFile(file.id, file.name, file.extension).catch((err) => {
      console.error(err);
      dispatch(displaySnackbar({ message: t('ErrorDownload', { ns: 'Common' }), severity: 'error' }));
    });
  };

  const FileIcon = getFileExtensionIcon(file.extension);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent sx={{ maxWidth: 930, height: 600, overflow: 'hidden', p: 7, pr: 5, position: 'relative' }}>
        <IconButton
          sx={{ position: 'absolute', top: 24, right: 24 }}
          aria-label="close"
          onClick={handleClose}
          data-fullstory="FileDetailsClose"
        >
          <FontAwesomeSvgIcon fontSize="inherit" icon={faTimes} />
        </IconButton>
        <Stack direction="row" gap={6} height="100%">
          <Box flex={45} height="100%" overflow="hidden" display="flex" justifyContent="center" alignItems="center">
            <img alt="" src={file.coverImageUrl} style={{ height: '480px' }} />
          </Box>
          <Stack flex={55}>
            <Typography variant="h6" mb={2}>
              {file.name}
            </Typography>
            <Stack direction="row" gap={0.5} mb={3}>
              <Typography p={1} variant="pMedium" fontWeight="700" border="1px solid #081F3D" bgcolor="#F6F9FE">
                {formatDateTranslate(t, 'FormattedDate', new Date(file.lastModified), 'Common')}
              </Typography>
              <Stack direction="row" alignItems="center" p={1} gap={0.5} border="1px solid #081F3D">
                <FileIcon style={{ color: 'black', fontSize: 15 }} />
                <Typography variant="pSmall">{getCleanedExtension(file.extension)}</Typography>
              </Stack>
            </Stack>
            <Typography flex={1} overflow="auto" mb={3}>
              {file.description}
            </Typography>
            <Button
              disabled={!hasAccess}
              sx={{
                mr: 1,
                width: 'min-content',
                px: 4,
                py: 2,
              }}
              variant="gradient"
              onClick={handleDownload}
            >
              {t('DownloadItem')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
