import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const OptionButton = styled(IconButton)(({ theme }) => ({
  '.MuiTouchRipple-child': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:hover': {
    backgroundColor: 'rgba(86, 146, 201, 0.1)',
  },
}));

export default OptionButton;
