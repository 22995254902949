import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from '../../../../../core/enums/platform.enum';
import { displaySnackbar } from '../../../../../core/notifications/snackbarSlice';
import { usePlatform } from '../../../../../core/platform/hooks';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import Button from '../../../../components/atoms/Button';
import DialogCloseButton from '../../../../components/atoms/DialogCloseButton';
import { useGetUserQuery, useUpdateUserMutation } from '../../../../hooks/backend';
import { useFreeTrialAvailabilityQuery, useGetAllPricingProductsQuery } from '../../../../hooks/usePricingEndpoints';
import {
  closeFreeTrialInformationPopup,
  openFreeTrialDialog,
  openFreeTrialInformationPopup,
} from '../../purchaseSlice';

const CenteredImage = styled('img')(() => ({
  display: 'block',
  margin: 'auto',
}));

export default function FreeTrialInformationPopup() {
  const { t } = useTranslation('Pricing');
  const [updateUser] = useUpdateUserMutation();
  const { data: user } = useGetUserQuery();
  const { data: freeTrialAvailability } = useFreeTrialAvailabilityQuery();
  const { isFreeTrialInformationPopupOpen } = useAppSelector((state) => state.purchase);
  const { data: pricingProducts } = useGetAllPricingProductsQuery();
  const dispatch = useAppDispatch();
  const { currentPlatform } = usePlatform();
  const popupCanAppear =
    user?.hasAcceptedTermsAndConditions &&
    user.hasRefusedFreeTrialInformationPopup === false &&
    freeTrialAvailability?.freeTrialPriceId;

  const handleYes = () => {
    dispatch(closeFreeTrialInformationPopup());

    const freeTrialProduct = pricingProducts?.find(
      (product) => product.priceId === freeTrialAvailability?.freeTrialPriceId,
    );
    if (freeTrialProduct) {
      dispatch(openFreeTrialDialog(freeTrialProduct));
    }
  };

  const handleNo = () => {
    dispatch(closeFreeTrialInformationPopup());
    updateUser({ hasRefusedFreeTrialInformationPopup: true }).catch((err) => {
      console.error(err);
      dispatch(displaySnackbar({ message: t('Error'), severity: 'error' }));
    });
  };

  const handleNotNow = () => {
    dispatch(closeFreeTrialInformationPopup());
  };

  useEffect(() => {
    if (currentPlatform === Platform.DDP && popupCanAppear) {
      dispatch(openFreeTrialInformationPopup());
    }
  }, [popupCanAppear, currentPlatform, dispatch]);

  if (user === undefined) {
    return null;
  }

  return (
    <Dialog open={isFreeTrialInformationPopupOpen}>
      <DialogContent sx={{ width: 480 }}>
        <DialogCloseButton closeDialog={handleNotNow} data-fullstory="FreeTrialInformationPopupClose" />
        <Typography variant="h4" marginTop={6} textAlign="center">
          {t('FreeTrialInformationPopup.FreeTrial')}
        </Typography>
        <Typography textAlign="center" marginY={5}>
          {t('FreeTrialInformationPopup.WelcomeToIHub')}
          <br />
          {t('FreeTrialInformationPopup.FirstTimeUser')}
          <br />
          {t('FreeTrialInformationPopup.ParticipateInFreeTrial')}
        </Typography>
        <CenteredImage src="/images/LogoIHubBlue.png" height="75px" alt="logo" />
      </DialogContent>
      <DialogActions sx={{ paddingY: 6, marginX: 'auto' }}>
        <Button variant="gradient" onClick={handleYes} data-fullstory="FreeTrialInformationPopupYes">
          {t('FreeTrialInformationPopup.Yes')}
        </Button>
        <Button
          variant="text"
          sx={{ textDecoration: 'underline' }}
          onClick={handleNo}
          data-fullstory="FreeTrialInformationPopupNo"
        >
          {t('FreeTrialInformationPopup.No')}
        </Button>
        <Button
          variant="text"
          sx={{ textDecoration: 'underline' }}
          onClick={handleNotNow}
          data-fullstory="FreeTrialInformationPopupNotNow"
        >
          {t('FreeTrialInformationPopup.NotNow')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
