/* eslint-disable no-param-reassign */
import { AsqAccess, AsqAirportAccess } from '../../../../../../../core/entities/asqAccess';
import FileModule from '../../../../enums/FileModule.enum';
import { FileFilterOption } from '../../../../types/FileFilter';
import { airportCodeFileFilterOptionComparer, moduleFileFilterOptionComparer } from '../../../../utils/filter';
import { AirportFileQueryArgs } from '../fetchAirportFiles';
import { FetchingFiltersState, SimpleFileFilterBase } from './types';

export class FetchingFiltersHelper {
  public static setAirportValues(
    draft: FetchingFiltersState,
    airportValues: FileFilterOption[],
    access: AsqAccess,
  ): void {
    draft.airportsFilter.values = airportValues;
    draft.modulesFilter.options = FetchingFiltersHelper.getModuleOptionsFromAirportValues(access, airportValues);

    draft.modulesFilter.values = draft.modulesFilter.values.filter((value) =>
      draft.modulesFilter.options.find((option) => option.key === value.key),
    );

    draft.airportFileQueryArgsList = FetchingFiltersHelper.generateQueryArgsList(
      access,
      draft.airportsFilter.values,
      draft.modulesFilter.values,
    );
  }

  public static setModuleValues(
    draft: FetchingFiltersState,
    moduleValues: FileFilterOption[],
    access: AsqAccess,
  ): void {
    draft.modulesFilter.values = moduleValues;

    draft.airportFileQueryArgsList = FetchingFiltersHelper.generateQueryArgsList(
      access,
      draft.airportsFilter.values,
      draft.modulesFilter.values,
    );
  }

  public static resetState(draft: FetchingFiltersState, newState: FetchingFiltersState): void {
    Object.assign(draft, newState);
  }

  private static getModuleOptionsFromAirportValues(
    access: AsqAccess,
    airportValues: FileFilterOption[],
  ): FileFilterOption[] {
    if (airportValues.length === 0) return [];

    const moduleAccess: AsqAirportAccess = {
      departure: false,
      arrival: false,
      commercial: false,
    };

    airportValues.forEach(({ key }) => {
      const airportAccess = access.airportAccesses[key];
      if (airportAccess) {
        if (airportAccess.departure) moduleAccess.departure = true;
        if (airportAccess.arrival) moduleAccess.arrival = true;
        if (airportAccess.commercial) moduleAccess.commercial = true;
      }
    });

    const options: FileFilterOption[] = [];

    if (moduleAccess.departure) {
      options.push({
        key: FileModule.Departure,
        label: FileModule.Departure,
      });
    }

    if (moduleAccess.arrival) {
      options.push({
        key: FileModule.Arrival,
        label: FileModule.Arrival,
      });
    }

    if (moduleAccess.commercial) {
      options.push({
        key: FileModule.Commercial,
        label: FileModule.Commercial,
      });
    }

    return options.sort(moduleFileFilterOptionComparer);
  }

  private static generateQueryArgsList(
    access: AsqAccess | undefined,
    airportValues: FileFilterOption[],
    moduleValues: FileFilterOption[],
  ): AirportFileQueryArgs[] {
    if (access === undefined) return [];

    const airportCodes = airportValues.map((e) => e.key);
    const modules = moduleValues.map((e) => e.key as FileModule);

    return modules.flatMap((module) => {
      const availableAirportCodes = FetchingFiltersHelper.getAvailableAirportCodesForModule(
        airportCodes,
        module,
        access,
      );

      return availableAirportCodes.map((airportCode) => ({ airportCode, module }));
    });
  }

  private static getAvailableAirportCodesForModule(
    airportCodes: string[],
    module: FileModule,
    access: AsqAccess,
  ): string[] {
    switch (module) {
      case FileModule.Departure:
        return airportCodes.filter((code) => access.airportAccesses[code]?.departure);
      case FileModule.Arrival:
        return airportCodes.filter((code) => access.airportAccesses[code]?.arrival);
      case FileModule.Commercial:
        return airportCodes.filter((code) => access.airportAccesses[code]?.commercial);
      default:
        return [];
    }
  }

  public static generateState(
    access: AsqAccess | undefined,
    initialAirportCode?: string,
    initialFileModule?: FileModule,
  ): FetchingFiltersState {
    const isLoading = access === undefined;

    const airportsFilter: SimpleFileFilterBase = {
      id: 'RepositoryAirportsFilter',
      labelTranslateKey: 'FileExplorer.Filters.Airport',
      options: [],
      values: [],
      isLoading,
    };

    const modulesFilter: SimpleFileFilterBase = {
      id: 'RepositoryModulesFilter',
      labelTranslateKey: 'FileExplorer.Filters.Module',
      options: [],
      values: [],
      spacing: true,
      isLoading,
    };

    if (access !== undefined) {
      const airportOptions = access.availableAirportsWithGroups.map(({ code, name }) => ({
        key: code,
        label: `${name} (${code})`,
      }));

      airportsFilter.options = airportOptions.sort(airportCodeFileFilterOptionComparer);

      if (initialAirportCode !== undefined) {
        const currentAirportOption = airportsFilter.options.find((option) => option.key === initialAirportCode);
        if (currentAirportOption) airportsFilter.values = [currentAirportOption];
      } else if (airportsFilter.options.length > 0) {
        airportsFilter.values = [airportsFilter.options[0]];
      }

      modulesFilter.options = FetchingFiltersHelper.getModuleOptionsFromAirportValues(access, airportsFilter.values);

      if (initialFileModule !== undefined) {
        const currentModuleOption = modulesFilter.options.find((option) => option.key === initialFileModule);
        if (currentModuleOption) modulesFilter.values = [currentModuleOption];
      } else {
        modulesFilter.values = [...modulesFilter.options];
      }
    }

    const airportFileQueryArgsList = FetchingFiltersHelper.generateQueryArgsList(
      access,
      airportsFilter.values,
      modulesFilter.values,
    );

    return {
      airportsFilter,
      modulesFilter,
      airportFileQueryArgsList,
    };
  }
}
