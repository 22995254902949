import { ReactElement } from 'react';

import { Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import BannerBox from '../../../../common/components/atoms/BannerBox';

export default function ConfidentialityBanner(): ReactElement {
  const { t } = useTranslation('Home', { keyPrefix: 'ASQ' });

  return (
    <BannerBox p={1.5} borderRadius="10px">
      <Typography component="div" variant="pMedium" color="black">
        <Trans t={t}>ConfidentialityBanner</Trans>
      </Typography>
    </BannerBox>
  );
}
