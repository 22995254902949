import { MiddlewareAPI, isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { displaySnackbar } from '../core/notifications/snackbarSlice';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    console.error(`Server error : ${action.error.message}`);
    api.dispatch(displaySnackbar({ message: 'Unexpected answer from the server', severity: 'error' }));
  }

  return next(action);
};
