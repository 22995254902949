import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { SlicersAndFilters, setCurrentPowerBiFilters } from "./powerBiFiltersSlice";
import { useAppSelector, useAppDispatch } from "../../store";
import { ArrivalModuleNav, CommercialModuleNav, DepartureModuleNav } from "../../modules/ASQ/navigation";

function usePowerBiFilters(): SlicersAndFilters {
    return useAppSelector((state) => state.powerBiFilters);
  }
  
export function useSetCurrentPowerBiFilters(): [SlicersAndFilters, (newSlicersAndFilters: SlicersAndFilters) => void] {
    const currentSlicersAndFilters = usePowerBiFilters();

    const dispatch = useAppDispatch();

    const setCurrentPlatformCallback = useCallback(
        (slicerAndFilters: SlicersAndFilters) => {
        dispatch(setCurrentPowerBiFilters(slicerAndFilters));
        },
        [dispatch],
    );

    return [currentSlicersAndFilters, setCurrentPlatformCallback];
}

export function useIsAsqPowerBiPage(): boolean {
    const { pathname } = useLocation();

    return ((DepartureModuleNav.isSelected && DepartureModuleNav.isSelected!(pathname)) ?? false)
        || ((ArrivalModuleNav.isSelected && ArrivalModuleNav.isSelected!(pathname)) ?? false)
        || ((CommercialModuleNav.isSelected && CommercialModuleNav.isSelected!(pathname)) ?? false);
}