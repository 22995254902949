/* eslint-disable react/destructuring-assignment */
import { faChevronDown, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import Autocomplete, { AutocompleteRenderGroupParams, AutocompleteRenderOptionState } from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import ListSubheader from '@mui/material/ListSubheader';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Fragment, ReactElement, SyntheticEvent, useState } from 'react';
import { ListboxComponent } from './AutoCompleteListBox';
import { FontAwesomeSvgIcon } from './FontAwesomeIcon';
import TextField from './TextField';

export interface AutoCompleteOption {
  label: string;
  key: string;
  group?: string;
}

export interface AutoCompleteProps {
  options: AutoCompleteOption[];
  loading?: boolean;
  label: string;
  width?: number | string;
  grouped?: boolean;
  value?: AutoCompleteOption[];
  disabled?: boolean;
  fullStoryTag: string;
  onChange: (value: any[]) => void;
  icon?: ReactElement;
}

const RenderOptions = (
  props: React.HTMLAttributes<HTMLLIElement>,
  option: AutoCompleteOption,
  { selected }: AutocompleteRenderOptionState,
) => (
  <li {...props} key={option.key}>
    <Checkbox checked={selected} />
    <Typography variant="pMedium">{option.label}</Typography>
  </li>
);

export const ListBoxRenderOptions = (
  prop: React.HTMLAttributes<HTMLLIElement>,
  option: AutoCompleteOption,
  state: AutocompleteRenderOptionState,
) => [prop, option, state];

const CustomPaper = (props: any) => <StyledCard sx={{ width: 500 }} {...props} />;

const RenderGroup =
  (open: boolean[], groups: string[], onClick: (group: string) => void) =>
  ({ key, group, children }: AutocompleteRenderGroupParams) => {
    const handleClick = () => {
      onClick(group);
    };

    const valueIndex = groups.findIndex((el) => el === group);

    return (
      <Fragment key={key}>
        <ClickableListSubheader key={key} onClick={handleClick}>
          {open[valueIndex] ? (
            <FontAwesomeSvgIcon fontSize="inherit" icon={faChevronDown} />
          ) : (
            <FontAwesomeSvgIcon fontSize="inherit" icon={faChevronRight} />
          )}
          <Typography variant="pMedium" fontWeight="700">
            {group}
          </Typography>
        </ClickableListSubheader>
        <Collapse key={`${key}-collapse`} in={open[valueIndex]} timeout="auto" unmountOnExit>
          <StyledList>{children}</StyledList>
        </Collapse>
      </Fragment>
    );
  };

export default function AutoComplete(props: AutoCompleteProps): ReactElement {
  const { options, label, loading, width, grouped, value, disabled, onChange, fullStoryTag, icon } = props;

  const groups = Array.from(new Set(options.map((item) => item.group ?? 'No group')));
  const [open, setOpen] = useState(Array(groups.length).fill(true));

  const handleSubGroupClick = (group: string) => {
    const valueIndex = groups.findIndex((el) => el === group);
    setOpen(Object.assign([], open, { [valueIndex]: !open[valueIndex] }));
  };

  const groupBy = grouped ? (option: AutoCompleteOption) => option.group! : undefined;
  const renderGroup = grouped ? RenderGroup(open, groups, handleSubGroupClick) : undefined;
  const renderOptions = grouped ? RenderOptions : ListBoxRenderOptions;
  const ListBox = grouped ? undefined : ListboxComponent;

  const handleMUIChanges = (_event: SyntheticEvent<Element, Event>, val: any) => {
    onChange(val);
  };

  return (
    <Autocomplete
      multiple
      disableClearable
      disableCloseOnSelect
      loading={loading}
      value={value}
      id={label}
      options={options}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option: any, val: any) => option.label === val.label}
      groupBy={groupBy}
      renderGroup={renderGroup}
      ListboxComponent={ListBox}
      sx={{ width }}
      renderInput={(params) => <TextField {...params} autoComplete="off" label={label} disabled={disabled} />}
      renderOption={renderOptions}
      PaperComponent={CustomPaper}
      renderTags={() => undefined}
      onChange={handleMUIChanges}
      data-fullstory={fullStoryTag}
      popupIcon={icon}
    />
  );
}

const StyledList = styled('ul')(({ theme }) => ({
  padding: theme.spacing(0),
}));

const ClickableListSubheader = styled(ListSubheader)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > p': {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
}));

const StyledCard = styled(Paper)(({ theme }) => ({
  '& .MuiAutocomplete-listbox': {
    padding: theme.spacing(0),
  },
  '& ul': {
    margin: 0,
  },
}));
