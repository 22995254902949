import { ReactElement } from 'react';

import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import Banner from '../../../../common/components/atoms/Banner';
import OrganizationTypography from '../../../../common/components/atoms/OrganizationTypography';
import { useGetBannerTextQuery } from '../../../../common/hooks/backend';
import { ModuleAccess } from '../../../../common/modules/purchase/entities/ModuleAccess';
import { defaultFirstName } from '../../../common/profile/constants';
import TileGrid from '../molecules/TileGrid';

export interface HomeProps {
  name?: string;
  accesses: ModuleAccess[] | undefined;
}

export default function Home({ name, accesses }: HomeProps): ReactElement {
  const { t } = useTranslation('Home');
  const { data, isLoading } = useGetBannerTextQuery();

  return (
    <>
      <Banner isLoading={isLoading} text={data?.text} />
      <section role="heading" aria-level={1}>
        <Typography variant="h2">
          {t('Welcome', { name: name === defaultFirstName ? '' : name })}
        </Typography>
        <OrganizationTypography />
      </section>
      <TileGrid accesses={accesses} />
    </>
  );
}
