import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { displaySnackbar } from '../../../../../core/notifications/snackbarSlice';
import { useAppDispatch } from '../../../../../store';
import { FileId } from '../../entities/BaseFile';
import {
  BulkDownloadErrorHandler,
  bulkDownloadFiles,
  BulkDownloadProgress,
  BulkDownloadState,
  BulkDownloadSuccessHandler,
  BulkFileBlobDownloader,
  getDatedFilename,
} from '../../utils/bulkDownload';

interface BulkDownloadReturnType {
  selectedFilesCount: number;
  setSelectedFiles: (ids: FileId[]) => void;
  started: boolean;
  progress?: BulkDownloadProgress;
  startDownload: () => void;
}

export function useBulkDownload(bulkFileBlobDownloader: BulkFileBlobDownloader): BulkDownloadReturnType {
  const { t } = useTranslation('Repository');
  const dispatch = useAppDispatch();

  const [selectedFiles, setSelectedFiles] = useState<FileId[]>([]);
  const [progress, setProgress] = useState<BulkDownloadProgress>();
  const [started, setStarted] = useState(false);

  const startDownload = useCallback(() => {
    if (!started && selectedFiles.length > 0) {
      setStarted(true);
    }
  }, [started, selectedFiles]);

  const setSelectedFilesCallback = useCallback(
    (ids: FileId[]) => {
      if (!started) setSelectedFiles(ids);
    },
    [started],
  );

  const onSuccess = useCallback<BulkDownloadSuccessHandler>(
    (zipFilename, fileIds) => {
      setStarted(false);
      setProgress(undefined);

      dispatch(
        displaySnackbar({
          message: t('Messages.BulkDownloadSuccess', { count: fileIds.length, filename: zipFilename }),
          severity: 'success',
        }),
      );
    },
    [dispatch, t],
  );

  const onError = useCallback<BulkDownloadErrorHandler>(
    ({ state, error }) => {
      console.error(error);

      setStarted(false);
      setProgress(undefined);

      if (state === BulkDownloadState.DownloadFiles) {
        dispatch(
          displaySnackbar({
            message: t('Errors.BulkDownloadFileError'),
            severity: 'error',
          }),
        );
      } else if (state === BulkDownloadState.ZipFiles) {
        dispatch(
          displaySnackbar({
            message: t('Errors.BulkZippingError'),
            severity: 'error',
          }),
        );
      }
    },
    [dispatch, t],
  );

  useEffect(() => {
    let cleanup: void | (() => void);

    if (started) {
      cleanup = bulkDownloadFiles(
        getDatedFilename('ASQ Files'),
        selectedFiles,
        bulkFileBlobDownloader,
        setProgress,
        onSuccess,
        onError,
      );
    }

    return cleanup;
  }, [started, selectedFiles, bulkFileBlobDownloader, onSuccess, onError]);

  return {
    selectedFilesCount: selectedFiles.length,
    setSelectedFiles: setSelectedFilesCallback,
    started,
    progress,
    startDownload,
  };
}
