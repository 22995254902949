const Primary = {
  100: '#003A8C',
  90: '#1A4E97',
  80: '#929498',
  30: '#B3C4DD',
  10: '#eef4fa',
};

const PrimaryContrast = {
  100: '#003A8C',
  90: '#1A4E97',
  80: '#ce93d8',
  30: '#ba68c8',
  10: '#ab47bc',
};

export { Primary, PrimaryContrast };
