import { ReactElement, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';

import { cancellablePromise } from '../../../../common/utils/promise';

export interface DisplayableData {
  title: string;
  value: string | Promise<string>;
  marginBottom?: number;
  dense?: boolean;
}

type DrawerDataFieldProps = DisplayableData;

export default function DrawerDataField({
  title,
  value,
  marginBottom = 2,
  dense = false,
}: DrawerDataFieldProps): ReactElement {
  const [finalValue, setFinalValue] = useState<string | undefined>(typeof value === 'string' ? value : undefined);

  const isLoading = finalValue === undefined;

  useEffect(() => {
    let cleanup: void | (() => void);

    if (value instanceof Promise) {
      setFinalValue(undefined);

      const { promise, cancel, isCancelled } = cancellablePromise(value);

      promise
        .then((result) => {
          if (!isCancelled()) setFinalValue(result);
        })
        .catch((error) => {
          console.error(error);
          if (!isCancelled()) setFinalValue('');
        });

      cleanup = cancel;
    } else {
      setFinalValue(value);
    }

    return cleanup;
  }, [value]);

  if (isLoading) {
    return (
      <Typography variant="pMedium" mb={marginBottom}>
        <Box display="flex" component="b" mr={0.5}>
          {title}
          <Skeleton sx={{ flex: 1 }} animation="wave" />
        </Box>
        {dense && (
          <>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </>
        )}
      </Typography>
    );
  }

  return (
    <Typography variant="pMedium" mb={marginBottom}>
      <Box component="b" mr={0.5}>
        {title}
      </Box>
      <Trans>{finalValue.replaceAll('\n', '<br />')}</Trans>
    </Typography>
  );
}
