import axios, { AxiosInstance } from 'axios';
import { getToken } from '../../core/Auth/MsalInstance';

export async function getApiClient(): Promise<AxiosInstance> {
  const token = await getToken();

  return axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
}
