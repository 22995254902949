/* eslint-disable no-param-reassign */

import { Comparer } from '../../../../../common/components/types/TableTypes';
import BaseFile, { FileId } from '../../entities/BaseFile';
import {
  FileFilterOption,
  FileFilterOptionSelector,
  FileFilterValuesSelector,
  OptionsIdsMap,
} from '../../types/FileFilter';
import { FileFilterSystemState, FileMap, SimpleFileFilter } from './types';

export class FileFilterSystemHelper {
  public static setFiles<File extends BaseFile>(draft: FileFilterSystemState<File>, files: File[]): void {
    FileFilterSystemHelper.updateFiles(draft, files);
    FileFilterSystemHelper.filter(draft);
  }

  public static setFilterValues<File extends BaseFile>(
    draft: FileFilterSystemState<File>,
    filterId: string,
    values: FileFilterOption[],
  ): void {
    FileFilterSystemHelper.updateFilterValuesById(draft, filterId, values);
    FileFilterSystemHelper.filter(draft);
  }

  public static resetFilters<File extends BaseFile>(draft: FileFilterSystemState<File>): void {
    FileFilterSystemHelper.resetFiltersValues(draft);
    FileFilterSystemHelper.filter(draft);
  }

  private static updateFiles<File extends BaseFile>(draft: FileFilterSystemState<File>, files: File[]): void {
    FileFilterSystemHelper.updateFilters(draft.filters, files);
    draft.filesMap = FileFilterSystemHelper.generateFilesMap(files);
    draft.filteredFiles = [...files];
  }

  private static updateFilters<File extends BaseFile>(filters: SimpleFileFilter<File>[], files: File[]): void {
    filters.forEach((filter) => {
      const optionsSet = new Set<string>();
      const optionsIdsMap: OptionsIdsMap = {};

      files.forEach((file) => {
        const option = filter.optionSelector(file);
        optionsSet.add(option);
        if (optionsIdsMap[option] !== undefined) optionsIdsMap[option]!.push(file.id);
        else optionsIdsMap[option] = [file.id];
      });

      const options = Array.from(optionsSet.values()).map((option) =>
        FileFilterSystemHelper.generateFilterOptionFromValue(option),
      );

      if (filter.optionComparer !== undefined) options.sort(filter.optionComparer);

      filter.options = options;
      filter.optionsIdsMap = optionsIdsMap;
    });

    filters.forEach((filter) => {
      const values = filter.valuesSelector?.(filter.options, filters) ?? [];
      FileFilterSystemHelper.updateFilterValues(filter, values);
    });
  }

  private static updateFilterValuesById<File extends BaseFile>(
    draft: FileFilterSystemState<File>,
    filterId: string,
    values: FileFilterOption[],
  ): void {
    const filter = draft.filters.find((f) => f.id === filterId);
    if (filter !== undefined) FileFilterSystemHelper.updateFilterValues(filter, values);
  }

  private static updateFilterValues<File extends BaseFile>(
    filter: SimpleFileFilter<File>,
    values: FileFilterOption[],
  ): void {
    filter.values = [...values];

    if (filter.values.length === filter.options.length || filter.values.length === 0) {
      filter.valuesIds = null;
    } else {
      const idsSet = new Set<string>();

      filter.values.forEach(({ key }) => {
        const idsForValue = filter.optionsIdsMap[key];
        if (idsForValue) idsForValue.forEach((id) => idsSet.add(id));
      });

      filter.valuesIds = Array.from(idsSet.values());
    }
  }

  private static filter<File extends BaseFile>(draft: FileFilterSystemState<File>): void {
    const idsList = draft.filters.map(({ valuesIds }) => valuesIds);

    const filteredFiles: File[] = [];
    const filesMapCount: Record<FileId, number | undefined> = {};
    let filterCount = 0;

    idsList.forEach((ids) => {
      if (ids !== null) {
        filterCount += 1;
        ids.forEach((id) => {
          if (filesMapCount[id] !== undefined) filesMapCount[id]! += 1;
          else filesMapCount[id] = 1;
        });
      }
    });

    if (filterCount === 0) {
      filteredFiles.push(...(Object.values(draft.filesMap) as File[]));
    } else {
      Object.entries(filesMapCount).forEach(([id, count]) => {
        if (count === filterCount) filteredFiles.push(draft.filesMap[id] as File);
      });
    }

    draft.filteredFiles = filteredFiles;
  }

  private static resetFiltersValues<File extends BaseFile>(draft: FileFilterSystemState<File>): void {
    draft.filters.forEach((filter) => FileFilterSystemHelper.updateFilterValues(filter, []));
  }

  private static generateFilesMap<File extends BaseFile>(files: File[]): FileMap<File> {
    const filesMap: FileMap<File> = {};

    files.forEach((file) => {
      filesMap[file.id] = file;
    });

    return filesMap;
  }

  private static generateFilterOptionFromValue(value: string): FileFilterOption {
    return {
      key: value,
      label: value,
    };
  }

  public static generateInitialFileFilter<File extends BaseFile>(
    id: string,
    labelTranslateKey: string,
    optionSelector: FileFilterOptionSelector<File>,
    optionComparer?: Comparer<FileFilterOption>,
    valuesSelector?: FileFilterValuesSelector,
  ): SimpleFileFilter<File> {
    return {
      id,
      labelTranslateKey,
      options: [],
      values: [],
      optionSelector,
      optionComparer,
      valuesSelector,
      optionsIdsMap: {},
      valuesIds: [],
    };
  }
}
