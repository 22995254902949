const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
    authority: process.env.REACT_APP_MSAL_AUTHORITY as string,
    knownAuthorities: ['ACIStagingB2C.b2clogin.com', 'AciProdB2c.b2clogin.com'],
    redirectUri: `${window.location.origin}/redirect`,
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

export const changePasswordPolicy = `https://${process.env.REACT_APP_B2CTENANT}.b2clogin.com/${
  process.env.REACT_APP_B2CTENANT
}.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1A_PASSWORDCHANGE&client_id=${
  process.env.REACT_APP_MSAL_CLIENT_ID
}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(
  msalConfig.auth.redirectUri,
)}&scope=openid&response_type=id_token`;

export default msalConfig;
