import { useContext, useMemo, useCallback } from 'react';
import { FeatureFlagKey } from './featureFlagKeys';
import { FeatureFlagContext } from './context';
import { FeatureFlags } from '../entities/featureFlags';
import { Platform } from '../enums/platform.enum';

function useFeatureFlags(): FeatureFlags {
  return useContext(FeatureFlagContext);
}

function useFeatureFlag(flagKey?: FeatureFlagKey | FeatureFlagKey[]): boolean {
  const featureFlags = useFeatureFlags();
  return isFeatureEnabled(featureFlags, flagKey);
}

function useFeatureFlagCallback(): (flagKey?: FeatureFlagKey | FeatureFlagKey[]) => boolean {
  const featureFlags = useFeatureFlags();

  return useCallback(
    (flagKey?: FeatureFlagKey | FeatureFlagKey[]): boolean => isFeatureEnabled(featureFlags, flagKey),
    [featureFlags],
  );
}

function useFeatureFlagPlatform(platform: Platform): boolean {
  const featureFlags = useFeatureFlags();
  return useMemo(() => isPlatformFeatureEnabled(featureFlags, platform), [featureFlags, platform]);
}

function isFeatureEnabled(featureFlags: FeatureFlags, flagKey?: FeatureFlagKey | FeatureFlagKey[]) {
  if (flagKey === undefined) return true;
  const keys = Array.isArray(flagKey) ? flagKey : [flagKey];
  return keys.some((key) => featureFlags[key]?.isEnabled ?? false);
}

function isPlatformFeatureEnabled(featureFlags: FeatureFlags, platformToCheck: Platform): boolean {
  return Object.values(featureFlags).some(({ platform, isEnabled }) => platform === platformToCheck && isEnabled);
}

export { useFeatureFlags, useFeatureFlagCallback, useFeatureFlag, useFeatureFlagPlatform };
