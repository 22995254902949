export function getEnv(): string {
  return process.env.REACT_APP_ENV || '';
}

export function isDevelopment(): boolean {
  return getEnv() === ENV_DEV;
}

export function isStaging(): boolean {
  return getEnv() === ENV_STAGING;
}

export function isProduction(): boolean {
  return getEnv() === ENV_PROD;
}

export function isStagingOrProduction() {
  return isStaging() || isProduction();
}

export const ENV_DEV = 'development';
export const ENV_STAGING = 'staging';
export const ENV_PROD = 'production';
