import { AsqUrlPrefix } from '../../../common/constants/urls';
import RepositoryPage from './enums/RepositoryPage.enum';

const RepositoryModuleBaseUrl = `/${AsqUrlPrefix}/repository`;

export const RepositoryRouterPath = `${RepositoryModuleBaseUrl}/*`;

export const RepositoryPagesUrls: Record<RepositoryPage, string> = {
  Airport: `${RepositoryModuleBaseUrl}/my-airport-reports`,
  Member: `${RepositoryModuleBaseUrl}/member-reports`,
  Public: `${RepositoryModuleBaseUrl}/public-reports`,
};
