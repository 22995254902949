import { ReactElement } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

interface BannerProps {
  isLoading?: boolean;
  text?: string;
  backgroundColor?: string;
}

export default function Banner({
  text,
  isLoading = false,
  backgroundColor,
}: BannerProps): ReactElement {
  if (isLoading || text === undefined || text.trim().length === 0) {
    return <></>;
  }

  return (
    <LayoutBox>
      <ContentBox sx={{ backgroundColor }}>
        <Typography variant="pMedium">
          {text}
        </Typography>
      </ContentBox>
    </LayoutBox>
  );
}

const LayoutBox = styled(Box)(({ theme }) => ({
  width: `calc(100% + ${theme.spacing(10)})`,
  marginTop: theme.spacing(-5),
  marginLeft: theme.spacing(-5),
  marginBottom: theme.spacing(5),
}));

const ContentBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 3),
  backgroundColor: "white",
}));
