import { faArrowToBottom, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { TFunction } from 'i18next';

import { ActionDef, RowDef } from '../../../../../../common/components/types/TableTypes';
import { MemberFile } from '../../../entities/MemberFile';

type Callback = ActionDef['onClick'];

export function generateRow(
  file: MemberFile,
  t: TFunction,
  onDownload: Callback,
  onInfos: Callback,
): RowDef<MemberFile> {
  return {
    id: file.id,
    data: file,
    actions: generateFileActions(t, onDownload, onInfos),
    fullStoryTag: 'AsqRepository',
  };
}

function generateFileActions(t: TFunction, onDownload: Callback, onInfos: Callback): ActionDef[] {
  return [
    {
      id: 'download',
      toolTip: t('FileExplorer.Actions.DownloadTooltip'),
      onClick: onDownload,
      icon: faArrowToBottom,
      fullStoryTag: 'RepositoryDownload',
    },
    {
      id: 'infos',
      toolTip: t('FileExplorer.Actions.DetailsTooltip'),
      onClick: onInfos,
      icon: faInfoCircle,
      fullStoryTag: 'RepositoryInfos',
    },
  ];
}
