import { AsqAccess } from '../../../../../../../core/entities/asqAccess';
import { FileFilterOption } from '../../../../types/FileFilter';
import { FetchingFiltersState } from './types';

export enum ActionType {
  SetAirportValues = 'SetAirportValues',
  SetModuleValues = 'SetModuleValues',
  ResetState = 'ResetState',
}

export interface SetAirportValuesAction {
  type: ActionType.SetAirportValues;
  payload: {
    values: FileFilterOption[];
    access: AsqAccess;
  };
}

export type SetAirportValuesActionPayload = SetAirportValuesAction['payload'];

export interface SetModuleValuesAction {
  type: ActionType.SetModuleValues;
  payload: {
    values: FileFilterOption[];
    access: AsqAccess;
  };
}

export type SetModuleValuesActionPayload = SetModuleValuesAction['payload'];

export interface ResetStateAction {
  type: ActionType.ResetState;
  payload: FetchingFiltersState;
}

export type ResetStateActionPayload = ResetStateAction['payload'];

export type FetchingFiltersAction = SetAirportValuesAction | SetModuleValuesAction | ResetStateAction;

export function setAirportValues(values: FileFilterOption[], access: AsqAccess): SetAirportValuesAction {
  return {
    type: ActionType.SetAirportValues,
    payload: { values, access },
  };
}

export function setModuleValues(values: FileFilterOption[], access: AsqAccess): SetModuleValuesAction {
  return {
    type: ActionType.SetModuleValues,
    payload: { values, access },
  };
}

export function resetState(payload: FetchingFiltersState): ResetStateAction {
  return {
    type: ActionType.ResetState,
    payload,
  };
}
