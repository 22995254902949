import {
  airportCodeComparer,
  categoryComparer,
  moduleComparer,
  periodComparer,
  yearComparer,
  titleComparer,
} from './comparer';
import { FileFilterOption } from '../types/FileFilter';
import { Comparer } from '../../../../common/components/types/TableTypes';

export const airportCodeFileFilterOptionComparer: Comparer<FileFilterOption> = (a, b) =>
  airportCodeComparer(a.key, b.key);

export const periodFileFilterOptionComparer: Comparer<FileFilterOption> = (a, b) => periodComparer(a.label, b.label);

export const yearFileFilterOptionComparer: Comparer<FileFilterOption> = (a, b) => yearComparer(a.label, b.label);

export const moduleFileFilterOptionComparer: Comparer<FileFilterOption> = (a, b) => moduleComparer(a.label, b.label);

export const categoryFileFilterOptionComparer: Comparer<FileFilterOption> = (a, b) =>
  categoryComparer(a.label, b.label);

export const titleFileFilterOptionComparer: Comparer<FileFilterOption> = (a, b) => titleComparer(a.label, b.label);
