import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { faChevronDown, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeSvgIcon } from './FontAwesomeIcon';

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];

  if (Object.prototype.hasOwnProperty.call(dataSet[0], 'group')) {
    const handleClick = () => {
      dataSet[1].handleSubGroupClick(dataSet[0].group);
    };

    const valueIndex = dataSet[1].groups.findIndex((el: string) => el === dataSet[0].group);

    return (
      <>
        <ClickableListSubheader key={dataSet[0].key} onClick={handleClick}>
          {dataSet[1].open[valueIndex] ? (
            <FontAwesomeSvgIcon fontSize="inherit" icon={faChevronDown} />
          ) : (
            <FontAwesomeSvgIcon fontSize="inherit" icon={faChevronRight} />
          )}
          <Typography variant="pMedium" fontWeight="700">
            {dataSet[0].group}
          </Typography>
        </ClickableListSubheader>
      </>
    );
  }

  return (
    <li
      {...dataSet[0]}
      style={{
        ...style,
        top: style.top as number,
      }}
      key={dataSet[1].key}
    >
      <Checkbox checked={dataSet[2].selected} />
      <Typography variant="pMedium">{dataSet[1].label}</Typography>
    </li>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
  (props: React.PropsWithChildren<React.HTMLAttributes<HTMLElement>>, ref) => {
    const { children, ...other } = props;
    const itemData: React.ReactChild[] = [];
    (children as React.ReactChild[]).forEach((item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;
    const itemSize = 42;

    const getChildSize = (child: React.ReactChild) => {
      if (Object.prototype.hasOwnProperty.call(child, 'group')) {
        return 24;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
      <div ref={ref}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight() + 2 * 8}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);

const ClickableListSubheader = styled(ListSubheader)(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  '& > p': {
    paddingLeft: theme.spacing(0.5),
    paddingTop: theme.spacing(0.5),
  },
}));
