import React, { ReactElement, useCallback, useMemo } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList, ListChildComponentProps, areEqual } from 'react-window';
import PaperTableRow, { PaperRowHeight } from '../atoms/PaperTableRow';
import { HeaderDef, RowDef } from '../types/TableTypes';

interface PaperListProps<T> {
  headers: HeaderDef<T>[];
  rows: RowDef<T>[];
  isRowSelected?: (id: string) => boolean;
  onRowSelected?: (id: string, selected: boolean) => void;
}

export default function PaperList<T>({ headers, rows, isRowSelected, onRowSelected }: PaperListProps<T>): ReactElement {
  const itemData = useMemo(
    () => ({
      headers,
      rows,
      isRowSelected,
      onRowSelected,
    }),
    [headers, rows, isRowSelected, onRowSelected],
  );

  return (
    <AutoSizer disableWidth>
      {({ height }) => (
        <FixedSizeList
          height={height - (height % PaperRowHeight)}
          width="100%"
          itemSize={PaperRowHeight}
          itemCount={rows.length}
          itemData={itemData}
          outerElementType={CustomScrollbarsVirtualList}
        >
          {MemoVirtualRow as React.FunctionComponent<ListChildComponentProps<PaperListProps<T>>>}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}

interface CustomScrollbarsProps {
  onScroll: React.UIEventHandler<any>;
  style: React.CSSProperties;
  forwardedRef: React.ForwardedRef<Scrollbars>;
  children: ReactElement;
}

const CustomScrollbars = ({ onScroll, style, forwardedRef, children }: CustomScrollbarsProps) => {
  const refSetter = useCallback(
    (scrollbars: Scrollbars) => {
      if (forwardedRef === null || typeof forwardedRef !== 'function') return;
      forwardedRef(scrollbars ?? null);
    },
    [forwardedRef],
  );

  return (
    <Scrollbars ref={refSetter} style={{ ...style, overflow: 'hidden' }} onScroll={onScroll}>
      {children}
    </Scrollbars>
  );
};

const CustomScrollbarsVirtualList = React.forwardRef<Scrollbars, Omit<CustomScrollbarsProps, 'forwardedRef'>>(
  (props, ref) => <CustomScrollbars {...props} forwardedRef={ref} />,
);

function VirtualRow<T>({
  data: { rows, headers, isRowSelected, onRowSelected },
  index,
  style,
}: ListChildComponentProps<PaperListProps<T>>): ReactElement {
  const row = rows[index];

  return (
    <div style={style}>
      <PaperTableRow row={row} headers={headers} selected={isRowSelected?.(row.id)} onRowSelected={onRowSelected} />
    </div>
  );
}

const MemoVirtualRow = React.memo(VirtualRow, areEqual);
