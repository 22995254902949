import { SvgIconProps } from '@mui/material';
import React from 'react';
import FileIcon from '../atoms/FileIcon';
import PDFIcon from '../atoms/PDFIcon';

const SupportedFileTypes = ['PDF'] as const;
type FileTypes = typeof SupportedFileTypes[number] | 'Others';

export const FileIconMap: Record<FileTypes, React.FC<SvgIconProps>> = {
  PDF: PDFIcon,
  Others: FileIcon,
};

export const getFileExtensionIcon = (extension: string) => {
  const extensionAbbreviation = getCleanedExtension(extension);
  if ((SupportedFileTypes as readonly string[]).includes(extensionAbbreviation))
    return FileIconMap[extensionAbbreviation as FileTypes];
  return FileIconMap.Others;
};

export const getCleanedExtension = (extension: string) => extension.replace(/\./g, '').toUpperCase();
