import { useCallback, useEffect, useMemo, useState } from 'react';

interface SelectItemsHookReturnType {
  allItemsSelected: boolean;
  selectedItemIds: string[];
  isItemSelected: (id: string) => boolean;
  updateItemState: (id: string, selected: boolean) => void;
  selectAllItems: () => void;
  unselectAllItems: () => void;
  toggleAllItems: () => void;
}

export function useSelectItemIds<T extends { id: string }>(items: T[]): SelectItemsHookReturnType {
  const [selectedItemsMap, setSelectedItemsMap] = useState<Partial<Record<string, boolean>>>();

  const selectedItemIds = useMemo(
    () =>
      selectedItemsMap
        ? Object.entries(selectedItemsMap)
            .filter(([, value]) => value === true)
            .map(([id]) => id)
        : [],
    [selectedItemsMap],
  );

  const allItemsSelected = selectedItemIds.length === items.length;

  const isItemSelected = useCallback((id: string) => selectedItemsMap?.[id] ?? false, [selectedItemsMap]);

  const updateItemState = useCallback((id: string, selected: boolean) => {
    setSelectedItemsMap((current) => ({ ...current, [id]: selected }));
  }, []);

  const selectAllItems = useCallback(() => {
    setSelectedItemsMap(reduceItemsToBooleanMap(items, true));
  }, [items]);

  const unselectAllItems = useCallback(() => {
    setSelectedItemsMap(reduceItemsToBooleanMap(items, false));
  }, [items]);

  const toggleAllItems = useCallback(() => {
    if (allItemsSelected) unselectAllItems();
    else selectAllItems();
  }, [allItemsSelected, selectAllItems, unselectAllItems]);

  useEffect(() => {
    unselectAllItems();
  }, [items, unselectAllItems]);

  return {
    allItemsSelected,
    selectedItemIds,
    isItemSelected,
    updateItemState,
    selectAllItems,
    unselectAllItems,
    toggleAllItems,
  };
}

function reduceItemsToBooleanMap<T extends { id: string }>(
  items: T[],
  value: boolean,
): Partial<Record<string, boolean>> {
  return items.reduce((obj, { id }) => {
    // eslint-disable-next-line no-param-reassign
    obj[id] = value;
    return obj;
  }, {} as Partial<Record<string, boolean>>);
}
