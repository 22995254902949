import { CategoryFilterId, PeriodFilterId, YearFilterId } from '../../../constants/ids';
import { MemberFile } from '../../../entities/MemberFile';
import {
  categoryFileFilterOptionComparer,
  periodFileFilterOptionComparer,
  yearFileFilterOptionComparer,
} from '../../../utils/filter';
import { periodValuesSelector, yearValuesSelector } from '../../../utils/selector';
import { FileFilterSystemHelper } from '../../fileFilterSystem/helper';
import { FileFilterSystemState } from '../../fileFilterSystem/types';

const periodFilter = FileFilterSystemHelper.generateInitialFileFilter(
  PeriodFilterId,
  'FileExplorer.Filters.Period',
  (file: MemberFile) => file.period,
  periodFileFilterOptionComparer,
  periodValuesSelector,
);

const yearFilter = FileFilterSystemHelper.generateInitialFileFilter(
  YearFilterId,
  'FileExplorer.Filters.Year',
  (file: MemberFile) => file.year,
  yearFileFilterOptionComparer,
  yearValuesSelector,
);

const categoryFilter = FileFilterSystemHelper.generateInitialFileFilter(
  CategoryFilterId,
  'FileExplorer.Filters.Category',
  (file: MemberFile) => file.category,
  categoryFileFilterOptionComparer,
);

export function generateInitialState(): FileFilterSystemState<MemberFile> {
  return {
    filters: [periodFilter, yearFilter, categoryFilter],
    filesMap: {},
    filteredFiles: [],
  };
}
