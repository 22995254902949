import { Stack } from '@mui/material';
import { ReactElement } from 'react';
import ReshapeData from './ReshapeData';
import SummaryInformationRow from './SummaryInformationRow';

export default function OptionsAndPreviewStep(): ReactElement {
  return (
    <Stack>
      <SummaryInformationRow />
      <ReshapeData />
    </Stack>
  );
}
