import Stack from '@mui/material/Stack';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MailToLink from '../../common/atoms/MailToLink';
import NewTabLink from '../../common/atoms/NewTabLink';
import { DdpUrls } from '../../urls';
import QuestionRow from '../atoms/QuestionRow';
import QuestionRowContent from '../atoms/QuestionRowContent';

interface QuestionsGroupProps {
  faqUrl: string;
}

export default function QuestionsGroup({ faqUrl }: QuestionsGroupProps): ReactElement {
  const { t } = useTranslation('Faq');
  const linkMailTo = <MailToLink subjectType="generic" />;
  const linkToPricingPage = <NewTabLink link={DdpUrls.Pricing} />;

  return (
    <Stack spacing={2} maxWidth="800px" width="100%">
      <QuestionRow id="question-1" header={t('Question1.Title')} faqUrl={faqUrl}>
        <Trans t={t} i18nKey="Question1.Text" components={{ linkMailTo }} />
      </QuestionRow>
      <QuestionRow id="question-2" header={t('Question2.Title')} faqUrl={faqUrl}>
        <Trans t={t} i18nKey="Question2.Text" components={{ linkMailTo, linkToPricingPage }} />
      </QuestionRow>
      <QuestionRow id="question-3" header={t('Question3.Title')} faqUrl={faqUrl}>
        <Trans t={t} i18nKey="Question3.Text" />
      </QuestionRow>
      <QuestionRow id="question-4" header={t('Question4.Title')} faqUrl={faqUrl}>
        <Trans t={t} i18nKey="Question4.Text" components={{ linkMailTo }} />
      </QuestionRow>
      <QuestionRow id="question-5" header={t('Question5.Title')} faqUrl={faqUrl}>
        <Trans t={t} i18nKey="Question5.Text" components={{ linkMailTo }} />
      </QuestionRow>
      <QuestionRow id="question-6" header={t('Question6.Title')} faqUrl={faqUrl}>
        <QuestionRowContent faqKey="Question6" />
      </QuestionRow>
      <QuestionRow id="question-7" header={t('Question7.Title')} faqUrl={faqUrl}>
        <QuestionRowContent faqKey="Question7" />
      </QuestionRow>
    </Stack>
  );
}
