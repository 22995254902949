import { forwardRef } from 'react';

import { SvgIcon, SvgIconProps } from '@mui/material';

import { IconDefinition } from '@fortawesome/fontawesome-common-types';

export interface FontAwesomeIconProps {
  icon: IconDefinition;
  htmlColor?: string;
}

export const FontAwesomeSvgIcon = forwardRef<SVGSVGElement, FontAwesomeIconProps & SvgIconProps>(
  (props: FontAwesomeIconProps, ref) => {
    const { icon, htmlColor } = props;
    const {
      icon: [width, height, , , svgPathData],
    } = icon;

    return (
      <SvgIcon {...props} ref={ref} viewBox={`0 0 ${width} ${height}`} htmlColor={htmlColor}>
        {typeof svgPathData === 'string' ? (
          <path d={svgPathData} />
        ) : (
          svgPathData.map((d: string, i: number) => <path style={{ opacity: i === 0 ? 0.4 : 1 }} d={d} />)
        )}
      </SvgIcon>
    );
  },
);
