import { ReactElement } from 'react';

import { Chip, Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useSetCurrentPowerBiFilters } from '../../../core/powerBi/hooks';

export default function PowerBiFiltersDisplay(): ReactElement {
  const [currentPowerBiFilters,] = useSetCurrentPowerBiFilters();
  const { t } = useTranslation('Navigation');
  const primaryFiltersOrder = ['airport', 'airport terminal', 'period', 'compared to', 'compared to ', 'value', 'subscription'];
  const sortedPrimaryFilters = [...currentPowerBiFilters.primaryFilters].filter(p => primaryFiltersOrder.indexOf(p.displayName.toLowerCase()) >= 0).sort((a, b) => {
    const aIndex = primaryFiltersOrder.indexOf(a.displayName.toLowerCase());
    const bIndex = primaryFiltersOrder.indexOf(b.displayName.toLowerCase());
    if (aIndex === -1) {
      return 1;
    }
    if (bIndex === -1) {
      return -1;
    }
    return aIndex - bIndex;
  });

  return (
    <StyledBox mx={2} mb={3}>
      {currentPowerBiFilters.primaryFilters && currentPowerBiFilters.primaryFilters.length > 0 && (
        <>
          <Typography variant="h6">{t(`PowerBi.PrimaryFilters`)}</Typography>
          {sortedPrimaryFilters.map((displayFilter, i) => (
            <div key={`${displayFilter.displayName}primary${i.toString()}`}>
              <Typography variant="pMedium">{displayFilter.displayName}:</Typography>
              {displayFilter.values.map((value, j) => (<StyledChip key={`${value}${j.toString()}`} label={value}/>))}
            </div>
          ))}
          <Box mt={1} />
        </>
      )}
      {currentPowerBiFilters.secondaryFilters && currentPowerBiFilters.secondaryFilters.length > 0 && (
        <>
          <Typography variant="h6">{t(`PowerBi.SecondaryFilters`)}</Typography>
          {currentPowerBiFilters.secondaryFilters.map((displayFilter, i) => (
            <div key={`${displayFilter.displayName}secondary${i.toString()}`}>
              <Typography variant="pMedium">{displayFilter.displayName}:</Typography>
              {displayFilter.values.map((value, j) => (<StyledChip key={`${value}${j.toString()}`} label={value}/>))}
            </div>
          ))}
        </>
      )}
    </StyledBox>
  );
}

const StyledBox = styled(Box)(() => ({
  height: '100%',
  overflowY: "auto",
  whiteSpace: "pre-wrap",
}));

const StyledChip = styled(Chip)(({theme}) => ({
  margin: '2px',
  backgroundColor: theme.palette.primary.light,

  // For multiline chips
  padding: '6px 2px',
  height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
}));
