import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useFreeTrialAvailabilityQuery,
  useGetAllPricingProductsQuery,
} from '../../../../common/hooks/usePricingEndpoints';
import { ModuleAccessStatus } from '../../../../common/modules/purchase/enums/moduleAccessStatus';
import { openUpgradeToFullPlanDialog } from '../../../../common/modules/purchase/purchaseSlice';
import { useAppDispatch } from '../../../../store';
import { ProductRecurringInterval } from '../../../DDP/pricing/enums/ProductReccuringInterval';
import useCustomerPortal from '../../../DDP/pricing/hooks/useCustomerPortal';

export interface ModuleAccessRowActionProps {
  accessStatus: ModuleAccessStatus;
  recurringInterval: ProductRecurringInterval;
}

export default function ModuleAccessRowAction({
  accessStatus,
  recurringInterval,
}: ModuleAccessRowActionProps): ReactElement | null {
  const { t } = useTranslation('Modules');
  const dispatch = useAppDispatch();
  const { customerPortalUrl } = useCustomerPortal();
  const { data: pricingProducts } = useGetAllPricingProductsQuery();
  const { data: freeTrialAvailabilityData } = useFreeTrialAvailabilityQuery();

  const handleOpenUpgradeToFullPlanDialog = () => {
    const freeTrialProduct = pricingProducts?.find((p) => p.priceId === freeTrialAvailabilityData?.freeTrialPriceId);
    if (freeTrialProduct) {
      dispatch(openUpgradeToFullPlanDialog(freeTrialProduct));
    }
  };

  if (recurringInterval !== ProductRecurringInterval.None) {
    if (accessStatus === ModuleAccessStatus.Active) {
      return (
        <Button
          sx={{ padding: 0, textDecoration: 'underline' }}
          href={customerPortalUrl}
          target="_blank"
          color="primary"
        >
          {t('Actions.Manage')}
        </Button>
      );
    }
    if (accessStatus === ModuleAccessStatus.Trialing) {
      return (
        <>
          <Button sx={{ padding: 0, textDecoration: 'underline' }} onClick={handleOpenUpgradeToFullPlanDialog}>
            {t('Actions.Upgrade')}
          </Button>
        </>
      );
    }
  }
  return <></>;
}
