import { ReactElement } from 'react';

import { Menu, MenuProps } from '@mui/material';
import { useIsMobile } from '../../hooks/hooks';
import { NavSubItemProps } from '../../types/NavItem';
import ASQDrawerMenuLink from './ASQDrawerMenuLink';

interface ASQDrawerMenuProps {
  open: boolean;
  onClose: () => void;
  onNavSubItemClick: (navSubItem: NavSubItemProps) => void;
  navSubItems: NavSubItemProps[];
  anchorEl: HTMLElement | null;
}

function ASQDrawerMenu({
  open,
  onClose,
  onNavSubItemClick,
  navSubItems,
  anchorEl,
}: ASQDrawerMenuProps): ReactElement | null {
  const isMobile = useIsMobile();

  if (navSubItems.length === 0) return null;

  const menuPositionProps: Partial<MenuProps> = isMobile
    ? {}
    : {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={onClose} {...menuPositionProps}>
      {navSubItems.map((navSubItem) => (
        <ASQDrawerMenuLink
          navSubItem={navSubItem}
          onClose={onClose}
          onClick={onNavSubItemClick}
          key={navSubItem.translateKey}
        />
      ))}
    </Menu>
  );
}

export default ASQDrawerMenu;
