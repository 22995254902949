import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export default function PDFIcon(props: SvgIconProps) {
  return (
    <SvgIcon sx={{ color: '#E55757' }} {...props} viewBox="0 0 18 24">
      <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.52656 12.0047C8.29219 11.2547 8.29688 9.80625 8.43281 9.80625C8.82656 9.80625 8.78906 11.5359 8.52656 12.0047ZM8.44687 14.2172C8.08594 15.1641 7.63594 16.2469 7.11563 17.1562C7.97344 16.8281 8.94375 16.35 10.0641 16.1297C9.46875 15.6797 8.89687 15.0328 8.44687 14.2172ZM4.03594 20.0672C4.03594 20.1047 4.65469 19.8141 5.67188 18.1828C5.35781 18.4781 4.30781 19.3313 4.03594 20.0672ZM11.625 7.5H18V22.875C18 23.4984 17.4984 24 16.875 24H1.125C0.501562 24 0 23.4984 0 22.875V1.125C0 0.501562 0.501562 0 1.125 0H10.5V6.375C10.5 6.99375 11.0063 7.5 11.625 7.5ZM11.25 15.5531C10.3125 14.9812 9.68906 14.1938 9.24844 13.0312C9.45938 12.1641 9.79219 10.8469 9.53906 10.0219C9.31875 8.64375 7.55156 8.77969 7.29844 9.70312C7.06406 10.5609 7.27969 11.7703 7.67813 13.3125C7.13438 14.6062 6.33281 16.3406 5.76562 17.3344C5.76094 17.3344 5.76094 17.3391 5.75625 17.3391C4.48594 17.9906 2.30625 19.425 3.20156 20.5266C3.46406 20.85 3.95156 20.9953 4.20938 20.9953C5.04844 20.9953 5.88281 20.1516 7.07344 18.0984C8.28281 17.7 9.60938 17.2031 10.7766 17.0109C11.7937 17.5641 12.9844 17.925 13.7766 17.925C15.1453 17.925 15.2391 16.425 14.7 15.8906C14.0484 15.2531 12.1547 15.4359 11.25 15.5531ZM17.6719 4.92188L13.0781 0.328125C12.8672 0.117188 12.5813 0 12.2812 0H12V6H18V5.71406C18 5.41875 17.8828 5.13281 17.6719 4.92188ZM14.1984 16.8891C14.3906 16.7625 14.0813 16.3312 12.1922 16.4672C13.9313 17.2078 14.1984 16.8891 14.1984 16.8891Z"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
}
