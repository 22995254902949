import { useMemo } from 'react';
import { AsqAccess } from '../../../../../../../core/entities/asqAccess';
import FileModule from '../../../../enums/FileModule.enum';

import { FileFilterBase, FileFilterOption } from '../../../../types/FileFilter';
import { useModulesFilter } from './modulesFilter';
import { MemberFileQueryArgs } from '../fetchMemberFiles';
import { useAsqAccess } from '../../../../../../../common/utils/accessHelper';

interface FetchingFiltersHookReturnType {
  modulesFilter: FileFilterBase;
  isFilterLoading: boolean;
  queryArgsList: MemberFileQueryArgs[];
}

export function useFetchingFilters(): FetchingFiltersHookReturnType {
  const { access } = useAsqAccess();

  const modulesFilter = useModulesFilter();

  const queryArgsList = useMemo(
    () => generateQueryArgsList(access, modulesFilter.values),
    [access, modulesFilter.values],
  );

  return {
    modulesFilter,
    isFilterLoading: modulesFilter.isLoading,
    queryArgsList,
  };
}

function generateQueryArgsList(access: AsqAccess | undefined, moduleValues: FileFilterOption[]): MemberFileQueryArgs[] {
  if (access === undefined) return [];

  const queryArgsList: MemberFileQueryArgs[] = [];

  const hasDeparture = moduleValues.find(({ key }) => key === FileModule.Departure) !== undefined;
  if (hasDeparture && access.hasDepartureGeneralAccess) queryArgsList.push({ module: FileModule.Departure });

  const hasArrival = moduleValues.find(({ key }) => key === FileModule.Arrival) !== undefined;
  if (hasArrival && access.hasArrivalGeneralAccess) queryArgsList.push({ module: FileModule.Arrival });

  const hasCommercial = moduleValues.find(({ key }) => key === FileModule.Commercial) !== undefined;
  if (hasCommercial && access.hasCommercialGeneralAccess) queryArgsList.push({ module: FileModule.Commercial });

  return queryArgsList;
}
