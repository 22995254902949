import { ReactElement } from 'react';

import { Box } from '@mui/material';
import LoadingSpinner from '../../../../common/components/molecules/LoadingSpinner';
import { useExecutiveReportsQuery } from '../../../../common/hooks/backend';
import FreeTrialForAccessDialog from '../../../../common/modules/purchase/components/dialogs/FreeTrialForAccessDialog';
import PurchaseForAccessDialog from '../../../../common/modules/purchase/components/dialogs/PurchaseForAccessDialog';
import { ModuleKey } from '../../../../common/modules/purchase/enums/moduleKey';
import ReportCard from '../molecules/ReportCard';

export interface ExecutivePageProps {
  hasAccess: boolean;
  isFreeTrial: boolean;
}

export default function ExecutiveHomePage(props: ExecutivePageProps): ReactElement {
  const { hasAccess, isFreeTrial } = props;

  const { data: reports, isLoading } = useExecutiveReportsQuery();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {!hasAccess && (
        <>
          <FreeTrialForAccessDialog moduleKey={ModuleKey.Executive} />
          <PurchaseForAccessDialog moduleKey={ModuleKey.Executive} />
        </>
      )}
      <Box display="flex" flexWrap="wrap">
        {reports?.map((report) => (
          <ReportCard key={report.reportId} report={report} hasAccess={hasAccess} isFreeTrial={isFreeTrial} />
        ))}
      </Box>
    </>
  );
}
