import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import MailToLink from '../../common/atoms/MailToLink';

export default function Header(): ReactElement {
  const { t } = useTranslation('Faq');

  return (
    <Stack textAlign="center" gap={2}>
      <Typography color="primary.main" variant="h2">
        {t('HeaderTitle')}
      </Typography>
      <Typography color="primary.main" variant="h4">
        {t('HeaderContactUs')} <MailToLink>{t('Common:Here')}</MailToLink>
      </Typography>
      <Typography color="primary.main" variant="h6" py={2}>
        {t('HeaderSubtitle')}
      </Typography>
    </Stack>
  );
}
