import { createContext, ReactElement, useMemo } from 'react';
import { useGetFeatureFlagsQuery } from '../../common/hooks/backend';
import { FeatureFlags } from '../entities/featureFlags';

type FeatureFlagQueryState = { isLoading: boolean; featureFlags: FeatureFlags };

function useFeatureFlagsQuery(): FeatureFlagQueryState {
  const { data: featureFlagsDtos = [], isLoading } = useGetFeatureFlagsQuery();

  const featureFlags = useMemo(
    () =>
      featureFlagsDtos.reduce<FeatureFlags>(
        (obj, { name, isEnabled, platform }) => ({
          ...obj,
          [name]: { isEnabled, platform },
        }),
        {},
      ),
    [featureFlagsDtos],
  );

  return { isLoading, featureFlags };
}

const FeatureFlagContext = createContext<FeatureFlags>({});

type ProviderProps = { children: React.ReactNode; fallback?: React.ReactElement | string };

function FeatureFlagContextProvider({ children, fallback }: ProviderProps): ReactElement | null {
  const { isLoading, featureFlags } = useFeatureFlagsQuery();

  if (isLoading) return fallback ? <>{fallback}</> : null;

  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
}

export { FeatureFlagContext, FeatureFlagContextProvider };

