import { ReactElement } from 'react';

import { Box, IconButton, Drawer as MuiDrawer, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import LoadingPromiseButton from '../../../../common/components/atoms/LoadingPromiseButton';
import DrawerDataField, { DisplayableData } from '../atoms/DrawerDataField';

export interface DetailsDrawerProps {
  open: boolean;
  onClose: () => void;
  onDowload?: () => Promise<void>;
  data?: DisplayableData[];
}

export default function DetailsDrawer({ open, onClose, onDowload, data }: DetailsDrawerProps): ReactElement {
  const { t } = useTranslation('Repository', { keyPrefix: 'FileExplorer.Details' });

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box p={5}>
        <Box display="flex" justifyContent="end">
          <IconButton onClick={onClose}>
            <FontAwesomeSvgIcon fontSize="inherit" icon={faTimes} />
          </IconButton>
        </Box>
        <Typography variant="h4">{t('Title')}</Typography>
        <Box py={5}>
          {data?.map((props) => (
            <DrawerDataField key={props.title} {...props} />
          ))}
          {onDowload !== undefined && (
            <LoadingPromiseButton variant="contained" onClick={onDowload} fullstory="RepositoryDownloadInDetails">
              {t('DownloadButton')}
            </LoadingPromiseButton>
          )}
        </Box>
      </Box>
    </Drawer>
  );
}

const Drawer = styled(MuiDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '460px',
    boxShadow: Array.from(theme.shadows)[26],
  },
  '& .MuiBackdrop-root': {
    opacity: '0 !important',
  },
  [theme.breakpoints.down('md')]: {
    '& .MuiDrawer-paper': {
      width: '100%',
    },
  },
}));
