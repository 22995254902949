import { Box, Checkbox, FormControlLabel, Stack } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MailToLink from '../../../../../modules/DDP/common/atoms/MailToLink';
import NewTabLink from '../../../../../modules/DDP/common/atoms/NewTabLink';
import useCustomerPortal from '../../../../../modules/DDP/pricing/hooks/useCustomerPortal';
import { AppUrls } from '../../../../../routes/urls';
import { useAppDispatch } from '../../../../../store';
import { changeModifiedForm, changeTermsAndConditions } from '../../purchaseSlice';
import PaymentCardForm from './PaymentCardForm';
import ProductsSummary from './ProductsSummary';

interface PaymentStepProps {
  paymentInfoIsAlreadySaved?: boolean;
  activateFreeTrialIfAvailable?: boolean;
  children?: ReactElement;
}

export default function PaymentStep(props: PaymentStepProps): ReactElement {
  const { paymentInfoIsAlreadySaved = false, activateFreeTrialIfAvailable = false, children } = props;
  const { t } = useTranslation('Pricing');
  const dispatch = useAppDispatch();
  const { customerPortalUrl } = useCustomerPortal();

  const handleTermsAndConditionsCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(changeTermsAndConditions(event.target.checked));
    dispatch(changeModifiedForm(true));
  };

  const linkTermsOfUse = <NewTabLink link={AppUrls.TermsOfUse}>{t('Common:TermsOfUse')}</NewTabLink>;
  const linkTermsOfSale = <NewTabLink link={AppUrls.TermsOfSale}>{t('Common:TermsOfSale')}</NewTabLink>;

  return (
    <Stack mt={5} direction="column" sx={{ gap: 1.5, textAlign: 'center' }}>
      <Typography variant="h4">{t(`PurchaseDialog.Payment`)}</Typography>
      <Typography variant="pMedium" sx={{ mx: 7 }}>
        {paymentInfoIsAlreadySaved ? (
          <>
            {t('PurchaseDialog.PaymentInfoSaved')}
            <Link href={customerPortalUrl} target="_blank">
              {t('Common:Here')}
            </Link>
            .
          </>
        ) : (
          t(`PurchaseDialog.ProvideInformation`)
        )}
      </Typography>

      <ProductsSummary isUpgrade={paymentInfoIsAlreadySaved} />

      <Typography variant="pMedium">
        {t('NeedQuote')} <MailToLink subjectType="quoting">{t('Common:Here')}</MailToLink>.
      </Typography>

      {!paymentInfoIsAlreadySaved && (
        <Box sx={{ my: 2, mx: 2 }}>
          <PaymentCardForm activateFreeTrialIfAvailable={activateFreeTrialIfAvailable} />
        </Box>
      )}
      {children}

      <FormControlLabel
        sx={{ mx: 2, position: 'relative' }}
        label={
          <Box textAlign="left" sx={{ position: 'absolute', top: 11 }}>
            <Typography variant="pSmall">
              <Trans t={t} components={{ linkTermsOfUse, linkTermsOfSale }}>
                PurchaseDialog.AgreeTerms
              </Trans>
            </Typography>
            <Typography variant="pXSmall">{t('PurchaseDialog.PersonalData')}</Typography>
          </Box>
        }
        control={<Checkbox onChange={handleTermsAndConditionsCheck} />}
      />
    </Stack>
  );
}
