import * as FullStory from '@fullstory/browser';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DdpUrls } from '../../../../../modules/DDP/urls';
import { useAppDispatch, useAppSelector } from '../../../../../store';
import DialogCloseButton from '../../../../components/atoms/DialogCloseButton';
import { PurchaseDialogStep } from '../../entities/PurchaseDialogStep';
import { ModuleKey } from '../../enums/moduleKey';
import { changeActiveStep, closeDialog } from '../../purchaseSlice';
import ExitConfirmationDialog from '../molecules/ExitConfirmationDialog';
import PurchaseDialogActions from '../molecules/PurchaseDialogActions';

export interface BasePurchaseDialogProps {
  steps: PurchaseDialogStep[];
  isDialogOpen: boolean;
  onSave: () => void | Promise<void>;
  fullstoryEventPrefix: string;
}

export default function BasePurchaseDialog(props: BasePurchaseDialogProps): ReactElement {
  const { steps, isDialogOpen, onSave, fullstoryEventPrefix } = props;
  const { currentStep, modifiedForm, selectedProduct } = useAppSelector((state) => state.purchase);
  const [isLeavingModalOpen, setIsLeavingModalOpen] = useState(false);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    FullStory.event(`${fullstoryEventPrefix} - Open`, {
      ProductPriceId: selectedProduct.priceId,
      ProductId: selectedProduct.id,
    });
  }, [selectedProduct, fullstoryEventPrefix]);

  const CurrentStepComponent = currentStep < steps.length ? steps[currentStep].component : steps[0].component;

  const handleNext = () => {
    dispatch(changeActiveStep(currentStep + 1));
  };

  const handleBack = () => {
    dispatch(changeActiveStep(currentStep - 1));
  };

  const handleClose = () => {
    if (modifiedForm) {
      setIsLeavingModalOpen(true);
    } else {
      dispatch(closeDialog());
      if (currentStep === steps.length - 1) {
        switch (selectedProduct.allowsAccessTo) {
          case ModuleKey.Analyst:
            history.push(DdpUrls.AnalystModuleReportCreation);
            break;
          case ModuleKey.AnalystHistorical:
            history.push(DdpUrls.AnalystModuleHistoricalData);
            break;
          case ModuleKey.Executive:
            history.push(DdpUrls.ExecutiveModuleReports);
            break;
          case ModuleKey.Library:
            history.push(DdpUrls.LibraryModuleTraffic);
            break;
          default: {
            history.push(DdpUrls.Home);
          }
        }
      }
    }
  };

  const callbackCloseDialog = (userConfirmation: boolean) => {
    if (userConfirmation) {
      dispatch(closeDialog());
    }

    setIsLeavingModalOpen(false);
  };

  return (
    <>
      <ExitConfirmationDialog isOpen={isLeavingModalOpen} onClose={callbackCloseDialog} />

      <Dialog open={isDialogOpen} onClose={handleClose}>
        <DialogContent sx={{ width: 480 }}>
          {currentStep !== steps.length - 1 && (
            <DialogCloseButton closeDialog={handleClose} data-fullstory="PurchaseDialogClose" />
          )}
          <CurrentStepComponent />
        </DialogContent>
        <PurchaseDialogActions
          fullstoryEventPrefix={fullstoryEventPrefix}
          currentStep={currentStep}
          steps={steps}
          onNext={handleNext}
          onClose={handleClose}
          onSave={onSave}
          onBack={handleBack}
        />
      </Dialog>
    </>
  );
}
