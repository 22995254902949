import React from 'react';

import { Box, Checkbox, Paper, Typography, styled } from '@mui/material';

import { ThemeSpacing } from '../../../core/theme/LightTheme';
import { ActionWidth, HeaderDef, HeaderType, RowDef } from '../types/TableTypes';
import PaperTableActions from './PaperTableActions';

export interface PaperTableRowProps<T> {
  headers: HeaderDef<T>[];
  row: RowDef<T>;
  selected?: boolean;
  onRowSelected?: (id: string, selected: boolean) => void;
}

export const PaperRowHeightSpacing = 10;
export const PaperRowBottomSpacing = 2;
export const PaperRowHeight = ThemeSpacing * (PaperRowHeightSpacing + PaperRowBottomSpacing);

export default function PaperTableRow<T>({ headers, row, selected, onRowSelected }: PaperTableRowProps<T>) {
  return (
    <PaperRow clickable={!!row.onClick} onClick={row.onClick} data-fullstory={row.fullStoryTag}>
      <Box display="flex" px={3} py={3.5} height={(theme) => theme.spacing(PaperRowHeightSpacing)}>
        {headers.map((header) => {
          if (header.type === HeaderType.Select) {
            return (
              <Box key={header.id} display="flex" alignItems="center" flex={header.flexWidth}>
                <Checkbox checked={selected} onChange={(_, value) => onRowSelected?.(row.id, value)} />
              </Box>
            );
          }

          if (header.type === HeaderType.Action) {
            if (row.actions) {
              return (
                <Box flex={header.flexWidth} minWidth={header.maxActions * ActionWidth} mt={-1} key={header.id}>
                  <PaperTableActions actions={row.actions} />
                </Box>
              );
            }
            return null;
          }

          if (header.type === HeaderType.Data) {
            if (header.key) {
              const data = row.data[header.key];

              if (React.isValidElement(data)) {
                return (
                  <Box key={header.id} display="flex" alignItems="center" flex={header.flexWidth}>
                    {data}
                  </Box>
                );
              }
              return (
                <Typography
                  key={header.id}
                  variant="pMedium"
                  color={header.rowsColor ?? 'inherit'}
                  flex={header.flexWidth}
                >
                  {data}
                </Typography>
              );
            }
          }

          return null;
        })}
      </Box>
    </PaperRow>
  );
}

const PaperRow = styled(Paper, { shouldForwardProp: (prop) => prop !== 'clickable' })<{ clickable: boolean }>(
  ({ clickable, theme }) => ({
    ...(clickable && { cursor: 'pointer' }),
    color: theme.palette.primaryText[100],
    marginBottom: theme.spacing(PaperRowBottomSpacing),
  }),
);
