import { ReactElement } from 'react';

import { Link } from 'react-router-dom';
import Button from '../../../../common/components/atoms/Button';

export interface ActionButtonProps {
  id: string;
  title: string;
  variant?: 'text' | 'contained' | 'outlined' | undefined;
  url?: string;
  onClick?: () => void;
  margin?: boolean;
}

function ActionButton({ id, title, variant, url, onClick, margin = false }: ActionButtonProps): ReactElement {
  if (url !== undefined) {
    return (
      <Button
        sx={{ mr: margin ? 2 : 0 }}
        component={Link}
        to={url}
        role="link"
        variant={variant}
        data-fullstory={id}
        onClick={onClick}
      >
        {title}
      </Button>
    );
  }

  return (
    <Button sx={{ mr: margin ? 2 : 0 }} role="button" variant={variant} data-fullstory={id} onClick={onClick}>
      {title}
    </Button>
  );
}

export interface TileActionsProps {
  primaryAction?: ActionButtonProps;
  secondaryAction?: ActionButtonProps;
}

export default function TileActions({ primaryAction, secondaryAction }: TileActionsProps): ReactElement {
  return (
    <>
      {primaryAction && <ActionButton {...primaryAction} />}
      {secondaryAction && <ActionButton {...secondaryAction} />}
    </>
  );
}
