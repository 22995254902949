import { ReactElement } from 'react';

import Typography from '@mui/material/Typography';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AppBarTitlePortal } from '../../../../common/components/atoms/AppBarTitlePortal';
import { useAsqAccess } from '../../../../common/utils/accessHelper';
import { AsqHomeFlagKeys, FeatureFlagKey } from '../../../../core/featureFlags/featureFlagKeys';
import { useFeatureFlag } from '../../../../core/featureFlags/hooks';
import { defaultFirstName } from '../../../common/profile/constants';
import AirportCodeFilter from '../atoms/AirportCodeFilter';
import NoAccessBanner from '../atoms/NoAccessBanner';
import PreReleaseBanner from '../atoms/PreReleaseBanner';
import TileGrid from '../molecules/TileGrid';

export interface HomePageProps {
  name?: string;
}

export default function HomePage({ name }: HomePageProps): ReactElement {
  const { access } = useAsqAccess();
  const { t } = useTranslation('Home');
  const homeTileGridEnabled = useFeatureFlag(AsqHomeFlagKeys);
  const asqPreReleaseBannerEnabled = useFeatureFlag(FeatureFlagKey.PreReleaseBanner);

  return (
    <>
      <AppBarTitlePortal>
        <h4>{t('ASQ.Title')}</h4>
      </AppBarTitlePortal>
      <Box mb={1} role="heading" aria-level={1}>
        <Typography variant="h2" color="primary">
          {t('Welcome', { name: name === defaultFirstName ? '' : name })}
        </Typography>
      </Box>
      {homeTileGridEnabled && !asqPreReleaseBannerEnabled && (
        <>
          {access?.hasAccess && <AirportCodeFilter />}
          {access?.hasAccess === false && <NoAccessBanner />}
          <TileGrid />
        </>
      )}
      {asqPreReleaseBannerEnabled && <PreReleaseBanner />}
    </>
  );
}
