import { ReactElement } from 'react';

import { styled } from '@mui/material';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';

import BasicCardAction, { BasicCardActionProps } from '../atoms/BasicCardAction';
import CardTag from '../atoms/CardTag';

export interface BasicCardProps {
  image: string;
  title: string;
  tag?: string;
  text: string;
  action?: BasicCardActionProps;
}

export default function BasicCard({ image, title, tag, text, action }: BasicCardProps): ReactElement {
  return (
    <>
      <Container maxWidth="md">
        <StyledPaper>
          {tag && <CardTag top={(theme) => theme.spacing(5)}>{tag}</CardTag>}
          <CenteredImage src={image} width="140" height="140" alt="logo" />
          <Typography variant="h3" color="primary" pb={3} align="center">
            {title}
          </Typography>
          <Typography variant="pMedium" pb={3}>
            <Trans>{text}</Trans>
          </Typography>
          {action && <BasicCardAction {...action} />}
        </StyledPaper>
      </Container>
    </>
  );
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 20,
  padding: theme.spacing(5),
  marginTop: theme.spacing(5),
  position: 'relative',
}));

const CenteredImage = styled('img')(({ theme }) => ({
  display: 'block',
  margin: '30px auto',
  marginBottom: theme.spacing(4),
}));
