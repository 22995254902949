import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const cacheBuster = new Date().getTime();

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: false,
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?cb=${cacheBuster}`,
    },
    react: {
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'h1', 'h2', 'h3', 'h4', 'ol', 'ul', 'li'],
    },
    interpolation: {
      escapeValue: false,
      format(value, format, lng, edit) {
        let formatParams;
        if (edit && edit.formatParams && edit.formatParams[edit.interpolationkey]) {
          formatParams = edit.formatParams[edit.interpolationkey];
        }

        if (format === 'number') {
          return new Intl.NumberFormat(lng, formatParams).format(value);
        }
        if (format === 'datetime') {
          return new Intl.DateTimeFormat(lng, formatParams).format(value);
        }
        return value;
      },
    },
  });

export default i18n;
