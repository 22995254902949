import { createContext, useContext } from 'react';

export type DrawerContextType = {
  drawerOpen: boolean;
  setDrawerOpen: (value: boolean) => void;
};

export const DrawerOpenContext = createContext<DrawerContextType>({ drawerOpen: false, setDrawerOpen: () => {} });

export const useDrawerOpen = () => useContext(DrawerOpenContext);
