import React, { useState } from 'react';

import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NavItemProps, NavItemType, NavSubItemProps } from '../../types/NavItem';
import ASQDrawerMenu from './ASQDrawerMenu';
import { FontAwesomeSvgIcon } from './FontAwesomeIcon';

const StyledDrawerLink = styled(MuiListItem)(({ theme }) => ({
  padding: theme.spacing(0, 2),
  marginBottom: theme.spacing(3),
  height: theme.spacing(5),
  '& .MuiListItemIcon-root': {
    minWidth: 40,
    justifyContent: 'center',
  },
  '& .MuiListItemButton-root': {
    padding: 0,
    height: theme.spacing(5),
    '&:hover': {
      backgroundColor: theme.palette.secondary[100],
      borderRadius: theme.spacing(1),
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.light,
      borderRadius: theme.spacing(1),
      '& .MuiTypography-root': {
        color: theme.palette.primary.main,
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
      },
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: 17,
  },
}));

export interface ASQDrawerLinkProps {
  navItem: NavItemProps;
  onClick: () => void;
  expanded: boolean;
}

function ASQDrawerLink({ navItem, expanded, onClick }: ASQDrawerLinkProps) {
  const history = useHistory();
  const { t } = useTranslation('Navigation');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const menuOpen = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLLIElement>) => {
    if (navItem.type === NavItemType.Menu) setAnchorEl(event.currentTarget);
    else if (navItem.type === NavItemType.Link) {
      onClick();
      history.push(navItem.url);
    }
  };

  const onNavSubItemClick = ({ url }: NavSubItemProps) => {
    onClick();
    history.push(url);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const selected = (navItem.type === NavItemType.Menu && menuOpen) || navItem.selected;

  return (
    <>
      <StyledDrawerLink onClick={handleClick}>
        <ListItemButton
          selected={selected}
          disableGutters
          role="link"
          data-fullstory={`LeftNav${navItem.translateKey}`}
        >
          <ListItemIcon>
            <navItem.Icon />
          </ListItemIcon>
          {expanded && (
            <>
              <ListItemText primary={<Typography variant="pMedium">{t(`${navItem.translateKey}`)}</Typography>} />
              {navItem.type === NavItemType.Menu && (
                <ListItemIcon>
                  <FontAwesomeSvgIcon icon={faChevronRight} />
                </ListItemIcon>
              )}
            </>
          )}
        </ListItemButton>
      </StyledDrawerLink>
      {navItem.type === NavItemType.Menu && (
        <ASQDrawerMenu
          open={menuOpen}
          onClose={handleCloseMenu}
          onNavSubItemClick={onNavSubItemClick}
          navSubItems={navItem.items!}
          anchorEl={anchorEl}
        />
      )}
    </>
  );
}

export default ASQDrawerLink;
