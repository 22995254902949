import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductRecurringInterval } from '../../../../../modules/DDP/pricing/enums/ProductReccuringInterval';
import { useAppSelector } from '../../../../../store';
import {
  usePurchaseOneTimePaymentItemMutation,
  usePurchaseSubscriptionItemMutation,
} from '../../../../hooks/usePricingEndpoints';
import { PurchaseDialogStep } from '../../entities/PurchaseDialogStep';
import usePaymentCard from '../../hooks/usePaymentCard';
import CongratulationsStep from '../molecules/CongratulationsStep';
import PaymentStep from '../molecules/PaymentStep';
import BasePurchaseDialog from './BasePurchaseDialog';

export default function PurchaseDialog(): ReactElement {
  const { t } = useTranslation('Pricing');
  const {
    selectedProduct,
    termsAndConditionsAccepted,
    isPurchaseDialogOpen,
    areBillingDetailsFilled,
    billingInformation,
  } = useAppSelector((state) => state.purchase);
  const [purchaseSubscriptionItem] = usePurchaseSubscriptionItemMutation();
  const [purchaseOneTimePurchaseItem] = usePurchaseOneTimePaymentItemMutation();
  const { handlePayment } = usePaymentCard();

  const steps: PurchaseDialogStep[] = [
    {
      component: PaymentStep,
      nextButtonGradient: 'contained',
      nextButtonText: t('PurchaseDialog.ConfirmPurchase'),
      validForm: areBillingDetailsFilled && termsAndConditionsAccepted,
      backButton: false,
      fullStoryName: 'Payment',
    },
    {
      component: CongratulationsStep,
      nextButtonGradient: 'gradient',
      nextButtonText: t('PurchaseDialog.AccessDashboard'),
      validForm: true,
      backButton: false,
      fullStoryName: 'Congratulations',
    },
  ];

  const save = async () => {
    const purchaseAction =
      selectedProduct.recurringInterval === ProductRecurringInterval.None
        ? purchaseOneTimePurchaseItem
        : purchaseSubscriptionItem;
    const saveInformationInSubscriptionCallback = () =>
      purchaseAction({
        priceId: selectedProduct.priceId,
        billingInformation: billingInformation!,
      }).unwrap();
    await handlePayment(saveInformationInSubscriptionCallback);
  };

  return (
    <BasePurchaseDialog
      isDialogOpen={isPurchaseDialogOpen}
      steps={steps}
      onSave={save}
      fullstoryEventPrefix="Purchase Flow"
    />
  );
}
