import { ReactElement } from 'react';

import { Route, Switch } from 'react-router-dom';
import { NotFoundPage } from '../../../../common/components/pages/NotFoundPage';
import { DepartureDashboardsRouterPath } from '../dashboards';
import { DepartureCommentsAnalysisDashboardsRouterPath } from '../dashboards/commentsAnalysis';
import { DepartureDissatisfiedPassengerDashboardsRouterPath } from '../dashboards/dissatisfiedPassenger';
import DashboardsFeature from '../molecules/Dashboards';
import DashboardsCommentsAnalysisFeature from '../molecules/DashboardsCommentsAnalysis';
import DashboardsDissatisfiedPassengerFeature from '../molecules/DashboardsDissatisfiedPassenger';

export default function DeparturePage(): ReactElement {
  return (
    <Switch>
      <Route path={DepartureDashboardsRouterPath}>
        <DashboardsFeature fallback={<NotFoundPage />} />
      </Route>
      <Route path={DepartureCommentsAnalysisDashboardsRouterPath}>
        <DashboardsCommentsAnalysisFeature fallback={<NotFoundPage />} />
      </Route>
      <Route path={DepartureDissatisfiedPassengerDashboardsRouterPath}>
        <DashboardsDissatisfiedPassengerFeature fallback={<NotFoundPage />} />
      </Route>
    </Switch>
  );
}
