import { ReactElement } from 'react';

import { Theme } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export interface RadioCardProps {
  label: string;
  value: string;
  description: string;
  selected: boolean;
  datafullstory: string;
  onClick?: (value: string) => void;
}

export default function RadioCard(props: RadioCardProps): ReactElement {
  const { selected, value, label, description, onClick, datafullstory } = props;

  const onContainerClick = () => {
    if (onClick) onClick(value);
  };

  return (
    <StyledCard onClick={onContainerClick} selected={selected} data-fullstory={datafullstory}>
      <CardContent sx={{ padding: (theme) => theme.spacing(2) }}>
        <FormControlLabel
          value={value}
          control={<Radio />}
          label={
            <StyledLabel variant="pMedium" fontWeight="bold" selected={selected}>
              {label}
            </StyledLabel>
          }
        />
        <Typography variant="pSmall">{description}</Typography>
      </CardContent>
    </StyledCard>
  );
}

const normalMixin = {
  border: '1px solid #77797E',
  backgroundColor: 'transparent',
};

const selectedMixin = (theme: Theme) => ({
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  backgroundColor: '#DDE9F4',
});

const selectedLabel = (theme: Theme) => ({
  color: theme.palette.primary.main,
});

const StyledCard = styled(Card)<{ selected: boolean }>(({ selected, theme }) => ({
  ...(selected && selectedMixin(theme)),
  ...(!selected && normalMixin),
  cursor: 'pointer',
  boxShadow: 'none',
  borderRadius: 0,
}));

const StyledLabel = styled(Typography)<{ selected: boolean }>(({ selected, theme }) => ({
  ...(selected && selectedLabel(theme)),
}));
