import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import TermsOfSale from '../../termsOfSale/pages/TermsOfSale';
import TermsOfUse from '../../termsOfUse/pages/TermsOfUse';
import { TermsAnchor } from '../models/TermsAnchor';

const TermsWrapper = () => {
  const { t } = useTranslation('TermsAndConditions');
  const [termsOfUseExpanded, setTermsOfUseExpanded] = useState(true);
  const [termsOfSaleExpanded, setTermsOfSaleExpanded] = useState(false);

  const { pathname, hash, key } = useLocation();

  // source : https://stackoverflow.com/a/61311926
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          if (hash === TermsAnchor.TermsOfUse && !termsOfUseExpanded) setTermsOfUseExpanded(true);
          if (hash === TermsAnchor.TermsOfSale && !termsOfSaleExpanded) setTermsOfSaleExpanded(true);
          element.scrollIntoView();
        }
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, hash, key]); // Important not to put expanded states here as it will always expand them back

  return (
    <>
      <Accordion
        id="terms-of-use"
        expanded={termsOfUseExpanded}
        onChange={() => setTermsOfUseExpanded(!termsOfUseExpanded)}
      >
        <AccordionSummary expandIcon={<FontAwesomeSvgIcon icon={faAngleDown} />}>
          <Typography variant="h5">{t('TermsOfUse.Title')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TermsOfUse asSection />
        </AccordionDetails>
      </Accordion>
      <Accordion
        id="terms-of-sale"
        expanded={termsOfSaleExpanded}
        onChange={() => setTermsOfSaleExpanded(!termsOfSaleExpanded)}
      >
        <AccordionSummary expandIcon={<FontAwesomeSvgIcon icon={faAngleDown} />}>
          <Typography variant="h5">{t('TermsOfSale.Title')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TermsOfSale asSection />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default TermsWrapper;
