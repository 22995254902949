import { models, Page, Report, VisualDescriptor } from 'powerbi-client';
import { AsqPeriod } from '../../core/enums/asqPeriod.enum';

interface SlicerName {
  name: string;
}
export interface SlicerInfos extends models.IColumnTarget, SlicerName {}

export interface HierarchySlicerInfos extends models.IHierarchyLevelTarget, SlicerName {}
export interface HierachyValues<
  KeyType extends models.PrimitiveValueType,
  ValuesType extends models.PrimitiveValueType,
> {
  key: KeyType;
  values: ValuesType[];
}

export function generateSlicerState({ table, column }: SlicerInfos, ...values: string[]): models.ISlicerState {
  const target = {
    table,
    column,
  };

  if (values.length > 0) {
    return {
      filters: [
        {
          $schema: 'http://powerbi.com/product/schema#basic',
          filterType: models.FilterType.Basic,
          operator: 'In',
          target,
          values,
          requireSingleSelection: false,
        },
      ],
      targets: [target],
    };
  }

  return {
    filters: [],
    targets: [target],
  };
}

export function generateHierarchySlicerState<
  KeyType extends models.PrimitiveValueType,
  ValuesType extends models.PrimitiveValueType,
>(
  hierarchySlicersInfos: HierarchySlicerInfos[],
  selectedValues: HierachyValues<KeyType, ValuesType>[],
): models.ISlicerState {
  const target = hierarchySlicersInfos.map((elem) => ({
    table: elem.table,
    hierarchy: elem.hierarchy,
    hierarchyLevel: elem.hierarchyLevel,
  }));

  const hierarchyData: models.IHierarchyFilterNode[] = selectedValues.map((elem) => ({
    operator: 'Inherited',
    value: elem.key,
    children: elem.values.map((value) => ({
      operator: 'Selected',
      value,
    })),
  }));

  return {
    filters: [
      {
        $schema: 'http://powerbi.com/product/schema#hierarchy',
        filterType: models.FilterType.Hierarchy,
        target,
        hierarchyData,
      },
    ],
  };
}

export async function getSlicerStateString(slicer: VisualDescriptor): Promise<string | null> {
  try {
    const state = await slicer.getSlicerState();
    const basicFilter = state.filters[0] as models.IBasicFilter | undefined;
    const value = basicFilter?.values[0];

    if (typeof value === 'string') return value;
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getVisuals(report: Report): Promise<VisualDescriptor[]> {
  try {
    const pages = await report.getPages();
    const currentPage = pages.find((page: Page) => page.isActive);

    if (currentPage === undefined) return [];
    return await currentPage.getVisuals();
  } catch (error) {
    console.error(error);
    return [];
  }
}

export async function getSlicers(report: Report): Promise<VisualDescriptor[]> {
  const visuals = await getVisuals(report);
  return visuals.filter((visual) => visual.type === 'slicer');
}

export function getSlicerFromVisuals(visuals: VisualDescriptor[], slicerName: string): VisualDescriptor | undefined {
  return visuals.find((visual) => visual.name === slicerName);
}

export async function getSlicerSelectedValues(report: Report, slicerName: string): Promise<string[]> {
  const visuals = await getVisuals(report);
  const slicer = getSlicerFromVisuals(visuals, slicerName);
  if (slicer) {
    const slicerState = await slicer?.getSlicerState();
    if (slicerState?.filters.length === 0) return [];
    return (slicerState?.filters[0] as models.IBasicFilter).values.map((x) => x.toString());
  }

  console.error(`The slicer '${slicerName}' was not found in the slicers of this page`);
  return [];
}

export async function getPeriodHierarchySlicerSelectedValues(
  report: Report,
  slicerName: string,
): Promise<HierachyValues<string, string>[]> {
  const visuals = await getVisuals(report);
  const slicer = getSlicerFromVisuals(visuals, slicerName);
  if (slicer) {
    const slicerState = await slicer?.getSlicerState();
    if (slicerState?.filters.length === 0) return [];
    return ExtractKeyValuesFromHierarchicSlicer(slicerState);
  }

  console.error(`The slicer '${slicerName}' was not found in the slicers of this page`);
  return [];
}

export function ExtractKeyValuesFromHierarchicSlicer(slicerState: models.ISlicerState) {
  const { hierarchyData } = slicerState?.filters[0] as models.IHierarchyFilter;
  return hierarchyData
    .filter((x) => x.value && (x.operator === 'Selected' || x.operator === 'Inherited'))
    .map((x) => {
      const key = x.value!.toString();
      const quarters = Object.values(AsqPeriod)
        .filter((val) => typeof val === 'string' && val.startsWith('Q'))
        .map((val) => val.toString());
      if (x.children) {
        const notSelectedValues = x.children
          .filter((child) => child.operator === 'NotSelected')
          .map((child) => child.value!.toString());
        if (notSelectedValues.length > 0) {
          const selectedQuarters = quarters.filter((quarter) => !notSelectedValues.includes(quarter.toString()));
          return { key, values: selectedQuarters };
        }
        return { key, values: x.children.map((child) => child.value!.toString()) };
      }
      return { key, values: quarters };
    });
}

export async function setSlicerValue(report: Report, slicerInfos: SlicerInfos, ...values: string[]): Promise<void> {
  try {
    const visuals = await getVisuals(report);
    const slicer = getSlicerFromVisuals(visuals, slicerInfos.name);
    if (slicer !== undefined) {
      const slicerState = generateSlicerState(slicerInfos, ...values);
      await slicer.setSlicerState(slicerState);
    } else {
      console.error(`The slicer '${slicerInfos.name}' was not found in the slicers of this page`);
    }
  } catch (error) {
    console.error(error);
  }
}

export async function setHierarchySlicerValue<
  KeyType extends models.PrimitiveValueType,
  ValuesType extends models.PrimitiveValueType,
>(
  report: Report,
  hierarchySlicersInfos: HierarchySlicerInfos[],
  selectedValues: HierachyValues<KeyType, ValuesType>[],
): Promise<void> {
  try {
    const visuals = await getVisuals(report);
    const slicer = getSlicerFromVisuals(visuals, hierarchySlicersInfos[0].name);

    if (slicer !== undefined) {
      const slicerState = generateHierarchySlicerState(hierarchySlicersInfos, selectedValues);
      await slicer.setSlicerState(slicerState);
    } else {
      console.error(`The slicer '${hierarchySlicersInfos[0].name}' was not found in the slicers of this page`);
    }
  } catch (error) {
    console.error(error);
  }
}
