export enum Module {
  Executive = 'executive',
  Library = 'library',
  Analyst = 'analyst',
  Benchmark = 'benchmark',
  Departure = 'departure',
  Arrival = 'arrival',
  Commercial = 'commercial',
  Training = 'training',
}
