/* eslint-disable no-case-declarations */
import { produce } from 'immer';

import BaseFile from '../../entities/BaseFile';
import { ActionType, FileFilterSystemAction, SetFilesActionPayload, SetFilterValuesActionPayload } from './actions';
import { FileFilterSystemHelper } from './helper';
import { FileFilterSystemState } from './types';

export type FileFilterSystemReducerType<File extends BaseFile> = {
  (state: FileFilterSystemState<File>, { type, payload }: FileFilterSystemAction<File>): FileFilterSystemState<File>;
};

export function reducer<File extends BaseFile>(
  state: FileFilterSystemState<File>,
  { type, payload }: FileFilterSystemAction<File>,
): FileFilterSystemState<File> {
  return produce(state, (draft: FileFilterSystemState<File>) => {
    switch (type) {
      case ActionType.SetFiles:
        const files = payload as SetFilesActionPayload<File>;
        FileFilterSystemHelper.setFiles(draft, files);
        break;
      case ActionType.SetFilterValues:
        const { filterId, values } = payload as SetFilterValuesActionPayload;
        FileFilterSystemHelper.setFilterValues(draft, filterId, values);
        break;
      case ActionType.ResetFilters:
        FileFilterSystemHelper.resetFilters(draft);
        break;
      default:
        break;
    }
  });
}
