import { ReactElement } from 'react';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PeriodType from '../enums/periodType.enum';

interface PeriodTypeChipProps {
  children: PeriodType;
}

export default function PeriodTypeChip({ children }: PeriodTypeChipProps): ReactElement {
  const { t } = useTranslation('Analyst');

  switch (children) {
    case PeriodType.Annually:
      return (
        <>
          <StyledTypography variant="pXSmall" borderColor="primary.main" color="primary.main" px={2} py={0.5}>
            {t(`Chips.${PeriodType[children]}`)}
          </StyledTypography>
        </>
      );
    case PeriodType.Monthly:
      return (
        <>
          <StyledTypography variant="pXSmall" borderColor="primary.main" color="primary.main" px={2} py={0.5}>
            {t(`Chips.${PeriodType[children]}`)}
          </StyledTypography>
        </>
      );
    default:
      return <></>;
  }
}

const StyledTypography = styled(Typography)(() => ({
  border: '1px solid',
  textTransform: 'uppercase',
  fontWeight: 'bold',
}));
