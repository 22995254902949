import { TFunction } from 'i18next';

import { HeaderDef, HeaderType } from '../../../../../../common/components/types/TableTypes';
import { AirportFile } from '../../../entities/AirportFile';
import {
  airportCodeHeaderComparer,
  categoryHeaderComparer,
  moduleHeaderComparer,
  periodHeaderComparer,
  yearHeaderComparer,
} from './comparer';

const Sizes = {
  XS: 0.2,
  S: 0.3,
  M: 0.5,
  L: 0.7,
};

export function getHeaders(t: TFunction): HeaderDef<AirportFile>[] {
  return [
    {
      id: 'checkbox',
      flexWidth: Sizes.XS,
      type: HeaderType.Select,
    },
    {
      id: 'airportCode',
      key: 'airportCode',
      label: t('FileExplorer.Headers.Airport'),
      rowsColor: 'primary',
      flexWidth: Sizes.S,
      comparer: airportCodeHeaderComparer,
      type: HeaderType.Data,
    },
    {
      id: 'period',
      key: 'period',
      label: t('FileExplorer.Headers.Period'),
      rowsColor: 'primary',
      flexWidth: Sizes.S,
      comparer: periodHeaderComparer,
      type: HeaderType.Data,
    },
    {
      id: 'year',
      key: 'year',
      label: t('FileExplorer.Headers.Year'),
      rowsColor: 'primary',
      flexWidth: Sizes.S,
      comparer: yearHeaderComparer,
      type: HeaderType.Data,
    },
    {
      id: 'module',
      key: 'module',
      label: t('FileExplorer.Headers.Module'),
      rowsColor: 'primary',
      flexWidth: Sizes.M,
      comparer: moduleHeaderComparer,
      type: HeaderType.Data,
    },
    {
      id: 'category',
      key: 'category',
      label: t('FileExplorer.Headers.Category'),
      rowsColor: 'primary',
      flexWidth: Sizes.L,
      comparer: categoryHeaderComparer,
      type: HeaderType.Data,
    },
    {
      id: 'actions',
      label: t('FileExplorer.Headers.Actions'),
      flexWidth: Sizes.M,
      maxActions: 2,
      type: HeaderType.Action,
    },
  ];
}
