import { useCallback, useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';

import { useTranslation } from 'react-i18next';

import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import AutoComplete, { AutoCompleteOption } from '../../../../common/components/atoms/AutoComplete';
import { FontAwesomeSvgIcon } from '../../../../common/components/atoms/FontAwesomeIcon';
import { useRegionFilterQuery } from '../../../../common/hooks/backend';
import RegionDto from '../entities/RegionDto';

interface RegionFiltersProps {
  value: string[];
  onValueChange: (newValue: string[]) => void;
}

export default function RegionFilters(props: RegionFiltersProps) {
  const { value, onValueChange } = props;

  const { data: filters = [], isLoading } = useRegionFilterQuery();

  const { t } = useTranslation('Analyst');

  const [selectedOptions, setSelectedOptions] = useState<AutoCompleteOption[]>([]);

  const changeSelectedOptions = (newvalue: AutoCompleteOption[]) => {
    setSelectedOptions(newvalue);
    onValueChange([
      ...value.filter(
        (val) =>
          !filters.filter((region) => region.parentRegionCode === null).find((filter) => val === filter.regionCode),
      ),
      ...newvalue.map((option) => option.key),
    ]);
  };

  const toAutoCompleteOptions = useCallback(
    (regions: RegionDto[]) =>
      regions
        .filter((region) => region.parentRegionCode === null)
        .map((region) => ({
          label: region.regionName,
          key: region.regionCode,
        })),
    [],
  );

  useEffect(() => {
    if (filters.length > 0) {
      setSelectedOptions(toAutoCompleteOptions(filters.filter((filter) => value.includes(filter.regionCode))));
    }
  }, [filters, toAutoCompleteOptions, value]);

  return (
    <Grid item width="20%">
      <AutoComplete
        loading={isLoading}
        options={toAutoCompleteOptions(filters)}
        width="100%"
        label={t('Region', { count: 2 })}
        value={selectedOptions}
        onChange={changeSelectedOptions}
        fullStoryTag="AnalystAggregationLocationRegion"
        icon={<FontAwesomeSvgIcon icon={faAngleDown} />}
      />
    </Grid>
  );
}
