import { Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Trans, useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../store';
import DialogCloseButton from '../../../../components/atoms/DialogCloseButton';
import { ModuleKey } from '../../enums/moduleKey';
import { closeDialog } from '../../purchaseSlice';

export interface BaseNoAccessDialogProps {
  moduleKey: ModuleKey;
  handleAccept: () => void;
  fullStory: string;
  isOpen: boolean;
  buttonText: string;
}

export default function BaseNoAccessDialog({
  moduleKey,
  handleAccept,
  fullStory,
  isOpen,
  buttonText,
}: BaseNoAccessDialogProps) {
  const { t } = useTranslation(['Pricing', 'Modules']);
  const dispatch = useAppDispatch();

  let moduleText: string;

  switch (moduleKey) {
    case ModuleKey.Analyst:
      moduleText = t('Modules:Names.Analyst');
      break;
    case ModuleKey.Executive:
      moduleText = t('Modules:Names.Executive');
      break;
    case ModuleKey.Library:
      moduleText = t('Modules:Names.Library');
      break;
    case ModuleKey.AnalystHistorical:
      moduleText = t('Modules:Names.AnalystHistoricalDataset');
      break;
    default:
      moduleText = t('NoAccessDialog.Module');
  }

  const handleClose = () => {
    dispatch(closeDialog());
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ width: 480 }}>
        <DialogCloseButton closeDialog={handleClose} data-fullstory={`${fullStory}Close`} />
        <Typography variant="h6" fontWeight="normal" textAlign="center" marginTop={6} marginX={3}>
          {t('NoAccessDialog.AccessDoesNotIncludeThisFeature', { module: moduleText })}
          {moduleKey === ModuleKey.Library && <Trans t={t}>NoAccessDialog.ExploreToDownload</Trans>}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ paddingBottom: 6, marginX: 'auto' }}>
        <Button variant="gradient" onClick={handleAccept} data-fullstory={`${fullStory}Accept`}>
          {buttonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
