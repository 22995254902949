import { useAppSelector } from '../../../../store';

const useCustomerPortal = () => {
  const { email } = useAppSelector((state) => state.profile);

  return {
    customerPortalUrl: `${process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}?prefilled_email=${email}`,
  };
};

export default useCustomerPortal;
